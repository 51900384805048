import React from 'react';
import PropTypes from 'prop-types';

import RichText from '../ui/RichText';
import Text from '../ui/Text';
import { AMEND_KEYS } from '../../config/amendRestrictions';
import { dictionaryItem } from '../../hocs/withDictionary';

const AmendRestriction = ({ id }) => (
  <>
    <Text
      align="center"
      dictionary={dictionaryItem(
        'Amend',
        'NotAllowed',
        AMEND_KEYS[id] || '',
        'Description',
      ).replace('____', '__')} // If dictionary key is blank
    />
    <Text dictionary={dictionaryItem('CamcCallCentrePhoneNumber')} weight="bold" marginBottom="2rem" />
    <RichText
      dictionary={dictionaryItem(
        'Amend',
        'NotAllowed',
      )}
      flexList
    />
  </>
);

AmendRestriction.propTypes = {
  id: PropTypes.number.isRequired,
};

export default AmendRestriction;

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Title from '../../ui/Title';
import RichText from '../../ui/RichText';
import PopOver from '../../PopOver';
import svgInfo from '../../../static/images/icons/Info.svg';

import ButtonInfo from '../../ui/Button/ButtonInfo.style';
import {
  TitleContainer, ToolTipTextContainer, ToolTipText,
} from '../../Availability/AvailabilityAddGuests.style';

function CampingGuestHeader({
  title,
  tooltipText,
  tooltipContent,
  children,
}) {
  const [open, setOpen] = useState(false);
  const popOverRef = useRef(null);

  return (
    <PopOver
      closeOnClick
      onClose={() => setOpen(false)}
      open={open}
      popOverRef={popOverRef}
      renderContent={() => (
        <>
          <RichText
            dictionary={tooltipContent}
            compact
          />
        </>
      )}
      renderReference={({ innerRef }) => (
        <>
          <TitleContainer>
            <Title
              marginBottom
              tag={1}
              size={4}
              dictionary={title}
            />
            <ToolTipTextContainer>
              <ToolTipText
                dictionary={tooltipText}
                onClick={() => setOpen(!open)}
              />
              <ButtonInfo
                ariaLabel="Information"
                buttonStyle={{ visibility: open ? 'hidden' : 'visible' }}
                disabled={false}
                icon={svgInfo}
                marginTop="0.2rem"
                marginLeft="0.2rem"
                noPadding
                onClick={() => setOpen(!open)}
                outerRef={innerRef}
                type="button"
              />
            </ToolTipTextContainer>
          </TitleContainer>
          {children}
        </>
      )}
    />
  );
}

CampingGuestHeader.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  tooltipContent: PropTypes.string.isRequired,
  children: PropTypes.node,
};

CampingGuestHeader.defaultProps = {
  children: undefined,
};

export default CampingGuestHeader;

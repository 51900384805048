import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  format,
  isAfter,
  isBefore,
  isValid,
  subDays,
  startOfMonth,
} from 'date-fns';

import { dictionaryItem } from '../../hocs/withDictionary';

import AvailabilityPicker from './AvailabilityPicker';
import Title from '../ui/Title';
import { Label, Select } from '../ui/Form';
import { Row, Col } from '../ui/Grid';
import { MessageWarning } from '../ui/Message';

import withDatepicker from '../ui/Datepicker/hocs/withDatepicker';
import { DateInputWrapper, SearchInput } from './AvailabilityDuration.style';

import svgCalendar from '../../static/images/icons/Calendar.svg';

import { DATE_FORMAT_INPUT } from '../../config/locale';
import { formatToHyphenFormat } from '../../lib/format';

import { Header } from './Availability.style';
import IbePropTypes from '../../IbePropTypes';

class AvailabilityDuration extends PureComponent {
  static propTypes = {
    availabilitySearchLoading: PropTypes.bool,
    dataError: PropTypes.bool.isRequired,
    datepicker: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    endDate: PropTypes.string,
    fetchAvailability: PropTypes.func.isRequired,
    fieldFocus: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    handleDatepickerChange: PropTypes.func.isRequired,
    handleFieldFocus: PropTypes.func.isRequired,
    handlePitchChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    memberBookableDate: PropTypes.string.isRequired,
    memberPricesAreSelected: PropTypes.bool.isRequired,
    nonMemberBookableDate: PropTypes.string.isRequired,
    notAvailableText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onGoToMemberPrices: PropTypes.func.isRequired,
    onRequestText: PropTypes.string,
    pitches: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.pitch)),
    pitchType: PropTypes.shape(),
    siteCode: PropTypes.string,
    startDate: PropTypes.string,
    selectedEvent: PropTypes.shape(IbePropTypes.event),
    selectedPitch: PropTypes.shape({
      id: PropTypes.string,
      restrictions: PropTypes.arrayOf(PropTypes.string),
    }),
    userIsMember: PropTypes.bool.isRequired,
    onCancelMemberPrices: PropTypes.func.isRequired,
    handleClear: PropTypes.func.isRequired,
  }

  static defaultProps = {
    availabilitySearchLoading: false,
    loading: false,
    fieldFocus: '',
    notAvailableText: '',
    onRequestText: '',
    pitches: [],
    pitchType: null,
    siteCode: null,
    selectedEvent: {},
    selectedPitch: {},
    startDate: null,
    endDate: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      startDate: this.props.startDate ? format(this.props.startDate, DATE_FORMAT_INPUT) : '',
      endDate: this.props.endDate ? format(this.props.endDate, DATE_FORMAT_INPUT) : '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.startDate !== this.props.startDate
      && format(this.props.startDate, DATE_FORMAT_INPUT) !== prevState.startDate) {
      this.updateDateState('startDate', this.props.startDate || '');
    }

    if (
      prevProps.endDate !== this.props.endDate
      && format(this.props.endDate, DATE_FORMAT_INPUT) !== prevState.endDate) {
      this.updateDateState('endDate', this.props.endDate || '');
    }
  }

  updateDateState = (name, value) => {
    const date = format(value, DATE_FORMAT_INPUT);
    this.setState({ [name]: date === 'Invalid Date' ? '' : date });
  }

  isValidDate = date => date.length === 10 && isValid(new Date(formatToHyphenFormat(date)));

  handleInputChange = ({ target: { name, value } }) => {
    const {
      handleChange,
      handleFieldFocus,
      fetchAvailability,
    } = this.props;

    const start = formatToHyphenFormat(this.state.startDate || this.props.startDate);
    const end = formatToHyphenFormat(this.state.endDate || this.props.endDate);
    const dateDurationArray = [start, end];

    this.setState({ [name]: value }, () => {
      if (this.isValidDate(value)) {
        dateDurationArray[name === 'startDate' ? 0 : 1] = formatToHyphenFormat(value);
        const [startDate, endDate] = dateDurationArray;
        let monthChanged = false;
        let startOfCalendarMonth;

        if (name === 'startDate') {
          const previousMonth = start.split('-')[1];
          const newMonth = startDate.split('-')[1];

          monthChanged = previousMonth !== newMonth;
          startOfCalendarMonth = format(startOfMonth(new Date(startDate)), 'YYYY-MM-DD');

          if (isAfter(startDate, endDate)) {
            dateDurationArray[1] = null;

            const endDateEl = document.getElementById('depart');
            if (endDateEl) {
              setTimeout(() => {
                handleFieldFocus('endDate');
                endDateEl.focus();
              }, 0);
            }
          }
        }

        if (name === 'endDate') {
          const previousMonth = end.split('-')[1];
          const newMonth = endDate.split('-')[1];

          monthChanged = previousMonth !== newMonth;
          startOfCalendarMonth = format(startOfMonth(new Date(endDate)), 'YYYY-MM-DD');

          if (isBefore(endDate, startDate)) {
            dateDurationArray[0] = format(subDays(new Date(endDate), 1), 'YYYY-MM-DD');

            const startDateEl = document.getElementById('arrive');
            if (startDateEl) {
              setTimeout(() => {
                handleFieldFocus('startDate');
                startDateEl.focus();
              }, 0);
            }
          }
        }

        if (monthChanged) {
          fetchAvailability({ startOfCalendarMonth });
        }

        handleChange(dateDurationArray);
      }
    });
  }

  handleCancelMemberPrices = () => {
    this.props.onCancelMemberPrices();
    this.props.handleClear();
  }

  handlePitchTypeChange = (value) => {
    const selectedPitchType = this.props.pitches.find(pitch => pitch.id === value);
    this.props.handlePitchChange(selectedPitchType);
  };

  render() {
    const {
      availabilitySearchLoading,
      datepicker,
      disabled,
      endDate,
      error,
      fetchAvailability,
      fieldFocus,
      handleChange,
      handleDatepickerChange,
      loading,
      memberBookableDate,
      memberPricesAreSelected,
      nonMemberBookableDate,
      notAvailableText,
      onGoToMemberPrices,
      onRequestText,
      pitches,
      selectedEvent,
      selectedPitch,
      startDate,
      userIsMember,
    } = this.props;

    const pickerProps = {
      datepicker,
      handleChange,
      handleDatepickerChange,
      startDate: this.isValidDate(this.state.startDate)
        ? formatToHyphenFormat(this.state.startDate)
        : startDate,
      endDate: this.isValidDate(this.state.endDate)
        ? formatToHyphenFormat(this.state.endDate)
        : endDate,
    };

    return (
      <>
        <Header>
          <Title dictionary={dictionaryItem('AvailabilityDuration')} tag={1} size={4} />
        </Header>
        {pitches && pitches.length > 0 && (
          <Row>
            <Col>
              <Label
                htmlFor="pitchTypeSelector"
                dictionary={dictionaryItem('AvailabilityDuration', 'PitchType')}
              />
              <Select
                block
                id="pitchTypeSelector"
                onChange={(e) => this.handlePitchTypeChange(e.target.value)}
                value={selectedPitch.id}
              >
                {pitches.map(pitchType => (
                  <option key={pitchType.id} value={pitchType.id}>{pitchType.name}</option>
                ))}
              </Select>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Label htmlFor="arrive" dictionary={dictionaryItem('AvailabilityDuration', 'Arrive')} />
            <DateInputWrapper>
              <SearchInput
                block
                icon={svgCalendar}
                id="arrive"
                name="startDate"
                onClick={e => this.props.handleFieldFocus(e.target.name)}
                onChange={this.handleInputChange}
                placeholder={DATE_FORMAT_INPUT}
                value={this.state.startDate}
                error={this.props.dataError}
              />
            </DateInputWrapper>
          </Col>
          <Col>
            <Label htmlFor="depart" dictionary={dictionaryItem('AvailabilityDuration', 'Depart')} />
            <DateInputWrapper>
              <SearchInput
                block
                icon={svgCalendar}
                id="depart"
                name="endDate"
                onClick={e => this.props.handleFieldFocus(e.target.name)}
                onChange={this.handleInputChange}
                placeholder={DATE_FORMAT_INPUT}
                value={this.state.endDate}
                error={this.props.dataError}
              />
            </DateInputWrapper>
          </Col>
        </Row>

        <AvailabilityPicker
          {...pickerProps}
          availabilitySearchLoading={availabilitySearchLoading}
          disabled={disabled}
          fullscreen={false}
          error={error}
          fetchAvailability={fetchAvailability}
          fieldFocus={fieldFocus}
          loading={loading}
          memberBookableDate={memberBookableDate}
          memberPricesAreSelected={memberPricesAreSelected}
          nonMemberBookableDate={nonMemberBookableDate}
          notAvailableText={notAvailableText}
          onGoToMemberPrices={onGoToMemberPrices}
          onRequestText={onRequestText}
          selectedEvent={selectedEvent}
          selectedPitch={selectedPitch}
          userIsMember={userIsMember}
          onCancelMemberPrices={this.handleCancelMemberPrices}
        />

        {selectedPitch.restrictions &&
          selectedPitch.restrictions.map(restriction => (
            <MessageWarning
              marginBottom
              key={restriction}
            >
              {restriction}
            </MessageWarning>
          ))
        }
      </>
    );
  }
}

export default withDatepicker(AvailabilityDuration);

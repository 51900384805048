import React from 'react';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';

import { isTabletOrSmaller } from '../../../lib/helpers/layout';

import { Icon } from '../../ui';

import { CloseButton, Wrapper } from '../PopOver.style';

import svgClose from '../../../static/images/icons/Close.svg';

function PopOverWelcome({
  children,
  onClose,
  open,
  renderContent,
  renderReference,
}) {
  const customPlacement = isTabletOrSmaller() ? 'top' : 'right-start';

  const modifiers = {
    flip: { enabled: false },
    preventOverflow: { enabled: false },
    hide: { enabled: false },
  };

  const render = renderReference || children;

  const size = 'large';
  const variant = 'light';

  return (
    <Manager>
      <>
        {open && (
          <Popper modifiers={modifiers} placement={customPlacement}>
            {({ ref, arrowProps, ...contentProps }) => {
              const wrapperProps = !isTabletOrSmaller() ? {
                ref,
                ...contentProps,
                style: {
                  ...contentProps.style,
                  marginTop: '1rem',
                  marginLeft: 'calc(2rem + 48px)',
                  opacity: 1,
                },
              } : {};

              return (
                <Wrapper
                  size={size}
                  variant={variant}
                  {...wrapperProps}
                  welcomePopover
                >
                  <CloseButton
                    ariaLabel="Close"
                    onClick={onClose}
                    type="button"
                  >
                    <Icon
                      icon={svgClose}
                    />
                  </CloseButton>

                  {renderContent({ ...contentProps })}
                </Wrapper>
              );
            }}
          </Popper>
        )}
      </>

      {render && (
        <Reference>
          {({ ref }) => render({ innerRef: ref })}
        </Reference>
      )}
    </Manager>
  );
}

PopOverWelcome.propTypes = {
  children: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  renderContent: PropTypes.func.isRequired,
  renderReference: PropTypes.func,
  size: PropTypes.string,
  theme: PropTypes.shape({
    COLOR_POPOVER_DESKTOP: PropTypes.string,
  }),
  variant: PropTypes.string,
};

PopOverWelcome.defaultProps = {
  children: null,
  onClose() {},
  renderReference: null,
  size: 'medium',
  theme: {
    COLOR_POPOVER_DESKTOP: '',
  },
  variant: 'dark',
};

export default PopOverWelcome;

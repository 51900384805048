import React from 'react';
import PropTypes from 'prop-types';

import testingAttr from '../../lib/testingAttr';

import Icon from '../ui/Icon';

import StyledRating, { Reviews } from './Rating.style';

import starSVG from '../../static/images/icons/StarFull.svg';

import theme from '../../styles/config/theme';

const defaultProps = {
  maxNoOfStars: 5,
  noOfReviews: null,
  size: '1rem',
  style: null,
  className: '',
};

const propTypes = {
  maxNoOfStars: PropTypes.number,
  noOfReviews: PropTypes.number,
  rating: PropTypes.number.isRequired,
  size: PropTypes.string,
  style: PropTypes.shape(),
  className: PropTypes.string,
};

function Rating(props) {
  const stars = [...Array(props.maxNoOfStars).keys()];

  const activeStars = stars.map(Number.call, Number).map(n => n < props.rating);

  const hasReviews = props.noOfReviews > 0;

  return (
    <StyledRating {...props}>
      {hasReviews && <Reviews {...testingAttr('rating__reviews')}>{props.noOfReviews}</Reviews>}

      {!!activeStars.length && activeStars.map((star, index) => (
        <Icon
          color={(star ? theme.COLOR_AMBER_ORANGE : theme.COLOR_GRAY_LIGHT)}
          icon={starSVG}
          key={stars[index]}
          size={props.size}
          style={{ marginLeft: '0.125em' }}
          className={props.className}
          testingId={`rating__star-${index}`}
        />
      ))}
    </StyledRating>
  );
}

Rating.defaultProps = defaultProps;
Rating.propTypes = propTypes;

export default Rating;

const FEATURE_TYPES = {
  UNKNOWN: 0,
  GALLERY: 1,
  FACILITIES_ON_SITE: 2,
  IDEAL_FOR: 3,
  THINGS_TO_DO_ON_SITE: 4,
  NEARBY: 5,
  ON_YOUR_DOORSTEP: 6,
  WHY_STAY_HERE: 7,
  DISABLED: 8,
  TOP_TEN: 9,
  ON_SITE_FILTERABLE: 10,
  NEARBY_FILTERABLE: 11,
  DISABLED_FILTERABLE: 12,
};

export const FACILITY_TYPES = [
  FEATURE_TYPES.FACILITIES_ON_SITE,
  FEATURE_TYPES.IDEAL_FOR,
  FEATURE_TYPES.THINGS_TO_DO_ON_SITE,
  FEATURE_TYPES.NEARBY,
];

export default FEATURE_TYPES;

/* eslint-disable */
export class Helper {
  amountOfNights = 0;
  amountOfSiteNightVouchers = 0;
  hasInboundCrossing = false;

  getAmountOfSiteNightVouchers(extras) {
    if (!extras) return 0;
    if (!extras.length) return 0;

    const numberOfSnvs = extras.map(obj => obj.quantity).reduce((a, b) => {
      return a + (b || 0)
    }, 0);

    return numberOfSnvs;
  }
}
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

import {
  Wrapper,
  Aside,
  ComparisonBadges,
  Row,
  Label,
  Badge,
} from './ComparisonModalBtn.style';

import queryHelper from '../../lib/queryHelper';
import {
  standardPropTypes,
  standardDefaultProps,
} from '../../lib/standardProptypes';

import GET_CAMPSITE_COMPARISON from './graphql/getCampsiteComparison';
import GET_COMPARE_MODULE_STATE from './graphql/getCompareModuleState';
import UPDATE_COMPARE_MODULE_STATE from './graphql/updateCompareModuleState';
import { dictionaryItem } from '../../hocs/withDictionary';

const propTypes = {
  updateCompareModuleState: PropTypes.func.isRequired,
  compareModuleState: PropTypes.shape({
    active: PropTypes.bool,
  }),
  ...standardPropTypes,
};

const defaultProps = {
  compareModuleState: {
    active: false,
  },
  ...standardDefaultProps,
};

function ComparisonModalBtn(props) {
  return (
    <Wrapper
      onClick={() => props.updateCompareModuleState(!props.compareModuleState.active)}
      id="mapComparisonControl"
    >
      <Aside>
        <Label
          dictionary={dictionaryItem('ComparisonModalBtn')}
        />
      </Aside>
      <ComparisonBadges>
        <Row>
          <Label
            dictionary={dictionaryItem('ComparisonModalBtn', 'Sites')}
          />
          <Badge>{props.comparisonCampsites.campsites.length}</Badge>
        </Row>
      </ComparisonBadges>
    </Wrapper>
  );
}

ComparisonModalBtn.propTypes = propTypes;
ComparisonModalBtn.defaultProps = defaultProps;

export default compose(
  memo,
  queryHelper(GET_CAMPSITE_COMPARISON, 'comparisonCampsites'),
  queryHelper(GET_COMPARE_MODULE_STATE, 'compareModuleState'),
  graphql(UPDATE_COMPARE_MODULE_STATE, {
    props: ({ mutate }) => ({
      updateCompareModuleState: active => mutate({ variables: { active } }),
    }),
  }),
)(ComparisonModalBtn);

import styled from 'styled-components';

const MAX_WIDTH = 420;

export const PickerHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  top: 0;

  @media (max-width: 768px) {
    border-bottom: 1px solid #dddddd;
  }
`;

export const PickerHeaderBody = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 1rem 0;
  width: 100%;
  max-width: ${MAX_WIDTH};

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const DayOfTheWeek = styled.div`
  flex: 1;
  font-size: 0.75rem;
  text-align: center;
  font-weight: 600;
`;

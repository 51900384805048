import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import EventButton from './EventButton';
import { MessageWarning } from '../../ui/Message';

import {
  FormGroup,
  FormGroupItem,
} from '../SearchForm.style';

import { VEHICLE_TYPES } from '../../../config/outfits';
import PLACE_EVENT_TYPES from '../../../config/eventTypes';
import IbePropTypes from '../../../IbePropTypes';
import { dictionaryItem } from '../../../hocs/withDictionary';

const quoteHasEventType = (quote, eventType) => (
  quote.campsiteBookings.some((campsiteBooking) => (
    campsiteBooking.pitches.some(
      ((pitch) => (
        pitch.eventType === eventType && !pitch.parentPitchId)
      ),
    )
  ))
);

const allowEventSelect = (quote, eventType) => {
  // Its only possible to switch from camping to touring when you have a roof tent pitch in your
  // quote, this is because the form becomes un-submittable due to being disabled in edit mode.
  // and the need to add a reg number. See CAMC23UAT-226
  const quoteIsRoofTentAndCampsiteEventType = quote?.outfit?.vehicleType === VEHICLE_TYPES.ROOF_TENT
    && eventType !== PLACE_EVENT_TYPES.CAMPING.id;
  if (quoteIsRoofTentAndCampsiteEventType || !quote?.quoteId || !quote?.campsiteBookings?.length) {
    return true;
  }
  return quoteHasEventType(quote, eventType);
};

function EventsButtonsWrapper({
  handleButtonClick,
  activeId,
  eventIds,
  center,
  events,
  quote,
  query,
}) {
  const [showError, setShowError] = useState(false);
  const isBookingWidget = query?.bookingWidget === 'true';

  useEffect(() => {
    // Show error for 5 seconds
    const SHOW_ERROR_MS = 5000;
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, SHOW_ERROR_MS);
    }
  }, [showError]);

  useEffect(() => {
    if (quote?.campsiteBookings?.length && !quoteHasEventType(quote, activeId)) {
      // Auto select other event type
      const newActiveEventType = Object.values(PLACE_EVENT_TYPES)
        .filter(eventType => eventType.enabled)
        .find((eventType) => eventType.id !== activeId);
      handleButtonClick(newActiveEventType.id);
    }
  }, [quote]);

  const handleClick = (eventItem, event) => {
    const eventType = events ? event?.eventType : eventItem.id;
    if (!allowEventSelect(quote, eventType)) {
      setShowError(true);
      return;
    }
    if (events) {
      handleButtonClick(event);
    } else {
      handleButtonClick(eventItem.id);
    }
  };
  return (
    <FormGroup marginTop>
      {Object.keys(PLACE_EVENT_TYPES)
        .filter(typeKey => PLACE_EVENT_TYPES[typeKey].enabled)
        .map((placeEventId) => {
          const eventItem = PLACE_EVENT_TYPES[placeEventId];
          if (eventIds?.length &&
            !eventIds.includes(eventItem?.id)) {
            return null;
          }
          const event = events?.find(
            selectedEvent => selectedEvent.eventType === Number(eventItem.id),
          );
          return (
            <FormGroupItem
              flex={1}
              key={`${placeEventId}`}
              center={center}
              isBookingWidget={isBookingWidget}
            >
              <EventButton
                key={`${placeEventId}`}
                event={eventItem}
                active={eventItem.id === activeId}
                ariaLabel="Filter"
                onClick={() => handleClick(eventItem, event)}
              />
            </FormGroupItem>
          );
        })}
      <FormGroupItem flex={1} center={center} hideOnMobile />
      {showError && <MessageWarning
        fullWidth
        marginBottom
        dictionary={dictionaryItem('CampsiteSearchForm', 'EventType', 'Error')}
      />}
    </FormGroup>
  );
}

EventsButtonsWrapper.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
  activeId: PropTypes.number,
  eventIds: PropTypes.arrayOf(PropTypes.number),
  center: PropTypes.bool,
  events: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.event)),
  quote: PropTypes.shape(IbePropTypes.quote),
  query: PropTypes.shape(IbePropTypes.query),
};

EventsButtonsWrapper.defaultProps = {
  activeId: PLACE_EVENT_TYPES.TOURING.id,
  eventIds: [],
  center: false,
  events: null,
  quote: null,
  query: null,
};

export default EventsButtonsWrapper;

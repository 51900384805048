import { extractTwoDecimalsRegex, hasMoreThanTwoDecimalsRegex, numericCharactersRegex } from './regexes';

/**
 * Formats the input to two decimal places if it contains more than two.
 * @param {string} input - The string input that potentially contains more than two decimal places.
 * @returns {string} The formatted string with two decimal places or
 *  the original input if no formatting is needed.
 */
export const formatToTwoDecimalPlaces = (input) => {
  if (hasMoreThanTwoDecimalsRegex.test(input)) {
    const [matchedValue] = input.match(extractTwoDecimalsRegex) || [];
    return matchedValue || input;
  }
  return input;
};

/**
 * Blocks invalid non-numeric characters and multiple decimal points in input fields.
 * Put on input in 'keydown' event, not in handler as this is the only way it wil work with Safari!
 * @param {KeyboardEvent} event - The keyboard event triggered when typing in an input field.
 */
export const blockInvalidChar = (event) => {
  const { key, target } = event;

  // Check if key is a decimal point and if one already exists in the input
  const isDecimalPoint = key === '.';
  const alreadyHasDecimal = target.value.includes('.');

  if (
    (isDecimalPoint && alreadyHasDecimal) || // Block decimal if already present
    // Prevent any non-numeric characters except for decimal
    (!isDecimalPoint && !numericCharactersRegex.test(key) && key.length === 1)
  ) {
    event.preventDefault();
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '../ui/Button/ButtonIcon';

import svgSearch from '../../static/images/icons/Search.svg';

import testingAttr from '../../lib/testingAttr';

import StyledSearchButton from './SearchButton.style';

function SearchButton({ disabled, onClick }) {
  return (
    <StyledSearchButton
      block
      disabled={disabled}
      onClick={onClick}
      {...testingAttr('search-results-campsite__search-summary__change-search-button')}
    >
      <SearchIcon
        icon={svgSearch}

      />
      <span>Change Search</span>
    </StyledSearchButton>
  );
}

SearchButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

SearchButton.defaultProps = {
  disabled: false,
};

export default SearchButton;

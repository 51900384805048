import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import formatPrice from '../../../lib/formatPrice';

import ButtonIcon from '../../ui/Button/ButtonIcon';
import Button from '../../ui/Button';
import Text from '../../ui/Text';
import PopOver from '../../PopOver';
import { dictionaryItem } from '../../../hocs/withDictionary';

import svgInfo from '../../../static/images/icons/Info.svg';
import svgArrowLeft from '../../../static/images/icons/ArrowLeft.svg';

import {
  ButtonCol,
  ButtonSelect,
  ButtonWrapper,
  CrossingPrice,
  ImageCol,
  PackageNote,
  PackageNoteButton,
  Price,
  PriceCol,
  PricingWrapper,
  WhatsIncludedButton,
  Wrapper,
} from './CrossingCost.style';

import {
  CompanyImg, ImageWrapper,
} from './SearchResultCrossings.style';
import ItxCrossingPopOver from './ItxCrossingPopOver';
import IbePropTypes from '../../../IbePropTypes';

class CrossingCost extends PureComponent {
  static propTypes = {
    hasCheaperItxOption: PropTypes.bool,
    index: PropTypes.number,
    isFeatured: PropTypes.bool,
    isITXApplicable: PropTypes.bool,
    isITXCrossing: PropTypes.bool,
    isReturn: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
    outboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
    ports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    quote: PropTypes.shape(IbePropTypes.quote),
    showBackButton: PropTypes.bool,
    totalAmount: PropTypes.number.isRequired,
    theme: PropTypes.shape({
      COLOR_WHITE: PropTypes.string,
      COLOR_BRAND_PRIMARY_DARK: PropTypes.string,
    }),
    url: PropTypes.string.isRequired,
  };

  static defaultProps = {
    hasCheaperItxOption: false,
    index: null,
    isFeatured: false,
    isITXApplicable: false,
    isITXCrossing: false,
    inboundItinerary: {},
    outboundItinerary: {},
    quote: {},
    showBackButton: false,
    theme: {
      COLOR_WHITE: '',
      COLOR_BRAND_PRIMARY_DARK: '',
    },
  };

  wrapperRef = createRef();

  state = {
    openPopover: false,
  }

  render() {
    const {
      hasCheaperItxOption,
      isFeatured,
      isITXApplicable,
      isITXCrossing,
      isReturn,
      onClick,
      inboundItinerary,
      outboundItinerary,
      ports,
      quote,
      showBackButton,
      totalAmount,
    } = this.props;

    if (showBackButton) {
      return (
        <Button
          block
          onClick={onClick}
          size="large"
        >
          <ButtonIcon
            icon={svgArrowLeft}
            style={{ marginRight: '1rem' }}
          />
          Back to Search Results
        </Button>
      );
    }

    return (
      <PopOver
        open={this.state.openPopover}
        renderContent={() => {
          if (isITXApplicable) {
            return (
              <ItxCrossingPopOver
                isReturn={isReturn}
                inboundItinerary={inboundItinerary}
                outboundItinerary={outboundItinerary}
                ports={ports}
                quote={quote}
              />
            );
          }

          return <Text dictionary={dictionaryItem('SearchCrossings', 'PackageAvailable')} />;
        }}
      >
        {({ innerRef }) => (
          <Wrapper
            ref={innerRef}
          >
            <PricingWrapper>
              <PriceCol>
                <div>
                  {isITXApplicable ? (
                    <>
                      <Text>
                        <Text span dictionary={dictionaryItem('SearchCrossings', 'CrossingPriceLabel')} />:
                        {' '}
                        <CrossingPrice>
                          {isITXCrossing ? (
                            <Text span dictionary={dictionaryItem('SearchCrossings', 'PackageFareLabel')} />
                          ) : formatPrice(totalAmount)}
                        </CrossingPrice>
                      </Text>
                      <>
                        <Text span dictionary={dictionaryItem('SearchCrossings', 'BasketTotalLabel')} />:
                        {' '}
                        <Price isITXApplicable>
                          {formatPrice(totalAmount + ((quote && quote.totalPrice) || 0))}
                        </Price>
                      </>
                    </>
                  ) : (
                    <>
                      <Price>{formatPrice(totalAmount)}</Price>
                      {hasCheaperItxOption && (
                        <div>
                          <PackageNoteButton
                            onClick={() => this.setState({ openPopover: !this.state.openPopover })}
                            onBlur={() => this.setState({ openPopover: false })}
                          >
                            <PackageNote
                              hasIcon
                              icon={svgInfo}
                              text={<Text dictionary={dictionaryItem('SearchCrossings', 'PackageAvailableLabel')} />}
                              color={this.props.theme.COLOR_BRAND_PRIMARY_DARK}
                            />
                          </PackageNoteButton>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </PriceCol>

              <ImageCol>
                <ImageWrapper>
                  <CompanyImg
                    src={this.props.url}
                  />
                </ImageWrapper>
              </ImageCol>

              <ButtonCol>
                <ButtonWrapper>
                  <ButtonSelect
                    dictionary={dictionaryItem('SearchResults', 'Crossing', 'CrossingCost')}
                    isFeatured={isFeatured}
                    onClick={onClick}
                    data-testid={`crossings-result__select-button__${this.props.index}`}
                  >
                    Select
                  </ButtonSelect>
                  {isITXApplicable && isITXCrossing && (
                    <WhatsIncludedButton
                      onClick={() => this.setState({ openPopover: !this.state.openPopover })}
                      onBlur={() => this.setState({ openPopover: false })}
                    >
                      <PackageNote
                        text={<Text dictionary={dictionaryItem('SearchCrossings', 'WhatsIncludedLabel')} />}
                        color={this.props.theme.COLOR_BRAND_PRIMARY_DARK}
                      />
                    </WhatsIncludedButton>
                  )}
                </ButtonWrapper>
              </ButtonCol>
            </PricingWrapper>
          </Wrapper>
        )}
      </PopOver>
    );
  }
}

export default withTheme(CrossingCost);

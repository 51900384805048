import React from 'react';
import PropTypes from 'prop-types';

import RoomSelectItemStyled, { Body, Content, Tick } from './RoomSelectItem.style';

import svgTick from '../../static/images/icons/Tick.svg';

function RoomSelectItem({
  marginBottom, isClickable, onClick, onQuantityChange, render, ...props
}) {
  const {
    id,
    disabled,
    icon,
    selected,
  } = props;

  const handleClick = isClickable ? onClick : () => null;

  return (
    <RoomSelectItemStyled
      disabled={disabled}
      key={id}
      isClickable={isClickable}
      onClick={() => handleClick(props)}
      selected={selected}
      marginBottom
    >
      <Body>
        <Tick icon={icon || svgTick} selected={selected} disabled={disabled} />
        <Content>
          {render({ onChange: onQuantityChange, ...props })}
        </Content>
      </Body>
    </RoomSelectItemStyled>
  );
}

RoomSelectItem.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  isClickable: PropTypes.bool,
  marginBottom: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func,
  render: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

RoomSelectItem.defaultProps = {
  disabled: false,
  icon: null,
  id: null,
  isClickable: true,
  marginBottom: false,
  onQuantityChange() {},
  selected: false,
};

export default RoomSelectItem;

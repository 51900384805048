import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';

import moment from 'moment';

import { DAYS, MONTHS, MONTHS_SHORT } from '../../constants';

import CalendarDayCell from './CalendarDayCell';
import DaysOfTheWeek from './DaysOfTheWeek';

import StyledCalendar, {
  Header,
  Table,
  LoadingOverlay,
  LoadingPrices,
} from './Calendar.style';

import variables from '../../variables';
import IbePropTypes from '../../../../../IbePropTypes';
import { getDictionaryItem } from '../../../../../hocs/withDictionary';
import { LoadingBlock } from '../../../Loading';
import theme from '../../../../../styles/config/theme';
import { CAMPSITE_CALENDAR_STATUS } from '../../../../../constants/campsiteStatus';

class Calendar extends PureComponent {
  static propTypes = {
    availabilitySearchLoading: PropTypes.bool,
    className: PropTypes.string,
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    disabled: PropTypes.bool,
    calculateCalendarsHeight: PropTypes.func,
    cells: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    endDate: PropTypes.string,
    index: PropTypes.number.isRequired,
    fullscreen: PropTypes.bool.isRequired,
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    days: PropTypes.number.isRequired,
    rowLength: PropTypes.number.isRequired,
    showMemberBookableOnly: PropTypes.arrayOf(PropTypes.bool),
    handleClick: PropTypes.func.isRequired,
    hasLowAvailability: PropTypes.arrayOf(PropTypes.bool),
    isLastDayInOpenRange: PropTypes.arrayOf(PropTypes.bool),
    isSearchFormPicker: PropTypes.bool,
    isSelectedEventClosed: PropTypes.arrayOf(PropTypes.bool),
    loading: PropTypes.bool,
    placeholder: PropTypes.string,
    showRemainder: PropTypes.bool.isRequired,
    startDate: PropTypes.string,
    startDay: PropTypes.number.isRequired,
    showHeader: PropTypes.bool,
    text: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ])),
    width: PropTypes.string,
    styles: PropTypes.shape(),
    showFirstMonth: PropTypes.bool,
    'data-testid': PropTypes.string,
  }

  static defaultProps = {
    availabilitySearchLoading: false,
    calculateCalendarsHeight() { },
    className: '',
    disabled: false,
    endDate: '',
    showMemberBookableOnly: [],
    hasLowAvailability: [],
    isSearchFormPicker: false,
    isSelectedEventClosed: [],
    isLastDayInOpenRange: [],
    loading: false,
    placeholder: null,
    showHeader: true,
    styles: {},
    startDate: '',
    text: [],
    width: null,
    showFirstMonth: false,
    'data-testid': null,
  }

  static getDate(year, month, dayDelta) {
    const date = new Date(`${year}/01/01`);
    date.setMonth(month);
    date.setDate(date.getDate() + dayDelta);

    return date;
  }

  constructor(props) {
    super(props);

    const { cells } = this.props;

    this.state = {
      cells,
    };

    this.lowAvailabilityLabel = getDictionaryItem(this.props.client, 'CalendarDayCell__LowAvailability__Text');
    this.isSelectedEventClosedLabel = getDictionaryItem(this.props.client, 'CalendarDayCell__IsSelectedEventClosed__Text');
    this.onRequestLabel = getDictionaryItem(this.props.client, 'AvailabilityDuration__OnRequest__Button');
    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    this.props.calculateCalendarsHeight(
      this.calendarRef.current.clientHeight,
      this.props.cells[0].date,
    );
  }

  render() {
    const calendarWidth = this.props.width || `${variables.calendarWidth}px`;

    const style = {
      width: !this.props.fullscreen ? calendarWidth : null,
    };

    let calendarMonth = this.props.month + 1;
    calendarMonth = calendarMonth < 10 ? `0${calendarMonth}` : calendarMonth;

    const loadingGradient = [
      theme.COLOR_WHITE,
      theme.COLOR_LAKE_GREEN_DARK,
      theme.COLOR_WHITE,
    ];

    return (
      <StyledCalendar
        className={this.props.className}
        fullscreen={this.props.fullscreen}
        ref={this.calendarRef}
        style={style}
      >
        {this.props.showHeader &&
          <Header
            data-testid={
              this.props['data-testid'] ?
                `${this.props['data-testid']}__header__date__${this.props.index}` : null
            }
          >
            {MONTHS[this.props.month]} {this.props.year}
          </Header>
        }

        {!this.props.fullscreen &&
          <DaysOfTheWeek />
        }

        {this.props.availabilitySearchLoading &&
          <LoadingOverlay>
            <LoadingBlock backgroundColor={`linear-gradient(90deg, ${loadingGradient.join(',')})`} size="4px" />
          </LoadingOverlay>
        }

        <Table>
          <tbody>
            {Array.from(Array(this.props.rowLength).keys()).map(row => (
              <tr key={row}>
                {DAYS.map((d, i) => {
                  const targetIndex = (row * DAYS.length) + i;
                  const cell = this.state.cells[targetIndex];
                  const date = Calendar.getDate(this.props.year, this.props.month, cell.day);
                  const text = this.props.text[targetIndex];
                  const closed = this.props.isSelectedEventClosed[targetIndex];
                  const isDisabled = closed || cell.disabled || this.props.disabled;
                  const onRequest = text === this.onRequestLabel;

                  return (
                    <CalendarDayCell
                      availabilitySearchLoading={this.props.availabilitySearchLoading}
                      endDate={this.props.endDate}
                      startDate={this.props.startDate}
                      showRemainder={this.props.showRemainder}
                      {...cell}
                      disabled={isDisabled}
                      handleClick={this.props.handleClick}
                      hasLowAvailability={!!text && this.props.hasLowAvailability[targetIndex]}
                      isLastDayInOpenRange={this.props.isLastDayInOpenRange[targetIndex]}
                      isSearchFormPicker={this.props.isSearchFormPicker}
                      isSelectedEventClosed={closed}
                      isSelectedEventClosedLabel={this.isSelectedEventClosedLabel}
                      showMemberBookableOnly={
                        !!text && this.props.showMemberBookableOnly[targetIndex]
                      }
                      loading={this.props.loading}
                      lowAvailabilityLabel={this.lowAvailabilityLabel}
                      key={`${this.props.year}_${this.props.month}_${cell.day}`}
                      month={MONTHS_SHORT[moment(date).format('M') - 1]}
                      placeholder={this.props.placeholder}
                      showFirstMonth={this.props.showFirstMonth}
                      text={!text ? CAMPSITE_CALENDAR_STATUS.FULL : text}
                      onRequest={onRequest}
                      data-testid={
                      this.props['data-testid'] ?
                        `${this.props['data-testid']}__date__day__${cell.day + 1}-${calendarMonth}-${this.props.year}` : null
                      }
                    />
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>

        {this.props.availabilitySearchLoading &&
          <LoadingPrices>Getting Prices...</LoadingPrices>
        }
      </StyledCalendar>
    );
  }
}

export default withApollo(Calendar);

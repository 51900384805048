import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'styled-components';

import Text from '../ui/Text';

import PartyForm from '../PartyForm/PartyForm';
import MessageWarningStyle from '../ui/Message/MessageWarning.style';
import { dictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';

const defaultProps = {
  max: 6,
  maxForCurrentPitch: null,
  maxChildAge: null,
  minChildAge: null,
  payload: {},
  productCode: null,
  isOverseas: false,
  useDob: false,
};

const propTypes = {
  maxChildAge: PropTypes.number,
  minChildAge: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  max: PropTypes.number,
  maxForCurrentPitch: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  payload: PropTypes.shape(IbePropTypes.quotePayload),
  dataError: PropTypes.bool.isRequired,
  productCode: PropTypes.number,
  isOverseas: PropTypes.bool,
  useDob: PropTypes.bool,
};

class AvailabilityParty extends PureComponent {
  static defaultProps = defaultProps;

  static propTypes = propTypes;

  handleChange = (partyMembers, skipValidation = false) => {
    const payload = { ...this.props.payload };
    payload.partyMembers = partyMembers;
    this.props.onChange(payload, skipValidation);
  }

  render() {
    const {
      dataError,
      disabled,
      max,
      maxForCurrentPitch,
      productCode,
      payload: { partyMembers },
      isOverseas,
      useDob,
      payload,
    } = this.props;

    return (
      <Fragment>
        <PartyForm
          disabled={disabled}
          max={max}
          maxForCurrentPitch={maxForCurrentPitch}
          partyMembers={partyMembers}
          productCode={productCode}
          onChange={this.handleChange}
          dataError={dataError}
          maxChildAge={this.props.maxChildAge}
          minChildAge={this.props.minChildAge}
          isOverseas={isOverseas}
          useDob={useDob}
          arrivalDate={payload?.start}
        />

        {partyMembers.length > maxForCurrentPitch &&
          <MessageWarningStyle marginTop>
            You have exceeded the maximum amount of party members for the selected pitch.
          </MessageWarningStyle>
        }

        {
          !useDob && <Text
            dictionary={dictionaryItem('AvailabilityParty', 'PartyNumbers')}
            size="0.875rem"
            marginTop
          />}
      </Fragment>
    );
  }
}

export default withTheme(AvailabilityParty);

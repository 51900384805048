import React, { memo } from 'react';
import PropTypes from 'prop-types';

import StyledInput from '../Input/Input.style';
import { InputIcon, Wrapper } from './InputWithIcon.style';
import { ButtonIcon } from '../../Button';

const defaultProps = {
  ariaLabel: '',
  block: false,
  className: '',
  customStyled: '',
  defaultValue: null,
  disabled: false,
  error: false,
  icon: '',
  id: '',
  inputRef: ({
    current: null,
  }),
  name: '',
  onChange() { },
  onFocus() { },
  onKeyUp() { },
  onKeyDown() { },
  onClick() { },
  onBlur() { },
  pattern: null,
  placeholder: '',
  readOnly: false,
  required: false,
  size: 'medium',
  type: 'text',
  value: '',
  hasSideButton: false,
  'data-testid': null,
};

const propTypes = {
  ariaLabel: PropTypes.string,
  error: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  customStyled: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ])),
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  id: PropTypes.string,
  icon: PropTypes.string,
  inputRef: PropTypes.shape({
    current: null,
  }),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  size: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  pattern: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  hasSideButton: PropTypes.bool,
  'data-testid': PropTypes.string,
};

export function Input({
  ariaLabel,
  error,
  block,
  className,
  customStyled,
  id,
  onChange,
  onFocus,
  onKeyUp,
  onKeyDown,
  onBlur,
  onClick,
  pattern,
  placeholder,
  required,
  defaultValue,
  size,
  type,
  name,
  value,
  disabled,
  readOnly,
  icon,
  hasSideButton,
  ...props
}) {
  const inputProps = {
    ariaLabel,
    error,
    block,
    className,
    customStyled,
    id,
    disabled,
    name,
    onChange,
    onFocus,
    onKeyUp,
    onKeyDown,
    onBlur,
    onClick,
    pattern,
    placeholder,
    readOnly,
    required,
    size,
    type,
  };

  inputProps.value = value;

  return (
    <Wrapper hasSideButton>
      <StyledInput
        {...inputProps}
        aria-label={ariaLabel}
        data-testid={props['data-testid']}
        data-error={error}
        hasIcon={!!icon}
        id={id}
        required={required}
        ref={props.inputRef}
        autoComplete="off"
      />

      {icon &&
        <InputIcon>
          <ButtonIcon icon={icon} />
        </InputIcon>
      }
    </Wrapper>
  );
}

Input.defaultProps = defaultProps;
Input.propTypes = propTypes;

export default memo(Input);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../../hocs/withDictionary';

import Title from '../../ui/Title';
import Text from '../../ui/Text';
import { MessageWarning } from '../../ui/Message';
import {
  SectionCol,
  SectionRow,
} from './QuoteCrossing.style';

function QuoteCrossingLayoutJourneys(props) {
  const {
    accommPriceError,
    dictionaryPrefix,
    errors,
    noInbound,
    noOutbound,
    renderColLeft,
    renderColRight,
    renderInboundText,
    renderOutboundText,
    renderText,
  } = props;

  return (
    <Fragment>
      <Title tag={1} size={2} dictionary={dictionaryItem(dictionaryPrefix)} marginBottom />

      {renderText() ?
        renderText() :
        <Text size="0.875rem" dictionary={dictionaryItem(dictionaryPrefix)} marginBottom />
      }

      <SectionRow>
        <SectionCol>
          <Title tag={2} size={3} dictionary={dictionaryItem(dictionaryPrefix, 'Outbound')} marginBottom marginTop />

          {!noOutbound && (
            renderOutboundText() ?
              renderOutboundText() :
              <Text size="0.875rem" dictionary={dictionaryItem(dictionaryPrefix, 'Outbound')} marginBottom />
          )}

          {noOutbound &&
            <Text size="0.875rem" dictionary={dictionaryItem(dictionaryPrefix, 'Outbound', 'EmptyState')} />
          }

          {renderColLeft(props)}
        </SectionCol>

        {!!renderColRight() &&
          <SectionCol>
            <Title tag={2} size={3} dictionary={dictionaryItem(dictionaryPrefix, 'Inbound')} marginBottom marginTop />

            {!noInbound && (
              renderInboundText() ?
                renderInboundText() :
                <Text size="0.875rem" dictionary={dictionaryItem(dictionaryPrefix, 'Inbound')} marginBottom />
            )}

            {noInbound &&
              <Text size="0.875rem" dictionary={dictionaryItem(dictionaryPrefix, 'Inbound', 'EmptyState')} />
            }

            {renderColRight(props)}
          </SectionCol>
        }
      </SectionRow>

      {errors && errors.map((error, index) => (
        <MessageWarning
          key={error.message}
          dictionary={error.message}
          marginBottom
          scrollToNode={index === 0}
        />
      ))}

      {accommPriceError && accommPriceError.message &&
        <MessageWarning
          key={accommPriceError.message}
          dictionary={accommPriceError.message}
          marginBottom
        />
      }
    </Fragment>
  );
}

QuoteCrossingLayoutJourneys.propTypes = {
  accommPriceError: PropTypes.shape({
    message: PropTypes.string,
  }),
  dictionaryPrefix: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.shape({})),
  noInbound: PropTypes.bool,
  noOutbound: PropTypes.bool,
  renderColLeft: PropTypes.func.isRequired,
  renderColRight: PropTypes.func,
  renderInboundText: PropTypes.func,
  renderOutboundText: PropTypes.func,
  renderText: PropTypes.func,
};

QuoteCrossingLayoutJourneys.defaultProps = {
  accommPriceError: {
    message: '',
  },
  dictionaryPrefix: 'QuoteCrossing',
  errors: [],
  noInbound: false,
  noOutbound: false,
  renderColRight: null,
  renderInboundText: () => null,
  renderOutboundText: () => null,
  renderText: () => null,
};

export default QuoteCrossingLayoutJourneys;

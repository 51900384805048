import { areRangesOverlapping } from 'date-fns';
import { differenceInDays } from '../../lib/dates';

export function parseDateString(str) {
  let dateString = str;
  if (!dateString) {
    dateString = '--';
  }
  const components = dateString.split('-');
  return {
    year: components[0] ?? '',
    month: (components[1] !== '') ? parseInt(components[1], 10) - 1 : '',
    day: (components[2] !== '') ? parseInt(components[2], 10) : '',
  };
}

export function bookingHasOverlaps(payload, bookings) {
  const { id, start, end } = payload;
  const otherBookings = bookings.filter((booking) => booking.id !== id);
  if (!otherBookings.length || !start || !end || start === end) {
    return false;
  }
  let hasOverlaps = false;
  otherBookings.forEach((booking) => {
    booking.pitches.forEach((pitch) => {
      if (areRangesOverlapping(
        start, end, pitch.bookingDates.fromDate, pitch.bookingDates.toDate,
      )) {
        hasOverlaps = true;
      }
    });
  });
  return hasOverlaps;
}

export const bookingReducedNights = (payload, pitches) => {
  const pitch = pitches?.find(({ id }) => id === payload?.id);
  if (!pitch || !payload?.start || !payload?.end) {
    return false;
  }
  const numNights = differenceInDays(pitch.bookingDates.toDate, pitch.bookingDates.fromDate);
  const newNumNights = differenceInDays(payload.end, payload.start);
  const datesOverlapping = areRangesOverlapping(pitch.bookingDates.fromDate,
    pitch.bookingDates.toDate, payload.start, payload.end);
  return newNumNights < numNights || !datesOverlapping;
};

export default parseDateString;

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../hocs/withDictionary';

import Title from '../ui/Title';
import Text from '../ui/Text';
import QuoteRemoveComponent from '../Quote/QuoteRemoveComponent';

import svgError from '../../static/images/icons/Error.svg';

import {
  Actions,
  Action,
  ActionBrand,
  AlertIcon,
} from './PopUp.styles';

const propTypes = {
  componentId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const RemoveVouchersPopUp = ({
  componentId,
  handleClose,
}) => (
  <>
    <AlertIcon icon={svgError} />

    <Title align="center" tag={3} size={2} marginBottom dictionary={dictionaryItem('RemoveVouchersPopUp')} />

    <Text align="center" dictionary={dictionaryItem('RemoveVouchersPopUp')} size="1rem" />
    <Actions>
      <Action
        onClick={handleClose}
        dictionary={dictionaryItem('RemoveVouchersPopUp', 'Decline')}
      />
      <QuoteRemoveComponent
        componentIds={componentId}
        onSuccess={handleClose}
      >
        <ActionBrand
          dictionary={dictionaryItem('RemoveVouchersPopUp', 'Confirm')}
        />
      </QuoteRemoveComponent>
    </Actions>
  </>
);

RemoveVouchersPopUp.propTypes = propTypes;

export default memo(RemoveVouchersPopUp);

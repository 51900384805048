import React from 'react';
import PropTypes from 'prop-types';

import formatPrice from '../../lib/formatPrice';

import SupplementsCampsiteContentStyled, {
  Name, Price, Select, Col, ColLarge, Row,
} from './SupplementsCampsiteContent.style';

function SupplementsCampsiteContent({
  checked,
  disabled,
  id,
  maxQuantity,
  name,
  onQuantity,
  quantity,
  totalPrice,
  numOfNights,
}) {
  const isDisabled = disabled || !checked;

  return (
    <SupplementsCampsiteContentStyled>
      <Row>
        <ColLarge>
          <Name>{name}</Name>
        </ColLarge>
        <Col>
          <Row>
            <Col flex="0 0 auto">
              {!!maxQuantity &&
                <Select
                  ariaLabel={`${name}: Select quantity`}
                  disabled={isDisabled}
                  id={`select-${name}`}
                  onChange={event => onQuantity(event, id)}
                  size="xs"
                  type="number"
                  value={quantity}
                >
                  {[...Array(maxQuantity).keys()].map(n => n + 1).map(key => (
                    <option key={key}>{key}</option>
                  ))}
                </Select>
              }
            </Col>
            <Col>
              <Price>
                <b>+ {formatPrice((totalPrice * (quantity || 1)) / numOfNights)}</b> Per Night
              </Price>
            </Col>
          </Row>
        </Col>
      </Row>
    </SupplementsCampsiteContentStyled>
  );
}

SupplementsCampsiteContent.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  maxQuantity: PropTypes.number,
  name: PropTypes.string.isRequired,
  onQuantity: PropTypes.func,
  quantity: PropTypes.number,
  totalPrice: PropTypes.number,
  numOfNights: PropTypes.number.isRequired,
};

SupplementsCampsiteContent.defaultProps = {
  disabled: false,
  checked: false,
  maxQuantity: 0,
  onQuantity() { },
  quantity: null,
  totalPrice: null,
};

export default SupplementsCampsiteContent;

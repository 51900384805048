import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose, withApollo } from 'react-apollo';
import { withRouter } from 'next/router';
import { withTheme } from 'styled-components';
import { isEmpty, isEqual } from 'lodash';
import filterInboundRoutes from '../../lib/filterInboundRoutes';

import testingAttr from '../../lib/testingAttr';
import { validateOutfitCrossing } from '../../lib/validation/availability';
import { validatePartyAges } from '../../lib/partyHelpers';

import { dictionaryItem } from '../../hocs/withDictionary';

import CrossingsJourneyContainer from './Crossings/CrossingsJourneyContainer';
import CrossingsAllowAlternativeRoutes from './Crossings/CrossingsAllowAlternativeRoutes';
import CrossingsDatePickerGroup from './Crossings/CrossingsDatePickerGroup';
import CrossingsJourney from './Crossings/CrossingsJourney';
import CrossingsRadioGroup from './Crossings/CrossingsRadioGroup';
import ErrorTypeMessage from '../ErrorTypeMessage';
import OutfitForm from '../Outfit';
import PartyForm from '../PartyForm';
import { ButtonIcon } from '../ui/Button';
import { MessageWarning } from '../ui/Message';
import Text from '../ui/Text';

import SearchFormCrossingsStyled, {
  SubmitWrapper,
} from './SearchFormCrossings.style';
import MessageWarningStyle from '../ui/Message/MessageWarning.style';

import {
  SearchFormCol, SearchFormRow, Title, cssInput,
} from './SearchForm.style';

import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import IbePropTypes from '../../IbePropTypes';
import PRODUCT_TYPES from '../../config/products';

import svgSearch from '../../static/images/icons/Search.svg';
import CrossingsSameReturnRoute from './Crossings/CrossingsSameReturnRoute';
import { SearchButton } from './Campsites/CampsitesButtonsWrapper.style';

export function findDirectReturnRoute(outboundItinerary, inboundRoutes) {
  if (!outboundItinerary.crossingRouteCode) return null;

  return inboundRoutes.find(({ arrivalPortName, departurePortName }) => (
    arrivalPortName === outboundItinerary.departurePortName &&
    departurePortName === outboundItinerary.arrivalPortName
  )) || null;
}

/**
 * SearchFormCrossings contains the UI for the Crossings search and summary
 */
class SearchFormCrossings extends PureComponent {
  static propTypes = {
    countBookings: PropTypes.number,
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      configuration: PropTypes.shape(IbePropTypes.configuration),
    }),
    error: PropTypes.string,
    handleAllSuppliers: PropTypes.func.isRequired,
    handleFormErrors: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    handleRadioChange: PropTypes.func.isRequired,
    onSameReturnRouteChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onInboundChange: PropTypes.func,
    onOutboundChange: PropTypes.func,
    onSubmit: PropTypes.func,
    oneWay: PropTypes.bool.isRequired,
    outfitErrors: PropTypes.arrayOf(PropTypes.shape({})),
    partyErrors: PropTypes.arrayOf(PropTypes.shape({})),
    payload: PropTypes.shape({
      outboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
      inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
      partyMembers: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.partyMember)),
      allowAlternativeRoutes: PropTypes.bool,
      outfit: PropTypes.shape(IbePropTypes.outfit),
      sameReturnRoute: PropTypes.bool,
    }).isRequired,
    payloadErrors: PropTypes.arrayOf(PropTypes.shape({})),
    removePayloadErrorByType: PropTypes.func.isRequired,
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    quote: PropTypes.shape(IbePropTypes.quote),
    routes: PropTypes.arrayOf(PropTypes.shape({})),
    sameReturnRoute: PropTypes.bool,
    allSuppliers: PropTypes.arrayOf(PropTypes.string),
    updatePayload: PropTypes.func.isRequired,
    theme: PropTypes.shape({
      COLOR_WHITE: PropTypes.string,
    }),
  };

  static defaultProps = {
    countBookings: null,
    data: {
      configuration: {
        defaultMaxVehicleOverallHeightCrossing: null,
        defaultMaxVehicleOverallLengthCrossing: null,
      },
      loading: true,
    },
    error: '',
    outfitErrors: [],
    partyErrors: [],
    payloadErrors: [],
    quote: null,
    routes: [],
    sameReturnRoute: false,
    allSuppliers: [],
    theme: {
      COLOR_WHITE: '',
    },
    onInboundChange() { },
    onOutboundChange() { },
    onSubmit() { },
  };

  constructor(props) {
    super(props);

    this.state = {
      dateErrors: [],
      submitted: false,
      outfitFieldsTouched: {
        vehicleLength: this.checkVehicleFields([props.payload, props.quote], 'vehicleLength'),
        vehicleHeight: this.checkVehicleFields([props.payload, props.quote], 'vehicleHeight'),
        towLength: this.checkVehicleFields([props.payload, props.quote], 'towLength'),
        towHeight: this.checkVehicleFields([props.payload, props.quote], 'towHeight'),
      },
    };
  }

  componentDidUpdate({ payload: prevPayload }) {
    const { payload } = this.props;
    const { submitted } = this.state;

    if (submitted && !isEqual(payload, prevPayload)) {
      this.updateSubmittedState(false);
    }
  }

  updateSubmittedState = submitted => this.setState({ submitted });

  checkVehicleFields = (data, key) => !!data.find(d => d && d.outfit && !!d.outfit[key]);

  handlePortChange = (itinerary, name, value) => {
    if (name === 'to') {
      return {
        ...itinerary,
        arrivalPortName: value,
      };
    }

    return {
      ...itinerary,
      departurePortName: value,
      arrivalPortName: '',
      routeCode: '',
      routeCodeWeb: '',
    };
  }

  handleOutboundChange = (route, value, name, allSuppliers) => {
    const payload = {
      ...this.props.payload,
      inboundItinerary: {
        ...this.props.payload.inboundItinerary,
        arrivalPortName: '',
        departurePortName: '',
        routeCode: '',
        routeCodeWeb: '',
      },
      outboundItinerary: this.handlePortChange(this.props.payload.outboundItinerary, name, value),
    };

    this.props.handleAllSuppliers(allSuppliers);
    // handleOutboundChange is called via the from and to dropdown on change, check to
    // reset correct one.
    if (name === 'to') {
      this.props.removePayloadErrorByType('inbound');
    } else {
      this.props.removePayloadErrorByType('outbound');
    }

    this.props.onOutboundChange(payload, route);
  }

  handleInboundChange = (route, value, name) => {
    const payload = { ...this.props.payload };
    payload.inboundItinerary = this.handlePortChange(payload.inboundItinerary, name, value);
    this.props.removePayloadErrorByType('inbound');
    this.props.onInboundChange(payload, route);
  }

  handleDateChange = (crossingDateTime, itinerary) => {
    const payload = { ...this.props.payload };

    payload[itinerary] = {
      ...payload[itinerary],
      crossingDateTime,
    };

    if (crossingDateTime) this.props.removePayloadErrorByType('dp');

    this.props.updatePayload({ ...payload });
  }

  handlePartyChange = (partyMembers, skipAgeValidation = false) => {
    const payload = { ...this.props.payload };
    payload.partyMembers = partyMembers;

    const agesValid = skipAgeValidation ? true : validatePartyAges(partyMembers);

    if (!agesValid) this.props.handleFormErrors([{ type: 'ages', message: dictionaryItem('SearchFormCrossings', 'PartyAges', 'Error') }], 'partyErrors');
    else this.props.handleFormErrors([], 'partyErrors');

    this.props.updatePayload({ ...payload });
  }

  handleOutfitChange = (outfit) => {
    const {
      data: { configuration }, payload, router, client,
    } = this.props;
    const outfitErrors = validateOutfitCrossing(outfit, true, configuration, router, client);
    this.props.handleFormErrors(outfitErrors, 'outfitChangeErrors');
    this.props.updatePayload({
      ...payload,
      outfit,
    });
  }

  handleDateClear = async (itinerary) => {
    const payload = { ...this.props.payload };

    payload.inboundItinerary.crossingDateTime = '';
    payload.outboundItinerary.crossingDateTime = '';

    this.props.updatePayload({ ...payload });
  }

  dateError = (errors) => {
    if (!errors) return;

    this.setState({
      dateErrors: errors,
    });
  }

  handleKeyDownDate = (event) => {
    const { name } = event.target;
    const payload = { ...this.props.payload };
    if (name === 'startDate' && event.keyCode === 8) {
      payload.outboundItinerary.crossingDateTime = '';
    } else if (name === 'endDate' && event.keyCode === 8) {
      payload.inboundItinerary.crossingDateTime = '';
    }

    this.props.updatePayload({ ...payload });
  }

  /**
   * Filter function to check payload errors against already visible
   * inline errors and to not show.
   */
  filterAgainstInlineErrorMatches = ({ type, message }) => {
    const outfitErrorMatches = this.props.outfitErrors.find(
      e => e.message === message && e.type === type,
    );

    const partyErrorsMatches = this.props.partyErrors.find(
      e => e.message === message && e.type === type,
    );

    return !outfitErrorMatches && !partyErrorsMatches;
  }

  handleOutfitBlur = ({ target }) => {
    const { name } = target;
    const { outfitFieldsTouched } = this.state;

    this.setState({
      outfitFieldsTouched: {
        ...outfitFieldsTouched,
        [name]: true,
      },
    });
  }

  handleOutfitDimensionsError = (validationErrors) => {
    this.props.handleFormErrors(validationErrors, 'outfitDimensionsErrors');
  };

  render() {
    const {
      data: {
        configuration: {
          defaultMaxVehicleOverallLengthCrossing,
          defaultMaxVehicleOverallHeightCrossing,
          products: confProducts,
        },
      },
      countBookings,
      error,
      payload,
      payloadErrors,
      quote,
      oneWay,
      onSameReturnRouteChange,
      onSubmit,
      routes,
      allSuppliers,
    } = this.props;

    const currentProduct = confProducts?.find(
      (product) => product.productCode === PRODUCT_TYPES.CAMC_MEMBER_PRODUCT,
    );

    const { outboundItinerary } = payload;

    const inboundRoutes = filterInboundRoutes(
      routes,
      allSuppliers,
      outboundItinerary.departurePortZone,
      outboundItinerary.arrivalPortZone,
    );

    const disabledSameReturnRoute = !!outboundItinerary.crossingRouteCode &&
      !findDirectReturnRoute(outboundItinerary, inboundRoutes);

    const crossingsExist = quote && quote.crossingBookings && !!quote.crossingBookings.length;

    const showAlternativeRoutesCheckboxOutbound =
      !this.props.payload.outboundItinerary.routeCode ||
      (this.props.payload.outboundItinerary.routeCodeWeb && oneWay) ||
      (this.props.payload.outboundItinerary.routeCodeWeb && payload.sameReturnRoute);

    let showAlternativeRoutesCheckboxInbound = false;

    if (isEmpty(this.props.payload.inboundItinerary)) {
      showAlternativeRoutesCheckboxInbound = true;
    } else if (!this.props.payload.inboundItinerary.routeCode ||
      (this.props.payload.inboundItinerary.routeCodeWeb && oneWay) ||
      (this.props.payload.inboundItinerary.routeCodeWeb && payload.sameReturnRoute)) {
      showAlternativeRoutesCheckboxInbound = true;
    } else {
      showAlternativeRoutesCheckboxInbound = false;
    }

    return (
      <SearchFormCrossingsStyled>
        {/* CrossingSearchForm__Title */}
        <Title dictionary={dictionaryItem('SearchFormCrossings')} size={1} marginBottom />

        <CrossingsRadioGroup
          oneWay={!!this.props.oneWay}
          handleRadioChange={this.props.handleRadioChange}
        />

        <CrossingsJourneyContainer
          routes={routes}
          onChange={this.handleOutboundChange}
          from={payload.outboundItinerary.departurePortName}
        >
          {({
            arrivalPorts, departurePorts, onChange, from,
          }) => (
            <CrossingsJourney
              from={from}
              onChange={onChange}
              arrivalPorts={arrivalPorts}
              departurePorts={departurePorts}
              to={payload.outboundItinerary.arrivalPortName}
              {...testingAttr('search-form-crossings__inbound')}
            />
          )}
        </CrossingsJourneyContainer>

        {!this.props.oneWay && !this.props.sameReturnRoute &&
          <CrossingsJourneyContainer
            routes={inboundRoutes}
            onChange={this.handleInboundChange}
            from={payload.inboundItinerary.departurePortName}
          >
            {({
              arrivalPorts, departurePorts, onChange, from,
            }) => (
              <CrossingsJourney
                arrivalPorts={arrivalPorts}
                departurePorts={departurePorts}
                disabled={!this.props.payload.outboundItinerary.routeCode}
                from={from}
                onChange={onChange}
                to={payload.inboundItinerary.arrivalPortName}
                {...testingAttr('search-form-crossings__inbound')}
              />
            )}
          </CrossingsJourneyContainer>
        }

        <SearchFormRow>
          {!this.props.oneWay &&
            <SearchFormCol>
              <CrossingsSameReturnRoute
                checked={this.props.sameReturnRoute}
                disabled={disabledSameReturnRoute}
                onChange={onSameReturnRouteChange}
              />
            </SearchFormCol>
          }

          {showAlternativeRoutesCheckboxOutbound && showAlternativeRoutesCheckboxInbound &&
            <SearchFormCol>
              <CrossingsAllowAlternativeRoutes
                checked={payload.allowAlternativeRoutes}
                onChange={data => this.props.handleCheckboxChange(data, 'allowAlternativeRoutes')}
              />
            </SearchFormCol>
          }
        </SearchFormRow>

        <CrossingsDatePickerGroup
          availabilityMaxDate={currentProduct?.memberBookableDate}
          dateErrors={this.state.dateErrors}
          handleDateClear={this.handleDateClear}
          handleDateInput={this.handleDateInput}
          handleKeyDownDate={this.handleKeyDownDate}
          inboundDateTime={payload.inboundItinerary.crossingDateTime}
          onDateChange={this.handleDateChange}
          onError={this.dateError}
          oneWay={this.props.oneWay}
          outboundDateTime={payload.outboundItinerary.crossingDateTime}
        />

        {/* e.message has to be changed into dictionary key. */}
        {this.state.dateErrors
          .map(({ message }) => (
            <MessageWarningStyle dictionary={message} key={message} marginTop />
          ))
        }

        <OutfitForm
          customStyled={cssInput}
          disableTowHeight={!!this.props.countBookings && crossingsExist}
          disableVehicleHeight={!!this.props.countBookings && crossingsExist}
          disabled={!!this.props.countBookings}
          onBlur={this.handleOutfitBlur}
          hasHeight
          maxFerryHeight={defaultMaxVehicleOverallHeightCrossing}
          maxOutfitHeight={defaultMaxVehicleOverallHeightCrossing}
          maxOutfitLength={defaultMaxVehicleOverallLengthCrossing}
          maxPitchlength={defaultMaxVehicleOverallLengthCrossing}
          onChange={this.handleOutfitChange}
          outfit={payload.outfit}
          isOverseas
          use="Crossings"
          onError={this.handleOutfitDimensionsError}
        />

        <Text
          dictionary={dictionaryItem('CrossingsSearch', 'VanNote')}
          size="0.875rem"
          marginTop
          color={this.props.theme.COLOR_WHITE}
        />

        {this.props.outfitErrors
          .map(({ id, message, type }) => {
            const { outfitFieldsTouched, submitted } = this.state;
            const displayError = submitted || outfitFieldsTouched[type];
            return displayError
              ? <MessageWarningStyle key={`${id}-${message}}`} marginTop>{message}</MessageWarningStyle>
              : null;
          })
        }

        <PartyForm
          arrivalDate={payload.outboundItinerary?.crossingDateTime}
          customStyled={cssInput}
          disabled={!!countBookings}
          max={6}
          partyMembers={payload.partyMembers}
          onChange={this.handlePartyChange}
          productCode={PRODUCT_TYPES.CAMC_MEMBER_PRODUCT}
          isOverseas
          withPortal
          useDob
          lightTheme
          countLead
        />

        {this.props.partyErrors
          .map(({ message }) => (
            <MessageWarningStyle dictionary={message} key={message} marginTop />
          ))
        }

        {payloadErrors
          .filter(this.filterAgainstInlineErrorMatches)
          .map(({ message }) => (
            <MessageWarning
              dictionary={message}
              key={message}
              marginTop
            />
          ))
        }

        {error &&
          <ErrorTypeMessage error={error} marginTop />
        }

        <SubmitWrapper>
          <SearchButton
            ariaLabel="Search Crossings"
            block
            onClick={() => {
              this.updateSubmittedState(true);
              onSubmit(payload);
            }}
            size="large"
            type="button"
            {...testingAttr('search__search-btn')}
          >
            <ButtonIcon
              icon={svgSearch}
            />
            Search Crossings
          </SearchButton>
        </SubmitWrapper>
      </SearchFormCrossingsStyled>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  withTheme,
  graphql(GET_CONFIGURATION),
)(SearchFormCrossings);

import { componentError } from '../../../context/error-context';

const { createError } = componentError('QuoteCrossingAccommodation');

export const InboundAboveMaxQuantity = createError('InboundAboveMaxQuantity');

export const InboundBelowMinQuantity = createError('InboundBelowMinQuantity');

export const OutboundAboveMaxQuantity = createError('OutboundAboveMaxQuantity');

export const OutboundBelowMinQuantity = createError('OutboundBelowMinQuantity');

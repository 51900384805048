import styled, { css } from 'styled-components';

import { field } from '../Form.style';

export const Wrapper = styled.div`
  position: relative;

  ${({ hasSideButton }) => hasSideButton && css`
    flex: 1 1 50%;
  `}
`;

export default styled.input`
  -webkit-appearance: none;
  ${field}
`;

export const InputIcon = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
`;

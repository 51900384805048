import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';

import { isTabletOrSmaller } from '../../lib/helpers/layout';

import { dictionaryItem } from '../../hocs/withDictionary';
import SearchSummaryStyled, {
  Body, Button, Container, Transition,
} from '../SearchSummary/SearchSummary.style';

import { Spinner } from '../ui/Loading/LoadingSpinner.style';
import SearchResultsHeaderStyled, { Loader } from './SearchResultsHeader.style';
import SearchResultsUkItinerary from './SearchResultsUkItinerary';

import svgLoading from '../../static/images/icons/Loading.svg';
import IbePropTypes from '../../IbePropTypes';

function getHeight(element) {
  if (!element) return 0;
  return element.getBoundingClientRect().height;
}

function SearchResultsHeader({
  count, handleClearUkItinerary, loading, renderActions, renderSummary, isCrossings,
  renderEventsToggle, renderToursToggle, type, sticky, activeUkItinerary, total,
}) {
  const [isStuck, setIsStuck] = useState(false);
  const [showSearchSummarySummary, setShowSummary] = useState(false);
  const [distanceOfShowSearchBtnFromTop, setDistanceOfShowSearchBtnFromTop] = useState(null);
  const [innerWindowWidth, setInnerWindowWidth] = useState(null);

  const searchSummaryContainerRef = useRef(null);
  const showSearchButtonRef = useRef(null);

  const searchSummaryContainerHeight = getHeight(searchSummaryContainerRef.current);

  // Top is either 0 or the summary height so only sort row shows sticky
  const top = !isTabletOrSmaller() || showSearchSummarySummary ? 0 : -searchSummaryContainerHeight;

  // Force summary toggle until next scroll event
  function handleToggleSummary(toggle) {
    setShowSummary(toggle);
  }

  function setLayoutOfShowSearchButtonFromScroll() {
    if (!isTabletOrSmaller()) return;
    const boundingTopOfShowSearchButton = showSearchButtonRef.current?.getBoundingClientRect().top;
    setDistanceOfShowSearchBtnFromTop(boundingTopOfShowSearchButton);
  }

  function setInnerWidthFromResize() {
    setInnerWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('scroll', setLayoutOfShowSearchButtonFromScroll, { passive: true });
    window.onresize = setInnerWidthFromResize;
    return () => {
      window.removeEventListener('scroll', setLayoutOfShowSearchButtonFromScroll);
      window.onresize = null;
    };
  }, []);

  useEffect(() => {
    setLayoutOfShowSearchButtonFromScroll();
  }, [isStuck]);

  // On scroll or resize
  useEffect(() => {
    const isTop = distanceOfShowSearchBtnFromTop !== null && distanceOfShowSearchBtnFromTop <= 0;
    setIsStuck(isTop);
    setShowSummary(!isTabletOrSmaller());
  }, [distanceOfShowSearchBtnFromTop, innerWindowWidth]);

  const summaryStyle = { top };

  const buttonStyle = !isStuck || showSearchSummarySummary ? {
    opacity: 0,
    pointerEvents: 'none',
  } : {
    opacity: 1,
  };

  return (
    <SearchSummaryStyled
      sticky={sticky}
      style={summaryStyle}
    >
      <Container ref={searchSummaryContainerRef}>
        <Body paddingBottom="0">
          {renderToursToggle(true)}
        </Body>
        <Body paddingBottom="0" center>
          {renderEventsToggle()}
        </Body>
        <Body>
          {renderSummary && renderSummary()}
        </Body>

        <Transition style={buttonStyle}>
          <Button
            dictionary={dictionaryItem('SearchSummary', 'Minimized')}
            onClick={() => handleToggleSummary(!showSearchSummarySummary)}
            outerRef={showSearchButtonRef}
          />
        </Transition>
      </Container>
      {activeUkItinerary &&
        <SearchResultsUkItinerary
          activeUkItinerary={activeUkItinerary}
          handleClearUkItinerary={handleClearUkItinerary}
        />
      }
      <SearchResultsHeaderStyled>
        {renderActions &&
          renderActions()
        }
        <Loader
          data-testid="search-results-campsite__search-summary__results-count"
        >
          {loading ?
            (
              <>
                <Spinner icon={svgLoading} size="1.25rem" marginRight />
                <span>Loading...</span>
              </>
            ) :
            `${(!isCrossings ? total : count) || 'No'} ${type} found`
          }
        </Loader>
      </SearchResultsHeaderStyled>
    </SearchSummaryStyled>
  );
}

SearchResultsHeader.propTypes = {
  activeUkItinerary: PropTypes.shape(IbePropTypes.activeUkItinerary),
  count: PropTypes.number,
  handleClearUkItinerary: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isCrossings: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  renderActions: PropTypes.func,
  renderSummary: PropTypes.func,
  renderEventsToggle: PropTypes.func,
  renderToursToggle: PropTypes.func,
  sticky: PropTypes.bool,
  total: PropTypes.number,
};

SearchResultsHeader.defaultProps = {
  activeUkItinerary: null,
  count: 0,
  handleClearUkItinerary: () => { },
  renderActions: null,
  renderSummary: null,
  sticky: true,
  renderEventsToggle: () => { },
  renderToursToggle: () => { },
  total: 0,
};

export default memo(SearchResultsHeader);

import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import StyledEventButton, { Icon } from './EventButton.style';

function EventButton({
  disabled,
  onClick,
  active,
  event,
  theme,
}) {
  return (
    <StyledEventButton
      disabled={disabled}
      align="center"
      textTransform="none"
      block
      active={active}
      onClick={onClick}
      type="button"
    >
      <Icon
        color={theme[`COLOR_EVENT_TYPE_${event.id}`]}
        active={active}
        largeIcon={event.largeIcon}
        icon={event.icon}
      />
      <span>{event.title}</span>
    </StyledEventButton>
  );
}

EventButton.propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  event: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    icon: PropTypes.string,
    largeIcon: PropTypes.bool,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

EventButton.defaultProps = {
  disabled: false,
  active: false,
};

export default withTheme(EventButton);

import styled from 'styled-components';

import TextStyled from '../ui/Text/Text.style';
import { Select as SelectComponent } from '../ui/Form';

export default styled.div``;

export const Header = styled.header`
  display: flex;
`;

export const Select = styled(SelectComponent)`
  margin-left: 1rem;
`;

export const Name = styled(TextStyled)`
  font-size: 0.875rem;
  font-weight: bold;
  padding-top: 0.125em;
`;

import {
  OVERSEAS_CROSSINGS_TAB,
  OVERSEAS_SITES_TAB,
  UK_SITES_TAB,
} from '../../constants/searchTabs';

const isTabSelected = (router, tab) => {
  const isCrossingsTab =
    router?.pathname?.includes(OVERSEAS_CROSSINGS_TAB.href);
  const isOverseas = router?.query?.isOverseas === 'true';

  if (isCrossingsTab) {
    return tab.id === OVERSEAS_CROSSINGS_TAB.id;
  }
  if (isOverseas) {
    return tab.id === OVERSEAS_SITES_TAB.id;
  }
  return isCrossingsTab ? false : tab.id === UK_SITES_TAB.id;
};

export default isTabSelected;

import { isNoneNullOrUndefined } from '../../lib/helpers/outfits';

// @ts-ignore
export function replaceTemplateString(template, replacements) {
  // Replace the match with the corresponding replacement value or the match itself if not found
  return template.replace(/\{\{(\w+)\}\}/g, (match, key) => (
    replacements[key] || match));
}

/**
 * Used in both length and height to find a length or height error exists, and returns the
 * max length or height. If provided, takes into account business logic omitting max space lengths.
 *
 * the vehicle max length or height e.g. for a motorhome type 12000mm.
 * @param {number} maxLengthOrHeightFromOutfitType
 *  The max allowed for the space e.g. the pitch can only fit 10000mm
 * @param {number} maxOutfitLengthOrHeightFromConfig
 *  Flag used to not take into account maxOutfitLengthOrHeightFromConfig
 * @param {boolean | undefined} ignoreMaxOutfitLengthFromPitch
 * Fallback max from general config if available.
 * @param {number | undefined} defaultFallbackMaxOverallLengthOrHeightFromConfig
 *
 * @returns {number} - The number of the lowest max height allowed and its source.
 */
export function getLowestMaxLengthOrHeightValue(
  maxLengthOrHeightFromConfigType,
  maxOutfitLengthOrHeightFromConfig,
  ignoreMaxOutfitLengthFromPitch,
  defaultFallbackMaxOverallLengthOrHeightFromConfig,
) {
  // Default fallback if amounts are undefined
  if (
    maxLengthOrHeightFromConfigType === undefined
    && maxOutfitLengthOrHeightFromConfig === undefined
  ) {
    return defaultFallbackMaxOverallLengthOrHeightFromConfig || 10000;
  }

  if (ignoreMaxOutfitLengthFromPitch) return maxLengthOrHeightFromConfigType;

  // Get the lowest max value of two
  return Math.min(
    maxLengthOrHeightFromConfigType ?? Infinity,
    maxOutfitLengthOrHeightFromConfig ?? Infinity,
  );
}

export const foundVehicleLength = (vehicleType, vehicleTypes) => {
  if (isNoneNullOrUndefined(vehicleType)) {
    return {
      doesVehicleNeedToFitOnPitch: false, foundVehicleType: null,
    };
  }
  const foundVehicleType = vehicleTypes.find(type => type.key === vehicleType);
  if (!foundVehicleType) return { doesVehicleNeedToFitOnPitch: false, foundVehicleType: null };
  const omitPitchLengthValidation = !!foundVehicleType.omitPitchLengthValidation;
  return {
    doesVehicleNeedToFitOnPitch: !omitPitchLengthValidation, foundVehicleType,
  };
};

export const foundTowLength = (towType, towTypes) => {
  if (isNoneNullOrUndefined(towType)) return { doesTowNeedToFitOnPitch: false, foundTowType: null };
  const foundTowType = towTypes.find(type => type.key === towType);
  if (!foundTowType) return { doesTowNeedToFitOnPitch: false, foundTowType: null };
  const omitPitchLengthValidation = !!foundTowType.omitPitchLengthValidation;

  return { doesTowNeedToFitOnPitch: !omitPitchLengthValidation, foundTowType };
};

export const foundVehicleHeight = (vehicleType, vehicleTypes) => {
  if (isNoneNullOrUndefined(vehicleType)) return undefined;
  const foundVehicleType = vehicleTypes.find(type => type.key === vehicleType);
  return foundVehicleType;
};

export const foundTowHeight = (towType, towTypes) => {
  if (isNoneNullOrUndefined(towType)) return undefined;
  const foundTowType = towTypes.find(type => type.key === towType);
  return foundTowType;
};

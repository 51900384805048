import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import StyledFieldset, { Input } from './Fieldset.style';

import { DATE_FORMAT_DISPLAY } from '../../../../../config/locale';

const defaultProps = {
  endDate: null,
  startDate: null,
  isDateRange: false,
};

const propTypes = {
  endDate: PropTypes.shape(),
  startDate: PropTypes.shape(),
  isDateRange: PropTypes.bool,
};

function Fieldset(props) {
  function parseDate(date) {
    if (!date) return '';
    return format(date, DATE_FORMAT_DISPLAY);
  }

  return (
    <StyledFieldset>
      <div>
        <Input type="text" value={parseDate(props.startDate)} placeholder="Start Date" readOnly disabled />
      </div>
      {props.isDateRange && (
        <React.Fragment>
          <div>to</div>
          <div>
            <Input type="text" value={parseDate(props.endDate)} placeholder="End Date" readOnly disabled />
          </div>
        </React.Fragment>
      )}
    </StyledFieldset>
  );
}

Fieldset.defaultProps = defaultProps;
Fieldset.propTypes = propTypes;

export default Fieldset;

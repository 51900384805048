import styled, { css } from 'styled-components';
import { Datepicker } from '../ui/Datepicker';
import InputWithIcon from '../ui/Form/InputWithIcon/InputWithIcon';

import { cssField, focusStateReset } from '../ui/Form/Form.style';

export default styled(Datepicker)`
  display: flex;
`;

export const DateInput = styled(InputWithIcon)`
  padding: 0 0 0 1rem;
  text-transform: uppercase;
  ${focusStateReset}
`;

export const Flex = styled.div`
  display: flex;
`;

export const DateGroup = styled.div`
  flex: 1;
`;

export const DateTimePickerField = styled.div`
  ${cssField}
  display: flex;
  padding: 0;
  overflow: hidden;
  ${({ customStyled }) => customStyled && css`${customStyled}`}
`;

export const TimeGroup = styled.div`
  flex: 0 0 6rem;
  margin-left: -0.75rem;
`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { cloneDeep } from 'lodash';

import AmendView from '../AmendView';
import Text from '../../ui/Text';
import Title from '../../ui/Title';
import { Row, Col } from '../../ui/Grid';
import { dictionaryItem } from '../../../hocs/withDictionary';
import { ValuePair, Label, Value } from './AmendForm.style';
import { ADULT, CHILD, INFANT } from '../../../lib/partyMemberTypes';
import { DATE_FORMAT_INPUT } from '../../../config/locale';
import formatDate from '../../../lib/format';
import IbePropTypes from '../../../IbePropTypes';
import { PartySelect } from '../../PartyForm';
import { mapPartyType, partyConfig, personTypes } from '../../../config/personType';
import { isMobileOrSmaller } from '../../../lib/helpers/layout';

const PartyMemberDetails = ({ member, isOverseas }) => {
  const isMobile = isMobileOrSmaller();
  return (
    <Row flexDirection={isMobile ? 'column' : 'row'} wrap={!isMobile}>
      {member.isLead &&
      <Col>
        <ValuePair>
          <Label dictionary={dictionaryItem('AmendForm', 'Party', 'Title')} />
          <Value>{member.fullName?.prefix}</Value>
        </ValuePair>
      </Col>}
      {member.isLead &&
      <Col>
        <ValuePair>
          <Label dictionary={dictionaryItem('AmendForm', 'Party', 'FirstName')} />
          <Value>{member.fullName?.firstName}</Value>
        </ValuePair>
      </Col>}
      {member.isLead &&
      <Col>
        <ValuePair>
          <Label dictionary={dictionaryItem('AmendForm', 'Party', 'LastName')} />
          <Value>{member.fullName?.surname}</Value>
        </ValuePair>
      </Col>}
      {isOverseas &&
        <Col>
          <ValuePair>
            <Label dictionary={dictionaryItem('AmendForm', 'Party', 'DateOfBirth')} />
            <Value>{formatDate(member.dateOfBirth, DATE_FORMAT_INPUT)}</Value>
          </ValuePair>
        </Col>
      }
    </Row>
  );
};

PartyMemberDetails.propTypes = {
  member: PropTypes.shape(IbePropTypes.membershipMember).isRequired,
  isOverseas: PropTypes.bool.isRequired,
};

const AmendParty = ({
  configuration,
  partyMembers,
  onEditPartyMember,
  setPartyMembers,
  isOverseas,
  max,
}) => {
  const [removingMember, setRemovingMember] = useState(null);
  const leadMember = partyMembers.find((member) => member.isLead);
  const adults = partyMembers.filter(
    (member) => !member.isLead && member.type === ADULT,
  );
  const children = partyMembers.filter((member) => member.type === CHILD);
  const infants = partyMembers.filter((member) => member.type === INFANT);

  const shouldShowConfirm = (member) => {
    if (!removingMember) {
      return false;
    }
    return removingMember.personId
      ? removingMember.personId === member.personId
      : removingMember.tempId === member.tempId;
  };

  const onRemove = (member) => {
    setRemovingMember(member);
  };

  const onRemoveConfirm = () => {
    if (!removingMember) {
      return;
    }
    setPartyMembers(
      partyMembers.filter((partyMember) => (removingMember.personId
        ? partyMember.personId !== removingMember.personId
        : partyMember.tempId !== removingMember.tempId)),
    );
    setRemovingMember(null);
  };

  const actualPartyMembers = partyMembers.filter((member) => !member.isLead);

  const handlePartyMemberChange = (e) => {
    // This is only ever used on UK-sites only
    const clonedPartyMembers = cloneDeep(partyMembers);
    configuration.partyMemberTypes.forEach((type) => {
      const currentMembers =
        actualPartyMembers.filter((member) => member.type === type.key) ?? [];
      const updatedMembers =
        e.find((returned) => returned.type === type.key) ?? {};
      const difference = updatedMembers.value - currentMembers.length ?? 0;
      const newMember = {
        type: type.key,
        personName: {
          __typename: 'PersonName',
          prefix: '.',
          firstName: '.',
          surname: '.',
        },
        personId: 0,
        __typename: 'PartyMember',
        isLead: false,
      };
      if (difference > 0) {
        // Member has been added
        clonedPartyMembers.push(newMember);
      } else if (difference < 0) {
        // Member has been removed
        const foundIndex = clonedPartyMembers.findIndex(
          (val) => val.type === type.key && !val.isLead,
        );
        clonedPartyMembers.splice(foundIndex, 1);
      }
    });
    setPartyMembers(clonedPartyMembers);
  };

  // Formatting party data for use in PartySelect component
  const partyMemberTypes = configuration?.partyMemberTypes;
  const partyMemberData =
    partyMemberTypes?.map((type) => {
      const members = actualPartyMembers.filter(
        (member) => member.type === type.key,
      );
      const config = partyConfig.find(
        (configType) => configType.type === type.key,
      );
      return {
        ...type,
        ...config,
        label: mapPartyType[type.key],
        value: members.length,
        min: 0,
      };
    }) ?? [];

  const childType = partyConfig.find((member) => member.type === personTypes.CHILDREN);
  const childToAge = childType.toAge;

  return (
    <>
      <Title
        marginBottom="0.5rem"
        tag={1}
        size={4}
        dictionary={dictionaryItem(
          'AvailabilityAmend',
          'Party',
          'LeadPassenger',
        )}
      />
      <Text
        size="0.85rem"
        marginBottom
        dictionary={dictionaryItem(
          'AvailabilityAmend',
          'Party',
          'LeadPassenger',
        )}
      />
      {leadMember && (
        <AmendView
          value="Adult 1"
          buttonLabelDictionary="EditDetails"
          onClick={() => onEditPartyMember(leadMember)}
          isOverseas={isOverseas}
          isLead
        >
          <PartyMemberDetails member={leadMember} isOverseas={isOverseas} />
        </AmendView>
      )}
      <Title
        marginTop="2rem"
        marginBottom="0.5rem"
        tag={1}
        size={4}
        dictionary={dictionaryItem(
          'AvailabilityAmend',
          'Party',
          'AdditionalPassenger',
        )}
      />
      <Text
        size="0.85rem"
        marginBottom
        dictionary={dictionaryItem(
          'AvailabilityAmend',
          'Party',
          'AdditionalPassenger',
        )}
      />
      {isOverseas ? (
        <>
          {adults.map((adult, i) => (
            <AmendView
              value={`Adult ${i + 2}`}
              buttonLabelDictionary="EditDetails"
              onClick={() => onEditPartyMember(adult, i)}
              onRemove={() => onRemove(adult)}
              onRemoveCancel={() => setRemovingMember(null)}
              onRemoveConfirm={onRemoveConfirm}
              showConfirm={shouldShowConfirm(adult)}
              isOverseas={isOverseas}
            >
              <PartyMemberDetails member={adult} isOverseas={isOverseas} />
            </AmendView>
          ))}
          {children.map((child, i) => (
            <AmendView
              value={`Child ${i + 1}`}
              buttonLabelDictionary="EditDetails"
              onClick={() => onEditPartyMember(child, i)}
              onRemove={() => onRemove(child)}
              onRemoveCancel={() => setRemovingMember(null)}
              onRemoveConfirm={onRemoveConfirm}
              showConfirm={shouldShowConfirm(child, i)}
              isOverseas={isOverseas}
            >
              <PartyMemberDetails member={child} isOverseas={isOverseas} />
            </AmendView>
          ))}
          {infants.map((infant, i) => (
            <AmendView
              value={`Infant ${i + 1}`}
              buttonLabelDictionary="EditDetails"
              onClick={() => onEditPartyMember(infant, i)}
              onRemove={() => onRemove(infant)}
              onRemoveCancel={() => setRemovingMember(null)}
              onRemoveConfirm={onRemoveConfirm}
              showConfirm={shouldShowConfirm(infant, i)}
              isOverseas={isOverseas}
            >
              <PartyMemberDetails member={infant} isOverseas={isOverseas} />
            </AmendView>
          ))}
        </>
      ) : (
        <>
          <PartySelect
            childToAge={childToAge}
            countLead={false}
            block
            disabled={false}
            data={partyMemberData}
            isOverseas={isOverseas}
            onChange={handlePartyMemberChange}
            partyMembers={partyMembers}
            partyMemberTypes={configuration.partyMemberTypes}
            options={{
              max,
            }}
          />
        </>
      )}
    </>
  );
};

AmendParty.propTypes = {
  configuration: PropTypes.shape(IbePropTypes.configuration).isRequired,
  partyMembers: PropTypes.arrayOf(
    PropTypes.shape(IbePropTypes.partyMember),
  ).isRequired,
  onEditPartyMember: PropTypes.func.isRequired,
  setPartyMembers: PropTypes.func.isRequired,
  isOverseas: PropTypes.bool.isRequired,
  max: PropTypes.number.isRequired,
};

export default withApollo(AmendParty);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'next/router';
import { uniqBy, cloneDeep } from 'lodash';

import buildPath from '../../lib/helpers/restLink';
import updateRouterQuery from '../../lib/updateRouterQuery';
import { defaults as defaultCampsite } from '../../resolvers/campsite';
import { stringifyPartyMembers } from '../../lib/helpers/party';
import {
  validateOutfitCampsite,
  validateParty,
  validateArrivalTime,
} from '../../lib/validation/availability';
import { getTotalCost, sortPitches } from '../../lib/availability';
import isLoggedIn, { isLoggedInMember } from '../../lib/isLoggedIn';

import Text from '../ui/Text';
import { MessageWarning } from '../ui/Message';
import LoadingSpinner from '../ui/Loading/LoadingSpinner';

import { QuoteCreate } from '../Quote';
import { partyConfig } from '../../config/personType';
import { dictionaryItem } from '../../hocs/withDictionary';
import GET_PITCHES from './graphql/getPitches.gql';
import GET_AVAILABILITY_AND_USER_CONFIG from './graphql/getAvailabilityAndUserConfig';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import PLACE_EVENT_TYPES, { SINGLE_EVENT_TYPE } from '../../config/eventTypes';
import { isMembershipInBasket } from '../../config/quoteMembershipTypes';
import IbePropTypes from '../../IbePropTypes';
import { ids, keys } from '../../config/campsiteTypes';

import {
  AvailabilityDuration,
  AvailabilityParty,
  AvailabilityPitches,
} from '.';
import { getErrorElement } from '../../lib/helpers/availability';

import AvailabilityOutfitDetails from './AvailabilityOutfitDetails';
import AvailabilityArrivalTime from './AvailabilityArrivalTime';
import ErrorTypeMessage from '../ErrorTypeMessage';
import CampingGuestsHeader from '../Quote/QuoteCampingGuests/CampingGuestsHeader';

import StyledAvailability, {
  Body,
} from './Availability.style';
import { getIsOverseas } from './helpers';
import AvailabilityDurationInfo from './AvailabilityDurationInfo';

// TODO: If needed, fix validation here to 2DP. This advanced guest feature
// is currently turned off and may now be redundant. See CAMC23D-128
class AvailabilityAdvancedGuest extends Component {
  static propTypes = {
    availabilitySearch: PropTypes.shape({
      calendarFirstCellDate: PropTypes.string,
      data: PropTypes.shape({
        availability: PropTypes.shape({
          pitchTypes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
      error: PropTypes.shape(IbePropTypes.error),
      loading: PropTypes.bool,
      networkStatus: PropTypes.number,
    }),
    availabilitySearchLoading: PropTypes.bool,
    availabilityTotal: PropTypes.shape({
      error: PropTypes.shape(IbePropTypes.apolloError),
      loading: PropTypes.bool,
      data: PropTypes.shape({
        availability: PropTypes.shape({
          pitchTypes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    }),
    closedDateError: PropTypes.shape({
      message: PropTypes.string,
    }),
    disabledForms: PropTypes.bool.isRequired,
    getPitches: PropTypes.shape({
      campsite: PropTypes.shape(IbePropTypes.campsite),
      error: PropTypes.shape(IbePropTypes.apolloError),
      loading: PropTypes.bool,
    }),
    data: PropTypes.shape({
      user: PropTypes.shape(IbePropTypes.user),
      loading: PropTypes.bool,
      error: PropTypes.shape(IbePropTypes.apolloError),
      dictionary: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    configuration: PropTypes.shape(IbePropTypes.configuration),
    campsiteId: PropTypes.string,
    continued: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    fetchAvailabilitySearch: PropTypes.func.isRequired,
    fetchAvailabilityTotal: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    inputDateError: PropTypes.shape({
      message: PropTypes.string,
    }),
    loading: PropTypes.bool,
    loadingAvailability: PropTypes.bool,
    prices: PropTypes.shape({
      count: PropTypes.number,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          error: PropTypes.shape(IbePropTypes.apolloError),
          loading: PropTypes.bool,
        }),
      ),
    }),
    onContinueClick: PropTypes.func.isRequired,
    onQueryChange: PropTypes.func.isRequired,
    payload: PropTypes.shape(IbePropTypes.quotePayload),
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    updatePayload: PropTypes.func.isRequired,
    query: PropTypes.shape(IbePropTypes.query),
    selectedEvent: PropTypes.shape(IbePropTypes.event),
    handleMemberNonMemberChange: PropTypes.func.isRequired,
    memberPricesAreSelected: PropTypes.bool.isRequired,
    product: PropTypes.shape(IbePropTypes.product),
    quote: PropTypes.shape(IbePropTypes.quote),
    client: PropTypes.shape(IbePropTypes.client).isRequired,
  };

  static defaultProps = {
    availabilitySearch: {
      data: {
        availability: {
          pitchTypes: [],
        },
      },
    },
    availabilitySearchLoading: false,
    availabilityTotal: {
      data: {
        availability: {
          pitchTypes: [],
        },
      },
    },
    closedDateError: undefined,
    getPitches: {
      error: false,
      campsite: defaultCampsite.campsite,
    },
    inputDateError: null,
    data: {},
    campsiteId: null,
    continued: false,
    error: false,
    loading: false,
    loadingAvailability: false,
    prices: {
      count: 0,
      data: [],
    },
    query: {},
    product: {},
    configuration: {},
    payload: {},
    selectedEvent: null,
    quote: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      outfitFieldsTouched: {
        vehicleLength: !!props.payload.outfit.vehicleLength,
        vehicleType: !!props.payload.outfit.vehicleType,
        towLength: !!props.payload.outfit.towLength,
        towType: !!props.payload.outfit.towType,
      },
      outfitLengthErrors: [],
      outfitErrors: [],
      fieldFocus: '',
      arrivalTimeErrors: [],
      partyErrors: [],
      sortType: undefined,
    };
  }

  componentDidMount() {
    this.props.fetchAvailabilitySearch();
    this.props.fetchAvailabilityTotal();
  }

  handleSortTypeChange = (sortType) => {
    this.setState({ sortType });
  }

  maxCampsitePartyLength = (pitches) => {
    if (!pitches.length) return 0;

    const maxPeopleInCampsite = pitches
      .map((pitch) => pitch.maxOccupancy)
      .reduce((acc, cur) => Math.max(acc, cur));

    return maxPeopleInCampsite;
  };

  handleUpdateMembers = (payload, start, end) => {
    const payloadCopy = cloneDeep(payload);
    const amendedPayload = {
      ...payloadCopy,
      start,
      end,
      partyMembers: payloadCopy.partyMembers.map((member) => ({
        ...member,
        stayStart: start,
        stayEnd: end,
      })),
    };
    return amendedPayload;
  }

  handleResetDates = async () => {
    const {
      fetchAvailabilityTotal,
      handleChange,
      onQueryChange,
      payload,
      query,
      updatePayload,
    } = this.props;
    const copiedQuery = { ...query };

    const mutatedPayload = cloneDeep(payload);

    mutatedPayload.start = undefined;
    mutatedPayload.end = undefined;

    await updatePayload(mutatedPayload);

    await handleChange({ continued: false });

    await onQueryChange({
      ...copiedQuery,
      start: mutatedPayload.start,
      end: mutatedPayload.end,
    });

    await updateRouterQuery(
      '/sites',
      {
        start: mutatedPayload.start,
        end: mutatedPayload.end,
      },
      'replace',
    );

    await handleChange(mutatedPayload);

    await fetchAvailabilityTotal(mutatedPayload);
  };

  handleCalendarMonthChange = (response) => {
    this.props.handleChange(response, this.props.fetchAvailabilitySearch);
  };

  handleDurationChange = async (duration) => {
    const {
      fetchAvailabilityTotal,
      handleChange,
      onQueryChange,
      payload,
      query,
      updatePayload,
    } = this.props;
    const copiedQuery = { ...query };
    const [start, end] = duration;

    const updatedPayload = this.handleUpdateMembers(payload, start, end);

    await updatePayload(updatedPayload);

    await handleChange({ continued: false });

    await onQueryChange({
      ...copiedQuery,
      start,
      end,
    });

    await updateRouterQuery(
      '/sites',
      {
        start: payload.start,
        end: payload.end,
      },
      'replace',
    );

    await handleChange(updatedPayload);

    await fetchAvailabilityTotal(updatedPayload);
  };

  handlePartyChange = async (payload, skipValidation = false) => {
    await this.props.updatePayload(payload);

    const partyErrors = skipValidation
      ? []
      : validateParty(payload.partyMembers, true, getIsOverseas(this.props.router));
    await this.setState({
      partyErrors,
    });

    this.props.fetchAvailabilitySearch();
    this.props.fetchAvailabilityTotal();
  };

  handleArrivalTimeChange = async (arrivalTime) => {
    const { payload, updatePayload } = this.props;
    payload.arrivalTime = arrivalTime;
    this.setState({
      arrivalTimeErrors: [],
    });
    updatePayload(payload);

    this.props.handleChange({ continued: false });
  };

  handlePitchChange = ({ id, maxOccupancy, name }) => {
    const payload = { ...this.props.payload };
    payload.pitchTypeId = id;
    payload.specReq = payload.specReq || [];
    this.props.handleChange({
      continued: false,
      payload,
      maxOccupancy,
      pitchName: name,
    });
  };

  handleOutfitLengthError = (errors) => {
    this.setState({
      outfitLengthErrors: [...errors],
    });
  };

  handleOutfitBlur = ({ target }) => {
    const { name } = target;
    const { outfitFieldsTouched } = this.state;

    this.setState({
      outfitFieldsTouched: {
        ...outfitFieldsTouched,
        [name]: true,
      },
    });
  };

  // Replace quote payload outfit prop with changed value
  handleOutfitDetailsChange = async (outfit) => {
    const { payload, updatePayload } = this.props;
    payload.outfit = outfit;
    await updatePayload(payload);

    await this.validate(payload);

    this.props.handleChange({ continued: false });
  };

  handleSubmit = async () => {
    this.setState({ submitted: true });
    await this.validate(this.props.payload, true);

    const element = getErrorElement();
    if (element) {
      return element.focus();
    }

    return this.props.onContinueClick(true);
  };

  // Get the most recently focused date input field and set it in state
  handleFieldFocus = (name) => {
    this.setState({ fieldFocus: name });
  };

  validate = (payload, submit = false) => {
    const { configuration, router, client } = this.props;
    const isOverseas = getIsOverseas(router);
    const outfitErrors = validateOutfitCampsite(
      payload.outfit, isOverseas, configuration, router, client,
    );
    const partyErrors = validateParty(payload.partyMembers, true);
    const arrivalTimeErrors = submit ? validateArrivalTime(payload.arrivalTime) : [];

    this.setState({
      outfitErrors: [...outfitErrors, ...this.state.outfitLengthErrors],
      partyErrors,
      arrivalTimeErrors,
    });
  };

  render() {
    const {
      availabilitySearch,
      availabilitySearchLoading,
      availabilityTotal,
      campsiteId,
      continued,
      disabledForms,
      getPitches,
      data,
      data: { dictionary },
      error,
      configuration,
      loadingAvailability,
      loading,
      payload,
      handleMemberNonMemberChange,
      memberPricesAreSelected,
      product,
      router,
      quote,
    } = this.props;

    const {
      outfitErrors,
      outfitFieldsTouched,
      outfitLengthErrors,
      submitted,
      sortType,
    } = this.state;

    const uniqueOutfitErrors = uniqBy([
      ...outfitLengthErrors,
      ...outfitErrors,
    ]);
    const filteredOutfitErrors = submitted
      ? uniqueOutfitErrors
      : uniqueOutfitErrors.filter(({ type }) => {
        if (type === 'outfit') {
          return outfitFieldsTouched.towType;
        }

        return outfitFieldsTouched[type];
      });

    // If any of the queries are still loading
    if (
      loadingAvailability ||
      (!availabilitySearch.networkStatus && !availabilitySearch.error) ||
      availabilitySearch.networkStatus < 7 ||
      getPitches.loading ||
      data.loading ||
      loading ||
      !configuration
    ) {
      return <LoadingSpinner marginTop />;
    }

    if (getPitches.error || !getPitches.campsite) {
      return <ErrorTypeMessage error={getPitches.error} />;
    }

    const { availability } = availabilitySearch.data;

    const pitches = getPitches.campsite.pitches
      .map((pitch) => {
        const pitchAvailabilitySearch = availability.pitchTypes.find(
          ({ code }) => code === pitch.id,
        );
        const totalCost = getTotalCost(
          availabilityTotal,
          payload.pitchTypeId,
          memberPricesAreSelected,
        );

        const availabilityTotalPitch =
          availabilityTotal.data.availability.pitchTypes.find(
            (pitchType) => pitchType.code === pitch.id,
          );

        return {
          ...pitch,
          ...pitchAvailabilitySearch,
          calendarFirstCellDate: availabilitySearch.calendarFirstCellDate,
          selected: pitch.id === payload.pitchTypeId,
          restrictions: availabilityTotalPitch
            ? availabilityTotalPitch.restrictions
            : null,
          totalCost,
        };
      })
      .filter((pitchItem) => {
        const eventType =
          Number(router.query?.eventType) || PLACE_EVENT_TYPES.CAMPING.id;
        const isOverseas = Number(router.query?.isOverseas === 'true');
        return (
          pitchItem.eventType === eventType ||
          (isOverseas && pitchItem.eventType === SINGLE_EVENT_TYPE.id)
        );
      });

    const sortedPitches =
      sortPitches(pitches, memberPricesAreSelected ? 'memberMinPricePerNight' : 'nonMemberMinPricePerNight', sortType);

    const selectedPitch =
      sortedPitches.find(({ id }) => id === payload.pitchTypeId) || {};

    const noAvailability = !selectedPitch.totalCost;

    const partyString = stringifyPartyMembers(
      payload.partyMembers,
      configuration?.partyMemberTypes,
    );

    // Disabled because its handled by BE
    let invalidDates = false;
    if (
      error &&
      error.networkError &&
      error.networkError.result &&
      error.networkError.result.errorCode === 3
    ) {
      invalidDates = true;
    }

    if (this.props.closedDateError) {
      invalidDates = true;
    }

    // Check outfit vehicle exists and has a length value
    const invalidOutfit =
      !payload.outfit.vehicleType ||
      payload.outfit.vehicleLength === null ||
      Number.isNaN(payload.outfit.vehicleLength);

    // TODO: Find a dynamic way to set the party max amount of type
    partyConfig[0].max = selectedPitch.maxOccupancy;
    partyConfig[1].max = selectedPitch.maxOccupancy - 1;
    partyConfig[2].max = selectedPitch.maxOccupancy - 1;

    const dictionaryMap = Object.assign(
      {},
      ...dictionary.map((obj) => ({ [obj.key]: obj.value })),
    );

    const userIsMember =
      isLoggedInMember(this.props.data?.user) || isMembershipInBasket(quote);

    const isUkClubSite = getPitches.campsite.type === ids[keys.UK_CLUB_SITES];
    const isOverseasSite = getPitches.campsite.type === ids[keys.OVERSEAS_SITE];

    const nonMemberBookableDate =
      product?.nonMemberBookableDate || configuration.availabilityMaxDate;
    const memberBookableDate =
      product?.memberBookableDate || configuration.availabilityMaxDate;

    const eventType = Number(router.query?.eventType ?? PLACE_EVENT_TYPES.CAMPING.id);

    return (
      <StyledAvailability>
        <Body>
          <CampingGuestsHeader
            title={dictionaryItem('AvailabilityAdvancedGuests')}
            tooltipText={dictionaryItem('AvailabilityAdvancedGuests', 'Tooltip')}
            tooltipContent={dictionaryItem('AvailabilityAdvancedGuests', 'Tooltip')}
          >
            <Text dictionary={dictionaryItem('AvailabilityAdvancedGuests', 'Description')} />
          </CampingGuestsHeader>
        </Body>

        <Body>
          <AvailabilityParty
            data={partyConfig}
            disabled={disabledForms}
            max={this.maxCampsitePartyLength(getPitches.campsite.pitches)}
            maxForCurrentPitch={selectedPitch.maxOccupancy}
            onChange={this.handlePartyChange}
            maxChildAge={selectedPitch.maxChildAge}
            minChildAge={selectedPitch.minChildAge}
            payload={payload}
            dataError={!!this.state.partyErrors?.length}
            productCode={product.productCode}
            isOverseas={isOverseasSite}
            useDob
          />

          {this.state.partyErrors.map((err) => (
            <MessageWarning
              key={err.message}
              marginTop
              dictionary={err.message}
            />
          ))}
        </Body>

        <Body>
          <AvailabilityOutfitDetails
            disabled={disabledForms}
            maxOutfitLength={
              selectedPitch.maxOutfitLength ||
              configuration.defaultMaxVehicleOverallLength
            }
            error={invalidOutfit}
            onError={this.handleOutfitLengthError}
            onUserOutfitChange={this.handleUserOutfitChange}
            onOutfitBlur={this.handleOutfitBlur}
            onOutfitChange={this.handleOutfitDetailsChange}
            outfit={payload.outfit}
            outfitErrors={filteredOutfitErrors}
            eventType={eventType}
            isOverseas={isOverseasSite}
          />

          {filteredOutfitErrors.map(err => (
            <MessageWarning
              key={err.message}
              marginTop
              dictionary={err.message}
            />
          ))}
        </Body>

        <Body>
          <AvailabilityPitches
            campsiteId={campsiteId}
            disabled={disabledForms}
            handlePitchChange={this.handlePitchChange}
            loading={loadingAvailability}
            memberPricesAreSelected={memberPricesAreSelected}
            pitches={sortedPitches}
            pitchTypesLink={configuration.pitchTypesLink}
            pitchTypeId={payload.pitchTypeId}
            showPrice={!!payload.start && !!payload.end}
            sortType={sortType}
            handleSortTypeChange={this.handleSortTypeChange}
          />
        </Body>

        <Body>
          <AvailabilityDuration
            availability={availability}
            availabilitySearchLoading={availabilitySearchLoading}
            disabled={disabledForms}
            error={!selectedPitch}
            endDate={payload.end}
            fetchAvailability={this.handleCalendarMonthChange}
            loading={loadingAvailability}
            memberBookableDate={memberBookableDate}
            memberPricesAreSelected={memberPricesAreSelected}
            noAvailability={noAvailability}
            nonMemberBookableDate={nonMemberBookableDate}
            onGoToMemberPrices={handleMemberNonMemberChange}
            onCancelMemberPrices={this.handleResetDates}
            onRequestText={
              dictionaryMap.AvailabilityDuration__OnRequest__Button
            }
            userIsMember={userIsMember}
            handleFieldFocus={this.handleFieldFocus}
            onChange={this.handleDurationChange}
            options={{
              dateRange: true,
              handleFieldFocus: this.handleFieldFocus,
              maxDate: payload.maxDate,
              minDate: payload.minDate,
              months: 1,
              name: 'campsiteAvailability_dateRange',
              showRemainder: false,
              static: true,
            }}
            fieldFocus={this.state.fieldFocus}
            selectedEvent={this.props.selectedEvent}
            selectedPitch={selectedPitch}
            startDate={payload.start}
            dataError={!!this.props.inputDateError || !!this.props.error}
            pitches={sortedPitches}
            handlePitchChange={this.handlePitchChange}
          />

          {!!this.props.inputDateError && (
            <MessageWarning
              key={this.props.inputDateError.message}
              marginTop
              marginBottom
              dictionary={this.props.inputDateError.message}
            />
          )}

          {!!this.props.closedDateError && (
            <MessageWarning
              key={this.props.closedDateError.message}
              marginTop
              marginBottom
              dictionary={this.props.closedDateError.message}
            />
          )}

          {selectedPitch.code && (
            <AvailabilityDurationInfo
              selectedPitch={selectedPitch}
              partyString={partyString}
              memberPricesAreSelected={memberPricesAreSelected}
              startDate={payload.start}
              endDate={payload.end}
            />
          )}
        </Body>

        {(selectedPitch.fromArrivalTime > -1 && isUkClubSite) ? (
          <Body>
            <AvailabilityArrivalTime
              start={selectedPitch.fromArrivalTime}
              end={selectedPitch.toArrivalTime}
              onChange={this.handleArrivalTimeChange}
              arrivalTimeErrors={this.state.arrivalTimeErrors}
              value={payload.arrivalTime}
            />
            {this.state.arrivalTimeErrors.map(err => (
              <MessageWarning
                key={err.message}
                marginTop
                dictionary={err.message}
              />
            ))}
          </Body>
        ) : null}

        {this.props.availabilityTotal.error && (
          <ErrorTypeMessage
            error={this.props.availabilityTotal.error}
            marginBottom
          />
        )}

        {!continued && !invalidDates && (
          <Body>
            <QuoteCreate
              disabled={disabledForms || !!selectedPitch.restrictions}
              handleContinueClick={this.handleSubmit}
              loading={loading || availabilityTotal.loading}
              payload={payload}
            />

            {data.error && (
              <ErrorTypeMessage errorCode={data.error} marginBottom />
            )}

            {getPitches.error && (
              <ErrorTypeMessage errorCode={getPitches.error} marginBottom />
            )}
          </Body>
        )}
      </StyledAvailability>
    );
  }
}

export default compose(
  withRouter,
  graphql(GET_CONFIGURATION, {
    props: ({ data }) => ({
      configuration: data.configuration,
      loading: data.loading,
    }),
  }),
  graphql(GET_AVAILABILITY_AND_USER_CONFIG, {
    options: () => ({
      variables: {
        dictionaryKeys: ['AvailabilityDuration__OnRequest__Button'],
        isLoggedIn: isLoggedIn(),
        pathBuilder: buildPath('dictionary/find'),
      },
    }),
  }),
  graphql(GET_PITCHES, {
    name: 'getPitches',
    options: ({ campsiteId }) => ({
      variables: {
        id: campsiteId,
        skip: !campsiteId,
      },
    }),
  }),
)(AvailabilityAdvancedGuest);

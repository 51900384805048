import styled, { css } from 'styled-components';

import { Body as StyledBody } from '../../SiteCard/SiteCard.style';
import StyledButtonBordered from '../../ui/Button/ButtonBordered.style';
import IconComponent from '../../ui/Icon/Icon';

export const Container = styled.div`
  display: block;
  ${({ branded, theme }) => branded && css`
    padding: 0.5rem 2rem;
    background-color: ${theme.COLOR_BRAND_PRIMARY};
  `}
`;

export const Body = styled(StyledBody)`
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  ${({ theme, withBackground }) => withBackground && css`
    background-color: ${theme.COLOR_GRAY_LIGHTER};
  `}
  ${({ borderless }) => borderless && css`
    border: none;
    margin-top: 1rem;
  `}
`;

export const StyledButton = styled(StyledButtonBordered)`
  background-color: ${({ theme }) => theme.COLOR_WHITE};

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
      color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
    }
  }
`;

export const Icon = styled(IconComponent)`
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  font-size: 1.125rem;
  margin-right: 0.5rem;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  margin-top: 0.5rem;
  aspect-ratio: 1.388; // ok for IE11 to degrade, ratio same as svg
  object-fit: cover; // ok for IE11 to degrade
`;

export const SvgImage = styled.div`
  margin-top: 0.5rem;
  svg {
    width: 100%;
  }
`;

import styled from 'styled-components';
import StyledSelect from '../ui/Form/Select';
import StyledText from '../ui/Text';

import { above } from '../../styles/libs';

export default styled.div`
  position: sticky;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  min-height: 3.125rem;
  padding: 0.5rem 0.75rem;
  transform: translate3d(0, 0, 0);
  transition: all 150ms ease;
  z-index: 1;
`;

export const Loader = styled.div`
  display: flex;
  color: ${({ theme }) => theme.COLOR_TEXT_BODY};
  height: 2.5rem;
  padding-left: 0.75rem;
  align-items: center;
  font-size: 0.75rem;
  text-transform: uppercase;
`;

export const SelectWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Select = styled(StyledSelect)`
  margin-left: 0.5rem;
  padding-right: 2.5rem;
  min-height: 2rem;
  font-size: 0.75rem;
  border: 1px solid #000;
  color: #000;

  ${({ theme }) => above(theme.TABLET)`
    font-size: 0.875rem;
  `}
`;

export const Text = styled(StyledText)`
  font-size: 0.75rem;
`;

import styled, { css } from 'styled-components';
import InputWithIcon from '../ui/Form/InputWithIcon/InputWithIcon';
import Text from '../ui/Text';
import { Col } from '../ui/Grid';

export const Input = styled(InputWithIcon)`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: 0;
  border-radius: 0.25rem;
  text-transform: uppercase;
`;

export const ListCol = styled(Col)`
  padding: 0 0.2rem;
  margin-top: 0.5rem;
  text-align: center;
`;

export const ListHeader = styled(Text)`
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  font-size: 0.85rem;
  word-wrap: normal;
`;

export default styled.div`
  & + & {
    border-top: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
    padding-top: 1.5rem;
  }

  margin-bottom: 1.5rem;
`;

export const GroupHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Body = styled.div`
  padding: 0.5rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
`;

export const ListBody = styled.div`
    margin-top: 1rem;
    padding: 1rem;
    border: none;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.COLOR_WHITE};
    font-size: 0.85rem;
`;

export const ListTotal = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
`;

export const BrandedText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.COLOR_BRAND_PRIMARY_DARK};
    font-weight: bold;
  `}
  ${({ marginLeft }) => marginLeft && css`
    margin-left: 1.5rem;
  `}
`;

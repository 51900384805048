import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withApollo, compose } from 'react-apollo';
import { withRouter } from 'next/router';
import DOMPurify from 'dompurify';

import CampingGuestsHeader from './CampingGuestsHeader';
import {
  Container, Body, StyledButton, Icon, Image, SvgImage,
} from './QuoteCampingGuests.style';
import { Col, Row } from '../../ui/Grid';
import Text from '../../ui/Text';
import AddToBasket from '../../AddToBasket/AddToBasket';

import svgCamp from '../../../static/images/icons/CampingEvent.svg';
import svgPlaceholder from '../../../static/images/placeholder.svg';

import { dictionaryItem, getDictionaryItem } from '../../../hocs/withDictionary';
import updateRouterQuery from '../../../lib/updateRouterQuery';
import routes from '../../../constants/routes';
import { parsePayload } from '../../AvailabilityDetails/AvailabilityDetailsFooter';
import MembershipDialog from '../../Dialog/MembershipDialog';
import IbePropTypes from '../../../IbePropTypes';
import ErrorTypeMessage from '../../ErrorTypeMessage';

function CampingGuests({
  onToggleAddingCampingGuests,
  handleCheckMembership,
  onSubmit,
  client,
  router,
  campsiteName,
  payload,
  open,
  loading,
  dialogMembershipProps,
  membershipDialog,
  campingMiniImage,
}) {
  const [error, setError] = useState(null);

  const skipLabel = getDictionaryItem(client, 'QuoteCampingGuests__CTA__Skip__Button');
  const handleAddToBasketClickCallBack = () => {
    const { campaignCode = null, ukItinerary } = router.query ?? {};
    if (localStorage.pendingCampsiteAdd) {
      return;
    }

    // TODO: create reusable func for campsite add callback
    updateRouterQuery(routes.sites, {
      componentId: null,
      campsiteId: null,
      campsiteName: null,
      location: null,
      showResults: ukItinerary ? true : null,
      siteCode: null,
      campaignCode,
    });

    client.writeData({
      data: {
        basketPopUp: {
          __typename: 'BasketPopUp',
          open: true,
          name: `${campsiteName} site`,
        },
      },
    });
  };

  const handleAddToBasketClick = () => {
    if (!handleCheckMembership) {
      handleAddToBasketClickCallBack(client);
    } else {
      handleCheckMembership(() => handleAddToBasketClickCallBack(client));
    }
  };

  const handleError = (newError) => {
    if (newError) {
      setError(newError);
    }
  };

  const handleSuccess = () => {
    handleAddToBasketClick(client);
    setError(null);
  };

  const parsedPayload = {
    ...parsePayload(payload),
    errataAccepted: true,
  };

  return (
    <Container>
      <MembershipDialog
        open={!!membershipDialog}
        loading={loading}
        {...dialogMembershipProps}
        {...membershipDialog}
      />
      <Body>
        <CampingGuestsHeader
          title={dictionaryItem('QuoteCampingGuests')}
          tooltipText={dictionaryItem(
            'QuoteCampingGuests', 'Tooltip',
          )}
          tooltipContent={dictionaryItem(
            'QuoteCampingGuests',
            'Tooltip',
          )}
        >
          <Text dictionary={dictionaryItem('QuoteCampingGuests', 'Description')} />
          <Text
            dictionary={dictionaryItem('QuoteCampingGuests', 'Note')}
            fontStyle="italic"
            size="0.9rem"
            marginTop
          />
        </CampingGuestsHeader>
      </Body>
      <Body withBackground borderless>
        <Row>
          <Col flex={1.5} justifyContent="center" flexbox>
            <Text
              dictionary={dictionaryItem('QuoteCampingGuests', 'CTA')}
              weight="bold"
            />
            {campingMiniImage ? (
              <Image src={campingMiniImage} />
            ) : (
              <SvgImage
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(svgPlaceholder) }}
              />
            )}
          </Col>
          <Col flex={2.5} justifyContent="center" flexbox>
            <Text dictionary={dictionaryItem('QuoteCampingGuests', 'CTA', 'Title')} />
          </Col>
          <Col flex={2.5} justifyContent="center" flexbox>
            <StyledButton
              disabled={false}
              align="center"
              block
              onClick={onToggleAddingCampingGuests}
              type="button"
            >
              <Icon icon={svgCamp} size="1.5rem" />
              <Text dictionary={dictionaryItem('QuoteCampingGuests', 'CTA', 'Button')} span />
            </StyledButton>
          </Col>
        </Row>
      </Body>
      <Body>
        <Row align="center" justify="center">
          {error && <ErrorTypeMessage error={error} marginBottom />}
          <AddToBasket
            size="large"
            align="center"
            onCompleted={() => onSubmit(false)}
            onError={handleError}
            onSubmit={() => onSubmit(true)}
            onSuccess={handleSuccess}
            handleCheckMembership={handleCheckMembership}
            payload={parsedPayload}
            block={false}
          >
            {skipLabel}
          </AddToBasket>
        </Row>
      </Body>
    </Container>
  );
}

CampingGuests.propTypes = {
  onToggleAddingCampingGuests: PropTypes.func.isRequired,
  handleCheckMembership: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  router: PropTypes.shape(IbePropTypes.router).isRequired,
  payload: PropTypes.shape(IbePropTypes.payload).isRequired,
  campsiteName: PropTypes.string,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  dialogMembershipProps: PropTypes.shape({}),
  membershipDialog: PropTypes.shape({}),
  campingMiniImage: PropTypes.string,
};

CampingGuests.defaultProps = {
  campsiteName: '',
  open: false,
  loading: false,
  dialogMembershipProps: {},
  membershipDialog: {},
  campingMiniImage: undefined,
};

export default compose(
  withApollo,
  withRouter,
)(CampingGuests);

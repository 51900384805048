import styled from 'styled-components';
import { focusStateInset } from '../../../Form/Form.style';
import zIndex from '../../../../../config/zIndex';

import theme from '../../theme';

// Picker needs to be above zIndex MAIN_TOP_GRADIENT

export default styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  font-family: sans-serif;
  z-index: ${zIndex.MODAL};
  ${({ fullscreen }) => (fullscreen ? `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 0;
    z-index: ${zIndex.MODAL - 1};
    -webkit-overflow-scrolling: touch;
  ` : null)}
`;

export const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PickerBody = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
`;

export const PickerLoadMore = styled.button`
  border: 0;
  background-color: transparent;
  padding: 1rem;
  width: 100%;
`;

export const PickerPaginationAction = styled.button`
  background-color: transparent;
  border: 0;
  margin: 1.5rem 1rem;
  padding: 0.5rem;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
  ${focusStateInset}
`;

export const PickerPagination = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
`;

export const PickerCalendars = styled.div`
  display: flex;
  max-width: none;
  position: absolute;
  padding: 0 0.5rem;
  ${({ fullscreen }) => (fullscreen ? `
    position: relative;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: ${theme.MAX_WIDTH}px;
  ` : null)}
`;

import styled, { css } from 'styled-components';
import { above, below } from '../../../styles/libs';
import detectiOS from '../../../lib/detectiOS';

import ButtonCloseComponent from '../Button/ButtonClose';
import TextStyled from '../Text';
import TitleStyled from '../Title';

export default styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  width: 100vw;
  z-index: 1;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  ${({ theme }) => css`
    ${above(theme.TABLET)`
      flex-direction: row;
      overflow-y: none;
    `}
  `}
  ${({ theme }) => below(theme.TABLET) && !!detectiOS() && css`
    padding-bottom: 5rem;
  `}
`;

export const ButtonClose = styled(ButtonCloseComponent)`
  position: absolute;
  width: 3rem;
  top: 0;
  right: 1rem;
  height: 3rem;
  margin: 1rem 1rem 0;
  z-index: 1;

  ${({ theme, small }) => css`
    ${above(theme.TABLET)`
      height: ${small ? '4rem' : '5rem'};
      width: ${small ? '4rem' : '5rem'};
      margin: ${small ? '1rem' : '2rem'};
      margin-right: ${small ? '2rem' : '4rem'};
    `}
  `}
`;

export const Content = styled.div`
  position: relative;
  flex: 1;
  overflow-y: none;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.COLOR_GRAY_LIGHTEST};
  ${({
    backgroundImage,
    backgroundImageMobile,
    theme,
  }) => backgroundImage && css`
    background-image: url(${backgroundImage});
    background-position: center;
    background-size: cover;
    ${backgroundImageMobile && below(theme.TABLET)`
      background-image: url(${backgroundImageMobile});
    `}
  `}
  ${({ theme }) => css`
    ${above(theme.TABLET)`
      overflow-y: auto;
    `}
  `}
  ${({ theme }) => css`
    ${below(theme.TABLET)`
      position: relative;
    `}
  `}
`;

export const ContentTitle = styled(TextStyled)`
  ${({ theme, marginTop }) => css`
    margin-bottom: 1rem;
    margin-top: ${marginTop || 0};
    color: ${theme.COLOR_TEXT_BODY};
    font-weight: bold;
    font-size: 1.25rem;
  `}
`;

export const ContentDescription = styled(TextStyled)`
  ${({ theme }) => css`
    color: ${theme.COLOR_TEXT_BODY};
    margin-bottom: 2rem;
    font-size: 1rem;
  `}
`;

export const OverlayLayer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const LeftContentContainer = styled.div`
  position: relative;
  padding: 2rem;
  min-height: 100%;
  ${({ theme }) => css`
    ${above(theme.TABLET)`
      padding: 5rem;
    `}
    ${below(theme.TABLET)`
      padding-bottom: 8rem;
    `}
  `}
`;

export const RightContentContainer = styled.div`
  ${({ theme }) => css`
    ${below(theme.TABLET)`
      position: absolute;
      top: -9rem;
      left: 5%;
      width: 90%;
    `}
  `}
  ${({ withPadding, theme }) => withPadding && css`
    padding: 2rem;
    ${above(theme.TABLET)`
      padding: 5rem;
      padding-bottom: 0;
    `}
  `}
`;

export const Text = styled(TextStyled)`
  max-width: 24rem;
  width: 100%;
  color: ${({ theme }) => theme.COLOR_WHITE};
  font-size: 1.25rem;
  ${({ subheading, theme }) => subheading && css`
    margin-bottom: 2rem;
    font-size: 2rem;
    ${below(theme.TABLET)`
      font-size: 1.5rem;
    `}
  `}
  ${({ theme, imageAlt }) => imageAlt && css`
    position: absolute;
    bottom: 1rem;
    right: 0;
    max-width: 100%;
    padding-right: 1rem;
    text-align: right;
    ${below(theme.TABLET)`
      display: none;
    `}
  `}
`;

export const Title = styled(TitleStyled)`
  width: 100%;
  max-width: 24rem;
  margin: 2rem 0 1rem 0;
  color: ${({ theme }) => theme.COLOR_WHITE};
  ${({ theme, small }) => css`
    ${above(theme.TABLET)`
      margin: 0 0 ${small ? '0.5rem' : '2rem'} 0;
    `}
  `}
  ${({ theme }) => css`
    ${below(theme.MOBILE_LARGE)`
      font-size: 2.5rem;
    `}
  `}
  ${({ small }) => small && css`
    max-width: 100%;
    font-size: 2.5rem;
  `}
`;

export const FooterRight = styled.div`
  display: flex;
  position: sticky;
  bottom: 0;
  padding: 1rem;
  border-top: 1px solid rgba(25,25,25,0.25);
  text-align: left;

  ${({ theme }) => css`
    background: ${theme.COLOR_WHITE};
    ${below(theme.DESKTOP_LARGE)`
      flex-wrap: wrap;
    `}
  `}
`;

export const ButtonContainer = styled.div`
  flex: 2;
  ${({ theme }) => css`
    ${below(theme.DESKTOP_LARGE)`
      display: inline-block;
      flex: none;
      width: 100%;
      margin-top: 1rem;
    `}
  `}
`;

export const MessageLeft = styled.div`
  flex: 2;
  ${({ theme }) => css`
    ${below(theme.DESKTOP_LARGE)`
      display: inline-block;
      flex: 3;
    `}
  `}
`;

export const BrandedText = styled.span`
  ${({ theme }) => css`
    color: ${theme.COLOR_BRAND_PRIMARY_DARK};
    font-weight: bold;
  `}
`;

export const PriceContainer = styled.div`
  flex: 1;
  padding: 0 2rem;
  ${({ theme }) => css`
    ${below(theme.DESKTOP_LARGE)`
      display: inline-block;
      padding-right: 0;
    `}
  `}
`;

import styled, { css } from 'styled-components';
import { ButtonBordered, ButtonClose } from '../ui/Button';
import Icon from '../ui/Icon';
import { above } from '../../styles/libs';
import detectIE from '../../lib/detectIE';

export const ComparisonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  min-height: 20rem;
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.TEXT_BODY_SIZE};
  font-weight: 500;
`;

export const NonScrollableContent = styled.div`
  display: block;
`;

export const ScrollableContent = styled.div`
  overflow: hidden;
`;

export const Row = styled.div`
  display: flex;
  ${({ sectionTitle }) => sectionTitle && css`
    position: sticky;
    top: 0;
    padding: 0.25rem 0;
  `}

  &:nth-child(odd) {
    background: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
  }

  &:nth-child(even) {
    background: ${({ greyBackground, theme }) => (greyBackground ? theme.COLOR_GRAY_LIGHTEST : theme.COLOR_WHITE)};
  }
`;

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ extraPadding }) => (extraPadding ? '1rem 1rem' : '0.5rem 1rem')};
  text-align: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '25%')};
  border-right: ${({ fullWidth, theme }) => (fullWidth ? '' : `1px solid ${theme.COLOR_GRAY_LIGHT}`)};

  ${({ theme, extraPadding }) => above(theme.TABLET)`
    padding: ${extraPadding ? '1rem 1.5rem' : '0.5rem 1.5rem'};
  `};

  ${({ theme, extraPadding }) => above(theme.DESKTOP)`
    padding: ${extraPadding ? '1rem 2rem' : '0.5rem 2rem'};
  `};

  &:nth-child(4n) {
    border: none;
  }
`;

export const ContentKey = styled(ContentSection)`
  text-align: left;
`;

export const RemoveBtn = styled(ButtonClose)`
  margin: 0.3em 0 0 auto;
`;

export const CompareInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

export const CampsiteDetails = styled.div`
  display: flex;
  margin: ${({ spacingTop }) => (spacingTop ? '1rem 0' : '0 0 1rem')};
`;

export const CampsiteInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;

  ${({ theme, extraPadding }) => above(theme.TABLET)`
    margin-right: 1.5rem;
  `};

  ${({ theme }) => above(theme.DESKTOP)`
    margin-right: 2rem;
  `};

  ${!!detectIE() && `
    width: 100%;
  `}
`;

export const CampsiteTitle = styled.h4`
  text-align: left;
  margin: 0;
`;

export const Price = styled.div`
  font-size: 0.875rem;
  margin-left: auto;
`;

export const Address = styled.address`
  font-style: normal;
  text-align: left;
  font-size: 0.9em;
  opacity: 0.8;
`;

export const CampsiteImage = styled.div`
  position: relative;
  margin-top: auto;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${({ theme }) => theme.RATIO_5_3};
  }
`;

export const Img = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center;
`;

export const Tick = styled(Icon)`
  margin: auto;
`;

export const LargeTick = styled(Icon)`
  margin: auto auto 1.2rem;
  padding: 0.8rem;
  border: 0.1rem solid ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  border-radius: 100%;
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
`;

export const ViewSiteButton = styled(ButtonBordered)`
  max-width: 50rem;
  margin: 0.5rem auto;
  padding: 0.5rem 2rem;
`;

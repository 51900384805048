import routes from './routes';

export const UK_SITES_TAB = {
  label: 'UK',
  id: '1',
  href: routes.sites,
};

export const OVERSEAS_SITES_TAB = {
  label: 'Europe',
  id: '2',
  href: `${routes.sites}?isOverseas=true`,
};

export const OVERSEAS_CROSSINGS_TAB = {
  label: 'Crossings',
  id: '3',
  href: routes.crossings,
};

const SEARCH_TABS = [
  UK_SITES_TAB,
  OVERSEAS_SITES_TAB,
  OVERSEAS_CROSSINGS_TAB,
];

export default SEARCH_TABS;

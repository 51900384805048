import styled from 'styled-components';
import { MessageWarning as MW } from '../../ui/Message';
import Text from '../../ui/Text';
import { below } from '../../../styles/libs';

export const MessageWarning = styled(MW)`
  margin: 1rem auto 0;
  width: 100%;
`;

export const Label = styled(Text)`
  display: block;
  font-size: 0.75rem;
`;

export const Value = styled(Text)`
  display: block;
  font-size: 0.85rem;
  font-weight: bold;
`;

export const ValuePair = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => below(theme.TABLET)`
    flex-direction: row;

    & ${Label},
    & ${Value} {
      flex: 0 0 50%;
      min-width: 50%;
    }
  `}
`;

export const Wrapper = styled.div`
  padding-bottom: 0.5rem 0;
`;

export default MessageWarning;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isTabActive from '../Search/searchHelpers';
import SearchFormStyled, { Body, Stick } from './SearchForm.style';
import IbePropTypes from '../../IbePropTypes';
import { OVERSEAS_CROSSINGS_TAB } from '../../constants/searchTabs';

/**
 * Search handles the logic for the SearchForm and the SearchResults.
 */
function SearchForm({
  children,
  className,
  formRef,
  router,
}) {
  const isCrossings = isTabActive(router, OVERSEAS_CROSSINGS_TAB);
  return (
    <SearchFormStyled className={className} autoComplete="off">
      <Stick ref={formRef}>
        <Body isCrossings={isCrossings}>
          {children}
        </Body>
      </Stick>
    </SearchFormStyled>
  );
}

SearchForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  className: PropTypes.string,
  formRef: PropTypes.shape({}),
  router: PropTypes.shape(IbePropTypes.router).isRequired,
};

SearchForm.defaultProps = {
  className: '',
  formRef: {},
};

export default memo(SearchForm);

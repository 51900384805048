import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  ApolloConsumer,
} from 'react-apollo';

import { withTheme } from 'styled-components';
import scrollToTop from '../../../lib/scrollToTop';
import { returnSupplierName } from '../../../lib/helpers/crossings';

import QuoteCrossingImportantRouteInfoModal from './QuoteCrossingImportantRouteInfoModal';
import SearchResultCrossings from '../../SearchResults/Crossings/SearchResultCrossings';

import withComponentError from '../../../hocs/withComponentError';
import { dictionaryItem } from '../../../hocs/withDictionary';

import QuoteCrossingStyled, { Header } from './QuoteCrossing.style';

import updateRouterQuery from '../../../lib/updateRouterQuery';
import QuoteCrossingForm from './QuoteCrossingForm';

import { handleValidate as handleValidateAccommodation } from './QuoteCrossingAccommodation';
import { dataLayerManager } from '../../../lib/dataLayer';
import routes from '../../../constants/routes';

function parsePayloadItineraryItem(payload, result = []) {
  return payload.map((payloadItem) => {
    const item = result.find(resultItem => resultItem.uId === payloadItem.uId);
    return item
      ? {
        id: item.id,
        quantity: payloadItem.quantity,
        uId: item.uId,
        maxOccupancy: item.maxOccupancy,
      }
      : payloadItem;
  });
}

function setSelectedAccomodation(accommodation = []) {
  return accommodation
    .filter(({ quantity }) => quantity > 0)
    .map(({
      id,
      quantity,
      uId,
      maxOccupancy,
    }) => ({
      id,
      quantity,
      uId,
      maxOccupancy,
    }));
}

/**
 * TODO: Remove all campsite specific code into seperate component
 */
class QuoteCrossing extends PureComponent {
  static propTypes = {
    countBookings: PropTypes.number,
    defaultMaxCrossingAccomQuantity: PropTypes.number,
    handleBack: PropTypes.func.isRequired,
    handleClearAndExpand: PropTypes.func.isRequired,
    isITXApplicable: PropTypes.bool,
    getComponentError: PropTypes.func.isRequired,
    onComponentError: PropTypes.func.isRequired,
    ports: PropTypes.arrayOf(PropTypes.shape({})),
    query: PropTypes.shape({
      outfit: PropTypes.shape({}),
      partyMembers: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    quote: PropTypes.shape({
      partyMembers: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    result: PropTypes.shape({
      id: PropTypes.string,
      inboundItinerary: PropTypes.shape({
        accommodation: PropTypes.arrayOf(PropTypes.shape({})),
        isAccomMandatory: PropTypes.bool,
      }),
      outboundItinerary: PropTypes.shape({
        accommodation: PropTypes.arrayOf(PropTypes.shape({})),
        isAccomMandatory: PropTypes.bool,
      }),
      relatedFareTypeId: PropTypes.number.isRequired,
    }).isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    suppliers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    theme: PropTypes.shape({
      COLOR_GRAY_LIGHTER: PropTypes.string,
    }),
    toggleBasket: PropTypes.func.isRequired,
    totalBasketAmount: PropTypes.number,
    onProductTypeMismatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    countBookings: null,
    defaultMaxCrossingAccomQuantity: null,
    isITXApplicable: false,
    ports: [],
    quote: {
      partyMembers: [],
    },
    theme: {
      COLOR_GRAY_LIGHTER: '',
    },
    totalBasketAmount: 0,
  }

  state = {
    accommPriceError: null,
    disabledForms: false,
    error: null,
    showImportantInformationModal: false,
    payload: {
      id: this.props.result.id,
      inboundItinerary: this.props.result.inboundItinerary
        ? {
          accommodation: setSelectedAccomodation(this.props.result.inboundItinerary.accommodation),
          isAccomMandatory:
          this.props.result.inboundItinerary && this.props.result.inboundItinerary.isAccomMandatory,
          supplements: [],
        }
        : {},
      outboundItinerary: {
        accommodation: setSelectedAccomodation(this.props.result.outboundItinerary.accommodation),
        isAccomMandatory: this.props.result.outboundItinerary.isAccomMandatory,
        supplements: [],
      },
      outfit: this.props.query.outfit,
      partyMembers: this.props.query.partyMembers,
    },
    result: { ...this.props.result },
  }

  handleCompleted = () => {
    dataLayerManager.pushAddToCart(dataLayerManager.subCategory.CROSSINGS, this.props.result);

    this.setState({ disabledForms: false, loading: false });
  }

  handleUpdatePayloadWithResults = ({ id, inboundItinerary, outboundItinerary }) => {
    const payload = { ...this.state.payload };

    const payloadWithResults = {
      ...payload,
      id,
      outboundItinerary: {
        accommodation: parsePayloadItineraryItem(
          payload.outboundItinerary.accommodation,
          outboundItinerary.accommodation,
        ),
        isAccomMandatory: outboundItinerary.isAccomMandatory,
        supplements: parsePayloadItineraryItem(
          payload.outboundItinerary.supplements,
          outboundItinerary.supplements,
        ),
      },
    };

    if (inboundItinerary) {
      payloadWithResults.inboundItinerary = {
        accommodation: parsePayloadItineraryItem(
          payload.inboundItinerary.accommodation,
          inboundItinerary.accommodation,
        ),
        isAccomMandatory: inboundItinerary.isAccomMandatory,
        supplements: parsePayloadItineraryItem(
          payload.inboundItinerary.supplements,
          inboundItinerary.supplements,
        ),
      };
    }

    return payloadWithResults;
  }

  handleAccommodationPrice = (type, data) => {
    const result = { ...this.state.result };

    result[type].accommodation = result[type].accommodation.map(accommodation => ({
      ...accommodation,
      totalPrice: accommodation.uId === data.uId ? data.totalPrice : accommodation.totalPrice,
    }));

    const accommPriceError = data.totalPrice ? null : { type: 'accommPriceError', message: dictionaryItem('QuoteCrossing', 'AccommodationPrice', 'Error') };
    this.setState({ result, loading: false, accommPriceError });
  }

  handleChange = (payload) => {
    this.setState({ isStale: true, payload });
  }

  handleInboundAccommodationChange = (accommodation) => {
    // TODO do not manipulate state, deep copy here
    const payload = { ...this.state.payload };
    payload.inboundItinerary.accommodation = accommodation;
    this.handleChange(payload);
  }

  handleOutboundAccommodationChange = (accommodation) => {
    // TODO do not manipulate state, deep copy here
    const payload = { ...this.state.payload };
    payload.outboundItinerary.accommodation = accommodation;
    this.handleChange(payload);
  }

  handleSupplementChange = (supplements, key) => {
    // TODO do not manipulate state, deep copy here
    const payload = { ...this.state.payload };
    payload[key].supplements = supplements;
    this.handleChange(payload);
  }

  handleAddToBasketClick = (cache) => {
    this.props.handleClearAndExpand();
    // Navigate back to crossings
    updateRouterQuery(routes.crossings);
    scrollToTop();
    // Update router query and enable add to basket confirmation modal.

    cache.writeData({
      data: {
        basketPopUp: {
          __typename: 'BasketPopUp',
          open: true,
          name: `${returnSupplierName(this.props.suppliers, this.state.result.supplierCode)} crossing`,
        },
      },
    });
    this.props.toggleBasket(true);
  }

  handleError = (error) => {
    console.error(error);
    this.setState({ error });
  }

  /**
   * I've made this into a promise here because Tad uses await on handleSubmit.
   */
  handleSubmit = () => new Promise(resolve => (
    this.setState({ error: null, loading: true }, () => resolve(true))
  ))

  handleUpdateResult = (response) => {
    const [result] = response.crossings;

    const payload = this.handleUpdatePayloadWithResults(result);

    this.setState({
      loading: false,
      isStale: false,
      payload,
      result,
    });
  }

  handleValidate = (callback) => {
    const { inboundItinerary, outboundItinerary } = this.props.result;

    const accommodationErrors = handleValidateAccommodation(
      [outboundItinerary.accommodation, inboundItinerary ? inboundItinerary.accommodation : []],
      this.state.payload,
    );

    this.props.onComponentError('QuoteCrossingAccommodation')(accommodationErrors);

    const errors = [...(accommodationErrors || [])];

    if (errors.length) return null;

    return callback();
  }

  render() {
    const {
      defaultMaxCrossingAccomQuantity,
      handleBack,
      isITXApplicable,
      getComponentError,
      onComponentError,
      ports,
      suppliers,
      theme,
      totalBasketAmount,
      onProductTypeMismatch,
    } = this.props;

    const {
      accommPriceError,
      disabledForms,
      error,
      loading,
      isStale,
      result,
    } = this.state;

    const accommodationErrors = getComponentError('QuoteCrossingAccommodation');

    const payload = { ...this.state.payload };

    const {
      outboundItinerary,
    } = result;

    const inboundItinerary = result.inboundItinerary || {};

    const isDisabled = loading || disabledForms;

    const quoteCrossingProps = {
      accommPriceError,
      accommodationErrors,
      defaultMaxCrossingAccomQuantity,
      disabledForms,
      error,
      handleValidate: this.handleValidate,
      inboundItinerary,
      inboundAccommodation: inboundItinerary.accommodation,
      isDisabled,
      isITXApplicable,
      isStale,
      loading,
      onComponentError,
      onError: this.handleError,
      onAccommodationPrice: this.handleAccommodationPrice,
      onCompleted: this.handleCompleted,
      onImportantInformationClick: () => this.setState({ showImportantInformationModal: true }),
      onInboundAccommodationChange: this.handleInboundAccommodationChange,
      onOutboundAccommodationChange: this.handleOutboundAccommodationChange,
      onSubmit: this.handleSubmit,
      onSuccess: () => null,
      onSupplementChange: this.handleSupplementChange,
      onUpdateResult: this.handleUpdateResult,
      outboundAccommodation: outboundItinerary.accommodation,
      outboundItinerary,
      payload,
      result,
      totalBasketAmount,
      onProductTypeMismatch,
    };

    dataLayerManager.pushProductDetails(dataLayerManager.category.CROSSINGS, {
      ...result,
      outfit: this.props.query.outfit,
    });

    return (
      <QuoteCrossingStyled data-quote-crossing-page-view="true">
        <Header>
          <SearchResultCrossings
            background={theme.COLOR_GRAY_LIGHTER}
            details={result}
            onResultClick={handleBack}
            ports={ports}
            showBackButton
            suppliers={suppliers}
          />
        </Header>
        <ApolloConsumer>
          {({ cache }) => <QuoteCrossingForm
            {...quoteCrossingProps}
            onSuccess={() => this.handleAddToBasketClick(cache)}
          />}
        </ApolloConsumer>
        <QuoteCrossingImportantRouteInfoModal
          open={this.state.showImportantInformationModal}
          onClose={() => this.setState({ showImportantInformationModal: false })}
          inboundErrata={inboundItinerary.errata}
          outboundErrata={outboundItinerary.errata}
        />
      </QuoteCrossingStyled>
    );
  }
}

export default compose(
  withTheme,
  withComponentError,
)(QuoteCrossing);

import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import testingAttr from '../../../lib/testingAttr';

import { dictionaryItem } from '../../../hocs/withDictionary';

import Checkbox from '../../ui/Form/Checkbox';

import {
  CheckboxFormGroup,
  CheckboxLabel,
  customStyled,
} from './CrossingsRadioGroup.style';

function CrossingsSameReturnRoute({
  checked,
  disabled,
  onChange,
  theme,
}) {
  return (
    <CheckboxFormGroup>
      <Checkbox
        customStyled={customStyled}
        checked={checked}
        disabled={disabled}
        name="sameReturnRoute"
        onChange={onChange}
        {...testingAttr('search-form-crossings__checkbox')}
      />

      <CheckboxLabel
        color={theme.COLOR_WHITE}
        dictionary={dictionaryItem('SearchFormCrossings', 'SameReturnRoute')}
        htmlFor="sameReturnRoute"
      />
    </CheckboxFormGroup>
  );
}

CrossingsSameReturnRoute.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    COLOR_WHITE: PropTypes.string,
  }),
};

CrossingsSameReturnRoute.defaultProps = {
  theme: {},
};

export default withTheme(CrossingsSameReturnRoute);

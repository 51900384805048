import styled from 'styled-components';
import { above } from '../../styles/libs';

import { Calendar } from '../ui/Datepicker/components/Picker';
import ButtonComponent from '../ui/Button';

export default styled.div`
  padding: 1.5rem 0;
`;

export const AvailabilityCalendar = styled(Calendar)`
  padding: 0;
`;

export const Button = styled(ButtonComponent)`
  color: ${({ theme }) => theme.COLOR_TEXT_BODY};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.COLOR_TEXT_HEADING};
  }

  &:disabled {
    color: ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  }
`;

export const Header = styled.header`
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => above(theme.TABLET)`
    padding: 0.5rem 1rem;
  `}
`;

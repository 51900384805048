import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { dictionaryItem } from '../../hocs/withDictionary';
import { personTypes } from '../../config/personType';

import Popper from '../ui/Popper';
import Text from '../ui/Text';

import PartySelectStyled, {
  Options,
  Option,
  Row,
  Details,
  Label,
  Description,
  Add,
  Remove,
  Value,
  Select,
  Badge,
  Wrapper,
} from './PartySelect.style';

function sum(data) {
  return data.map(opt => opt.value).reduce((acc, cur) => (acc + cur), 0);
}

class PartySelect extends Component {
  static propTypes = {
    childToAge: PropTypes.number,
    customStyled: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ])),
      PropTypes.string,
      PropTypes.shape({}),
    ]),
    countLead: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number,
    })),
    disabled: PropTypes.bool.isRequired,
    isOverseas: PropTypes.bool.isRequired,
    options: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    onChange: PropTypes.func.isRequired,
    partyMembers: PropTypes.arrayOf(PropTypes.shape()),
    partyMemberTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    theme: PropTypes.shape(),
    withPortal: PropTypes.bool,
  }

  static defaultProps = {
    childToAge: null,
    customStyled: '',
    countLead: false,
    data: [],
    options: {
      min: null,
      max: null,
    },
    partyMembers: [],
    theme: {},
    withPortal: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      total: sum(props.data),
      isOpen: false,
    };

    this.containerRef = createRef();
    this.partySelectRef = createRef();
    this.selectRef = createRef();
  }

  componentDidMount() {
    window.document.addEventListener('mousedown', this.handleClickOutside);
    window.document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const total = sum(this.props.data);
    if (total !== sum(prevProps.data)) {
      this.setTotal(total);
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener('mousedown', this.handleClickOutside);
    window.document.removeEventListener('touchstart', this.handleClickOutside);
  }

  setTotal = (total) => {
    this.setState({
      total,
    });
  }

  isMin = (opt) => {
    const { total } = this.state;
    return total <= this.props.options.min || opt.value <= opt.min || opt.value === 0;
  }

  isMax = (opt) => {
    const { total } = this.state;
    return total >= this.props.options.max || opt.value >= opt.max;
  }

  handleChange = (data) => {
    this.setState({ total: sum(data) });
    this.props.onChange(data);
  }

  handleAdd = (index) => {
    const { data } = this.props;
    data[index].value += 1;
    this.handleChange(data);
  }

  handleRemove = (index) => {
    const { data } = this.props;
    data[index].value -= 1;
    this.handleChange(data);
  }

  handleToggle = (toggle) => {
    const { isOpen } = this.state;
    this.setState({ isOpen: toggle || !isOpen });
  }

  handleClickOutside = (event) => {
    if (!this.state.isOpen) return;

    if (this.selectRef && this.selectRef.current.contains(event.target)) return;

    if (this.containerRef && !this.containerRef.current.contains(event.target)) {
      this.handleToggle(false);
    }
  }

  render() {
    const {
      childToAge,
      customStyled,
      countLead,
      data,
      disabled,
      isOverseas,
      options,
      partyMembers,
      partyMemberTypes,
      theme,
      withPortal,
    } = this.props;

    const { total } = this.state;

    const list = [];
    data.every((option) => {
      const valid = option.toAge === null || option.toAge > 0;

      if (valid) {
        list.push(option);
      }

      return valid;
    });

    // Build summary of party to display in Party Members label
    // this will respect campsite's maximum child age rules,
    // regardless of child statuses in associated crossing
    const shortPartySummary = () => {
      const countAdults = partyMembers.length
        ? partyMembers.filter(
          // Member.type of 0 or member.age of NaN signifies 18 or over
          member => (
            (member.type === personTypes.ADULT && ((countLead || disabled) || !member.isLead)) ||
            !childToAge ||
            (childToAge && member.age > childToAge)
          ),
        ).length
        : list.find(({ key }) => key === personTypes.ADULT)?.value;

      const countChildren = partyMembers.length
        ? partyMembers.filter(
          member => member.type === personTypes.CHILDREN,
        ).length
        : list.find(({ key }) => key === personTypes.CHILDREN)?.value;

      const countInfants = partyMembers.length
        ? partyMembers.filter(
          member => member.type === personTypes.INFANT,
        ).length
        : list.find(({ key }) => key === personTypes.INFANT)?.value;

      const labelAdults = `${countAdults} ${partyMemberTypes.length
        && partyMemberTypes[personTypes.ADULT] && partyMemberTypes[personTypes.ADULT].value}`;

      const labelChildren = countChildren >= 0 ?
        `, ${countChildren} ${partyMemberTypes &&
          partyMemberTypes[personTypes.CHILDREN] && partyMemberTypes[personTypes.CHILDREN].value}` :
        '';

      const labelInfant = countInfants >= 0 ?
        `, ${countInfants} ${partyMemberTypes &&
          partyMemberTypes[personTypes.INFANT] && partyMemberTypes[personTypes.INFANT].value}` :
        '';

      const totalMembers = countAdults + countChildren + countInfants;
      const party = `${labelAdults}${labelChildren}${isOverseas ? '' : labelInfant}`;

      return totalMembers > 0 ? party : 'No party';
    };

    return (
      <PartySelectStyled ref={this.partySelectRef}>
        <Select
          block
          customStyled={customStyled}
          disabled={disabled}
          onClick={() => this.handleToggle()}
          ref={this.selectRef}
          data-testid="partySelect.select"
        >
          <>
            {!!total &&
              <Badge>{total} total</Badge>
            }
            {shortPartySummary()}
          </>
        </Select>

        <Popper
          anchor={this.partySelectRef}
          open={this.state.isOpen}
          placement="bottom"
          disablePortal={!withPortal}
          options={{
            modifiers: {
              preventOverflow: {
                boundariesElement: 'viewport',
                escapeWithReference: true,
              },
            },
          }}
        >
          {({ forwardedRef }) => (
            <Wrapper
              ref={forwardedRef}
              style={{ width: this.partySelectRef.current.clientWidth }}
            >
              <Options ref={this.containerRef}>
                {list.map((opt, index) => (
                  <Option
                    data-testid={`partySelect.options.${opt.key}`}
                    key={`${opt.key}`}
                  >
                    <Row>
                      <Details>
                        <Label>{opt.label}</Label>
                        <Description>{opt.description}</Description>
                      </Details>
                      <Remove
                        type="button"
                        onClick={() => this.handleRemove(index)}
                        disabled={this.isMin(opt)}
                      >
                        -
                      </Remove>
                      <Value length={String(opt.value).length} data-testid={`partySelect.options.${opt.key}.value`}>{opt.value}</Value>
                      <Add
                        type="button"
                        onClick={() => this.handleAdd(index)}
                        disabled={this.isMax(opt)}
                      >
                        +
                      </Add>
                    </Row>
                    {opt.value >= opt.max &&
                      <Text size="0.75rem" color={theme.COLOR_WARNING}>{`You have reached the maximum amount of ${opt.label}`}</Text>
                    }
                  </Option>
                ))}
                {total >= options.max &&
                  <Text dictionary={dictionaryItem('PartySelect', 'WarnMaxTotal')} size="0.75rem" color={theme.COLOR_WARNING} marginTop />
                }
              </Options>
            </Wrapper>
          )}
        </Popper>

      </PartySelectStyled>
    );
  }
}

export default withTheme(PartySelect);

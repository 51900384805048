import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CostItem,
  CostSummary,
  CostSummaryHeader,
  CostSummaryTitle,
  CostName,
  CostValue,
} from './AvailabilityDetailsCostSummary.style';
import formatPrice from '../../lib/formatPrice';
import PriceSummaryModal from './modals/PriceSummaryModal';
import RetainedDepositModal from './modals/RetainedDepositModal';
import { Icon, Text } from '../ui';
import theme from '../../styles/config/theme';

import svgInfo from '../../static/images/icons/Info.svg';
import { dictionaryItem } from '../../hocs/withDictionary';

const MODALS = {
  PRICE_SUMMARY: 'price_summary',
  RETAINED_DEPOSIT: 'retained_deposit',
};

const AvailabilityDetailsCostSummary = ({
  prevStayTotal,
  stayTotal,
  retainedDeposit,
}) => {
  const [openModals, setOpenModals] = useState({
    [MODALS.PRICE_SUMMARY]: false,
    [MODALS.RETAINED_DEPOSIT]: false,
  });

  const toggleModal = (modal) => {
    setOpenModals((prevOpenModals) => ({
      ...prevOpenModals,
      [modal]: !prevOpenModals[modal],
    }));
  };

  const changeInPrice = formatPrice(stayTotal - prevStayTotal);
  const finalPrice = formatPrice(stayTotal + retainedDeposit);
  return (
    <>
      <CostSummary>
        <CostSummaryHeader>
          <CostSummaryTitle>Price Summary</CostSummaryTitle>
          <Icon
            onClick={() => toggleModal(MODALS.PRICE_SUMMARY)}
            icon={svgInfo}
            color={theme.COLOR_LAKE_GREEN_ORIGINAL}
            size="1.25rem"
            style={{
              cursor: 'pointer',
              marginLeft: 'auto',
            }}
          />
        </CostSummaryHeader>
        <CostItem>
          <CostName>
            <Text dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'PreviousStayTotal')} />:
          </CostName>
          <CostValue>{formatPrice(prevStayTotal)}</CostValue>
        </CostItem>
        <CostItem>
          <CostName>
            <Text dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'ChangesMade')} />:
          </CostName>
          <CostValue>{changeInPrice}</CostValue>
        </CostItem>
        {retainedDeposit > 0 &&
        <CostItem>
          <CostName>
            <Text dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'RetainedDeposit')} />:
            <Icon
              onClick={() => toggleModal(MODALS.RETAINED_DEPOSIT)}
              icon={svgInfo}
              color={theme.COLOR_LAKE_GREEN_ORIGINAL}
              marginLeft="0.5rem"
              style={{
                cursor: 'pointer',
              }}
            />
          </CostName>
          <CostValue>{formatPrice(retainedDeposit)}</CostValue>
        </CostItem>
        }
        <CostItem bold onClick={() => toggleModal(MODALS.PRICE_SUMMARY)}>
          <CostName>
            <Text dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'NewStayTotal')} />:
          </CostName>
          <CostValue>{finalPrice}</CostValue>
        </CostItem>
      </CostSummary>
      {/* Modals (Outside normal document flow) */}
      <PriceSummaryModal
        active={openModals[MODALS.PRICE_SUMMARY]}
        toggleModal={() => toggleModal(MODALS.PRICE_SUMMARY)}
      />
      <RetainedDepositModal
        active={openModals[MODALS.RETAINED_DEPOSIT]}
        toggleModal={() => toggleModal(MODALS.RETAINED_DEPOSIT)}
      />
    </>
  );
};

AvailabilityDetailsCostSummary.propTypes = {
  prevStayTotal: PropTypes.number,
  stayTotal: PropTypes.number,
  retainedDeposit: PropTypes.number,
};

AvailabilityDetailsCostSummary.defaultProps = {
  prevStayTotal: 0,
  stayTotal: 0,
  retainedDeposit: 0,
};

export default AvailabilityDetailsCostSummary;

import React from 'react';
import PropTypes from 'prop-types';

import Modal, { ModalContent, ModalClose } from '../../ui/Modal';
import { Text, Title } from '../../ui';
import { dictionaryItem } from '../../../hocs/withDictionary';
import { PriceSummaryTextGroup } from '../AvailabilityDetailsCostSummary.style';

const PriceSummaryModal = ({ active, toggleModal }) => (
  <Modal active={active} onClickOutside={toggleModal} overlay>
    <ModalContent>
      <ModalClose onClick={toggleModal} />
      <PriceSummaryTextGroup>
        <Title size={1} dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'PreviousStayTotal')} />
        <Text dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'PreviousStayTotal', 'Description')} />
      </PriceSummaryTextGroup>
      <PriceSummaryTextGroup>
        <Title size={1} dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'ChangesMade')} />
        <Text dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'ChangesMade', 'Description')} />
      </PriceSummaryTextGroup>
      <PriceSummaryTextGroup>
        <Title size={1} dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'RetainedDeposit')} />
        <Text dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'RetainedDeposit', 'Description')} />
      </PriceSummaryTextGroup>
      <PriceSummaryTextGroup>
        <Title size={1} dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'NewStayTotal')} />
        <Text dictionary={dictionaryItem('AvailabilityDetails', 'PriceSummary', 'NewStayTotal', 'Description')} />
      </PriceSummaryTextGroup>
    </ModalContent>
  </Modal>
);

PriceSummaryModal.propTypes = {
  active: PropTypes.bool,
  toggleModal: PropTypes.func,
};

PriceSummaryModal.defaultProps = {
  active: false,
  toggleModal: () => {},
};

export default PriceSummaryModal;

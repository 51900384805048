import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { dictionaryItem } from '../../hocs/withDictionary';

import Title from '../ui/Title';
import Text from '../ui/Text';

import svgAlert from '../../static/images/icons/Error.svg';
import theme from '../../styles/config/theme';

import {
  QuestionIcon,
} from './PopUp.styles';
import { formatPriceWithCurrency } from '../../lib/format';

const DepositTable = styled.div`
  margin-top: 1rem;
`;

const HeaderRow = styled.div`
  display: flex;
`;

const ComponentsBody = styled.div`
  max-height: 7rem;
  min-height: 5rem;
  padding: 0.25 0rem;
  background: white;
  overflow-x: auto;
`;

const ComponentsRow = styled.div`
  display: flex;
`;

const ColDeposit = styled.div`
  width: 8rem;
  padding: 0.25rem;
  text-align: left;
`;

const ColName = styled.div`
  flex: 1;
  padding: 0.25rem;
  text-align: left;
`;

const propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape({
    deposit: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
};

const DepositComponentSummaryPopUp = ({
  components,
}) => (
  <>
    <QuestionIcon icon={svgAlert} />

    <Title align="center" tag={3} size={2} marginBottom dictionary={dictionaryItem('DepositComponentSummaryPopUp')} color={theme.COLOR_LAKE_GREEN_DARK} />

    <Text align="center" dictionary={dictionaryItem('DepositComponentSummaryPopUp')} size="1rem" />

    <DepositTable>
      <HeaderRow>
        <ColDeposit>
          <Text weight="bold" size="0.875rem" dictionary={dictionaryItem('DepositComponentSummaryPopUp', 'TableColDeposit')} />
        </ColDeposit>
        <ColName>
          <Text weight="bold" size="0.875rem" dictionary={dictionaryItem('DepositComponentSummaryPopUp', 'TableColName')} />
        </ColName>
      </HeaderRow>
      <ComponentsBody>
        {components.map((component) => (
          <ComponentsRow key={`${component.name}${component.deposit}`}>
            <ColDeposit>
              <Text size="0.75rem">
                {formatPriceWithCurrency(component.deposit)}
              </Text>
            </ColDeposit>
            <ColName>
              <Text size="0.75rem">
                {component.name}
              </Text>
            </ColName>
          </ComponentsRow>
        ))}
      </ComponentsBody>

    </DepositTable>

  </>
);

DepositComponentSummaryPopUp.propTypes = propTypes;

export default memo(DepositComponentSummaryPopUp);

import React from 'react';
import PropTypes from 'prop-types';

import fillRange from '../../lib/fillRange';

import RoomSelectFerryContentStyled, {
  CostAndQuantity, Description, Details, MaxOccupancy, Select,
} from './RoomSelectFerryContent.style';
import testingAttr from '../../lib/testingAttr';

function RoomSelectFerryContent({
  disabled,
  id,
  maxOccupancy,
  maxQuantity,
  name,
  onChange,
  selectedAccommodation,
  leg,
  uId,
}) {
  const handleChange = (event) => {
    const { target: { value } } = event;
    return onChange({ id, quantity: value, uId });
  };

  const selectOptions =
    fillRange(0, maxQuantity).map(value => <option key={value} value={value}>{value}</option>);

  return (
    <RoomSelectFerryContentStyled>
      <Details>
        <Description>{name}</Description>
        <MaxOccupancy>Max occupancy: {maxOccupancy}</MaxOccupancy>
      </Details>

      <CostAndQuantity>
        <Select
          disabled={disabled}
          onChange={handleChange}
          size="small"
          type="number"
          value={selectedAccommodation.quantity || 0}
          {...testingAttr(`${leg}-${name.replace(/ /g, '_').toLowerCase()}-select`)}
        >
          {selectOptions}
        </Select>
      </CostAndQuantity>
    </RoomSelectFerryContentStyled>
  );
}

RoomSelectFerryContent.propTypes = {
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  maxOccupancy: PropTypes.number.isRequired,
  maxQuantity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedAccommodation: PropTypes.shape({
    quantity: PropTypes.number,
  }),
  leg: PropTypes.string.isRequired,
  uId: PropTypes.number.isRequired,
};

RoomSelectFerryContent.defaultProps = {
  selectedAccommodation: {},
};

export default RoomSelectFerryContent;

import styled from 'styled-components';

import { Row as StyledRow } from '../ui/Grid';
import ColComponent from '../ui/Grid/Col';
import { above } from '../../styles/libs';

export const ColLength = styled(ColComponent)`
  flex: 0 0 50%;
  padding: 0 0.375rem;

  ${({ theme }) => above(theme.MOBILE_LARGE)`
    flex: 1;
  `}
`;

export const ColType = styled(ColComponent)`
  flex: 0 0 100%;
  padding: 0 0.375rem;

  ${({ theme }) => above(theme.MOBILE_LARGE)`
    flex: 2;
  `}
`;

export const ColLengths = styled(ColComponent)`
  flex: 0 0 100%;
  padding: 0 0.375rem;

  ${({ theme }) => above(theme.MOBILE_LARGE)`
    flex: 0 0 50%;
  `}
`;

export const Row = styled(StyledRow)`
  margin: 0 -0.375rem;
  position: relative;
`;

import styled from 'styled-components';
import StyledText from '../../ui/Text';
import { Col as StyledCol } from '../../ui/Grid';
import { above } from '../../../styles/libs';

export default styled.div`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  padding: 0.5rem 0.75rem;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  & > div {
    flex: 1;
  }

  ${({ theme }) => above(theme.DESKTOP)`
    border-radius: 5px;
    border-bottom: 0;
  `};
`;

export const TextWrapper = styled.div`
  ${({ theme }) => above(theme.DESKTOP)`
    display: block;
    width: auto;
  `}
`;

export const CenteredText = styled(StyledText)`
  text-align: center;
`;

export const ShipText = styled(StyledText)`
  font-size: 0.8125rem;
  margin: 0 -0.5rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Col = styled(StyledCol)`
  min-width: 0;
  flex: 5;

  ${({ theme }) => above(theme.DESKTOP)`
    flex: 0 0 6rem;
  `}
`;

export const MiddleCol = styled(StyledCol)`
  min-width: 0;
  flex: 5;

  ${({ theme }) => above(theme.TABLET)`
    flex: 3;
  `}

  ${({ theme }) => above(theme.DESKTOP)`
    flex: 1;
  `}
`;

export const ArrowContainer = styled.div`
  display: block;
  max-width: 4.75rem;
  margin: 0.625rem auto;

  ${({ theme }) => above(theme.DESKTOP_LARGE)`
    position: static;
    width: 80%;
    top: auto;
    right: auto;
    transform: translateY(0%) rotate(0deg);
  `}
`;

export const Arrow = styled.span`
  position: relative;
  display: block;
  margin: auto;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHT};

  ${({ theme }) => above(theme.DESKTOP)`
    min-width: 2rem;
  `}

  ${({ theme }) => above(theme.DESKTOP_LARGE)`
    width: 100%;
  `}

  &::before,
  &::after  {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0.5rem;
    height: 2px;
    background-color: inherit;
    transform: rotate(40deg);
    transform-origin: right;
  }

  &::after {
    transform: rotate(-40deg);
  }
`;

import { isBefore, isValid } from 'date-fns';
import { validatePartyAges } from '../partyHelpers';
import { dictionaryItem } from '../../hocs/withDictionary';
import { TOW_TYPES, VEHICLE_TYPES } from '../../config/outfits';

export function validateAges(partyMembers) {
  const errors = [];

  const validAges = validatePartyAges(partyMembers);

  if (!validAges) {
    errors.push({ type: 'ages', message: dictionaryItem('SearchFormCrossings', 'Error', 'Ages') });
  }

  return errors;
}

export function validateJourney(outbound = {}, inbound = null, isReturn, isSameRouteReturn) {
  const errors = [];

  if (!outbound.crossingRouteCode) {
    errors.push({ type: 'outbound', message: dictionaryItem('SearchFormCrossings', 'Error', 'OutboundJourney') });
  }

  if (!inbound && isReturn) {
    errors.push({ type: 'inbound', message: dictionaryItem('SearchFormCrossings', 'Error', 'InboundJourney') });
  }

  if (inbound && !inbound.crossingRouteCode && isReturn && !isSameRouteReturn) {
    errors.push({ type: 'inbound', message: dictionaryItem('SearchFormCrossings', 'Error', 'InboundJourney') });
  }

  return errors;
}

export function validateDate(outbound = {}, inbound = {}, isReturn) {
  const errors = [];

  // balk if outbound and inbounds are invalid and we have a return, as
  // additional validation is pointless
  if (isReturn && (!outbound.crossingRouteCode || !inbound.crossingRouteCode)) return errors;

  const inDate = inbound.crossingDateTime ? new Date(inbound.crossingDateTime) : '';
  const outDate = outbound.crossingDateTime ? new Date(outbound.crossingDateTime) : '';

  if ((!outDate || !isValid(outDate)) ||
    (isReturn && (inDate && !isValid(inDate)))) {
    errors.push({ type: 'dp', message: dictionaryItem('SearchFormCrossings', 'Error', 'Date') });
  }

  if (isReturn && outDate) {
    if (!inDate || isBefore(inDate, outDate)) {
      errors.push({ type: 'dp', message: dictionaryItem('SearchFormCrossings', 'Error', 'DateOrder') });
    }
  }

  return errors;
}

export function validateOutfit(outfit) {
  const errors = [];

  if (outfit.vehicleType === VEHICLE_TYPES.NONE) {
    errors.push({ type: 'vehicleType', message: dictionaryItem('SearchFormCrossings', 'Error', 'MissingVehicle') });
  }

  if (outfit.vehicleType !== VEHICLE_TYPES.NONE) {
    if (!outfit.vehicleLength) {
      errors.push({ type: 'vehicleLength', message: dictionaryItem('SearchFormCrossings', 'Error', 'MissingVehicleLength') });
    } if (!outfit.vehicleHeight) {
      errors.push({ type: 'vehicleHeight', message: dictionaryItem('SearchFormCrossings', 'Error', 'MissingVehicleHeight') });
    }
  }

  if (outfit.towType !== TOW_TYPES.NONE) {
    if (!outfit.vehicleLength) {
      errors.push({ type: 'towLength', message: dictionaryItem('SearchFormCrossings', 'Error', 'MissingTowLength') });
    } if (!outfit.vehicleHeight) {
      errors.push({ type: 'towHeight', message: dictionaryItem('SearchFormCrossings', 'Error', 'MissingTowHeight') });
    }
  }
  return errors;
}

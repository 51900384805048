const CAMPSITE_STATUS = {
  FULL: 'FULL',
  CLOSED: 'CLOSED',
};

export const CAMPSITE_CALENDAR_STATUS = {
  FULL: 'PITCH FULL',
  CLOSED: 'CLOSED',
};

export default CAMPSITE_STATUS;

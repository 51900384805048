import styled, { css } from 'styled-components';

import { above, below } from '../../styles/libs';

import ButtonBordered from '../ui/Button/ButtonBordered.style';

export const BecomeAMemberCard = styled.div`
  background-color: ${({ theme }) => theme.COLOR_GRAY_DARK};
  ${({ backgroundImage }) => backgroundImage && css`
    background-image: url(${backgroundImage});
    background-position: center;
    background-size: cover;
  `}
  color: ${({ theme }) => theme.COLOR_WHITE};
  display: flex;
  min-height: 1;
  flex-direction: column;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.PADDING};
  width: 100%;

  ${({ theme }) => css`
    ${above(theme.TABLET)`
      flex-direction: row;
      padding: ${theme.PADDING_LARGE};
    `}

    ${above(theme.DESKTOP)`
      padding: ${theme.PADDING_XL};
    `}
  `}
`;

export const Details = styled.div`
  padding-right: 0.75rem;

  ${({ theme }) => css`
    ${above(theme.TABLET)`
      padding-right: ${theme.PADDING};
    `}

    ${above(theme.DESKTOP)`
      padding-right: ${theme.PADDING_LARGE};
    `}
  `}
`;

export const CTA = styled.div`
  padding-left: 0.75rem;
  margin-left: auto;

  ${({ theme }) => css`
    ${below(theme.TABLET)`
      padding-top: ${theme.PADDING};
    `}
    ${above(theme.TABLET)`
      padding-left: ${theme.PADDING};
    `}

    ${above(theme.DESKTOP)`
      padding-left: ${theme.PADDING_LARGE};
    `}
  `}
`;

export const Button = styled(ButtonBordered)`
  white-space: nowrap;
`;

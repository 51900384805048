import styled, { css } from 'styled-components';

import { above, below } from '../../../styles/libs';
import { Col as ColStyled } from '../../ui/Grid';
import RowStyled from '../../ui/Grid/Row.style';

export const ResultWrapper = styled.div`
  background-color: ${({ isFeatured, theme }) => (isFeatured ? theme.COLOR_BRAND_PRIMARY_LIGHT : theme.COLOR_GRAY_LIGHTER)};
  min-height: 5rem;
  position: relative;
  padding: 0.5rem;

  ${({ theme }) => above(theme.DESKTOP)`
    padding: 1.25rem;
  `}

  & + & {
    border-top: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  }

  ${({ background }) => `background-color: ${background}`}
`;

export const Row = styled(RowStyled)``;

export const Col = styled(ColStyled)`
  flex: 0 0 100%;

  ${({ theme }) => above(theme.DESKTOP)`
    border-radius: 5px;
    ${({ isReturn }) => css`flex: ${isReturn ? 1 : 2}`};
  `}
`;

export const ImageCol = styled(ColStyled)`
  display: none;

  ${({ theme }) => above(theme.DESKTOP)`
    flex: 0 0 auto;
    min-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 5rem;
  padding-bottom: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.COLOR_WHITE};

  ${({ theme }) => below(theme.TABLET)`
    width: auto;
  `}
`;

export const CompanyImg = styled.img`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  max-width: 100%;
`;

export const PriceCol = styled(ColStyled)`
  margin-top: 0;
  display: flex;
  flex: 0 0 100%;
  flex-flow: column;
  justify-content: center;

  ${({ theme }) => above(theme.DESKTOP)`
    margin-top: 1rem;
  `}

  ${({ theme }) => above(theme.DESKTOP_LARGE)`
    margin-top: 0rem;
    flex: 1;

    ${({ isITXApplicable }) => isITXApplicable && css`
      flex: 1.25;
    `}
  `}
`;

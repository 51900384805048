import React, { Fragment } from 'react';
import Text from '../ui/Text';
import { Icon } from '../ui';
import {
  MainTextWrapper, MainText,
} from './HelpBubble.style';

import { dictionaryItem } from '../../hocs/withDictionary';
import svgInfo from '../../static/images/icons/Info.svg';

function MainHelpBubbleContent(props) {
  return (
    <Fragment>
      <MainTextWrapper>
        <Icon
          icon={svgInfo}
        />
        <MainText size="1rem" weight="bold" dictionary={dictionaryItem('HelpBubble', 'Header')} />
      </MainTextWrapper>
      <Text size="1rem" marginTop dictionary={dictionaryItem('HelpBubble', 'Paragraph', '1')} />
      <Text size="1rem" marginTop dictionary={dictionaryItem('HelpBubble', 'Paragraph', '2')} />
    </Fragment>
  );
}

export default MainHelpBubbleContent;

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import testingAttr from '../../../lib/testingAttr';

import { dictionaryItem } from '../../../hocs/withDictionary';

import Checkbox from '../../ui/Form/Checkbox';
import PopOver from '../../PopOver';
import ButtonInfo from '../../ui/Button/ButtonInfo.style';
import { Text } from '../../ui';

import {
  CheckboxFormGroup,
  CheckboxLabel,
  CheckboxWrapper,
  CloseButton,
  customStyled,
} from './CrossingsRadioGroup.style';

import svgInfo from '../../../static/images/icons/Info.svg';

function CrossingsAllowAlternativeRoutes({
  checked,
  color,
  disabled,
  onChange,
  onPopOverToggle,
  open,
}) {
  const popOverRef = useRef(null);

  return (
    <CheckboxWrapper>
      <PopOver
        closeOnClick
        onClose={() => onPopOverToggle(false)}
        open={open}
        popOverRef={popOverRef}
        renderContent={() => (
          <Text
            dictionary={dictionaryItem('CrossingsAllowAlternativeRoutes', 'PopOver')}
            size="0.875rem"
          />
        )}
        renderReference={({ innerRef }) => (
          <>
            <CheckboxFormGroup>
              <Checkbox
                customStyled={customStyled}
                defaultChecked={checked}
                disabled={disabled}
                name="allowAlternativeRoutes"
                onChange={onChange}
                {...testingAttr('CrossingsAllowAlternativeRoutes.Checkbox')}
              />

              <CheckboxLabel
                color={color}
                dictionary={dictionaryItem('SearchFormCrossings', 'AllowAlternativeRoutes')}
                htmlFor="alternativeRoute"
              />
            </CheckboxFormGroup>

            <ButtonInfo
              ariaLabel="Information"
              buttonStyle={{ visibility: open ? 'hidden' : 'visible' }}
              color={color}
              disabled={open}
              icon={svgInfo}
              marginTop="0.2rem"
              marginLeft="1rem"
              noPadding
              onClick={() => onPopOverToggle(!open)}
              outerRef={innerRef}
              type="button"
            />
          </>
        )}
      />
      {open && (
        <CloseButton
          ariaLabel="Information"
          color={color}
          icon={svgInfo}
          marginTop="0.2rem"
          marginLeft="1rem"
          noPadding
          onClick={() => onPopOverToggle(false)}
          type="button"
        />
      )}
    </CheckboxWrapper>
  );
}

CrossingsAllowAlternativeRoutes.propTypes = {
  color: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onPopOverToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

CrossingsAllowAlternativeRoutes.defaultProps = {
  color: '',
  disabled: false,
};

function CrossingsAllowAlternativeRoutesContainer({
  checked,
  disabled,
  onChange,
  theme,
}) {
  const [open, setOpen] = useState(false);

  // Open/close Alternative Routes tooltip on checkbox change
  useEffect(() => {
    setOpen(checked);
  }, [checked]);

  return (
    <CrossingsAllowAlternativeRoutes
      checked={checked}
      color={theme.COLOR_WHITE}
      disabled={disabled}
      onChange={onChange}
      onPopOverToggle={value => setOpen(value)}
      open={open}
    />
  );
}

CrossingsAllowAlternativeRoutesContainer.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    COLOR_WHITE: PropTypes.string,
  }),
};

CrossingsAllowAlternativeRoutesContainer.defaultProps = {
  disabled: false,
  theme: {},
};

export default withTheme(CrossingsAllowAlternativeRoutesContainer);

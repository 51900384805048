import styled, { css } from 'styled-components';
import Icon from '../ui/Icon';

export const Body = styled.div`
  ${({ theme }) => css`
    position: relative;
    border-radius: 5px;
    background-color: ${theme.COLOR_GRAY_LIGHTER};
  `}

  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: 1rem;
  `}
`;

export const Header = styled.div`
  padding: 1rem;
  ${({ compact }) => compact && css`
    padding: 0.5rem 1rem;
  `}
`;

export const Content = styled.div`
  padding: 1rem;
  border-top: ${({ theme }) => `1px solid ${theme.COLOR_GRAY_LIGHT}`};
`;

export const Overlay = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255,0.8);
`;

export const LeftIcon = styled(Icon)`
  margin: 1rem;
  font-size: 3.5rem;
  color: ${({ theme, color }) => `${color || theme.COLOR_LAKE_GREEN_DARK}`};
`;

export const RightContent = styled.div`
  padding: 1rem 0;
`;

export const ContentActions = styled.div`
  display: flex;
  padding: 1rem 0;
`;

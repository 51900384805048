import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { format } from 'date-fns';

import Text from '../ui/Text';
import { ButtonBrand } from '../ui/Button';

import StyledQuoteFooter, { Error } from './QuoteFooter.style';

import UPDATE_QUOTE from './graphql/updateQuote';
import UPDATE_CONFIRMATION_MODAL_STATUS from '../../config/graphql/updateConfirmationModalState';
import queryHelper from '../../lib/queryHelper';

class QuoteFooter extends Component {
  static propTypes = {
    basePrice: PropTypes.number.isRequired,
    quoteId: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    quoteParameters: PropTypes.shape().isRequired,
    updateQuote: PropTypes.func.isRequired,
  }

  state = {
    error: null,
    errors: [],
    basePrice: this.props.basePrice,
  }

  handleAddToBasketClick = () => {
    this.props.updateQuote({
      quoteId: this.props.quoteId,
      ...this.props.quoteParameters,
      start: format(this.props.quoteParameters.start, 'YYYY-MM-DD'),
      end: format(this.props.quoteParameters.end, 'YYYY-MM-DD'),
      // Remove
      errataAccepted: false,
    })
      .then(() => {
        this.setState({ error: null, errors: [] });
        this.updateConfirmationModalState(true);
      })
      .catch(error => this.setState({
        error,
        errors: error.networkError.result,
      }));
  }

  render() {
    const { error, errors } = this.state;

    return (
      <StyledQuoteFooter>
        <Text>TOTAL: {this.state.basePrice}</Text>

        {error &&
          <Error>
            {Object.keys(errors).map(key => (
              <div key={key}>{errors[key]}</div>
            ))}
          </Error>
        }

        <ButtonBrand
          block
          disabled={error}
          onClick={this.handleAddToBasketClick}
          size="xl"
        >
          Add To Basket
        </ButtonBrand>
      </StyledQuoteFooter>
    );
  }
}

export default compose(
  graphql(UPDATE_QUOTE, {
    props: ({ mutate }) => ({
      updateQuote: input => mutate({ variables: { input } }),
    }),
  }),
  queryHelper(UPDATE_CONFIRMATION_MODAL_STATUS),
)(QuoteFooter);

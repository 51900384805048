import styled from 'styled-components';
import { above } from '../../styles/libs';
import TextStyled from '../ui/Text';

import CheckboxStyled from '../ui/Form/Checkbox';

export const Col = styled.div`
  margin-top: 0.2rem;
  width: 100%;
  display: inline-flex;
  ${({ theme }) => above(theme.TABLET)`
    display: inline-flex;
    width: 50%;
  `}
`;

export const Row = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-flow: column;

  ${({ theme }) => above(theme.TABLET)`
    flex-flow: row wrap;
  `}
`;

export const CheckboxText = styled(TextStyled)`
  margin-left: 0.5rem;
`;

export const Checkbox = styled(CheckboxStyled)``;

export const CheckboxLabel = styled.label``;

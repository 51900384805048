import React from 'react';
import PropTypes from 'prop-types';

import Text from '../ui/Text';
import { dictionaryItem } from '../../hocs/withDictionary';
import { BrandedText, ListTotal } from './Guests.style';
import formatPrice from '../../lib/formatPrice';
import IbePropTypes from '../../IbePropTypes';

function GuestTotal({ guests, guestPrices }) {
  if (!guests) return null;
  const guestTotal = guests.reduce(
    (total, guest) => total + (guest.price || guestPrices[guest.personId] || 0),
    0,
  );

  return (
    <ListTotal>
      <Text dictionary={dictionaryItem('Guests', 'TotalCost')} />
      <BrandedText size="1.5rem" marginLeft="1.5rem">{formatPrice(guestTotal)}</BrandedText>
    </ListTotal>
  );
}

GuestTotal.propTypes = {
  guests: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.guest)),
  guestPrices: PropTypes.shape({}).isRequired,
};

GuestTotal.defaultProps = {
  guests: [],
};

export default GuestTotal;

import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../ui/Tooltip';

import StyledCampsiteFeature, { Feature, Text } from './CampsiteFeature.style';

import CampsiteFeatureIcon from './CampsiteFeatureIcon';

function CampsiteFeature({
  disabled,
  hasDescription,
  hasIcon,
  hideText,
  isRightColumn,
  handleClick,
  ...props
}) {
  if (props.value && props.value === 'False') {
    return null;
  }
  let { description } = props;
  const { id, name } = props;
  if (props.value && props.value !== 'True') {
    description = props.value;
  }

  return (
    <StyledCampsiteFeature onClick={handleClick} data-testid={`feature-${id}`}>
      <Tooltip
        disabled={disabled || !hasDescription || !props.name}
        placement={isRightColumn ? 'left' : 'right'}
        modifiers={{
          flip: {
            behavior: ['left', 'right-start'],
          },
        }}
        text={description || props.name}
        withPortal
      >
        <Feature disabled={disabled}>
          {hasIcon &&
            <CampsiteFeatureIcon {...props} />
          }
          {!hideText && (
            <Text>
              {name || id}

              {hasDescription && !!description &&
              <Fragment> &mdash; {description}</Fragment>
              }
            </Text>
          )}
        </Feature>
      </Tooltip>
    </StyledCampsiteFeature>
  );
}

CampsiteFeature.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  hasDescription: PropTypes.bool,
  hasIcon: PropTypes.bool,
  hideText: PropTypes.bool,
  isRightColumn: PropTypes.bool,
  name: PropTypes.string,
  handleClick: PropTypes.func,
  value: PropTypes.string,
};

CampsiteFeature.defaultProps = {
  disabled: false,
  description: '',
  hasDescription: false,
  hasIcon: false,
  hideText: false,
  isRightColumn: false,
  name: '',
  value: '',
  handleClick: undefined,
};

export default memo(CampsiteFeature);

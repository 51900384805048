import React from 'react';

import Field from './Input.style';

function Input(props) {
  return (
    <Field {...props} />
  );
}

export default Input;

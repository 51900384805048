import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';

import Popper from '../Popper';
import Text from '../Text';

import TooltipStyled, { TooltipPositioner, TooltipWrapper } from './Tooltip.style';

class Tooltip extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    modifiers: PropTypes.shape({}),
    placement: PropTypes.string,
    withPortal: PropTypes.bool,
    text: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  }

  static defaultProps = {
    disabled: false,
    modifiers: {},
    placement: 'bottom',
    withPortal: false,
    children: null,
    text: '',
  }

  state = {
    open: false,
  }

  ref = createRef();

  handleMouseEnter = () => this.setState({ open: true });

  handleMouseLeave = () => this.setState({ open: false });

  render() {
    const { disabled, children, placement } = this.props;

    const isRenderProps = typeof children === 'function';

    if (disabled) return isRenderProps ? children() : children;

    const tooltipProps = {
      onBlur: this.handleMouseLeave,
      onFocus: this.handleMouseEnter,
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
    };

    return (
      <TooltipStyled
        {...(!isRenderProps ? tooltipProps : {})}
        ref={this.ref}
      >
        {isRenderProps ? children(tooltipProps) : children}

        <Popper
          anchor={this.ref}
          open={this.state.open}
          modifiers={this.props.modifiers}
          placement={this.props.placement}
          disablePortal={!this.props.withPortal}
        >
          {({ forwardedRef }) => (
            <TooltipPositioner ref={forwardedRef}>
              <TooltipWrapper placement={placement}>
                <Text>{this.props.text}</Text>
              </TooltipWrapper>
            </TooltipPositioner>
          )}
        </Popper>
      </TooltipStyled>
    );
  }
}

export default Tooltip;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { inRange } from 'lodash';
import { isDesktopOrSmaller } from '../../lib/helpers/layout';

import { Label, Select } from '../ui/Form';
import { Row, Col } from '../ui/Grid';

import { ADULT, CHILD, INFANT } from '../../lib/partyMemberTypes';
import testingAttr from '../../lib/testingAttr';

const defaultProps = {
  partyMembers: [],
};

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  partyMembers: PropTypes.arrayOf(PropTypes.shape()),
  partyOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  valid: PropTypes.bool.isRequired,
};

const mapPartyTypeLabels = {
  [ADULT]: 'Adult',
  [CHILD]: 'Child',
  [INFANT]: 'Infant',
};

export function rangeFrom(min, max, indexAt0) {
  // min may be -1, this was to cope with a fix from Deiniol.
  const cappedMin = Math.max(0, min);

  let length = max - cappedMin;

  if (indexAt0) {
    length += 1;
  }

  return Array.from({ length }, (v, n) => n + cappedMin);
}

function hasAgeRange(option) {
  // we are infering -1 to be of a minmum age
  const rule = val => val >= -1 && val !== null;
  return rule(option.fromAge) && rule(option.toAge);
}

function PartyAges({
  partyMembers,
  partyOptions,
  onChange,
  valid,
  disabled,
}) {
  // Check if age range exists
  const hasAges =
    partyMembers.filter(
      ({ age }) => partyOptions.some(({ toAge }) => (age || 0) < toAge),
    ).length > 0;

  if (!hasAges) return null;

  let n = 0;

  const fullDurationPartyMembers = partyMembers.filter(member => typeof member.stayStart === 'undefined');

  return (
    <Fragment>
      <Row>
        {fullDurationPartyMembers.map((member, index) => {
          // removed age check as we are just going by type, as per Deiniols advisement
          if (member.type === ADULT) return null;

          const findPartyOption = ({ fromAge, toAge, type }) => (member.age !== null
            ? toAge && inRange(member.age || 0, fromAge, toAge + 1)
            : member.type === type);

          const option = partyOptions.find(findPartyOption);

          if (!option) return null;

          n = member.type === partyMembers[Math.max(0, index - 1)].type
            ? n + 1
            : 1;

          if (hasAgeRange(option)) {
            return (
              <Col
                col={isDesktopOrSmaller() ? 6 : 3}
                key={`${member.type}-${n}`}
              >
                <Label>{mapPartyTypeLabels[member.type]} {n} Age*</Label>
                <Select
                  block
                  onChange={e => onChange(index, e.target.value)}
                  placeholder="Select"
                  value={member.age === 0 || member.age ? member.age : ''}
                  disabled={disabled}
                  error={member.age === null}
                  {...testingAttr(`party-ages__age-${index}`)}
                >
                  <Fragment>
                    {rangeFrom(option.fromAge, option.toAge, true).map(age => (
                      <option key={age} value={age}>{age}</option>
                    ))}
                  </Fragment>
                </Select>
              </Col>
            );
          }

          return <Fragment key={`${member.type}-${n}`} />;
        })}
      </Row>
    </Fragment>
  );
}

PartyAges.defaultProps = defaultProps;
PartyAges.propTypes = propTypes;

export default PartyAges;

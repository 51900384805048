import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  compose, filter, reverse, uniqBy,
} from 'lodash/fp';

import { dictionaryItem } from '../../hocs/withDictionary';

import RoomSelectItem from './RoomSelectItem';
import EmptyState from '../EmptyState';
import Text from '../ui/Text';

import RoomSelectStyled from './RoomSelect.style';

const quantityChange = compose(
  filter(({ quantity }) => quantity > 0),
  uniqBy('uId'),
  reverse,
);

class RoomSelect extends PureComponent {
  static propTypes = {
    error: PropTypes.shape({}),
    disabled: PropTypes.bool.isRequired,
    hideEmpty: PropTypes.bool.isRequired,
    isRequired: PropTypes.bool.isRequired,
    maxQuantity: PropTypes.number,
    multi: PropTypes.bool,
    onChange: PropTypes.func,
    onError: PropTypes.func,
    placeholder: PropTypes.string,
    renderContent: PropTypes.func,
    renderLabel: PropTypes.func,
    renderPlaceholder: PropTypes.func,
    rooms: PropTypes.arrayOf(PropTypes.shape({})),
    selectedRooms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    totalMaxOccupancy: PropTypes.number,
  }

  static defaultProps = {
    multi: false,
    error: null,
    maxQuantity: null,
    onChange() { },
    onError() { },
    placeholder: 'None',
    renderPlaceholder: null,
    renderLabel: () => null,
    renderContent: null,
    rooms: [{}],
    totalMaxOccupancy: Infinity,
  }

  handleClick = ({ id, uId }) => {
    const accommodation = id ? [{ id, quantity: 1, uId }] : [];
    return this.props.onChange(accommodation);
  }

  handleQuantityChange = (payload) => {
    const state = this.props.selectedRooms;
    const newState = quantityChange([...state, payload]);
    return this.props.onChange(newState);
  }

  calcMaxQuantity = (roomMaxQuantity) => {
    const { maxQuantity } = this.props;
    if (maxQuantity === null) return roomMaxQuantity;
    return Math.min(maxQuantity, maxQuantity);
  }

  render() {
    const {
      disabled,
      hideEmpty,
      isRequired,
      multi,
      placeholder,
      renderContent,
      renderLabel,
      renderPlaceholder,
      rooms,
      selectedRooms,
    } = this.props;

    if (!rooms.length) {
      return hideEmpty ? null : <EmptyState dictionary={dictionaryItem('Supplements', 'EmptyState')} />;
    }

    const selectedUIds = selectedRooms.map(({ uId }) => uId);

    const preSelectedRooms = rooms.filter(({ quantity }) => quantity > 0);
    const otherRooms = rooms.filter(({ quantity }) => quantity === 0);

    return (
      <RoomSelectStyled>
        {!isRequired &&
          <RoomSelectItem
            clickable
            disabled={disabled}
            onClick={this.handleClick}
            render={renderPlaceholder || <Text>{placeholder}</Text>}
            selected={selectedUIds.length < 1}
            marginBottom
          />
        }

        {isRequired && preSelectedRooms.map((room) => {
          const isSelected = selectedUIds.includes(room.uId);

          return (
            <RoomSelectItem
              disabled={disabled}
              isClickable={!multi}
              onClick={this.handleClick}
              onQuantityChange={this.handleQuantityChange}
              render={renderContent}
              selected={isSelected}
              {...room}
              maxQuantity={this.calcMaxQuantity(room.maxQuantity)}
            />
          );
        })}

        {(isRequired ? otherRooms : rooms).map((room, index) => {
          const isSelected = selectedUIds.includes(room.uId);

          return (
            <Fragment key={room.uId}>
              {index === 0 && renderLabel()}

              <RoomSelectItem
                disabled={disabled}
                isClickable={!multi}
                onClick={this.handleClick}
                onQuantityChange={this.handleQuantityChange}
                render={renderContent}
                selected={isSelected}
                {...room}
                maxQuantity={this.calcMaxQuantity(room.maxQuantity)}
              />
            </Fragment>
          );
        })}
      </RoomSelectStyled>
    );
  }
}

export default RoomSelect;

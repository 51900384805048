import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';

import ErrorTypeMessage from '../ErrorTypeMessage';
import { defaults as defaultQuote } from '../../resolvers/quote';
import { generateDefaultPartyMembers } from '../../lib/helpers/quote';
import GET_QUOTE from './graphql/getQuote';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import IbePropTypes from '../../IbePropTypes';
import FetchPolicy from '../../constants/FetchPolicy';

class Quote extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.func,
      PropTypes.node,
    ]).isRequired,
    configuration: PropTypes.shape(IbePropTypes.configuration),
    loading: PropTypes.bool,
    isOverseas: PropTypes.bool,
    quote: PropTypes.shape(IbePropTypes.quote),
    error: PropTypes.shape(IbePropTypes.apolloError),
    client: PropTypes.shape(IbePropTypes.client),
  };

  static defaultProps = {
    configuration: null,
    loading: false,
    isOverseas: false,
    quote: undefined,
    error: null,
    client: null,
  };

  render() {
    const {
      children,
      loading: configLoading,
      configuration,
      isOverseas,
      client,
      error,
      quote: dataQuote,
      loading: quoteLoading,
    } = this.props;
    if (configLoading || !configuration || !children) {
      return null;
    }

    if (error && error.networkError.statusCode === 502) {
      return (
        <ErrorTypeMessage>
          There was a problem with your request.
        </ErrorTypeMessage>
      );
    }

    let countBookings = 0;
    let onlyExtras = false;
    const quote = dataQuote || {
      ...defaultQuote,
      partyMembers: generateDefaultPartyMembers(
        configuration,
        isOverseas,
      ),
    };

    if (dataQuote) {
      const { campsiteBookings, crossingBookings, extras } = dataQuote;

      countBookings = campsiteBookings.length + crossingBookings.length;
      onlyExtras = (!!campsiteBookings.length || !!crossingBookings.length)
        ? false
        : !!extras.length;
    }

    if (dataQuote) {
      const { campsiteBookings, crossingBookings, extras } = dataQuote;

      countBookings = campsiteBookings.length + crossingBookings.length;
      onlyExtras = (!!campsiteBookings.length || !!crossingBookings.length)
        ? false
        : !!extras.length;
    }

    return children({
      client, countBookings, onlyExtras, error, quoteLoading, quote,
    });
  }
}

export default compose(
  withApollo,
  graphql(GET_CONFIGURATION, {
    props: ({ data }) => ({
      configuration: data.configuration,
      loading: data.loading,
    }),
  }),
  graphql(GET_QUOTE, {
    options: {
      fetchPolicy: FetchPolicy.CACHE_AND_NETWORK,
    },
    skip: ({ query }) => query?.bookingWidget === 'true',
    props: ({ data }) => ({
      quote: data.quote,
      loading: data.loading,
      error: data.error,
    }),
  }),
)(Quote);

import { isEmpty } from 'lodash';
import { getPitchBookings } from '../../Checkout/flattenBookingArray';
import { Helper } from './Helper';

class QuoteHelper extends Helper {
  constructor(quote) {
    super();
    let quoteCopy = { ...quote };
    if (!quote) quoteCopy = {};

    this.amountOfNights = this.getAmountOfNightsFromBookings(quoteCopy.campsiteBookings);

    this.amountOfSiteNightVouchers = this.getAmountOfSiteNightVouchers(quoteCopy.extras);
    this.hasInboundCrossing = this.hasAnyCrossingInbound(quoteCopy.crossingBookings);
    this.crossingIds = this.getCrossingIds(quoteCopy.crossingBookings);
  }

  hasAnyCrossingInbound = (crossingBookings) => {
    if (!crossingBookings) return false;
    if (crossingBookings && !crossingBookings.length) return false;

    const isInbound =
      crossingBookings.some(crossing => !isEmpty(crossing.inboundItinerary));

    return isInbound;
  }

  // parses campsiteBookings array and returns number of nights in all bookings.
  getAmountOfNightsFromBookings = (campsiteBookings) => {
    if (!campsiteBookings) return 0;
    if (!campsiteBookings.length) return 0;

    // get array of array which index 0 contains object with ...props and pitch.
    // It contains as well pitches but it should be ignored!.
    const pitchBookings = getPitchBookings(campsiteBookings);

    // create array of pitch then of numNights with map and then sum them with reduce.
    // TODO: ADD FILTERING FOR OVERSEAS CAMPSITES WHEN BACKEND WILL FIX THAT!
    const sumOfNights = pitchBookings
      .map(b => b.pitch)
      .map(p => p.numNights)
      .reduce((a, c) => a + c);

    return sumOfNights;
  }

  getCrossingIds = (crossingBookings) => {
    if (!crossingBookings) return [];
    const ids = [];

    crossingBookings.forEach((booking) => {
      ids.push(booking.id);
    });

    return ids;
  }
}

export default QuoteHelper;

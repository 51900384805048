import React, { memo } from 'react';
import PropTypes from 'prop-types';

import formatPrice from '../../lib/formatPrice';

import Text from '../ui/Text';

import {
  PitchPrice,
  Price,
} from './AvailabilityPitches.style';

import {
  ToggleBodyWrapper,
  ToggleContentContainer,
  ToggleContent,
  ToggleDescription,
  ToggleTitle,
  Tick,
} from './Availability.style';

import svgTick from '../../static/images/icons/Tick.svg';

import { dictionaryItem } from '../../hocs/withDictionary';

function PitchBody({
  selected,
  description,
  disabled,
  loading,
  name,
  minPricePerNight,
  pitchTypesLink,
  showPrice,
}) {
  return (
    <ToggleBodyWrapper>
      <Tick icon={svgTick} selected={selected} disabled={disabled} />
      <ToggleContentContainer>
        <ToggleContent disabled={disabled}>
          <ToggleTitle disabled={disabled}>{name}</ToggleTitle>
          <ToggleDescription>{description || 'No description provided.'}</ToggleDescription>
        </ToggleContent>
        {showPrice &&
          <PitchPrice>
            <Price>
              {loading &&
                <Text>Loading</Text>
              }
              {!loading && minPricePerNight &&
                <Text>
                  <span>From </span>
                  <b>{formatPrice(minPricePerNight)}</b>
                  <span> per night</span>
                </Text>
              }
              {!loading && !minPricePerNight &&
                <Text dictionary={dictionaryItem('AvailabilityPitches', 'NotAvailable')} />
              }
            </Price>
          </PitchPrice>
        }
      </ToggleContentContainer>
    </ToggleBodyWrapper>
  );
}

PitchBody.propTypes = {
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  minPricePerNight: PropTypes.number,
  pitchTypesLink: PropTypes.string.isRequired,
  showPrice: PropTypes.bool,
};

PitchBody.defaultProps = {
  loading: false,
  minPricePerNight: null,
  showPrice: false,
};

export default memo(PitchBody);

import gql from 'graphql-tag';

export function getCampsiteOptions(props) {
  return {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      id: props.campsiteId,
      skip: !props.campsiteId || !!props.isTours,
    },
  };
}

export default gql`
  query campsite ($id: String!, $skip: Boolean) {
    campsite (id: $id) @rest(
      type: "Campsite",
      path: "campsite?id={args.id}"
    ) @skip(if: $skip) {
      id
      address {
        addressLines
        country
        postCode
      }
      contactName
      distanceMeters
      emailAddress
      events {
        id
        files {
          fileExtension
          filesizeKb
          name
          url
        }
        features
        isMemberExclusive
        openDates
        eventType
        earliestArrival
        latestArrival
        maxOutfitLength
        cheapestMemberPrice,
        cheapestStandardPrice
      }
      features {
        id
        name
        tags
        value
        iconUrl
      }
      fromArrivalTime
      hasLowAvailability
      isFeatured
      isMemberOnly
      isoPlaceCode
      location {
        lat
        lon
      }
      name
      numberOfReviews
      pitches {
        id
        description
        isMemberExclusive
        maxChildAge
        maxOccupancy
        maxOutfitLength
        minChildAge
        eventType
        name
      }
      placeUrl
      qtyHardstandingOnSite
      qtyPodsOnSite
      qtyTentPitchesOnSite
      qtyTotal
      qtyTouringPitchesOnSite
      rating
      siteCode
      telephoneNumber
      toArrivalTime
      type
      websiteUrl
    }
  }
`;

import React, { useRef, useState, useCallback } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';

import { ButtonInfo } from '../ui/Button';
import { dictionaryItem, mapDictionaryItems } from '../../hocs/withDictionary';
import { formatPriceWithCurrency } from '../../lib/format';
import { Header, ToggleButton, ToggleButtonColumnContainer } from './Availability.style';
import { Title, RichText } from '../ui';
import IbePropTypes from '../../IbePropTypes';
import MemberNonMemberBody from './MemberNonMemberBody';
import Modal, { ModalClose, ModalContent } from '../ui/Modal';
import svgInfo from '../../static/images/icons/Info.svg';

const STRING_REPLACE_KEY = '{{Cost}}';

const AvailabilityMemberNonMember = ({
  client,
  handleMemberNonMemberChange,
  memberPrice,
  memberPricesAreSelected,
  nonMemberPrice,
}) => {
  const [isModalOpen, setIsModelOpen] = useState(false);
  const toggleModal = useCallback(() => setIsModelOpen(isOpen => !isOpen), []);

  const buttonLabels = useRef(mapDictionaryItems(client, {
    member: 'AvailabilityMemberNonMember__MemberButton',
    memberSelectedDescription: 'AvailabilityMemberNonMember__MemberButtonSelectedDescription',
    memberDescription: 'AvailabilityMemberNonMember__MemberButtonDescription',
    nonMember: 'AvailabilityMemberNonMember__NonMemberButton',
    nonMemberSelectedDescription: 'AvailabilityMemberNonMember__NonMemberButtonSelectedDescription',
    nonMemberDescription: 'AvailabilityMemberNonMember__NonMemberButtonDescription',
  }));

  const saving = formatPriceWithCurrency((nonMemberPrice || 0) - (memberPrice || 0));

  const memberDescription = memberPricesAreSelected ?
    buttonLabels.current?.memberSelectedDescription :
    buttonLabels.current?.memberDescription.replace(STRING_REPLACE_KEY, saving);

  const nonMemberDescription = !memberPricesAreSelected ?
    buttonLabels.current?.nonMemberSelectedDescription :
    buttonLabels.current?.nonMemberDescription.replace(STRING_REPLACE_KEY, saving);

  return (
    <>
      <Modal
        active={isModalOpen}
        overlay
      >
        <ModalContent>
          <ModalClose onClick={toggleModal} />
          <RichText dictionary={dictionaryItem('AvailabilityMemberNonMember', 'Modal')} />
        </ModalContent>
      </Modal>
      <Header>
        <Title dictionary={dictionaryItem('AvailabilityMemberNonMember')} tag={1} size={4} />
        <ButtonInfo
          ariaLabel="Information"
          icon={svgInfo}
          marginLeft="0.5rem"
          noPadding
          onClick={toggleModal}
          type="button"
        />
      </Header>
      <ToggleButtonColumnContainer>
        <ToggleButton
          disabled={false}
          onClick={() => handleMemberNonMemberChange(true)}
          selected={memberPricesAreSelected}
        >
          <MemberNonMemberBody
            disabled={false}
            name={buttonLabels.current.member}
            description={memberDescription}
            selected={memberPricesAreSelected}
          />
        </ToggleButton>
        <ToggleButton
          disabled={false}
          onClick={() => handleMemberNonMemberChange(false)}
          selected={!memberPricesAreSelected}
          marginLeft="2rem"
        >
          <MemberNonMemberBody
            disabled={false}
            name={buttonLabels.current.nonMember}
            description={nonMemberDescription}
            selected={!memberPricesAreSelected}
          />
        </ToggleButton>
      </ToggleButtonColumnContainer>
    </>
  );
};

AvailabilityMemberNonMember.propTypes = {
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  handleMemberNonMemberChange: PropTypes.func.isRequired,
  memberPrice: PropTypes.number,
  memberPricesAreSelected: PropTypes.bool.isRequired,
  nonMemberPrice: PropTypes.number,
};

AvailabilityMemberNonMember.defaultProps = {
  memberPrice: undefined,
  nonMemberPrice: undefined,
};

export default withApollo(AvailabilityMemberNonMember);

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { differenceInDays } from '../../../lib/dates';
import QuoteCrossingLayoutJourneys from './QuoteCrossingLayoutJourneys';
import Supplements, { SupplementsCrossingContent } from '../../Supplements';
import IbePropTypes from '../../../IbePropTypes';

function QuoteCrossingSupplements({
  disabled,
  payload: { inboundItinerary, outboundItinerary },
  onChange,
  supplements: { inbound, outbound },
  showInbound,
  outboundTimeTable,
  inboundTimeTable,
}) {
  const dictionaryPrefix = 'CrossingSupplements';

  return (
    <QuoteCrossingLayoutJourneys
      dictionaryPrefix={dictionaryPrefix}
      noInbound={inbound && !inbound.length}
      noOutbound={!outbound.length}
      renderColLeft={() => (
        <Supplements
          disabled={disabled}
          fetchedSupplements={outbound}
          hideEmpty
          name="outboundItinerary"
          numOfNights={
            differenceInDays(
              new Date(outboundTimeTable.toDate),
              new Date(outboundTimeTable.fromDate),
            )
          }
          onChange={supplements => onChange(supplements, 'outboundItinerary')}
          renderContent={contentProps => (
            <SupplementsCrossingContent {...contentProps} />
          )}
          values={outboundItinerary.supplements}
        />
      )}
      renderColRight={() => showInbound && (
        <Supplements
          disabled={disabled}
          fetchedSupplements={inbound}
          hideEmpty
          name="inboundItinerary"
          numOfNights={
            differenceInDays(
              new Date(inboundTimeTable.toDate),
              new Date(inboundTimeTable.fromDate),
            )
          }
          onChange={supplements => onChange(supplements, 'inboundItinerary')}
          renderContent={contentProps => (
            <SupplementsCrossingContent {...contentProps} />
          )}
          values={inboundItinerary.supplements}
        />
      )}
    />
  );
}

QuoteCrossingSupplements.propTypes = {
  disabled: PropTypes.bool,
  supplements: PropTypes.shape({
    inbound: PropTypes.arrayOf(PropTypes.shape({})),
    outbound: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  payload: PropTypes.shape({
    inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
    outboundItinerary: PropTypes.shape(IbePropTypes.itinerary).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  showInbound: PropTypes.bool.isRequired,
  outboundTimeTable: PropTypes.shape(IbePropTypes.timeTable),
  inboundTimeTable: PropTypes.shape(IbePropTypes.timeTable),
};

QuoteCrossingSupplements.defaultProps = {
  disabled: false,
  supplements: {
    inbound: null,
    outbound: [],
  },
  outboundTimeTable: null,
  inboundTimeTable: null,
};

export default memo(QuoteCrossingSupplements);

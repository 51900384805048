import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import Title from '../../ui/Title';
import Text from '../../ui/Text';
import { formatPortNameByCode } from '../../../lib/helpers/crossings';

import { dictionaryItem } from '../../../hocs/withDictionary';

import {
  List,
  Item,
} from './ItxCrossingPopOver.style';
import IbePropTypes from '../../../IbePropTypes';

const CrossingItem = ({
  children,
  isReturn,
  inboundItinerary,
  outboundItinerary,
  ports,
}) => {
  const sameRoute = isReturn &&
    (outboundItinerary.arrivalPort === inboundItinerary.departurePort) &&
    (outboundItinerary.departurePort === inboundItinerary.arrivalPort);

  return (
    <Item>
      {isReturn ? (
        <>
          {sameRoute ? (
            <>
              Return crossing
              {' '}from{' '}
              {formatPortNameByCode(ports, outboundItinerary.departurePort)}
              {' '}to{' '}
              {formatPortNameByCode(ports, outboundItinerary.arrivalPort)}
            </>
          ) : (
            <>
              Outbound crossing
              {' '}from{' '}
              {formatPortNameByCode(ports, outboundItinerary.departurePort)}
              {' '}to{' '}
              {formatPortNameByCode(ports, outboundItinerary.arrivalPort)}
              <br />
              Return crossing
              {' '}from{' '}
              {formatPortNameByCode(ports, inboundItinerary.departurePort)}
              {' '}to{' '}
              {formatPortNameByCode(ports, inboundItinerary.arrivalPort)}
            </>
          )}
        </>
      ) : (
        <>
          Crossing
          {' '}from{' '}
          {formatPortNameByCode(ports, outboundItinerary.departurePort)}
          {' '}to{' '}
          {formatPortNameByCode(ports, outboundItinerary.arrivalPort)}
        </>
      )}

      {children}
    </Item>
  );
};

CrossingItem.propTypes = {
  children: PropTypes.node,
  isReturn: PropTypes.bool.isRequired,
  inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
  outboundItinerary: PropTypes.shape(IbePropTypes.itinerary).isRequired,
  ports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CrossingItem.defaultProps = {
  children: null,
  inboundItinerary: null,
};

const ExtraItem = ({ name, quantity }) => (
  <Item>
    {name} x {quantity}
  </Item>
);

ExtraItem.propTypes = {
  name: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
};

const ItxCrossingPopOver = ({
  inboundItinerary,
  isReturn,
  outboundItinerary,
  ports,
  quote,
}) => (
  <>
    <Title
      color="inherit"
      dictionary={dictionaryItem('SearchCrossings', 'WhatsIncludedPopover')}
      marginBottom
      size={3}
      tag={3}
    />
    <Text
      dictionary={dictionaryItem('SearchCrossings', 'WhatsIncludedPopover', 'Body')}
      marginBottom
      paragraph
      span
    />
    <Text
      dictionary={dictionaryItem('SearchCrossings', 'WhatsIncludedPopover', 'Package')}
      marginBottom
      paragraph
      span
    />

    <List>
      {/* Campsite bookings */}
      {!!quote.campsiteBookings && quote.campsiteBookings.map(booking => (
        <Item key={booking.id}>
          {booking.pitches.map(pitch => (
            <Fragment key={`${pitch.id}_${pitch.code}`}>
              {pitch.numNights}
              {' '}nights at{' '}
              {booking.campsite.name}

              {/* Campsite Extras */}
              {!!pitch.supplements.length && (
                <List subList>
                  {pitch.supplements.map(p => <ExtraItem key={p.uId} {...p} />)}
                </List>
              )}
            </Fragment>
          ))}
        </Item>
      ))}

      {/* Crossing bookings */}
      {!!quote.crossingBookings && quote.crossingBookings.map((crossing) => {
        const { id, inboundItinerary: inbound, outboundItinerary: outbound } = crossing;
        const hasReturn = !!inbound;
        const outboundExtras = [...outbound.accommodation, ...outbound.supplements];
        const inboundExtas = hasReturn
          ? [...inbound.accommodation, ...inbound.supplements]
          : [];

        return (
          <CrossingItem
            isReturn={hasReturn}
            key={id}
            inboundItinerary={inbound}
            outboundItinerary={outbound}
            ports={ports}
          >
            {/* Outbound extras */}
            {!!outboundExtras.length && (
              <List subList>
                {hasReturn && (
                  <Item
                    title
                    unstyled
                  >
                    Outbound:
                  </Item>
                )}
                {outboundExtras.map(p => <ExtraItem key={p.uId} {...p} />)}
              </List>
            )}

            {/* Inbound Extras */}
            {!!inboundExtas.length && (
              <List subList>
                <Item
                  title
                  unstyled
                >
                  Return:
                </Item>
                {inboundExtas.map(p => <ExtraItem key={p.uId} {...p} />)}
              </List>
            )}
          </CrossingItem>
        );
      })}

      {/* Site night vouchers */}
      {!!quote.extras && quote.extras.map(p => <ExtraItem key={p.uId} {...p} />)}

      {/* Current crossing search result */}
      <CrossingItem
        isReturn={isReturn}
        inboundItinerary={inboundItinerary}
        outboundItinerary={outboundItinerary}
        ports={ports}
      >
        {/* Outbound mandatory accommodation */}
        {!!outboundItinerary.isAccomMandatory && (
          <List subList>
            {!!inboundItinerary && (
              <Item
                title
                unstyled
              >
                Outbound:
              </Item>
            )}
            {outboundItinerary.accommodation
              .filter(({ isMandatory }) => isMandatory)
              .map(p => (
                <ExtraItem key={p.uId} {...p} />
              ))
            }
          </List>
        )}

        {/* Inbound mandatory accommodation */}
        {inboundItinerary.isAccomMandatory && (
          <List subList>
            <Item
              title
              unstyled
            >
              Return:
            </Item>
            {inboundItinerary.accommodation
              .filter(({ isMandatory }) => isMandatory)
              .map(p => (
                <ExtraItem key={p.uId} {...p} />
              ))
            }
          </List>
        )}
      </CrossingItem>
    </List>
  </>
);

ItxCrossingPopOver.propTypes = {
  inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
  outboundItinerary: PropTypes.shape(IbePropTypes.itinerary).isRequired,
  ports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  quote: PropTypes.shape(IbePropTypes.quote),
  isReturn: PropTypes.bool,
};
ItxCrossingPopOver.defaultProps = {
  inboundItinerary: null,
  isReturn: false,
  quote: null,
};

export default memo(ItxCrossingPopOver);

import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { compose, graphql } from 'react-apollo';

import BecomeAMember from './BecomeAMember';
import isLoggedIn from '../../lib/isLoggedIn';
import GET_USER_STATUS from '../../config/graphql/getUser';
import { RENEWAL_STATUSES } from '../../config/membershipStatus';
import { FEATURE_FLAGS, getFeatureFlag } from '../../config/featureFlags';

import StyledSearchTabs, {
  Stick,
  Tabs,
  TabsNavigation,
  TabsContent,
  TabLink,
} from './SearchTabs.style';
import { BasketToggle } from '../Basket';
import SEARCH_TABS, {
  UK_SITES_TAB,
} from '../../constants/searchTabs';
import isTabActive from './searchHelpers';
import IbePropTypes from '../../IbePropTypes';
import FetchPolicy from '../../constants/FetchPolicy';
import { appendQuery } from '../../lib/location';

class SearchTabs extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.func,
    ]).isRequired,
    toggleBasket: PropTypes.func.isRequired,
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    searchTabsClick: PropTypes.func.isRequired,
    stickyAdvert: PropTypes.bool,
    onProductTypeMismatch: PropTypes.func,
    userStatus: PropTypes.shape({
      loading: PropTypes.bool,
      user: PropTypes.shape(IbePropTypes.user),
    }),
  };

  static defaultProps = {
    stickyAdvert: false,
    userStatus: {
      loading: true,
      user: null,
    },
    onProductTypeMismatch: null,
  }

  drawerRef = createRef();

  formRef = createRef()

  advertRef = createRef();

  tabsRef = createRef();

  header = null;

  componentDidMount() {
    this.header = document.getElementById('header');
  }

  handleTabClick = (e, isOverseas = false) => {
    const { router: { query } } = this.props;
    e.persist();
    e.preventDefault();

    const path = e.target?.getAttribute('href');
    const clickAction = () => {
      if (query.bookingWidget === 'true') {
        document.location.href = appendQuery(path, { bookingWidget: query.bookingWidget });
      } else {
        document.location.href = path;
      }
      this.props.searchTabsClick();
    };

    const showProductMismatchDialog = this.props.onProductTypeMismatch?.(isOverseas, clickAction);
    if (!showProductMismatchDialog) {
      clickAction();
    }
  }

  render() {
    const {
      children, stickyAdvert, userStatus, router,
    } = this.props;
    let combinedHeight = null;
    // get the combined height for header + form + tabs
    if (this.formRef.current && this.tabsRef.current) {
      combinedHeight =
        this.formRef.current.offsetHeight +
        this.tabsRef.current.offsetHeight +
        (this.header?.offsetHeight ?? 0);
    }

    let isThereSpaceForAd = false;

    // if space left is more then height of advert, make advert sticky.
    if (this.advertRef.current) {
      // Count the space left in drawer, for users screen
      const spaceLeft = window.innerHeight - combinedHeight;
      isThereSpaceForAd = spaceLeft > this.advertRef.current.offsetHeight;
    }

    const isAdvertSticky = isThereSpaceForAd && stickyAdvert;
    const isBookingWidget = router.query.bookingWidget === 'true';
    const showBecomeAMember = (!isLoggedIn()
      || (userStatus.user?.membershipExpired)) &&
      !RENEWAL_STATUSES.includes(userStatus.user?.membershipStatus) &&
      !isBookingWidget;

    let contentHeight;

    if (this.tabsRef.current && this.drawerRef.current) {
      contentHeight = this.drawerRef.current.offsetHeight - this.tabsRef.current.offsetHeight;
    }

    let tabsToShow = [...SEARCH_TABS];
    if (!getFeatureFlag(FEATURE_FLAGS.UK_SITES)) {
      tabsToShow = tabsToShow.filter(tabItem => tabItem.id !== UK_SITES_TAB.id);
    }

    return (
      <StyledSearchTabs isBookingWidget={isBookingWidget}>
        <Tabs
          ref={this.drawerRef}
        >
          <TabsNavigation
            ref={this.tabsRef}
          >
            {tabsToShow.map((tab, index) => (
              <Link
                key={tab.id}
                href={tab.href}
              >
                <TabLink
                  active={isTabActive(this.props.router, tab)}
                  href={tab.href}
                  onClick={(e) => this.handleTabClick(e, tab.id !== UK_SITES_TAB.id)}
                >
                  {tab.label}
                </TabLink>
              </Link>
            ))}
            {!isBookingWidget && <BasketToggle handleClick={this.props.toggleBasket} />}
          </TabsNavigation>

          <TabsContent contentHeight={contentHeight}>
            {children({
              formRef: this.formRef,
              height: this.advertRef.current ? (contentHeight - this.advertRef.current.offsetHeight) : 'auto',
            })}
            {showBecomeAMember &&
              <Stick sticky={isAdvertSticky}>
                <BecomeAMember
                  advertRef={this.advertRef}
                  userStatus={userStatus}
                  toggleBasket={this.props.toggleBasket}
                  isOverseas={router?.query?.isOverseas === 'true'}
                />
              </Stick>
            }
          </TabsContent>
        </Tabs>
      </StyledSearchTabs>
    );
  }
}

export default compose(
  withRouter,
  graphql(GET_USER_STATUS, {
    name: 'userStatus',
    options: {
      fetchPolicy: FetchPolicy.CACHE_ONLY,
    },
    skip: props => !isLoggedIn(),
  }),
)(SearchTabs);

import getConfig from 'next/config';
import React from 'react';

import PropTypes from 'prop-types';

import { useJsApiLoader } from '@react-google-maps/api';
import {
  GOOGLE_API_LANGUAGE,
  GOOGLE_API_LIBRARIES,
  GOOGLE_API_REGION,
  GOOGLE_SCRIPT_ID,
} from '../config/apis';

const { publicRuntimeConfig: { ENVIRONMENT } } = getConfig();

const GoogleContext = React.createContext({ loading: false, scriptReady: false });

const GoogleProvider = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: GOOGLE_SCRIPT_ID,
    googleMapsApiKey: ENVIRONMENT.GOOGLE_API_KEY,
    version: ENVIRONMENT.GOOGLE_MAP_API_VERSION,
    libraries: GOOGLE_API_LIBRARIES,
    language: GOOGLE_API_LANGUAGE,
    region: GOOGLE_API_REGION,
  });

  return (
    <GoogleContext.Provider
      value={{
        loading: !isLoaded,
        scriptReady: isLoaded && !loadError,
      }}
    >
      {children}
    </GoogleContext.Provider>
  );
};

GoogleProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

GoogleProvider.defaultProps = {
  children: null,
};

const useGoogle = () => React.useContext(GoogleContext);

export { GoogleContext, GoogleProvider, useGoogle };

import gql from 'graphql-tag';

export default gql`
mutation AmendBookingOutfit($input: AmendBookingOutfit) {
  amendBookingOutfit(input: $input)
    @rest(
      type: "Amend"
      path: "BookingAmendment/UpdateBookingAmendmentoutfit"
      method: "POST"
    )
    {
      errorCode
      warnings
    }
}
`;

import gql from 'graphql-tag';

export default gql`
  query GuestPrice(
    $pitchCode: String!,
    $productCode: Int!,
    $guestType: Int!,
    $dateOfBirth: String!,
    $arrivalDate: String!,
    $departureDate: String!,
    $path: any!,
    $skip: Boolean,
  ) @skip(if: $skip) {
    guestPrice(
      PitchCode: $pitchCode,
      ProductCode: $productCode,
      GuestType: $guestType,
      DateOfBirth: $dateOfBirth,
      ArrivalDate: $arrivalDate,
      DepartureDate: $departureDate
    ) @rest(
      type: "GuestPrice",
      path: $path
    ) {
      price
    }
  }
`;

import Events from 'eventemitter3';
import moment from 'moment';

export function getCalendarConfig(calendarDate) {
  // Day of the week
  let startDay = calendarDate.startOf('month').day();
  const delta = startDay < 1 ? 6 : -1;
  startDay += delta;

  // Calculate the total amount of cells and the calendar row length
  const daysInMonth = calendarDate.daysInMonth();
  const cellLength = startDay + daysInMonth;
  const rowLength = Math.ceil(cellLength / 7);
  const totalCells = rowLength * 7;

  // Get calendar month and year
  const month = calendarDate.month();
  const year = calendarDate.year();

  return {
    cellLength,
    daysInMonth,
    month,
    rowLength,
    startDay,
    totalCells,
    year,
  };
}

const datepicker = (opt) => {
  const options = {
    click: 0, // will return 0:1 to determine date range
    months: 1,
    startDate: null,
    endDate: null,
    dateRange: false,
    maxDate: null,
    minDate: null,
    name: '',
    open: false,
    showRemainder: false,
    static: false, // absolute || relative
    ...opt,
  };

  return Object.assign(this || {}, {
    ...options,
    open: options.static || options.open,
    startDate: options.startDate ? moment(options.startDate).startOf('day') : null,
    endDate: options.endDate ? moment(options.endDate).startOf('day') : null,
    minDate: options.minDate ? moment(options.minDate) : null,
    maxDate: options.maxDate ? moment(options.maxDate) : null,

    onCellClick(date, fieldFocus, isLastDayInOpenRange) {
      // Check if a date input field has been recently focused, and if so set the cell accordingly
      const { handleFieldFocus } = options;

      if (fieldFocus) {
        if (fieldFocus === 'startDate') {
          if (date.isAfter(this.endDate)) {
            this.clear();
          }

          this.click = 0;
        } else {
          if (date.isBefore(this.startDate)) {
            this.startDate = moment(date).subtract(1, 'days');
          }

          this.click = 1;
        }
      } else if (
        this.startDate
        && this.endDate
        && date.isAfter(this.startDate)
        && date.isBefore(this.endDate)
      ) {
        // If date is in range, reset and set startDate
        this.clear();
        this.click = 0;
      } else if (this.endDate && date.isAfter(this.endDate)) {
        // If date is after existing endDate, adjust the endDate
        this.click = 1;
      } else if (this.startDate && date.isBefore(this.startDate)) {
        // If date is before existing startDate, adjust the startDate
        this.click = 0;
      } else if (this.dateRange && this.startDate && !this.endDate) {
        // If range picker and no endDate, set the endDate
        this.click = 1;
      } else if (this.dateRange && isLastDayInOpenRange) {
        // If ranger picker and we click on the very last day, interpret this as endDate
        this.click = 1;
      } else {
        // Set the startDate
        this.click = 0;
      }

      // Remove current field focus (used in Site Card 3)
      if (handleFieldFocus) {
        handleFieldFocus('');
      }
      return this.click;
    },

    clear() {
      this.startDate = null;
      this.endDate = null;
    },

    close() {
      this.open = false;
    },

    /**
     *
     * @param {Object} monthDate Moment date
     */
    getCalendar(calendarDate = moment().startOf('month')) {
      // Calculate the total amount of cells and the calendar row length
      const config = getCalendarConfig(calendarDate);

      // Create and assign calendar cell data
      const cells = Array.from(Array(config.totalCells).keys()).map((n, i) => {
        const day = i - config.startDay;
        const date = moment(calendarDate).add(day, 'days');
        const disabled = date.isBefore(this.minDate) || date.isAfter(this.maxDate);
        const visible = day > -1 && day < config.daysInMonth;
        const isMaxDay = date.isSame(this.maxDate);
        const isMinDay = date.isSame(this.minDate);

        const cell = {
          disabled,
          date,
          day,
          isMaxDay,
          isMinDay,
          visible,
        };

        return cell;
      });

      const calendar = {
        calendarDate,
        cells,
        days: config.daysInMonth,
        rowLength: config.rowLength,
        month: config.month,
        startDay: config.startDay,
        year: config.year,
      };

      return calendar;
    },

  }, Events.prototype);
};

export default datepicker;

import styled from 'styled-components';
import StyledBadge from '../ui/Badge/Badge.style';

export const TabWrapper = styled.li`
  display: flex;
  list-style: none;
  margin: auto 0 0;
  min-height: ${({ theme }) => theme.BUTTON_SIZE_MEDIUM};
  min-width: fit-content;
  width: 18%;
  max-width: 40%;
  min-height: 3.1rem;
  margin-top: ${({ active }) => (active ? '0.7rem' : '1rem')};
  margin-right: 0.25rem;

  &:first-child {
    margin-left: ${({ theme }) => theme.PAGE_GUTTER};
  }

  &:last-child {
    margin-right: ${({ theme }) => theme.PAGE_GUTTER};
  }
`;

export const TabLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  flex: 1;
  margin-right: 0.125rem;
  background-color: ${({ active, theme }) => (active ? theme.COLOR_BRAND_PRIMARY : theme.COLOR_WHITE)};
  border: 2px solid ${({ active, theme }) => (active ? theme.COLOR_BRAND_PRIMARY : theme.COLOR_BRAND_PRIMARY_DARK)};
  border-bottom: 0;
  color: ${({ active, theme }) => (active ? theme.COLOR_WHITE : theme.COLOR_BRAND_PRIMARY_DARK)};
  font-size: 0.875rem;
  font-weight: 500;
  padding: ${({ active }) => (active ? '0.3rem' : 0)} 1rem 0;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ active, theme }) => (active ? theme.COLOR_BRAND_PRIMARY : theme.COLOR_GRAY_LIGHTEST)};
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const Badge = styled(StyledBadge)`
  color: ${props => props.theme.COLOR_WHITE};
  background-color: ${props => props.theme.COLOR_BRAND_PRIMARY_DARK};
  margin: 0.1rem 0 0 0.5rem;
  padding: 0 0.5rem;
  min-width: 1.6rem;
  text-align: center;
`;

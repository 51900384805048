import styled from 'styled-components';
import Text from '../ui/Text';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ToolTipTextContainer = styled.div`
  display: flex;
`;

export const ToolTipText = styled(Text)`
  font-size: 0.8rem;
  text-decoration: underline;
  cursor: pointer;
`;

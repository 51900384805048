import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  padding-right: 0;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border-bottom: ${({ theme }) => `1px solid ${theme.COLOR_GRAY_LIGHT}`};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

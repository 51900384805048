import React from 'react';
import PropTypes from 'prop-types';

import StyledNote, { NoteIcon, NoteText } from './Note.style';

import theme from '../../styles/config/theme';

const defaultProps = {
  backgroundColor: 'transparent',
  color: theme.COLOR_AMBER_ORANGE,
  className: '',
  dictionary: '',
  icon: null,
  size: '',
  text: '',
  innerRef: null,
};

const propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  dictionary: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string,
  innerRef: PropTypes.shape({}),
};

function Note(props) {
  return (
    <StyledNote ref={props.innerRef} {...props}>
      {!!props.icon &&
        <NoteIcon
          color={props.color}
          icon={props.icon}
          size={props.size}
        />
      }
      <NoteText
        color={props.backgroundColor ? props.color : null}
        dictionary={props.dictionary}
        size={props.size}
      >
        {props.text}
      </NoteText>
    </StyledNote>
  );
}

Note.defaultProps = defaultProps;
Note.propTypes = propTypes;

export default Note;

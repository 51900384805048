import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Query, graphql } from 'react-apollo';
import DOMPurify from 'isomorphic-dompurify';

import { dictionaryItem } from '../../hocs/withDictionary';
import GET_DICTIONARY_ITEM from '../../hocs/withDictionary/getDictionaryItem.gql';
import GET_QUOTE from '../Quote/graphql/getQuote';

import Title from '../ui/Title';
import Text from '../ui/Text';
import QuoteAcceptComponent from '../Quote/QuoteAcceptComponent';

import svgCheck from '../../static/images/icons/Check.svg';

import {
  Actions,
  Action,
  ActionBrand,
  AlertIcon,
} from './PopUp.styles';

const propTypes = {
  quote: PropTypes.shape({
    crossingBookings: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  saving: PropTypes.number,
  onDeclineUpSellClick: PropTypes.func,
  onApplyUpSellClick: PropTypes.func,
};

const defaultProps = {
  quote: {
    crossingBookings: [],
  },
  saving: 0,
  onDeclineUpSellClick() {},
  onApplyUpSellClick() {},
};

function getCrossingIds(props) {
  return props.quote.crossingBookings.map(c => c.id);
}

function UpSellPopUp(props) {
  return (
    <Fragment>
      <AlertIcon icon={svgCheck} />
      {/* Manually get dictionary item so we can string replace the saving */}
      <Query query={GET_DICTIONARY_ITEM} variables={{ key: 'UpSellPopUp__Prompt' }}>
        {({ data }) => {
          if (!data || !data.dictionaryItem) return null;

          let promptHtml = data.dictionaryItem && data.dictionaryItem.value;
          const savingHtml = `<span class="highlight">&#163;${props.saving}</span>`;
          promptHtml = promptHtml.replace('{{saving}}', savingHtml);

          return (
            <Title align="center" tag={3} size={2} marginBottom>
              <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(promptHtml) }} />
            </Title>
          );
        }}
      </Query>

      <Text align="center" dictionary={dictionaryItem('UpSellPopUp')} size="1rem" />
      <Actions>
        <Action
          onClick={props.onDeclineUpSellClick}
          dictionary={dictionaryItem('UpSellPopUp', 'DeclineUpSell')}
        />
        <QuoteAcceptComponent
          componentIds={getCrossingIds(props)}
          onSuccess={props.onApplyUpSellClick}
        >
          <ActionBrand
            dictionary={dictionaryItem('UpSellPopUp', 'ApplyUpSell')}
          />
        </QuoteAcceptComponent>
      </Actions>
    </Fragment>
  );
}

UpSellPopUp.propTypes = propTypes;
UpSellPopUp.defaultProps = defaultProps;

export default graphql(GET_QUOTE, {
  props: ({ data }) => ({ ...data }),
})(UpSellPopUp);

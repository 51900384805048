import React, { memo, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';
import { withTheme } from 'styled-components';
import DOMPurify from 'dompurify';

import { Modal } from '../ui';
import AddToBasket from '../AddToBasket';
import MembershipLightBoxTable, {
  MembershipLightBoxTableMobile,
} from './MembershipLightBoxTable';

import ProductModalStyled, {
  ButtonClose,
  Content,
  LeftContentContainer,
  RightContentContainer,
  PriceContainer,
  ButtonContainer,
  MessageLeft,
  FooterRight,
  BrandedText,
  Title,
  Text,
} from '../ui/Modal/ProductModal.style';
import RichTextList from '../ui/RichText/RichTextList.style';

import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import GET_QUOTE from '../Quote/graphql/getQuote';
import { formatPriceWithCurrency } from '../../lib/format';
import IbePropTypes from '../../IbePropTypes';
import { getDictionaryItem } from '../../hocs/withDictionary';
import quoteMembershipTypes, {
  getENumWithValue,
  loggedInMemberTypes,
} from '../../config/quoteMembershipTypes';
import PRODUCT_TYPES from '../../config/products';
import { getProductCodeByName } from '../../lib/campsiteTypes';
import { dataLayerManager } from '../../lib/dataLayer';

const propTypes = {
  active: PropTypes.bool,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  configuration: PropTypes.shape(IbePropTypes.configuration),
  theme: PropTypes.shape({
    COLOR_BRAND_PRIMARY_DARK: PropTypes.string,
  }),
  toggleBasket: PropTypes.func.isRequired,
  quoteLoading: PropTypes.bool,
  isOverseas: PropTypes.bool,
  quote: PropTypes.shape(IbePropTypes.quote),
};

const defaultProps = {
  active: false,
  theme: {},
  configuration: null,
  quoteLoading: false,
  quote: {},
  isOverseas: false,
};

function MembershipLightBox({
  client,
  configuration,
  toggleBasket,
  quoteLoading,
  ...props
}) {
  if (!configuration?.membershipContent) return null;
  const { membershipContent, membershipTypes } = configuration;

  const {
    product,
    nonMemberSupplementsCost,
    outfit,
  } = props.quote ?? {};

  const footerText = getDictionaryItem(
    client,
    'BecomeAMemberCard__Footer__Text',
  ).replace('{{Cost}}', ReactDOMServer.renderToString(
    <BrandedText theme={props.theme}>
      {formatPriceWithCurrency(nonMemberSupplementsCost ?? 0)}
    </BrandedText>,
  ));

  const closeLightBox =
    () => client.writeData({ data: { becomeAMemberPopUp: false } });

  const existingQuote = !!props.quote?.quoteId;

  const membershipByDDType = getENumWithValue(
    quoteMembershipTypes.MembershipByDD,
  );
  const membershipType = membershipByDDType;

  const productCode = getProductCodeByName(
    product,
    configuration?.products,
  );

  const defaultProductCode = props.isOverseas ?
    PRODUCT_TYPES.CAMC_MEMBER_PRODUCT : PRODUCT_TYPES.CAMC_UK_PRODUCT;

  const {
    vehicleHeight,
    vehicleLength,
    vehicleMake,
    vehicleModel,
    vehicleReg,
    vehicleType,
  } = outfit ?? {};

  const payload = {
    errataAccepted: true,
    membershipType,
    productCode: defaultProductCode,
    ...(props.quote?.quoteId ? {
      productCode,
      outfit: vehicleLength ? ({
        vehicleHeight,
        vehicleLength,
        vehicleMake,
        vehicleModel,
        vehicleReg,
        vehicleType,
      }) : undefined,
      partyMembers: props.quote.partyMembers.map(({ dateOfBirth, type, personId }) => ({
        dateOfBirth,
        type,
        personId,
      })),
    } : {}),
  };

  useEffect(() => {
    if (props.active) {
      dataLayerManager.pushPromoView(
        membershipContent.titleText,
        true,
        'Membership Light Box',
        'Membership Light Box',
        'Modal',
      );
    }
  }, [props.active]);

  return (
    <Modal active={props.active}>
      <ProductModalStyled>
        <ButtonClose
          crossWidth="1.875rem"
          onClick={closeLightBox}
          width="4.125rem"
          height="4.125rem"
        />
        <Content
          backgroundColor={props.theme.COLOR_BRAND_PRIMARY_DARK}
          backgroundImage={membershipContent.desktopImageUrl}
          backgroundImageMobile={membershipContent.mobileImageUrl}
        >
          <LeftContentContainer>
            <Title tag={0} marginBottom>
              {membershipContent.titleText}
            </Title>
            <Text subheading>{membershipContent.leftContent}</Text>
            <MembershipLightBoxTable configuration={configuration} />
            <Text imageAlt>{membershipContent.imageAltText}</Text>
          </LeftContentContainer>
        </Content>
        <Content>
          <RightContentContainer withPadding>
            <MembershipLightBoxTableMobile configuration={configuration} />
            <RichTextList withLabel>
              {membershipContent.rightContent}
            </RichTextList>
            <FooterRight>
              {props.active &&
                <MessageLeft dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(footerText) }} />
              }
              <PriceContainer>
                {membershipContent.fromText}<br />
                <b>{formatPriceWithCurrency(
                  membershipTypes.directDebit?.costSingle,
                )}
                </b><br />
                {membershipContent.perYearText}
              </PriceContainer>
              <ButtonContainer>
                <AddToBasket
                  block
                  membershipOnly={!existingQuote}
                  onError={(error) => {
                    console.error(error);
                    closeLightBox();
                  }}
                  onSuccess={() => {
                    closeLightBox();
                    toggleBasket();
                  }}
                  payload={payload}
                  disabled={quoteLoading ||
                    loggedInMemberTypes.includes(props.quote?.membershipType)}
                />
              </ButtonContainer>
            </FooterRight>
          </RightContentContainer>
        </Content>
      </ProductModalStyled>
    </Modal>
  );
}

MembershipLightBox.propTypes = propTypes;
MembershipLightBox.defaultProps = defaultProps;

export default compose(
  memo,
  withTheme,
  withApollo,
  graphql(GET_QUOTE, {
    options: {
      fetchPolicy: 'cache-and-network',
    },
    props: ({ data }) => ({
      quote: data.quote,
      quoteLoading: data.loading,
    }),
  }),
  graphql(GET_CONFIGURATION, {
    props: ({ data }) => ({ configuration: data.configuration }),
  }),
)(MembershipLightBox);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Text from '../ui/Text';
import Title from '../ui/Title';

import {
  Checkbox, Row, Col, CheckboxLabel, CheckboxText,
} from './AvailabilityDetailsSpecReq.style';

import { dictionaryItem } from '../../hocs/withDictionary';
import testingAttr from '../../lib/testingAttr';

const propTypes = {
  handleChange: PropTypes.func.isRequired,
  specReq: PropTypes.arrayOf(
    PropTypes.shape(
      { name: PropTypes.string },
    ),
  ),
  fetchedRequests: PropTypes.arrayOf(PropTypes.shape({})),
  selectedSpecReq: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool.isRequired,
};

const defaultProps = {
  specReq: [],
  fetchedRequests: [],
  selectedSpecReq: [],
};

function AvailabilityDetailsSpecReq({ disabled, selectedSpecReq, ...props }) {
  if (!props.fetchedRequests.length) {
    return (
      <Text dictionary={dictionaryItem('AvailabilityDetailsSpecReq', 'NoSpecialRequests')} />
    );
  }

  return (
    <Fragment>
      <Title dictionary={dictionaryItem('AvailabilityDetailsSpecReq')} marginBottom size={3} tag={1} />
      <Text size="0.875rem" dictionary={dictionaryItem('AvailabilityDetailsSpecReq')} />
      <Row>
        {props.fetchedRequests.map((req, index) => {
          const isSelected = selectedSpecReq.some(({ name }) => name === req.name);
          const isDisabled = disabled || isSelected;
          const isChecked = props.specReq.some(({ name }) => name === req.name) || isSelected;

          return (
            <Col flex="0 0 auto" key={req.name}>
              <Checkbox
                checked={isChecked}
                disabled={isDisabled}
                id={req.name}
                name={req.name}
                onChange={props.handleChange}
                {...testingAttr(`special-request_index-${index}`)}
              />
              <CheckboxLabel htmlFor={req.name}>
                <CheckboxText size="0.875rem">{req.name}</CheckboxText>
              </CheckboxLabel>
            </Col>
          );
        })}
      </Row>
    </Fragment>
  );
}

AvailabilityDetailsSpecReq.propTypes = propTypes;
AvailabilityDetailsSpecReq.defaultProps = defaultProps;

export default AvailabilityDetailsSpecReq;

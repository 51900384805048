import styled, { css } from 'styled-components';
import { above } from '../../../styles/libs';
import Label from '../../ui/Form/Label';
import ButtonInfo from '../../ui/Button/ButtonInfo.style';

export const RadioGroup = styled.div`
  display: flex;
  padding: 0 0 1rem;
  flex-wrap: wrap;

  ${({ theme }) => above(theme.TABLET)`
    flex-wrap: nowrap;
  `};
`;

export const RadioGroupItem = styled.div`
  flex: 0 0 auto;
  margin-right: 0.5rem;

  ${({ theme }) => above(theme.DESKTOP)`
    margin-right: 3.5rem;
  `};
`;

export const CheckboxFormGroup = styled.div`
  display: flex;
`;

export const CheckboxLabel = styled(Label)`
  font-size: 0.875rem;
  margin-left: 0.5rem;
  padding-top: 0.125rem;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1.5rem;
  position: relative;

  ${({ theme }) => above(theme.TABLET)`
    flex-wrap: no-wrap;
    width: auto;
    margin-top: 0;
  `};
`;

export const CloseButton = styled(ButtonInfo)`
  left: calc(182px + 1rem);
  margin: 0;
  position: absolute;
  top: 0.2rem;
`;

export const customStyled = css`
  border: 1px solid ${({ theme }) => theme.COLOR_LAKE_GREEN_DARK};
  border-radius: 0.25rem;
`;

import styled from 'styled-components';
import zIndex from '../../config/zIndex';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
  z-index: ${zIndex.IMPORTANT_CONTENT};
`;

export const Content = styled.div`
  overflow-y: auto;
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
`;

export const Header = styled.header`
  display: flex;
  min-height: 5.5rem;
  border-bottom: 0.25rem solid ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  background: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
  position: sticky;
  top: 0;
  z-index: ${zIndex.IMPORTANT_CONTENT};;
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
`;

export const TabNav = styled.ul`
  display: flex;
  flex: 1;
  margin: auto 0 0;
  padding: 0;
`;

module.exports = {
  breakpoints: {
    mobile: 0,
    'mobile-large': 480,
    tablet: 768,
    desktop: 1024,
    'desktop-large': 1440,
  },
  bodyPadding: 16,
  calendarWidth: 320,
};

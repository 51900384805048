import { compose } from 'lodash/fp';
import { v4 as uuid } from 'uuid';
import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';
import { TOW_TYPES, VEHICLE_TYPES } from '../../config/outfits';
import { isNoneNullOrUndefined, shouldValidateOutfitLength } from '../helpers/outfits';

// Distinction needed between dictionaryItem retrieval due to string replacement.
// Outfit errors need makeErrorGetDictionaryItem
function makeErrorDictionaryItem(type, ...args) {
  if (!type) return null;
  return { id: uuid(), type, message: dictionaryItem('OutfitForm', ...args, 'Error') };
}

function makeErrorGetDictionaryItem(type, client, ...args) {
  if (!type) return null;
  const argsJoined = args.join('__');
  return { id: uuid(), type, message: getDictionaryItem(client, `OutfitForm__${argsJoined}__Error__Text`) };
}

function makeErrorArrivalTime(type, ...args) {
  if (!type) return null;
  return { type, message: dictionaryItem('AvailabilityArrivalTime', ...args, 'Error') };
}

export const validateOutfitTowRequired = ({ towType, towRequired }, client) => (errors = []) => {
  if (!towRequired) return errors;

  const towProvided = (!!towType || towType !== '') || towType === TOW_TYPES.NONE;

  if (towProvided) return errors;

  return [...errors, makeErrorGetDictionaryItem('outfit', client, 'Tow', 'Missing')];
};

export const validateOutfitHeightRequired = (outfit, client) => (errors = []) => {
  // abort if we already have a validateOutfit error
  if (errors.find(e => e.type === 'outfit')) return errors;

  const vehicleHeightExists =
    !outfit.vehicleHeight || (outfit.vehicleHeight && Number.isNaN(outfit.vehicleHeight));

  const towExists = !!outfit.towType;
  const towHeightExists = !outfit.towHeight || (outfit.towHeight && Number.isNaN(outfit.towHeight));

  if (vehicleHeightExists) {
    errors.push(makeErrorGetDictionaryItem('vehicleHeight', client, 'VehicleHeight', 'Missing'));
  }

  if (towExists && towHeightExists) {
    errors.push(makeErrorGetDictionaryItem('towHeight', client, 'TowHeight', 'Missing'));
  }

  return errors;
};

const validateOutfitLengthRequired = (
  outfit, isOverseas, config, router, client,
) => (errors = []) => {
  // Check if outfit exists
  const vehicleIsNull = isNoneNullOrUndefined(outfit.vehicleType);
  const vehicleIsMissing = outfit.vehicleType === '';
  const vehicleIsNone = outfit.vehicleType === VEHICLE_TYPES.NONE;
  const vehicleExists = !(vehicleIsNull || vehicleIsMissing || vehicleIsNone);
  const towingExists = !!outfit.towType;
  const registrationExists = !!outfit.vehicleReg;

  if (!vehicleExists) {
    errors.push(makeErrorGetDictionaryItem('outfit', client, 'Missing'));
  }
  const shouldValidateVehicleLength = shouldValidateOutfitLength(
    outfit.vehicleType,
    true,
    isOverseas,
    config,
    router.query.eventType,
  );
  const shouldValidateTowLength = shouldValidateOutfitLength(
    outfit.towType,
    false,
    isOverseas,
    config,
    router.query.eventType,
  );

  if (vehicleExists && outfit.vehicleType !== VEHICLE_TYPES.NONE) {
    if (
      shouldValidateVehicleLength &&
      ((!outfit.vehicleLength && outfit.lengthRequired) ||
        (outfit.vehicleLength && Number.isNaN(outfit.vehicleLength)))
    ) {
      errors.push(makeErrorGetDictionaryItem('vehicleLength', client, 'VehicleLength', 'Missing'));
    }
    if (outfit.registrationRequired && !registrationExists) {
      errors.push(makeErrorGetDictionaryItem('registration', client, 'Registration', 'Missing'));
    }
  }

  if (towingExists) {
    if (
      shouldValidateTowLength &&
      (!outfit.towLength ||
        (outfit.towLength && Number.isNaN(outfit.towLength)))
    ) {
      errors.push(makeErrorGetDictionaryItem('towLength', client, 'TowLength', 'Missing'));
    }
  }

  return errors;
};

export const validateParty = (partyMembers, useDob, isOverseas) => {
  const errors = [];

  // If member is not adult and no age is selected
  const invalidAges = partyMembers.some(member => member.type > 0 && member.age === null);
  const invalidDobs = partyMembers.some(member => member.type > 0 && !member.dateOfBirth);

  if (invalidAges && !useDob) {
    errors.push(makeErrorDictionaryItem('outfit', 'AvailabilityParty', 'Age'));
  }

  if (isOverseas && invalidDobs && useDob) {
    errors.push(makeErrorDictionaryItem('outfit', 'AvailabilityParty', 'DateOfBirth'));
  }

  return errors;
};

export const validateArrivalTime = (arrivalTime) => {
  const errors = [];

  if (arrivalTime === undefined || arrivalTime === '') {
    errors.push(makeErrorArrivalTime('arrivalTime', 'Missing'));
  }

  return errors;
};

export const validateOutfitCampsite = (outfit, isOverseas, config, router, client) => compose(
  validateOutfitTowRequired(outfit, client),
  validateOutfitLengthRequired(outfit, isOverseas, config, router, client),
)();

export const validateOutfitCrossing = (outfit, isOverseas, config, router, client) => compose(
  validateOutfitHeightRequired(outfit, client),
  validateOutfitLengthRequired(outfit, isOverseas, config, router, client),
)();

import styled from 'styled-components';

export const CostSummary = styled.div``;

export const CostItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;

  &:first-of-type {
    margin-top: 1rem;
  }

  ${({ bold }) => bold && `
    font-weight: bold;
    font-size: 32px;
    padding: 1rem 0;
  `}
`;

export const CostSummaryTitle = styled.div`
  margin-bottom: 1rem;
  font-weight: bold;
`;

export const CostName = styled.div`
  display: flex;
  align-items: center;
`;

export const CostValue = styled.div`
  letter-spacing: 0.5px;
`;

export const PriceSummaryTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &:not(:last-of-type) {
    margin: 0rem 0 1.75rem;
  }
`;

export const CostSummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Text from '../ui/Text';
import { Col, Row } from '../ui/Grid';
import { ButtonBordered } from '../ui/Button';

import { Body } from './Guests.style';
import GuestsForm from './GuestForm';
import GuestList from './GuestList';
import GuestTotal from './GuestTotal';

import { dictionaryItem } from '../../hocs/withDictionary';
import usePrevious from '../../lib/hooks/usePrevious';
import IbePropTypes from '../../IbePropTypes';

function Guests({
  configuration, maxDate, minDate, maxLength, maxChildAge, minChildAge,
  onChange, guests, payload, validatePayload, getErrorElement, isOverseas,
}) {
  const [guest, setGuest] = useState(null);
  const [editing, setEditing] = useState(false);
  const [deletingGuest, setDeletingGuest] = useState(null);
  const [guestPrices, setGuestPrices] = useState({});

  const prevMinDate = usePrevious(minDate);
  const prevMaxDate = usePrevious(maxDate);

  useEffect(() => {
    if (deletingGuest) {
      setDeletingGuest(null);
      const newGuests = guests.filter((guestItem) => guestItem.personId !== deletingGuest &&
        deletingGuest !== guestItem.tempId);
      onChange(newGuests);
    }
  }, [deletingGuest]);

  useEffect(() => {
    const minDateChanged = prevMinDate && prevMinDate !== minDate;
    const maxDateChanged = prevMaxDate && prevMaxDate !== maxDate;
    if (minDateChanged || maxDateChanged) {
      onChange([]);
      setEditing(false);
      setGuest(null);
    }
  }, [payload, minDate, maxDate]);

  const handleReset = (newGuest) => {
    if (!minDate || !maxDate) {
      validatePayload(payload);
      const element = getErrorElement();
      if (element) {
        return element.focus();
      }
    }
    setGuest(newGuest);
    setEditing(false);
    return true;
  };

  const handleAddGuest = (newGuest) => {
    const existing = guests.find(
      (guestItem) => guestItem.personId === newGuest.tempId ||
      guestItem.tempId === newGuest.tempId,
    );
    if (existing) {
      onChange(guests.map((guestItem) => {
        if (
          guestItem.personId === newGuest.tempId ||
          guestItem.tempId === newGuest.tempId) {
          return newGuest;
        }
        return guestItem;
      }));
    } else {
      onChange([
        ...guests,
        newGuest,
      ]);
    }
    setGuest(null);
  };

  const handleRemoveGuest = (personId) => setDeletingGuest(personId);

  const handleEditGuest = (personId) => {
    const guestToEdit = guests.find(
      (guestItem) => guestItem.personId === personId || guestItem.tempId === personId,
    );
    setGuest(guestToEdit);
    setEditing(true);
  };

  const handleGuestPrice = (personId, price) => {
    setGuestPrices({
      ...guestPrices,
      [personId]: price,
    });
    const updatedGuests = guests.map((guestItem) => {
      const guestCopy = { ...guestItem };
      if (guestCopy.personId === personId) {
        guestCopy.price = price;
      }
      return guestCopy;
    });
    onChange(updatedGuests);
  };

  let addGuestLabel = guest ? 'AddingGuest' : 'AddGuest';
  if (!guest && guests.length) {
    addGuestLabel = 'AddMoreGuests';
  }
  const addDisabled = !!guest || guests.length >= maxLength;

  return (
    <>
      <Body>
        <Row align="center" size="small" marginLess>
          <Col flex={2}>
            <Text
              dictionary={dictionaryItem('Guests', 'AddGuestsNote')}
              size="0.875rem"
            />
          </Col>
          <Col flex={1}>
            <ButtonBordered
              disabled={addDisabled}
              onClick={() => handleReset({})}
              block
              dictionary={dictionaryItem('Guests', addGuestLabel)}
            />
          </Col>
        </Row>
      </Body>
      {guest && (
        <GuestsForm
          onCancel={() => handleReset(null)}
          configuration={configuration}
          maxChildAge={maxChildAge}
          maxDate={maxDate}
          minChildAge={minChildAge}
          minDate={minDate}
          onAddGuest={handleAddGuest}
          guest={guest}
          payload={payload}
          editing={editing}
          isOverseas={isOverseas}
        />
      )}
      {
        guests.length > 0 && <GuestList
          guests={guests}
          isOverseas={isOverseas}
          onEditGuest={handleEditGuest}
          onRemoveGuest={handleRemoveGuest}
          onUpdateGuestPrice={handleGuestPrice}
          onChange={onChange}
          payload={payload}
        />
      }
      {guests.length > 0 && <GuestTotal guests={guests} guestPrices={guestPrices} />}
    </>
  );
}

Guests.propTypes = {
  configuration: PropTypes.shape(IbePropTypes.configuration).isRequired,
  maxChildAge: PropTypes.number,
  maxDate: PropTypes.string,
  minChildAge: PropTypes.number,
  minDate: PropTypes.string,
  maxLength: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  validatePayload: PropTypes.func.isRequired,
  getErrorElement: PropTypes.func.isRequired,
  payload: PropTypes.shape(IbePropTypes.payload).isRequired,
  guests: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.guest)),
  isOverseas: PropTypes.bool.isRequired,
};

Guests.defaultProps = {
  guests: [],
  maxChildAge: undefined,
  maxDate: undefined,
  minChildAge: undefined,
  minDate: undefined,
};

export default Guests;

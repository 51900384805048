import React, { memo } from 'react';
import PropTypes from 'prop-types';

import theme from '../../styles/config/theme';
import {
  ToggleBodyWrapper,
  ToggleContentContainer,
  ToggleContent,
  ToggleSubTitle,
  ToggleTitle,
  Tick,
} from './Availability.style';
import svgTick from '../../static/images/icons/Tick.svg';

function MemberNonMemberBody({
  selected,
  description,
  disabled,
  name,
}) {
  return (
    <ToggleBodyWrapper>
      <Tick icon={svgTick} selected={selected} disabled={disabled} />
      <ToggleContentContainer>
        <ToggleContent disabled={disabled}>
          <ToggleTitle disabled={disabled}>{name}</ToggleTitle>
          <ToggleSubTitle
            weight="bold"
            color={selected ? theme.COLOR_BRAND_PRIMARY_DARK : theme.COLOR_TEXT_BODY}
          >
            {description}
          </ToggleSubTitle>
        </ToggleContent>
      </ToggleContentContainer>
    </ToggleBodyWrapper>
  );
}

MemberNonMemberBody.propTypes = {
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default memo(MemberNonMemberBody);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';

import queryHelper from '../../lib/queryHelper';

import { standardPropTypes, standardDefaultProps } from '../../lib/standardProptypes';

import ComparisonTabLink from './ComparisonTabLink';
import ComparisonTabContent from './ComparisonTabContent';
import CompareCampsites from './CompareCampsites';
import { ButtonClose } from '../ui/Button';

import GET_CAMPSITE_COMPARISON from './graphql/getCampsiteComparison';

import {
  Section,
  Content,
  Header,
  Main,
  TabNav,
} from './ComparisonTab.style';

class CompareTab extends Component {
  static propTypes = {
    ...standardPropTypes,
    compareModule: PropTypes.shape({
      active: PropTypes.bool,
    }),
    comparisonCampsites: PropTypes.shape({
      campsites: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    ...standardDefaultProps,
    compareModule: {
      active: false,
    },
  };

  constructor() {
    super();

    this.state = {
      activeTab: 0,
    };

    this.setActiveTab = this.setActiveTab.bind(this);
  }

  setActiveTab(event, index) {
    event.preventDefault();
    this.setState({
      activeTab: index,
    });
  }

  render() {
    return (
      <Section>
        <Content>
          <Header>
            <TabNav role="tablist">
              <ComparisonTabLink
                index={0}
                label="Compare sites"
                quantity={this.props.comparisonCampsites.campsites.length}
                active={this.state.activeTab === 0}
                handleClick={this.setActiveTab}
              />
            </TabNav>
            <ButtonClose onClick={this.props.handleClose} />
          </Header>
          <Main>
            <ComparisonTabContent
              index={0}
              active={this.state.activeTab === 0}
            >
              <CompareCampsites />
            </ComparisonTabContent>
          </Main>
        </Content>
      </Section>
    );
  }
}

export default compose(
  queryHelper(GET_CAMPSITE_COMPARISON, 'comparisonCampsites'),
)(CompareTab);

import uniqueObjectsByKey from '../uniqueObjectsByKey';

export const campsiteFeatureIds = {
  WEBSITE_DESCRIPTION: 'Website Description',
};

export const mergeFeatures = (campsiteFeatures = [], eventFeatures = []) => {
  const updatedFeatures = campsiteFeatures.map((campsiteFeature) => {
    // Features present in both campsite and event level
    const eventFeatureItem = eventFeatures.find(
      eventFeature => eventFeature.id === campsiteFeature.id,
    );
    if (eventFeatureItem) {
      return eventFeatureItem;
    }
    return campsiteFeature;
  });
  // Features present only in event level
  const newEventFeatures = eventFeatures.filter(
    (eventFeature) => !campsiteFeatures.find(
      (campsiteFeature) => campsiteFeature.id === eventFeature.id,
    ),
  );
  return [
    ...updatedFeatures,
    ...newEventFeatures,
  ];
};

export const getCampsiteFeatureByTag = (features, tag, unique = true) => {
  if (!features?.length || !tag) {
    return [];
  }
  const result = features.filter(feature => feature.tags.includes(tag));
  if (!unique) {
    return result;
  }
  return uniqueObjectsByKey(
    result,
    'id',
  );
};

export const getCampsiteFeatureByTags = (obj, tags, isFeatures = false) => {
  const features = isFeatures ? obj : obj?.features;
  if (!features?.length || !tags.length) {
    return [];
  }
  return features.filter((feature) => {
    let include = false;
    tags.forEach((tag) => {
      if (feature.tags.includes(tag)) {
        include = true;
      }
    });
    return include;
  });
};

export const getCampsiteFeatureById = (features, id) => {
  const defaultValue = { value: '' };
  if (!features?.length || !id) {
    return defaultValue;
  }
  return features.find(feature => feature.id === id) ?? defaultValue;
};

/**
 * There is slightly differing behaviour for a scroll to active for the top special listing
 * item, this is because it does not render immediately because of nested wrapping queries.
 *
 * Therefore a querySelector call on the render after the load of listing and price data fails.
 * We could implement a call back from the special listing item, however this is probably overkill
 * for this isolated functionality.
 *
 * So because the special listing item is at the top we can safely scroll to this and allow it to
 * appear when ready.
 */
export const scrollToActive = (divWrapperRef, isMobile, activePin, isSpecialListingItemAtTop) => {
  if (!isMobile && activePin && divWrapperRef.current) {
    const selected = divWrapperRef.current.querySelector('.selected');

    if (selected || isSpecialListingItemAtTop) {
      const top = isSpecialListingItemAtTop ?
        0 : selected.offsetTop - divWrapperRef.current.parentNode.offsetTop;
      divWrapperRef.current.parentNode.scrollTo({ top });
    }
  }
};

import styled, { css } from 'styled-components';

import { below, above } from '../../styles/libs';
import RichTextComponent from '../ui/RichText';

export const RichText = styled(RichTextComponent)`
  width: 100%;

  ul {
    list-style: none;
    padding: 0 2rem;

    li {
      position: relative;
      padding-left: 1.5rem;
      margin: 0;
      text-align: left;

      label {
        display: block;
        font-weight: bold;
      }

      ${({ withLabel }) => withLabel && css`
        :not(label) {
          font-weight: normal;
        }
      `}

      &::before {
        content: "";
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        margin-top: 0.5em;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url("/static/images/icons/ListItemTick.svg");
      }

      ${({ theme }) => below(theme.DESKTOP_LARGE) && css`
      ul {
        list-style: none;
        padding: 0 0.5rem;
      }
        padding-left: 1.5rem;
      `}
    }
  }

  ${({ theme }) => below(theme.TABLET)`
    display: flex;
    justify-content: center;
    border-top: 1px solid rgba(25,25,25, .25);

    ul {
      width: 240px;
      max-width: 100%;
    }
  `}
`;

export const LightBoxTable = styled.table`
  width: 100%;
  table-layout: fixed;
  vertical-align: top;
  border-collapse: collapse;
  border-style: hidden;
  background-color: white;
`;

export const LightBoxBody = styled.tbody`
  display: none;
  table-layout: fixed;
  width: 100%;
  vertical-align: top;
  border-style: hidden;

  ${({ theme, isMobile }) => isMobile && below(theme.TABLET)`
    display: flex;
    flex-direction: column;
    text-align: center;
  `}
  ${({ theme, isMobile }) => !isMobile && above(theme.TABLET)`
    display: table;
  `}
`;

export const LightBoxRow = styled.tr`
  width: 100%;
  vertical-align: top;
`;

export const LightBoxHeader = styled.th`
  width: 100%;
  vertical-align: top;
  background-color: ${({ theme }) => theme.COLOR_LAKE_GREEN_ORIGINAL};
  border-bottom: 0;
`;

export const LightBoxCol = styled.td`
  padding: 0.5rem;
  vertical-align: top;
  padding: 0;
  border: 1px solid black;
  text-align: center;
`;

export const LightBoxTitle = styled.h2`
  margin: 0;
  padding: 1rem;
  color: white;
  text-align: center;
  font-size: 1rem;
`;

export const LightBoxAmount = styled.span`
  display: block;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const LightBoxContent = styled.div`
  padding: 0.5rem 0;
`;

export const LightBoxFooter = styled.tr`
  background-color: ${({ theme }) => theme.COLOR_GRANITE_GRAY};
  text-align: center;
  color: white;
`;

export const LightBoxFooterContent = styled.td`
  padding: 0.5rem;
  font-size: 0.9rem;
`;

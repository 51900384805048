import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import Checkbox from '../ui/Form/Checkbox';
import Text from '../ui/Text';
import { Ul, Li } from '../ui/Text/Text.style';
import Title from '../ui/Title';

import { dictionaryItem } from '../../hocs/withDictionary';
import testingAttr from '../../lib/testingAttr';

import StyledAvailabilityDetailsErrata, { ErrataFooter, ErrataLabel } from './AvailabilityDetailsErrata.style';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  errata: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  errataAccepted: PropTypes.bool.isRequired,
};

function AvailabilityDetailsErrata(props) {
  return (
    <StyledAvailabilityDetailsErrata>
      <Title dictionary={dictionaryItem('AvailabilityDetailsErrata')} marginBottom size={3} tag={1} />
      <Ul marginBottom>
        {props.errata.map(text => (
          <Li key={uuid()}>
            <Text size="0.875rem">{text}</Text>
          </Li>
        ))}
      </Ul>
      <ErrataFooter>
        <Checkbox
          checked={props.errataAccepted}
          disabled={props.disabled}
          name="errataAccepted"
          onChange={props.handleChange}
          {...testingAttr('availability-details__important-info-checkbox')}

        />
        <ErrataLabel htmlFor="errataAccepted" dictionary={dictionaryItem('AvailabilityDetailsErrata', 'AgreeNote')} />
      </ErrataFooter>
    </StyledAvailabilityDetailsErrata>
  );
}

AvailabilityDetailsErrata.propTypes = propTypes;

export default AvailabilityDetailsErrata;

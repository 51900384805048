import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { differenceInDays } from '../../lib/dates';
import formatDate from '../../lib/format';
import { formatHourToRange } from '../../lib/helpers/availability';
import { DATE_FORMAT_DISPLAY } from '../../config/locale';

import BookingSummaryItem from './BookingSummaryItem';
import { DividerWrapper } from './BookingSummaryItem.style';
import Title from '../ui/Title';
import Text from '../ui/Text';

import { dictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';
import FormatAddress from '../../lib/formatAddress';

function stringifyParty(party, partyMemberTypes) {
  const partyObj = {};

  party.forEach(({ type }) => {
    const partyMemberType = partyMemberTypes.find(({ key }) => key === type) || {};
    const partyMemberTypeValue = partyMemberType.value;

    partyObj[partyMemberTypeValue] = partyObj[partyMemberTypeValue] ?
      partyObj[partyMemberTypeValue] + 1 :
      1;
  });

  // eg. 'Adult x 1, Child x 2, Infant x 1'
  if (!Object.keys(partyObj).length) return 'None';
  return Object.keys(partyObj).map(key => `${key} x ${partyObj[key]}`).join(', ');
}

function BookingSummary({
  availabilityDetails,
  basePrice,
  bookingSummary: payload,
  partyMemberTypes,
  campsiteName,
  campsiteAddress,
  pitchName,
  selectedSpecReq,
  towTypes,
  vehicleTypes,
  arrivalTime,
  payloads,
  pitches,
}) {
  const bookingSummary = payloads?.touring || payload;
  const duration = differenceInDays(bookingSummary.end, bookingSummary.start);
  const guestsString = stringifyParty(bookingSummary.guests, partyMemberTypes);
  const partyString = stringifyParty(bookingSummary.partyMembers, partyMemberTypes);
  const address = campsiteAddress && FormatAddress(campsiteAddress);

  const vehicle = vehicleTypes.find(({ key }) => key === bookingSummary.outfit.vehicleType) || {};
  const tow = towTypes.find(({ key }) => key === bookingSummary.outfit.towType) || {};
  const NONE_VALUE = 'None';
  const towString = (vehicleValue, towValue) => (vehicleValue === NONE_VALUE ? '' : ` and ${towValue}`);

  const outfitString = `
    ${vehicle.value}
    ${bookingSummary.outfit.towType ? towString(vehicle.value, tow.value) : ''}
  `;

  const arriveRange = ` (${formatHourToRange(arrivalTime)})`;
  const arriveText = `${formatDate(
    bookingSummary.start, DATE_FORMAT_DISPLAY,
  )}${arrivalTime === null ? '' : arriveRange}`;

  const renderGuestParty = (guestPayload, index) => {
    const advancedGuestPitchName = pitches.find(
      (pitch) => pitch.id === guestPayload?.pitchTypeId,
    )?.name || '';
    const advancedPartyString = stringifyParty(
      guestPayload.partyMembers, partyMemberTypes,
    );
    const advancedPartyDuration = differenceInDays(guestPayload.end, guestPayload.start);
    const advancedPartyArriveRange = ` (${formatHourToRange(guestPayload.arrivalTime)})`;
    const advancedPartyArriveText = `${formatDate(
      guestPayload.start, DATE_FORMAT_DISPLAY,
    )}${guestPayload.arrivalTime === null ? '' : advancedPartyArriveRange}`;
    const advancedVehicle =
      vehicleTypes.find(({ key }) => key === guestPayload.outfit.vehicleType) || {};
    const advancedTow = towTypes.find(({ key }) => key === guestPayload.outfit.towType) || {};

    const advancedOutfitString = `
      ${advancedVehicle.value}
      ${guestPayload.outfit.towType ? towString(advancedVehicle.value, advancedTow.value) : ''}
    `;

    return (
      <DividerWrapper>
        <Title tag={3} size={3}>{`Guest Party ${index + 1}`}</Title>
        <Text marginBottom size="0.875rem">{advancedPartyString}</Text>
        {
          guestPayload?.outfit?.vehicleType !== null && <BookingSummaryItem
            flex="0 0 8rem"
            label="Outfit Type"
            value={{ name: advancedOutfitString, price: null }}
            duration={advancedPartyDuration}
          />
        }
        <BookingSummaryItem flex="0 0 8rem" label="Arriving" value={{ name: advancedPartyArriveText, price: null }} />
        <BookingSummaryItem flex="0 0 8rem" label="Departing" value={{ name: formatDate(guestPayload.end, DATE_FORMAT_DISPLAY), price: null }} />
        <BookingSummaryItem flex="0 0 8rem" label="Duration" value={{ name: `${advancedPartyDuration} nights`, price: null }} />
        <BookingSummaryItem flex="0 0 8rem" label="Pitch" value={{ name: advancedGuestPitchName, price: null }} />
        <BookingSummaryItem
          flex="0 0 8rem"
          label="Special Requests"
          value={[...(guestPayload?.specReq ?? [])].map(request => ({
            name: `${request.name}`,
          }))}
        />
        <BookingSummaryItem
          flex="0 0 8rem"
          label="Extras"
          value={guestPayload.supplements && guestPayload.supplements.map(supplement => (
            {
              name: supplement.quantity ? `${supplement.name} x${supplement.quantity}` : 'None',
              price: supplement.quantity ? (supplement.totalPrice * supplement.quantity) : null,
            }
          ))}
        />
      </DividerWrapper>
    );
  };

  return (
    <Fragment>
      <Title dictionary={dictionaryItem('BookingSummary')} marginBottom size={4} />

      <Title tag={2} size={2}>{campsiteName}</Title>
      <Text marginBottom>{address}</Text>

      {
        bookingSummary.outfit.vehicleType !== null && <BookingSummaryItem
          flex="0 0 8rem"
          label="Outfit Type"
          price={basePrice}
          value={{ name: outfitString, price: null }}
          duration={duration}
        />
      }
      <BookingSummaryItem flex="0 0 8rem" label="Party Details" value={{ name: partyString, price: null }} />
      {bookingSummary.guests?.length > 0 && (
        <BookingSummaryItem flex="0 0 8rem" label="Guests" value={{ name: guestsString, price: null }} />)}
      <BookingSummaryItem flex="0 0 8rem" label="Arriving" value={{ name: arriveText, price: null }} />
      <BookingSummaryItem flex="0 0 8rem" label="Departing" value={{ name: formatDate(bookingSummary.end, DATE_FORMAT_DISPLAY), price: null }} />
      <BookingSummaryItem flex="0 0 8rem" label="Duration" value={{ name: `${duration} nights`, price: null }} />
      <BookingSummaryItem flex="0 0 8rem" label="Pitch" value={{ name: pitchName, price: null }} />
      <BookingSummaryItem
        flex="0 0 8rem"
        label="Offers"
        value={availabilityDetails.offers && availabilityDetails.offers.map(offer => (
          {
            name: offer.title,
            price: null,
          }
        ))}
      />
      <BookingSummaryItem
        flex="0 0 8rem"
        label="Extras"
        value={bookingSummary.supplements && bookingSummary.supplements.map(supplement => (
          {
            name: supplement.quantity ? `${supplement.name} x${supplement.quantity}` : 'None',
            price: supplement.quantity ? (supplement.totalPrice * supplement.quantity) : null,
          }
        ))}
      />
      <BookingSummaryItem
        flex="0 0 8rem"
        label="Special Requests"
        value={[...(bookingSummary.specReq ?? []), ...selectedSpecReq].map(request => ({
          name: `${request.name}`,
        }))}
      />
      {!!payloads?.camping?.length > 0 && payloads.camping.map((campingPayload, index) => (
        renderGuestParty(campingPayload, index)
      ))}
    </Fragment>
  );
}

BookingSummary.propTypes = {
  arrivalTime: PropTypes.number,
  availabilityDetails: PropTypes.shape({
    offers: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  basePrice: PropTypes.number,
  campsiteAddress: PropTypes.shape({
    addressLines: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  campsiteName: PropTypes.string.isRequired,
  bookingSummary: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    outfit: PropTypes.shape(IbePropTypes.outfit),
    guests: PropTypes.arrayOf(PropTypes.shape({})),
    supplements: PropTypes.arrayOf(PropTypes.shape({
      quantity: PropTypes.number,
      name: PropTypes.string,
      totalPrice: PropTypes.number,
    })),
    partyMembers: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.partyMember)),
    specReq: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  partyMemberTypes: PropTypes.arrayOf(PropTypes.shape({})),
  pitchName: PropTypes.string,
  towTypes: PropTypes.arrayOf(PropTypes.shape({})),
  vehicleTypes: PropTypes.arrayOf(PropTypes.shape({})),
  selectedSpecReq: PropTypes.arrayOf(PropTypes.shape({})),
  pitches: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.pitch)),
  payloads: PropTypes.shape({
    camping: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.quotePayload)),
    touring: PropTypes.shape(IbePropTypes.quotePayload),
  }),
};

BookingSummary.defaultProps = {
  arrivalTime: null,
  basePrice: null,
  partyMemberTypes: [],
  towTypes: [],
  vehicleTypes: [],
  pitchName: null,
  selectedSpecReq: [],
  pitches: [],
  payloads: null,
};

export default BookingSummary;

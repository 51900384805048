import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import MemberForm, {
  ADD_MEMBER_TITLE,
  ADD_MEMBER_FIRST_NAME,
  ADD_MEMBER_LAST_NAME,
  ADD_MEMBER_DATE_OF_BIRTH_YEAR,
  ADD_MEMBER_DATE_OF_BIRTH_MONTH,
  ADD_MEMBER_DATE_OF_BIRTH_DAY,
  ADD_MEMBER_TELEPHONE_NUMBER,
} from '../../Checkout/Sections/Membership/AddMemberForm';
import IbePropTypes from '../../../IbePropTypes';

const AmendPartyForm = ({
  member, onEdit, errors, addErrors, removeErrors, isOverseas,
}) => {
  const partyFormRef = useRef();

  if (!member) {
    return null;
  }
  const hiddenFields = [ADD_MEMBER_TELEPHONE_NUMBER];
  if (!member.isLead) {
    const nonleadHiddenFields = [
      ADD_MEMBER_TITLE,
      ADD_MEMBER_FIRST_NAME,
      ADD_MEMBER_LAST_NAME,
    ];
    hiddenFields.push(...nonleadHiddenFields);
  }
  return (
    <MemberForm
      hideDob={!isOverseas}
      member={member}
      updateMember={(newMember) => onEdit(newMember)}
      addErrors={addErrors}
      removeErrors={removeErrors}
      ref={partyFormRef}
      errors={errors}
      requiredFields={[
        ADD_MEMBER_TITLE,
        ADD_MEMBER_FIRST_NAME,
        ADD_MEMBER_LAST_NAME,
        ADD_MEMBER_DATE_OF_BIRTH_YEAR,
        ADD_MEMBER_DATE_OF_BIRTH_MONTH,
        ADD_MEMBER_DATE_OF_BIRTH_DAY,
      ]}
      hiddenFields={hiddenFields}
      isOpen
      isAmend
    />
  );
};

AmendPartyForm.propTypes = {
  member: PropTypes.shape(IbePropTypes.membershipMember).isRequired,
  onEdit: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  addErrors: PropTypes.func.isRequired,
  removeErrors: PropTypes.func.isRequired,
  isOverseas: PropTypes.bool.isRequired,
};

export default AmendPartyForm;

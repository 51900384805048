import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import Text from '../ui/Text';
import PopOver from '../PopOver';
import {
  ColLength, ColLengths, ColType, Row,
} from './OutfitLayout.style';

const propTypes = {
  onTogglePopOver: PropTypes.func.isRequired,
  outfitType: PropTypes.node.isRequired,
  renderOutfitLength: PropTypes.func.isRequired,
  renderOutfitHeight: PropTypes.func.isRequired,
  renderOutfitRegistration: PropTypes.func,
  popOverText: PropTypes.string,
  showHeightField: PropTypes.bool.isRequired,
  showLengthFields: PropTypes.bool,
  showLengthField: PropTypes.bool,
  showRegistrationField: PropTypes.bool,
  theme: PropTypes.shape({
    COLOR_TEXT_BODY: PropTypes.string,
    COLOR_WHITE: PropTypes.string,
  }),
};

const defaultProps = {
  popOverText: '',
  showLengthFields: true,
  showLengthField: false,
  showRegistrationField: false,
  renderOutfitRegistration: () => {},
  theme: {
    COLOR_TEXT_BODY: '',
    COLOR_WHITE: '',
  },
};

function OutfitLayout({
  popOverText,
  renderOutfitHeight,
  renderOutfitLength,
  showHeightField,
  showLengthField,
  showLengthFields,
  showRegistrationField,
  renderOutfitRegistration,
  ...props
}) {
  return (
    <Row>
      <ColType>{props.outfitType}</ColType>
      {
        showRegistrationField && (
        <ColLengths>
          <Row>
            <ColLength>
              {renderOutfitRegistration()}
            </ColLength>

            {showLengthField &&
              <ColLength>
                {renderOutfitLength()}
              </ColLength>
            }
          </Row>
        </ColLengths>)
      }
      {showLengthFields &&
      <>
        <PopOver
          onClose={() => props.onTogglePopOver()}
          open={!!popOverText}
          renderContent={() => (
            <Text
              dictionary={popOverText}
              size="0.875rem"
            />
          )}
        >
          {({ innerRef }) => (
            <ColLengths>
              <div ref={innerRef}>
                <Row>
                  <ColLength>
                    {renderOutfitLength()}
                  </ColLength>

                  {showHeightField &&
                    <ColLength>
                      {renderOutfitHeight()}
                    </ColLength>
                  }
                </Row>
              </div>
            </ColLengths>
          )}
        </PopOver>
      </>
      }
    </Row>
  );
}

OutfitLayout.propTypes = propTypes;
OutfitLayout.defaultProps = defaultProps;

export default withTheme(OutfitLayout);

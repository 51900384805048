import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ErrorTypeMessage from '../ErrorTypeMessage';
import SearchResultsHeader from './SearchResultsHeader';

import SearchResultsStyled, {
  Error, Results, CampsiteResultsContainer, Loader,
} from './SearchResults.style';

import { StyledListingMap, SmallLine } from '../Listing/Listing.style';
import IbePropTypes from '../../IbePropTypes';
import { LoadingSpinner } from '../ui/Loading';
import { dictionaryItem } from '../../hocs/withDictionary';

/**
 * Render sort bar, handle loading state and listing
 */
class SearchResults extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
    ]),
    count: PropTypes.number,
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.object,
    ]),
    handleClearUkItinerary: PropTypes.func,
    isMobileMapOpen: PropTypes.bool,
    isCrossings: PropTypes.bool,
    loading: PropTypes.bool,
    renderActions: PropTypes.func,
    renderResults: PropTypes.func,
    renderSummary: PropTypes.func.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({})),
    refMap: PropTypes.shape({}),
    sticky: PropTypes.bool,
    type: PropTypes.string,
    renderEventsToggle: PropTypes.func,
    renderToursToggle: PropTypes.func,
    activeUkItinerary: PropTypes.shape(IbePropTypes.ukItinerary),
    total: PropTypes.number,
    setResultsListRef: PropTypes.func,
  }

  static defaultProps = {
    activeUkItinerary: null,
    children: null,
    count: 0,
    error: false,
    handleClearUkItinerary: () => { },
    isMobileMapOpen: false,
    isCrossings: false,
    loading: false,
    refMap: null,
    renderActions: null,
    renderResults: null,
    renderEventsToggle: () => { },
    renderToursToggle: () => { },
    results: [],
    sticky: true,
    type: 'results',
    total: 0,
    setResultsListRef: () => { },
  };

  render() {
    // Parse any data for search results here
    // Parse/sort any data for search results here
    const results = this.props.results ? [...this.props.results] : [];

    const {
      activeUkItinerary,
      children,
      count,
      handleClearUkItinerary,
      loading,
      type,
      error,
      isCrossings,
      isMobileMapOpen,
      refMap,
      renderActions,
      renderResults,
      renderSummary,
      renderEventsToggle,
      renderToursToggle,
      sticky,
      total,
      setResultsListRef,
    } = this.props;

    return (
      <SearchResultsStyled>
        <>
          {!isMobileMapOpen &&
            <SearchResultsHeader
              activeUkItinerary={activeUkItinerary}
              handleClearUkItinerary={handleClearUkItinerary}
              renderEventsToggle={renderEventsToggle}
              renderToursToggle={renderToursToggle}
              isCrossings={isCrossings}
              count={count}
              total={total}
              loading={loading}
              renderSummary={renderSummary}
              type={type}
              renderActions={renderActions && renderActions}
              sticky={sticky}
            />
          }

          {!!isMobileMapOpen && <SmallLine />}

          {isMobileMapOpen && (
            <StyledListingMap className="map-ref" ref={refMap} />
          )}

          {!isMobileMapOpen && (
            <Results>
              {error && (
                <Error>
                  <ErrorTypeMessage error={error} />
                </Error>
              )}

              {loading && isCrossings && (
                <Loader>
                  <LoadingSpinner dictionary={dictionaryItem('SearchResults', 'Crossing', 'Fetching')} />
                </Loader>
              )}

              {renderResults
                ?
                  <CampsiteResultsContainer ref={(el) => setResultsListRef(el)}>
                    { renderResults() }
                  </CampsiteResultsContainer>
                : children({ results })}
            </Results>
          )}
        </>
      </SearchResultsStyled>
    );
  }
}

export default SearchResults;

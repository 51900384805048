import React from 'react';

import featureFlags, { getFeatureFlag } from '../config/featureFlags';

/**
 * @summary Pass phase config as component props
 * @param {*} WrappedComponent The React component
 * @param {...string} featureFlags featureFlag keys
 * @returns {*} WrappedComponent with prev and phase config props
 */
export default function WithPhase(WrappedComponent, ...selectedFeatureFlags) {
  return function withPhase(props) {
    let phase = {};

    if (selectedFeatureFlags.length) {
      selectedFeatureFlags.forEach((featureFlag) => {
        phase[featureFlag] = getFeatureFlag(featureFlag);
      });
    } else {
      phase = { ...featureFlags };
    }

    return <WrappedComponent {...props} {...phase} />;
  };
}

import styled from 'styled-components';
import InputWithIcon from '../ui/Form/InputWithIcon/InputWithIcon';

export default styled.div`
  display: block;
`;

export const DateInputWrapper = styled.div`
  position: relative;
`;

export const SearchInput = styled(InputWithIcon)`
  
`;

export const SearchIcon = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
`;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';

import queryHelper from '../../lib/queryHelper';
import { standardPropTypes, standardDefaultProps } from '../../lib/standardProptypes';

import Modal from '../ui/Modal';
import ComparisonTab from './ComparisonTab';

import GET_COMPARE_MODULE_STATE from './graphql/getCompareModuleState';
import UPDATE_COMPARE_MODULE_STATE from './graphql/updateCompareModuleState';

class CompareModal extends Component {
  static propTypes = {
    ...standardPropTypes,
    compareModuleState: PropTypes.shape({
      active: PropTypes.bool,
    }),
  };

  static defaultProps = {
    ...standardDefaultProps,
    compareModuleState: {
      active: false,
    },
  };

  constructor() {
    super();
    this.toggleActiveState = this.toggleActiveState.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
  }

  componentDidMount() {
    this.bindEvents();
  }

  shouldComponentUpdate(nextProps) {
    return this.props.compareModuleState.active !== nextProps.compareModuleState.active;
  }

  componentDidUpdate() {
    this.bindEvents();
  }

  componentWillUnmount() {
    this.bindEvents(false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.toggleActiveState();
    }
  }

  bindEvents(activeState = this.props.compareModuleState.active) {
    if (activeState) {
      window.document.addEventListener('keydown', this.escFunction, false);
    } else {
      window.document.removeEventListener('keydown', this.escFunction, false);
    }
  }

  toggleActiveState() {
    this.bindEvents(!this.props.compareModuleState.active);
    this.props.updateCompareModuleState(!this.props.compareModuleState.active);
  }

  render() {
    return (
      <Modal active={this.props.compareModuleState.active}>
        <ComparisonTab handleClose={this.toggleActiveState} />
      </Modal>
    );
  }
}

export default compose(
  queryHelper(GET_COMPARE_MODULE_STATE, 'compareModuleState'),
  graphql(UPDATE_COMPARE_MODULE_STATE, {
    props: ({ mutate }) => ({
      updateCompareModuleState: active => mutate({ variables: { active } }),
    }),
  }),
)(CompareModal);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { debounce, isEqual } from 'lodash';

import dp from '../datepicker';
import variables from '../variables';

function isMobile() {
  return window.innerWidth < variables.breakpoints.tablet;
}

export default function WithDatepicker(WrappedComponent) {
  return class withDatepicker extends PureComponent {
    static propTypes = {
      children: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
      ]),
      className: PropTypes.string,
      datepicker: PropTypes.shape({}),
      endDate: PropTypes.string,
      handleClear: PropTypes.func,
      fullscreen: PropTypes.bool,
      options: PropTypes.shape({
        endDate: PropTypes.string,
        startDate: PropTypes.string,
        dateRange: PropTypes.bool,
        handleFieldFocus: PropTypes.func,
        maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        months: PropTypes.number,
        name: PropTypes.string,
        showRemainder: PropTypes.bool,
        static: PropTypes.bool,
        open: PropTypes.bool,
      }).isRequired,
      onChange: PropTypes.func,
      onSubmit: PropTypes.func,
      open: PropTypes.bool,
      static: PropTypes.bool,
      startDate: PropTypes.string,
    };

    static defaultProps = {
      children: null,
      className: null,
      datepicker: null,
      endDate: '',
      fullscreen: false,
      handleClear() { },
      onChange() { },
      onSubmit() { },
      static: false,
      startDate: '',
      open: false,
    };

    mounted = false;

    constructor(props) {
      super(props);

      const { options } = props;

      this.state = {
        datepicker: props.datepicker || {
          ...dp({
            ...options,
            endDate: options.endDate || props.endDate,
            startDate: options.startDate || props.startDate,
          }),
        },
        fullscreen: false,
      };

      this.windowResizeDebounce = debounce(this.updateDimensions, 200);
    }

    componentDidMount() {
      this.setState({
        fullscreen: this.props.static ? false : (this.props.fullscreen || isMobile()),
      });
      this.mounted = true;
      window.addEventListener('resize', this.windowResizeDebounce);
      if (!this.props.open) return;
      this.handleOpen();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.startDate !== this.props.startDate) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(prevState => ({
          ...prevState,
          datepicker: {
            ...prevState.datepicker,
            startDate: this.props.startDate,
          },
        }));
      }

      if (isEqual(prevProps.options, this.props.options)) return;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => ({
        ...prevState,
        datepicker: this.props.datepicker || { ...dp(this.props.options) },
      }));
    }

    componentWillUnmount() {
      this.mounted = false;
      window.removeEventListener('resize', this.windowResizeDebounce);
      if (this.props.open) return;
      this.handleClose();
    }

    updateDimensions = () => {
      const { fullscreen } = this.state;
      if (isMobile() && !fullscreen) this.setState({ fullscreen: true });
      if (!isMobile() && fullscreen) this.setState({ fullscreen: false });
    }

    handleClose = () => new Promise((resolve) => {
      const datepicker = { ...this.state.datepicker };
      datepicker.close();

      this.setState({ datepicker }, () => {
        window.document.body.removeAttribute('style');
      });

      resolve();
    })

    handleOpen = event => new Promise((resolve) => {
      const datepicker = { ...this.state.datepicker };
      datepicker.open = true;

      this.setState({ datepicker }, () => {
        if (this.body !== null && this.state.fullscreen) {
          window.document.body.style.height = '100vh';
          window.document.body.style.overflow = 'hidden';
        }
      });

      resolve();
    });

    handleClear = () => {
      const datepickerCopy = { ...this.state.datepicker };
      this.props.handleClear();
      datepickerCopy.clear();
      this.setState({
        datepicker: datepickerCopy,
      });
    }

    handleFocus = (event) => {
      this.handleOpen();
    }

    handleDatepickerChange = (values) => {
      const startDate = values[0] ? moment(values[0]).format('YYYY-MM-DD') : null;
      const endDate = values[1] ? moment(values[1]).format('YYYY-MM-DD') : null;
      this.props.onChange([startDate, endDate]);
    }

    handleSubmit = () => {
      const { endDate, startDate } = this.props;

      this.handleClose();

      this.props.onSubmit([
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
      ]);
    }

    render() {
      const mapEvents = {
        handleChange: this.props.onChange,
        handleClear: this.handleClear,
        handleClose: this.handleClose,
        handleDatepickerChange: this.handleDatepickerChange,
        handleFocus: this.handleFocus,
        handleSetDate: this.handleSetDate,
        handleOpen: this.handleOpen,
        handleSubmit: this.handleSubmit,
        onClick: this.handleOpen,
      };

      return (
        <WrappedComponent
          {...this.props}
          {...mapEvents}
          {...this.state}
        />
      );
    }
  };
}

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../../hocs/withDictionary';

import Text from '../../ui/Text';

import NoResultsStyled from './NoResults.style';

function NoResults({ dictionary }) {
  return (
    <NoResultsStyled>
      <Text
        align="center"
        dictionary={dictionary}
        marginBottom
      />
    </NoResultsStyled>
  );
}

NoResults.propTypes = {
  dictionary: PropTypes.string,
};

NoResults.defaultProps = {
  dictionary: dictionaryItem('NoResults'),
};

export default memo(NoResults);

import React from 'react';
import PropTypes from 'prop-types';

import IbePropTypes from '../../IbePropTypes';
import Guests from '../Guests';

function AvailabilityGuests(props) {
  const {
    payload, onChange, maxLength, minChildAge, maxChildAge,
    configuration, getErrorElement, validatePayload, isOverseas,
  } = props;
  const { end, guests, start } = payload;
  const handleChange = (newGuests) => {
    const newPayload = { ...payload };
    newPayload.guests = [...newGuests];
    onChange(newPayload);
  };

  if (!configuration) {
    return null;
  }

  return (
    <Guests
      guests={guests}
      maxDate={end}
      minDate={start}
      maxLength={maxLength}
      onChange={handleChange}
      configuration={configuration}
      payload={payload}
      validatePayload={validatePayload}
      getErrorElement={getErrorElement}
      maxChildAge={maxChildAge}
      minChildAge={minChildAge}
      isOverseas={isOverseas}
    />
  );
}

AvailabilityGuests.propTypes = {
  onChange: PropTypes.func.isRequired,
  getErrorElement: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired,
  minChildAge: PropTypes.number,
  maxChildAge: PropTypes.number,
  payload: PropTypes.shape(IbePropTypes.quotePayload).isRequired,
  validatePayload: PropTypes.func.isRequired,
  configuration: PropTypes.shape(IbePropTypes.configuration),
  isOverseas: PropTypes.bool.isRequired,
};

AvailabilityGuests.defaultProps = {
  configuration: {},
  maxChildAge: undefined,
  minChildAge: undefined,
};

export default AvailabilityGuests;

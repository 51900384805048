import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import Radio from '../../ui/Form/Radio';
import testingAttr from '../../../lib/testingAttr';

import { RadioGroupItem, RadioGroup } from './CrossingsRadioGroup.style';

function CrossingsRadioGroup(props) {
  return (
    <RadioGroup>
      <RadioGroupItem>
        <Radio
          id="oneWay"
          color={props.theme.COLOR_WHITE}
          label="One way"
          name="oneWay"
          checked={!!props.oneWay}
          onChange={props.handleRadioChange}
          {...testingAttr('search-form-crossings__one-way')}
        />
      </RadioGroupItem>
      <RadioGroupItem>
        <Radio
          id="return"
          color={props.theme.COLOR_WHITE}
          label="Return"
          name="return"
          checked={!props.oneWay}
          onChange={props.handleRadioChange}
          {...testingAttr('search-form-crossings__return')}
        />
      </RadioGroupItem>
    </RadioGroup>
  );
}

CrossingsRadioGroup.propTypes = {
  oneWay: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    COLOR_WHITE: PropTypes.string,
  }),
  handleRadioChange: PropTypes.func.isRequired,
};

CrossingsRadioGroup.defaultProps = {
  theme: {
    COLOR_WHITE: '',
  },
};

export default withTheme(CrossingsRadioGroup);

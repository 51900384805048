import { stringify } from 'query-string';
import gql from 'graphql-tag';

export function getNearestCampsitesOptions(variables) {
  function pathBuilder({ args }) {
    return `campsite/nearest?${stringify(args)}`;
  }

  return {
    variables: {
      pathBuilder,
      count: 4,
      features: variables.features,
      types: variables.types,
      lat: variables.sw_lat,
      lon: variables.sw_lng,
    },
  };
}

export const GET_NEAREST_BOUNDARIES = gql`
  query SearchBoundaries {
    nearestBoundaries (
      features: $features
      types: $types
      count: $count
      lat: $lat
      lon: $lon
    ) @rest(
      type: "SearchBoundaries",
      pathBuilder: $pathBuilder
    ) {
      topLeftLat
      topLeftLon
      bottomRightLat
      bottomRightLon
    }
  }
`;

import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { compose, withApollo } from 'react-apollo';
import { useLazyQuery } from '@apollo/client';

import { Row } from '../ui/Grid';
import { ButtonLink } from '../ui/Button';
import { Button as SvgButton, Icon } from '../ui';
import theme from '../../styles/config/theme';

import {
  ListBody, ListHeader, ListCol,
} from './Guests.style';
import { dictionaryItem } from '../../hocs/withDictionary';
import { personTypes, mapPartyType } from '../../config/personType';
import PRODUCT_TYPES from '../../config/products';
import { DATE_FORMAT_SHORT } from '../../config/locale';
import formatPrice from '../../lib/formatPrice';
import IbePropTypes from '../../IbePropTypes';
import { isTabletOrSmaller } from '../../lib/helpers/layout';
import binSvg from '../../static/images/icons/bin.svg';
import { parseVariables } from './guestHelpers';

import FETCH_POLICY from '../../constants/FetchPolicy';
import GET_GUEST_PRICE from './graphql/getGuestPrice';

function GuestItem({
  guestItem, onEditGuest, onRemoveGuest, index,
  onUpdateGuestPrice, client, payload, isOverseas,
}) {
  const [
    getGuestPrice,
    { data },
  ] = useLazyQuery(GET_GUEST_PRICE, {
    client,
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });
  const isChildOrInfantType =
    guestItem.type === personTypes.CHILDREN || guestItem.type === personTypes.INFANT;
  const defaultLabel = mapPartyType[guestItem.type];
  const defaultName = `${defaultLabel} ${index + 1}`;

  useEffect(() => {
    if (!guestItem.price && guestItem.price !== 0 && !data) {
      getGuestPrice({
        variables: parseVariables({
          pitchCode: payload.pitchTypeId,
          productCode: PRODUCT_TYPES.CAMC_UK_PRODUCT,
          guestType: guestItem.type,
          dateOfBirth: guestItem.dateOfBirth,
          arrivalDate: guestItem.stayStart,
          departureDate: guestItem.stayEnd,
        }, 'Reservations/GuestMemberPrice'),
      });
    }
  }, []);

  useEffect(() => {
    if (data?.guestPrice || data?.guestPrice === 0) {
      onUpdateGuestPrice(guestItem.personId, data.guestPrice.price);
    }
  }, [data?.guestPrice]);

  const price = guestItem.price ?? data?.guestPrice?.price;

  return (
    <Row align="center" key={`guest-${defaultName}`}>
      <ListCol>{defaultName}</ListCol>
      {isOverseas &&
        <ListCol>{(isChildOrInfantType) ? moment(
          guestItem.dateOfBirth,
        ).format(DATE_FORMAT_SHORT) : 'N/A'}
        </ListCol>
      }
      <ListCol>{moment(guestItem.stayStart).format(DATE_FORMAT_SHORT)}</ListCol>
      <ListCol>{moment(guestItem.stayEnd).format(DATE_FORMAT_SHORT)}</ListCol>
      <ListCol>{price || price === 0 ? formatPrice(price) : '--'}</ListCol>
      <ListCol flex={0.75}>
        <ButtonLink
          dictionary={dictionaryItem('GuestsTable', 'Edit')}
          color={theme.COLOR_AMBER_ORANGE}
          textTransform="none"
          onClick={() => onEditGuest(guestItem.personId || guestItem.tempId)}
          noPadding
        />
      </ListCol>
      <ListCol flex={0.75}>
        {!isTabletOrSmaller() ? (
          <ButtonLink
            dictionary={dictionaryItem('GuestsTable', 'Remove')}
            color={theme.COLOR_BERRY_RED}
            textTransform="none"
            onClick={() => onRemoveGuest(guestItem.personId || guestItem.tempId)}
            noPadding
          />) : (
            <SvgButton
              ariaLabel="Remove"
              color={theme.COLOR_BERRY_RED}
              size="small"
              onClick={() => onRemoveGuest(guestItem.personId || guestItem.tempId)}
            >
              <Icon size="1.2rem" icon={binSvg} />
            </SvgButton>
        )}
      </ListCol>
    </Row>
  );
}

GuestItem.propTypes = {
  guestItem: PropTypes.shape(IbePropTypes.guest).isRequired,
  isOverseas: PropTypes.bool.isRequired,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  index: PropTypes.number.isRequired,
  onRemoveGuest: PropTypes.func.isRequired,
  onUpdateGuestPrice: PropTypes.func.isRequired,
  onEditGuest: PropTypes.func.isRequired,
  payload: PropTypes.shape(IbePropTypes.payload).isRequired,
};

function GuestList({
  guests, onEditGuest, onRemoveGuest,
  payload, client, onUpdateGuestPrice,
  isOverseas,
}) {
  const adultGuests = guests.filter(
    (guest) => guest.type === personTypes.ADULT,
  );
  const childGuests = guests.filter(
    (guest) => guest.type === personTypes.CHILDREN,
  );
  const infantGuests = guests.filter(
    (guest) => guest.type === personTypes.INFANT,
  );

  const renderGuestType = (guestItems) => guestItems.map(
    (guestItem, index) => (
      <GuestItem
        guestItem={guestItem}
        isOverseas={isOverseas}
        onEditGuest={onEditGuest}
        onRemoveGuest={onRemoveGuest}
        onUpdateGuestPrice={onUpdateGuestPrice}
        key={guestItem.personId || guestItem.tempId}
        index={index}
        payload={payload}
        client={client}
      />
    ),
  );

  return (
    <ListBody>
      <Row align="center">
        <ListCol>
          <ListHeader
            dictionary={dictionaryItem('GuestsTable', 'Guest')}
            weight="bold"
          />
        </ListCol>
        {isOverseas && (
          <ListCol>
            <ListHeader
              dictionary={dictionaryItem('GuestsTable', 'DateOfBirth')}
              weight="bold"
            />
          </ListCol>
        )}
        <ListCol>
          <ListHeader
            dictionary={dictionaryItem('GuestsTable', 'Arrive')}
            weight="bold"
          />
        </ListCol>
        <ListCol>
          <ListHeader
            dictionary={dictionaryItem('GuestsTable', 'Depart')}
            weight="bold"
          />
        </ListCol>
        <ListCol>
          <ListHeader
            dictionary={dictionaryItem('GuestsTable', 'Price')}
            weight="bold"
          />
        </ListCol>
        <ListCol flex={0.75} />
        <ListCol flex={0.75} />
      </Row>
      {renderGuestType(adultGuests)}
      {renderGuestType(childGuests)}
      {renderGuestType(infantGuests)}
    </ListBody>
  );
}

GuestList.propTypes = {
  guests: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.guest)),
  isOverseas: PropTypes.bool.isRequired,
  onEditGuest: PropTypes.func.isRequired,
  onRemoveGuest: PropTypes.func.isRequired,
  onUpdateGuestPrice: PropTypes.func.isRequired,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  payload: PropTypes.shape(IbePropTypes.payload).isRequired,
};

GuestList.defaultProps = {
  guests: [],
};

export default compose(
  withApollo,
)(GuestList);

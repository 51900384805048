import styled from 'styled-components';

export default styled.span`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  font-size: ${({ size }) => size};
  line-height: ${({ size }) => size};
  font-weight: bold;

  svg {
    pointer-events: none;
  }
`;

export const Reviews = styled.div`
  margin-right: 0.25rem;
  font-size: 0.75rem;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import Text from '../ui/Text';
import { Col, Row } from '../ui/Grid';
import { ButtonBordered, ButtonBrand } from '../ui/Button';
import { RemoveButton } from '../Basket/BasketItem.style';

import {
  Body, Header, Content, Overlay, RightContent, LeftIcon, ContentActions,
} from './AmendView.style';

import { dictionaryItem } from '../../hocs/withDictionary';
import svgError from '../../static/images/icons/Error.svg';

function AmendView({
  label, value, onClick, onRemove, children, onRemoveCancel, marginBottom,
  buttonLabelDictionary, showConfirm, theme, onRemoveConfirm, isMemberSection,
  isOverseas, isLead,
}) {
  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };
  const handleRemove = (e) => {
    e.preventDefault();
    onRemove();
  };
  const handleRemoveConfirm = (e) => {
    e.preventDefault();
    onRemoveConfirm();
  };
  const handleRemoveCancel = (e) => {
    e.preventDefault();
    onRemoveCancel();
  };

  const isLeadOrOverseas = isLead || isOverseas;
  const showEditButton = (!isMemberSection || isLeadOrOverseas);

  return (
    <Body marginBottom={!!children || marginBottom}>
      <Header compact={!!children}>
        <Row align="center" justify="space-between">
          <Col flex={1}>
            {label &&
            <Text
              size="0.75rem"
            >
              {label}
            </Text>}
            <Text
              weight="bold"
              size="0.85rem"
            >
              {value}
            </Text>
          </Col>
          <Col flexbox flexDirection="row" justifyContent="flex-end">
            {showEditButton &&
              <ButtonBordered
                size="small"
                disabled={false}
                onClick={handleClick}
                marginRight={!!onRemove}
                dictionary={buttonLabelDictionary && dictionaryItem('AvailabilityAmend', buttonLabelDictionary)}
              />
            }
            {!!onRemove && <RemoveButton onClick={handleRemove} small />}
          </Col>
        </Row>
      </Header>
      {(children && showEditButton) &&
      <Content>
        {children}
      </Content>
      }
      {showConfirm &&
        <Overlay>
          <LeftIcon icon={svgError} color={theme.COLOR_LAKE_GREEN_ORIGINAL} />
          <RightContent>
            <Text
              weight="bold"
              size="0.85rem"
            >
              Are you sure you want to remove this passenger?
            </Text>
            <ContentActions>
              <ButtonBrand
                size="small"
                type="Button"
                onClick={handleRemoveCancel}
                dictionary={dictionaryItem('AvailabilityAmend', 'Modal', 'No')}
                marginRight="0.5rem"
                minWidth="5rem"
              />
              <ButtonBordered
                size="small"
                onClick={handleRemoveConfirm}
                dictionary={dictionaryItem('AvailabilityAmend', 'Modal', 'Yes')}
                minWidth="5rem"
              />
            </ContentActions>
          </RightContent>
        </Overlay>
      }
    </Body>
  );
}

AmendView.propTypes = {
  theme: PropTypes.shape({
    COLOR_LAKE_GREEN_ORIGINAL: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  onRemoveCancel: PropTypes.func,
  onRemoveConfirm: PropTypes.func,
  children: PropTypes.node,
  buttonLabelDictionary: PropTypes.string,
  showConfirm: PropTypes.bool,
  value: PropTypes.string,
  marginBottom: PropTypes.bool,
  isMemberSection: PropTypes.bool,
  isOverseas: PropTypes.bool,
  isLead: PropTypes.bool,
};

AmendView.defaultProps = {
  label: '',
  onRemove: undefined,
  onRemoveCancel: undefined,
  onRemoveConfirm: undefined,
  children: undefined,
  buttonLabelDictionary: '',
  showConfirm: false,
  value: '',
  marginBottom: false,
  isMemberSection: true,
  isLead: false,
  isOverseas: false,
};

export default withTheme(AmendView);

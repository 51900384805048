import React from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../../../../hocs/withDictionary';

import Fieldset from './Fieldset';

import DaysOfTheWeek from './DaysOfTheWeek';
import { Actions, Wrapper } from './Header.style';
import { ActionClear, ActionSubmit } from './Button.style';

const propTypes = {
  datepicker: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
};

const defaultProps = {
  handleClear: null,
};

function Header(props) {
  return (
    <Wrapper>
      <Actions>
        <ActionSubmit
          dictionary={dictionaryItem('Picker', 'Apply')}
          type="button"
          onClick={props.handleClose}
        />

        <ActionClear
          dictionary={dictionaryItem('Picker', 'Clear')}
          type="button"
          onClick={props.handleClear}
        />
      </Actions>

      <Fieldset
        isDateRange={props.datepicker.dateRange}
        startDate={props.datepicker.startDate}
        endDate={props.datepicker.endDate}
      />

      <DaysOfTheWeek />
    </Wrapper>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;

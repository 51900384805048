import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';

import { v4 as uuid } from 'uuid';
import formatPrice from '../../../lib/formatPrice';
import { formatSearch, generatePayload } from '../../../lib/helpers/crossings';
import { dictionaryItem } from '../../../hocs/withDictionary';

import AddToBasket from '../../AddToBasket';
import ErrorTypeMessage from '../../ErrorTypeMessage';
import { QuoteCrossingAccommodation, QuoteCrossingUpdateCost, QuoteCrossingSupplements } from '.';
import { ButtonBrand } from '../../ui/Button';
import isLoggedIn from '../../../lib/isLoggedIn';
import GET_USER from '../../../config/graphql/getUser';

import {
  ButtonAddToBasket,
  ButtonImportantInformation,
  Footer,
  FooterCol,
  FooterRow,
  FooterPrice,
  Section,
} from './QuoteCrossing.style';
import testingAttr from '../../../lib/testingAttr';
import IbePropTypes from '../../../IbePropTypes';
import FetchPolicy from '../../../constants/FetchPolicy';

function QuoteCrossingForm({
  accommPriceError,
  accommodationErrors,
  defaultMaxCrossingAccomQuantity,
  disabledForms,
  error,
  inboundItinerary,
  inboundAccommodation,
  isDisabled,
  isITXApplicable,
  isStale,
  loading,
  handleValidate,
  onComponentError,
  onError,
  onAccommodationPrice,
  onCompleted,
  onImportantInformationClick,
  onInboundAccommodationChange,
  onOutboundAccommodationChange,
  onSupplementChange,
  onSubmit,
  onSuccess,
  onUpdateResult,
  outboundAccommodation,
  outboundItinerary,
  payload,
  result,
  totalBasketAmount,
  user,
  onProductTypeMismatch,
}) {
  const [pulse, triggerPulse] = useState(false);

  // called when the 'Add to Basket' button is clicked when the quote needs updating
  const handleProhibitedClick = (e) => {
    triggerPulse(true);
    e.preventDefault();
    setTimeout(() => {
      triggerPulse(false);
    }, 2000);
  };

  const totalPrice = isITXApplicable
    ? formatPrice(result.charges.totalAmount + totalBasketAmount)
    : formatPrice(result.charges.totalAmount);

  // Add outbound crossing date time to any payloads
  // that don't have one
  const formattedPayload = {
    ...payload,
    ...(!payload?.outboundItinerary?.crossingDateTime && {
      outboundItinerary: {
        ...payload.outboundItinerary,
        crossingDateTime: outboundItinerary.crossingDateTime,
      },
    }),
  };

  return (
    <>
      {/* Crossing Accommodation */}
      <Section>
        <QuoteCrossingAccommodation
          accommPriceError={accommPriceError}
          availableInboundAccommodation={inboundAccommodation || []}
          availableOutboundAccommodation={outboundAccommodation}
          defaultMaxCrossingAccomQuantity={defaultMaxCrossingAccomQuantity}
          disabled={isDisabled}
          errors={accommodationErrors}
          isInboundRequired={inboundItinerary.isAccomMandatory}
          isOutboundRequired={outboundItinerary.isAccomMandatory}
          isStale={isStale}
          onCompleted={onCompleted}
          onError={onComponentError('QuoteCrossingAccommodation')}
          onInboundAccommodationChange={onInboundAccommodationChange}
          onOutboundAccommodationChange={onOutboundAccommodationChange}
          onSubmit={onSubmit}
          onSuccess={onAccommodationPrice}
          payload={payload}
          showInbound={!!result.inboundItinerary}
        />
      </Section>

      {/* Crossing Supplements */}
      <Section>
        <QuoteCrossingSupplements
          disabled={isDisabled}
          supplements={{
            inbound: inboundItinerary.supplements,
            outbound: outboundItinerary.supplements,
          }}
          outboundTimeTable={outboundItinerary.timeTable}
          inboundTimeTable={inboundItinerary.timeTable}
          onChange={onSupplementChange}
          payload={payload}
          showInbound={!!result.inboundItinerary}
        />
      </Section>

      <Footer>
        {error && <ErrorTypeMessage error={error} marginBottom />}

        {isStale &&
          <QuoteCrossingUpdateCost
            disabled={isDisabled}
            onCompleted={onCompleted}
            onError={onError}
            onSubmit={onSubmit}
            onSuccess={onUpdateResult}
            payload={formatSearch(formattedPayload)}
          >
            {({ onClick, ...buttonProps }) => (
              <ButtonBrand
                {...buttonProps}
                block
                disabled={isDisabled}
                dictionary={isDisabled ? dictionaryItem('QuoteCrossing', 'UpdatingQuote') : dictionaryItem('QuoteCrossing', 'UpdateQuote')}
                key={uuid()}
                marginBottom
                pulse={pulse}
                size="xl"
                onClick={event => handleValidate(() => onClick(event))}
                {...testingAttr('ferry-update-quote-button')}
              />
            )}
          </QuoteCrossingUpdateCost>
        }

        <FooterRow>
          <FooterCol>
            <ButtonImportantInformation
              onClick={onImportantInformationClick}
            >
              Important Route Information
            </ButtonImportantInformation>
          </FooterCol>
          <FooterCol>
            <FooterPrice>
              {isITXApplicable && 'Basket '}
              Total:
              {' '}
              <b>{totalPrice}</b>
            </FooterPrice>
          </FooterCol>
          <FooterCol>
            <AddToBasket
              block
              disabled={disabledForms}
              payload={generatePayload(payload, user, result)}
              onCompleted={onCompleted}
              onError={onError}
              onSubmit={onSubmit}
              onSuccess={onSuccess}
              render={({ disabled, ...renderProps }) => (
                isStale ?
                  <ButtonAddToBasket
                    {...renderProps}
                    onClick={e => handleProhibitedClick(e)}
                    isStale
                  >
                    Please update quote
                  </ButtonAddToBasket>
                  :
                  <ButtonAddToBasket
                    {...renderProps}
                    disabled={disabled || loading}
                    {...testingAttr('ferry-add-to-basket-button')}
                  >
                    Add to Basket
                  </ButtonAddToBasket>
              )}
              onProductTypeMismatch={onProductTypeMismatch}
            />
          </FooterCol>
        </FooterRow>
      </Footer>
    </>
  );
}

QuoteCrossingForm.propTypes = {
  accommPriceError: PropTypes.shape({}),
  accommodationErrors: PropTypes.arrayOf(PropTypes.shape({})),
  defaultMaxCrossingAccomQuantity: PropTypes.number,
  disabledForms: PropTypes.bool,
  error: PropTypes.shape({}),
  handleValidate: PropTypes.func.isRequired,
  isITXApplicable: PropTypes.bool,
  inboundAccommodation: PropTypes.arrayOf(PropTypes.shape({})),
  inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
  isDisabled: PropTypes.bool,
  isStale: PropTypes.bool,
  loading: PropTypes.bool,
  onAccommodationPrice: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
  onImportantInformationClick: PropTypes.func.isRequired,
  onInboundAccommodationChange: PropTypes.func.isRequired,
  onOutboundAccommodationChange: PropTypes.func.isRequired,
  onComponentError: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSupplementChange: PropTypes.func.isRequired,
  onUpdateResult: PropTypes.func.isRequired,
  outboundAccommodation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  outboundItinerary: PropTypes.shape(IbePropTypes.itinerary).isRequired,
  payload: PropTypes.shape({
    inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
    outboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
  }).isRequired,
  result: PropTypes.shape({
    inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
    outboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
    charges: PropTypes.shape({
      totalAmount: PropTypes.number,
    }),
  }).isRequired,
  totalBasketAmount: PropTypes.number,
  user: PropTypes.shape(IbePropTypes.user),
  onProductTypeMismatch: PropTypes.func.isRequired,
};

QuoteCrossingForm.defaultProps = {
  accommodationErrors: [],
  accommPriceError: null,
  defaultMaxCrossingAccomQuantity: null,
  disabledForms: false,
  error: null,
  inboundAccommodation: null,
  inboundItinerary: PropTypes.shape({}),
  isDisabled: false,
  isITXApplicable: false,
  isStale: false,
  loading: false,
  totalBasketAmount: 0,
  user: undefined,
};

export default graphql(GET_USER, {
  options: {
    fetchPolicy: FetchPolicy.CACHE_ONLY,
  },
  props: ({ data }) => ({
    user: data.user,
  }),
  skip: props => !isLoggedIn(),
})(QuoteCrossingForm);

import styled, { css } from 'styled-components';

import zIndex from '../../config/zIndex';
import { select } from '../ui/Form/Select/Select.style';

export default styled.div`
  position: relative;
`;

export const Select = styled.button.attrs(props => ({
  type: 'button',
}))`
  ${select}
  height: ${({ theme }) => theme.FIELD_SIZE_MEDIUM};
  padding-right: 6rem;
  text-align: left;
  cursor: pointer;
  ${({ customStyled }) => customStyled && css`${customStyled}`}
`;

export const Options = styled.div`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  border-top-color: ${({ theme }) => theme.COLOR_WHITE};
  padding: 1rem 2rem 2rem;
  width: 100%;
  ${({ style }) => style && css`${style}`};
`;

export const Option = styled.div`
  & + & {
    margin-top: 1rem;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Badge = styled.span`
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem;
  line-height: ${({ theme }) => theme.FIELD_HEIGHT};
  padding: 0 1rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.COLOR_TEXT_MUTED};
`;

export const Details = styled.div`
  flex: 1;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.COLOR_TEXT_BODY};
  font-weight: 600;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.COLOR_TEXT_MUTED};
  font-size: 0.875rem;
`;

export const button = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 0;
  font-weight: 600;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  
  &:hover {
    background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.COLOR_WHITE};
    color: ${({ theme }) => theme.COLOR_GRAY_LIGHT};
    cursor: default;
  }
`;

export const Add = styled.button`
  ${button}
`;

export const Value = styled.div`
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.5rem;
  width: ${({ length }) => `${1 + (length * 0.6)}rem`};
  text-align: center;
`;

export const Remove = styled.button`
  ${button}
`;

export const Footer = styled.div`
  margin-top: 1rem;
  margin-bottom: -0.5rem;
`;

export const Wrapper = styled.div`
  margin-top: -1px;
  z-index: ${zIndex.POPOVER};
`;

import styled, { css } from 'styled-components';
import { above } from '../../../styles/libs';

import NoteComponent from '../../Note';
import { Col as StyledCol } from '../../ui/Grid';
import { Span } from '../../ui/Text/Text.style';
import ButtonBordered from '../../ui/Button/ButtonBordered.style';

export const PricingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ theme }) => above(theme.TABLET - 1)`
    margin: 0 -0.25rem;
    flex-wrap: no-wrap;
  `};
`;

export const PackageNoteButton = styled.button`
  padding: 0;
  border: none;
  cursor: pointer;
`;

export const WhatsIncludedButton = styled.button`
  margin-top: 0.5rem;
  padding: 0;
  border: none;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.75rem;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  align-items: center;

  & > div {
    flex: 1;
  }

  ${({ theme }) => above(theme.DESKTOP)`
    border-radius: 5px;
  `};
`;

export const ButtonCol = styled(StyledCol)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 0 100%;

  ${({ theme }) => above(theme.TABLET - 1)`
    align-items: flex-end;
    flex: 0 0 33%;
  `};

  ${({ theme }) => above(theme.DESKTOP)`
    flex: 0 0 auto;
    padding: 0 0.48rem;
  `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;

  ${({ theme }) => above(theme.TABLET - 1)`
    width: auto;
  `};
`;

export const ButtonSelect = styled(ButtonBordered)`
  width: 100%;
  align-items: center;

  ${({ isFeatured, theme }) => isFeatured && css`
    background-color: ${theme.COLOR_BRAND_PRIMARY_DARK};
    color: ${theme.COLOR_WHITE};
  `}

  ${({ theme }) => above(theme.TABLET - 1)`
    width: 7.5rem;
  `}
`;

export const ImageCol = styled(StyledCol)`
  display: flex;
  justify-content: flex-end;
  flex: 0 0 26%;

  ${({ theme }) => above(theme.MOBILE_LARGE)`
    flex: 0 0 22%;
  `}

  ${({ theme }) => above(theme.TABLET - 1)`
    flex: 0 0 auto;
  `}

  ${({ theme }) => above(theme.DESKTOP)`
    display: none;
  `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

export const CompanyImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 5px;
`;

export const PriceCol = styled(StyledCol)`
  display: flex;
  align-items: center;
  flex: 1 0 74%;

  ${({ theme }) => above(theme.MOBILE_LARGE)`
    flex: 0 0 78%;
  `}

  ${({ theme }) => above(theme.TABLET - 1)`
    flex: 0 0 38%;
  `}

  ${({ theme }) => above(theme.DESKTOP)`
    flex: 0 0 auto;
    padding: 0 0.48rem;
  `}
`;

export const TextSvgWrapper = styled.div`
  display: flex;
`;

export const PackageNote = styled(NoteComponent)`
  min-height: 0;
  padding: 0;
`;

export const CrossingPrice = styled(Span)`
  font-weight: bold;
`;

export const Price = styled(Span)`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.25;

  ${({ theme }) => above(theme.DESKTOP)`
    font-size: 1.75rem;
  `}

  ${({ theme }) => above(theme.DESKTOP_LARGE)`
    font-size: 1.35rem;

    ${({ isITXApplicable }) => !isITXApplicable && css`
      font-size: 2rem;
    `}
  `}
`;

import styled, { css } from 'styled-components';
import Text from '../ui/Text';
import { above, below } from '../../styles/libs';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  ${({ center, theme }) => center && above(theme.MOBILE_LARGE)`
    align-items: center;
  `}
  ${({ marginRight }) => marginRight && css`
    margin-right: 0.75rem;
  `}
  ${({ marginTop }) => marginTop && css`
    margin-top: 0.75rem;
  `}
`;

export const ToolTipTextContainer = styled.div`
  display: flex;

  ${({ hasMobileMargin, theme }) => hasMobileMargin &&
  below(theme.MOBILE_LARGE)`
    margin-top: 0.5rem;
  `}
`;

export const ToolTipText = styled(Text)`
  font-size: 0.8rem;
  text-decoration: underline;
  cursor: pointer;
`;

import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import PropTypes from 'prop-types';
import { cloneDeep, isEqual, uniqBy } from 'lodash';

import { withTheme } from 'styled-components';
import PayloadHelper from '../../lib/helpers/Itx/PayloadHelper';
import QuoteHelper from '../../lib/helpers/Itx/QuoteHelper';
import ItxProvider from '../../lib/helpers/Itx/ItxProvider';
import { dataLayerManager } from '../../lib/dataLayer';
import { virtualPageViewTypes } from '../../lib/constants';

import { dictionaryItem } from '../../hocs/withDictionary';

import NoResults from './Crossings/NoResults';
import QuoteCrossing from '../Quote/QuoteCrossing';
import SearchResultCrossings from './Crossings/SearchResultCrossings';
import SearchResults from './SearchResults';
import SearchSummaryCrossings from '../SearchSummary/SearchSummaryCrossings';

import { SelectWrapper, Select, Text } from './SearchResultsHeader.style';
import { AlternativeResultsHeading, AlternativeResultsTitle } from './SearchResults.style';
import { Body, Container } from '../SearchSummary/SearchSummary.style';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import { returnPortName } from '../../lib/helpers/crossings';
import IbePropTypes from '../../IbePropTypes';

/**
 * Render sort bar, handle loading state and listing
 */
class SearchResultsCrossings extends Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool,
      configuration: PropTypes.shape(IbePropTypes.configuration),
    }),
    defaultMaxCrossingAccomQuantity: PropTypes.number,
    error: PropTypes.shape({}),
    handleClearAndExpand: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onChangeSearch: PropTypes.func.isRequired,
    onImpression: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onResultClick: PropTypes.func.isRequired,
    isReturn: PropTypes.bool.isRequired,
    query: PropTypes.shape(IbePropTypes.query).isRequired,
    quote: PropTypes.shape(IbePropTypes.quote),
    result: PropTypes.shape({
      charges: PropTypes.shape({
        totalAmount: PropTypes.number,
      }),
    }),
    results: PropTypes.arrayOf(PropTypes.shape({})),
    theme: PropTypes.shape({
      COLOR_BRAND_PRIMARY_LIGHT: PropTypes.string,
      COLOR_GRAY_LIGHTER: PropTypes.string,
    }),
    toggleBasket: PropTypes.func.isRequired,
    onProductTypeMismatch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    data: {
      loading: false,
      configuration: {
        itxPackageRules: {},
        suppliers: [],
        ports: [],
      },
    },
    defaultMaxCrossingAccomQuantity: null,
    error: null,
    loading: false,
    quote: {
      campsiteBookings: [],
      extras: [],
    },
    theme: {
      COLOR_BRAND_PRIMARY_LIGHT: '',
      COLOR_GRAY_LIGHTER: '',
    },
    result: null,
    results: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      isITXApplicable: false,
      sortBy: 'Price',
      // Store query in the state to prevent updating when selecting a result
      query: this.props.query,
    };
  }

  componentDidMount() {
    // Fires a search on mount and page refresh
    this.props.onMount();
    this.checkITXApplicability();
  }

  componentDidUpdate(
    { query: prevQuery, quote: prevQuote },
    { isITXApplicable: wasITXApplicable },
  ) {
    const { onResultClick, query, quote } = this.props;
    const { isITXApplicable } = this.state;

    // If the basket is no longer ITX applicable return to search results
    if (wasITXApplicable && !isITXApplicable) {
      onResultClick(null);
    }

    if (!isEqual(prevQuery, query) || !isEqual(prevQuote, quote)) {
      this.checkITXApplicability();
    }

    if (!this.props.loading && this.props.result === null && this.props.results.length) {
      const { ports } = this.props.data.configuration;
      const {
        directResults,
        alternativeResults,
      } = this.filterAndSortResults(this.props.results, this.state.query, ports);

      let allCrossings = cloneDeep(directResults.concat(alternativeResults));
      allCrossings = allCrossings.map(c => ({
        ...c,
        outfit: { ...this.props.query.outfit },
      }));

      this.props.onImpression(allCrossings);
    }
  }

  handleSortByChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  sortBy = (results) => {
    const { sortBy } = this.state;

    const newResults = results.sort((a, b) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      if (sortBy === 'Departure') {
        const newA = a.outboundItinerary.timeTable.fromDate;
        const newB = b.outboundItinerary.timeTable.fromDate;
        return new Date(newA) - new Date(newB);
      }

      return a.charges.totalAmount - b.charges.totalAmount;
    });

    return newResults;
  }

  checkITXApplicability() {
    const {
      data,
      quote,
    } = this.props;

    const { query } = this.state;

    const quoteObj = new QuoteHelper(quote);
    const payloadObj = new PayloadHelper(query);
    let isITXApplicable = false;

    if (data && data.configuration && data.configuration.itxPackageRules) {
      const itxProvider =
        new ItxProvider(
          quoteObj,
          payloadObj,
          data.configuration.itxPackageRules.qtyOvernightStaysRequired,
          data.configuration.itxPackageRules.qtyOverseasSiteNightVouchersRequired,
        );

      isITXApplicable = itxProvider.isItx;
    }

    this.setState({ isITXApplicable });
  }

  filterAndSortResults(results = [], query, ports) {
    const { isITXApplicable } = this.state;
    const { arrivalPort, departurePort } = query.outboundItinerary;

    const arrivalPortName = returnPortName(ports, arrivalPort);
    const departurePortName = returnPortName(ports, departurePort);
    let filteredResults;

    // Split ITX and retail results in to separate arrays
    const [itxResults, retailResults] = results
      .reduce(([itx, retail], result) => (result.fareType === 1
        ? [[...itx, result], retail]
        : [itx, [...retail, result]]
      ), [[], []]);

    // If basket is ITX applicable return ITX (if available and cheaper) or retail results
    if (isITXApplicable) {
      filteredResults = retailResults.map((result) => {
        const itxEquivalent = itxResults
          .find(({ relatedFareTypeId }) => relatedFareTypeId === result.relatedFareTypeId);

        if (itxEquivalent && itxEquivalent.charges.totalAmount < result.charges.totalAmount) {
          return itxEquivalent;
        }

        return result;
      });
    // Otherwise only return retail results
    } else {
      filteredResults = retailResults;
    }

    let directCrossings = filteredResults.filter(item => (
      arrivalPortName === returnPortName(ports, item.outboundItinerary.arrivalPort)
      && departurePortName === returnPortName(ports, item.outboundItinerary.departurePort)
    ));

    let alternativeRouteCrossings = filteredResults.filter(item => (
      arrivalPortName !== returnPortName(ports, item.outboundItinerary.arrivalPort)
      || departurePortName !== returnPortName(ports, item.outboundItinerary.departurePort)
    ));

    if (query.allowAlternativeRoutes && query.inboundItinerary.crossingRouteCode) {
      directCrossings = directCrossings.filter(item => (
        departurePortName === returnPortName(ports, item.inboundItinerary.arrivalPort)
        && arrivalPortName === returnPortName(ports, item.inboundItinerary.departurePort)
      ));

      alternativeRouteCrossings = uniqBy([
        ...alternativeRouteCrossings,
        ...filteredResults.filter(item => (
          departurePortName !== returnPortName(ports, item.inboundItinerary.arrivalPort)
          || arrivalPortName !== returnPortName(ports, item.inboundItinerary.departurePort)
        )),
      ], 'id');
    }

    const directResults = this.sortBy(directCrossings);
    const alternativeResults = this.sortBy(alternativeRouteCrossings);

    return {
      directResults,
      alternativeResults,
      count: directResults.length + alternativeResults.length,
    };
  }

  render() {
    if (this.props.data.loading) return null;

    const {
      data,
      defaultMaxCrossingAccomQuantity,
      error,
      handleClearAndExpand,
      loading,
      onChangeSearch,
      onReset,
      onResultClick,
      isReturn,
      quote,
      result,
      results,
      toggleBasket,
      onProductTypeMismatch,
    } = this.props;

    const { isITXApplicable, query } = this.state;
    const { ports } = data.configuration;
    const {
      directResults,
      alternativeResults,
      count,
    } = this.filterAndSortResults(results, query, ports);

    if (results.length && result) {
      return (
        <>
          <Container>
            <Body>
              <SearchSummaryCrossings
                isReturn={isReturn}
                loading={loading}
                query={query}
                onChangeSearch={onChangeSearch}
                sticky
              />
            </Body>
          </Container>
          <QuoteCrossing
            defaultMaxCrossingAccomQuantity={defaultMaxCrossingAccomQuantity}
            result={result}
            results={results}
            onReset={onReset}
            handleClearAndExpand={handleClearAndExpand}
            // TODO refactor this, quick solution to prompt page view change
            handleBack={(...args) => {
              onResultClick(...args);
              dataLayerManager.pushVirtualPageViewEvent(virtualPageViewTypes.CROSSINGS_RESULTS);
            }}
            query={query}
            isITXApplicable={isITXApplicable}
            totalBasketAmount={(quote && quote.totalPrice) || 0}
            ports={ports}
            price={result.charges.totalAmount}
            suppliers={data.configuration.suppliers}
            toggleBasket={toggleBasket}
            onProductTypeMismatch={onProductTypeMismatch}
          />
        </>
      );
    }

    return (
      <SearchResults
        count={count}
        error={error}
        loading={loading}
        isCrossings
        renderActions={() => (
          <SelectWrapper>
            <Text dictionary={dictionaryItem('SearchResultsHeader', 'SortBy')} />
            <Select
              name="sortBy"
              value={this.state.sortBy}
              onChange={this.handleSortByChange}
            >
              <option value="Price">PRICE</option>
              <option value="Departure">DEPARTURE</option>
            </Select>
          </SelectWrapper>
        )}
        renderSummary={renderSummaryProps => (
          <SearchSummaryCrossings
            isReturn={isReturn}
            loading={loading}
            query={query}
            onChangeSearch={onChangeSearch}
            {...renderSummaryProps}
          />
        )}
      >
        {() => {
          if (!loading && !directResults.length && !alternativeResults.length) {
            return (
              <NoResults
                dictionary={dictionaryItem('SearchResultsCrossings', 'NoResults')}
              />
            );
          }

          return !loading && (
            <>
              {!directResults.length &&
                <NoResults
                  dictionary={dictionaryItem('SearchResultsCrossings', 'NoResults')}
                />
              }

              {directResults.map((item, index) => (
                <SearchResultCrossings
                  isFeatured={index === 0}
                  details={item}
                  hasCheaperItxOption={
                    item.fareType === 1
                    || ItxProvider.isITXCrossingAvailable(item, results)
                  }
                  isITXApplicable={isITXApplicable}
                  key={item.id}
                  onResultClick={onResultClick}
                  ports={ports}
                  query={query}
                  quote={quote}
                  suppliers={data.configuration.suppliers}
                  index={index}
                />
              ))}

              {query.allowAlternativeRoutes &&
                <>
                  <AlternativeResultsHeading>
                    <AlternativeResultsTitle dictionary={dictionaryItem('SearchResultsCrossings', 'AlternativeRoutes')} />
                  </AlternativeResultsHeading>

                  {!alternativeResults.length &&
                    <NoResults
                      dictionary={dictionaryItem('SearchResultsCrossings', 'NoResults')}
                    />
                  }

                  {alternativeResults.map((item, index) => (
                    <SearchResultCrossings
                      details={item}
                      hasCheaperItxOption={
                        item.fareType === 1
                        || ItxProvider.isITXCrossingAvailable(item, results)
                      }
                      isITXApplicable={isITXApplicable}
                      key={item.id}
                      onResultClick={onResultClick}
                      ports={ports}
                      query={query}
                      quote={quote}
                      suppliers={data.configuration.suppliers}
                      index={directResults.length + index}
                    />
                  ))}
                </>
              }
            </>
          );
        }}
      </SearchResults>
    );
  }
}

export default compose(
  withTheme,
  graphql(GET_CONFIGURATION),
)(SearchResultsCrossings);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Text } from '../ui';
import {
  AvailabilityDurationInfoList,
  AvailabilityDurationInfoItem,
  FullKey,
  FullValue,
  LowAvailabilityKey,
  LowAvailabilityValue,
  SummaryItem,
} from './Availability.style';
import { dictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';
import { DATE_FORMAT_INPUT } from '../../config/locale';
import { differenceInDays } from '../../lib/dates';

const propTypes = {
  selectedPitch: PropTypes.shape(IbePropTypes.pitch),
  partyString: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

const defaultProps = {
  selectedPitch: {},
  partyString: '',
  startDate: '',
  endDate: '',
};

const formatDate = (date, format = DATE_FORMAT_INPUT) => moment(date).format(format);

const AvailabilityDurationInfo = ({
  selectedPitch,
  partyString,
  startDate,
  endDate,
}) => {
  const duration = differenceInDays(endDate, startDate);
  return (
    <Fragment>
      <SummaryItem size="0.875rem">
        <LowAvailabilityValue
          span
          dictionary={dictionaryItem(
            'Availability',
            'LowAvailabilityValue',
          )}
        />
        <LowAvailabilityKey
          span
          dictionary={dictionaryItem(
            'Availability',
            'LowAvailabilityKey',
          )}
        />
      </SummaryItem>
      <SummaryItem size="0.875rem">
        <FullValue
          span
          dictionary={dictionaryItem(
            'Availability',
            'FullValue',
          )}
        />
        <FullKey
          span
          dictionary={dictionaryItem(
            'Availability',
            'FullKey',
          )}
        />
      </SummaryItem>

      <AvailabilityDurationInfoList>
        {(startDate && endDate) &&
          <AvailabilityDurationInfoItem>
            <Text size="0.875rem">
              Arrive {formatDate(startDate)} Depart {formatDate(endDate)} ({duration} nights)
            </Text>
          </AvailabilityDurationInfoItem>
        }
        <AvailabilityDurationInfoItem>
          <Text size="0.875rem">
            Prices shown are for a {selectedPitch.name}
          </Text>
        </AvailabilityDurationInfoItem>
        <AvailabilityDurationInfoItem>
          <Text size="0.875rem">Based on {partyString}</Text>
        </AvailabilityDurationInfoItem>
      </AvailabilityDurationInfoList>
    </Fragment>
  );
};

AvailabilityDurationInfo.propTypes = propTypes;
AvailabilityDurationInfo.defaultProps = defaultProps;

export default AvailabilityDurationInfo;

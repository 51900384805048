import { flatten } from 'lodash';
import * as partyMember from './partyMemberTypes';

/**
 * @summary Generate party member age description
 * @param {Object} param
 * @returns {string} Party member age description
 */
export function getPartyMemberAgeDescription({ fromAge, toAge }) {
  if (typeof (toAge) === 'undefined') return '';
  return toAge !== null ? `(ages ${fromAge} - ${toAge - 1})` : `(aged ${fromAge}+)`;
}

export function parsePartyMembersToPartySelect(partyMembers, partyConfig) {
  // Create a map of each party type amount
  const valuesMap = new Map();
  partyMembers.forEach((member) => {
    const value = valuesMap.get(member.type) || 0;
    valuesMap.set(member.type, value + 1);
  });

  return partyConfig.map(option => ({
    max: option.max,
    min: option.min,
    description: getPartyMemberAgeDescription(option),
    value: valuesMap.get(option.type) || 0,
    key: option.type,
    label: option.label,
  }));
}

export function parsePartySelectToPartyMembers(partyMembers, selectOptions, pitchConfig) {
  const currentPartyMembers = [...partyMembers];
  const getDefaultAge = (type) => {
    if (!pitchConfig) {
      return null;
    }
    if (type === partyMember.CHILD) {
      return pitchConfig.childFromAge;
    }
    if (type === partyMember.INFANT) {
      return pitchConfig.infantFromAge;
    }
    return null;
  };

  // If there are no party members of the type then add one
  selectOptions.forEach((item) => {
    if (!item.value || currentPartyMembers.find(member => member.type === item.key)) return;
    currentPartyMembers.push({
      __typename: 'PartyMember',
      age: getDefaultAge(item.key),
      personId: 0,
      type: item.key,
    });
  });

  // Split party array into chunks of members of the same type
  const chunks = currentPartyMembers.reduce((acc, cur) => {
    acc[cur.type] = [].concat((acc[cur.type] || []), cur);
    return acc;
  }, []).filter(chunk => !!chunk);

  /**
   * Check chunk lengths against the select value and if not equal, add or remove a member
   * of that type. This method preserves remaining member selectOptions.
   */
  const partyMembersChunks = flatten(chunks.map((chunk) => {
    const newChunk = [...chunk];
    const { type } = chunk[0];
    const { value } = selectOptions.find(item => item.key === type);

    if (newChunk.length < value) {
      newChunk.push({
        __typename: 'PartyMember',
        age: getDefaultAge(type),
        personId: 0,
        type,
      });
    } else if (newChunk.length > value) {
      newChunk.pop();
    }

    return newChunk;
  }));
  // Update quote parameters
  return partyMembersChunks;
}

/**
 * @summary Check member requires an age range
 * @param {Object} option
 * @returns {boolean} Requires an age range
 */
export function hasAgeRange(option = {}) {
  const rule = val => val > -1 && val !== null;
  return rule(option.fromAge) && rule(option.toAge);
}

/**
 * @summary Validate that all required ages exist
 * @param {Object[]} partyOptions
 * @param {Object[]} partyMembers
 * @returns {boolean} Passed validation
 */
export function validateAges(partyOptions, partyMembers) {
  function getOption(member) {
    return partyOptions.find(({ type }) => type === member.type);
  }

  let i = 0;

  // Fail if age is required and null
  while (i < partyMembers.length) {
    const member = partyMembers[i];
    if (hasAgeRange(getOption(member)) && member.age === null) return false;
    i += 1;
  }

  return true;
}

export function validatePartyAges(partyMembers) {
  const children = partyMembers
    .filter(member => member.type === partyMember.CHILD);

  const infants = partyMembers
    .filter(member => member.type === partyMember.INFANT);

  let invalidChildAges = false;
  let invalidInfantAges = false;

  const hasNoAge = members => members.some(({ age }) => !(age >= 0 && age !== null));

  if (children.length) {
    invalidChildAges = hasNoAge(children);
  }

  if (infants.length) {
    invalidInfantAges = hasNoAge(infants);
  }

  return !(invalidChildAges || invalidInfantAges);
}

export function validatePartyDobs(partyMembers) {
  return !partyMembers.some(
    (member) => (member.type === partyMember.CHILD || member.type === partyMember.INFANT) &&
    !member.dateOfBirth,
  );
}

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../../hocs/withDictionary';
import testingAttr from '../../../lib/testingAttr';

import {
  FormGroup, FormGroupItem, Label, Select,
} from './CrossingsJourney.style';

function CrossingsJourney({
  disabled,
  onChange,
  arrivalPorts,
  departurePorts,
  from,
  to,
  ...props
}) {
  return (
    <FormGroup>
      <FormGroupItem>
        <Label
          dictionary={dictionaryItem('SearchFormCrossings', 'From')}
          htmlFor="from"
        />
        <Select
          id="from"
          disabled={disabled}
          name="from"
          onChange={onChange}
          value={from}
          {...testingAttr(`${props['data-testid']}__from`)}
        >
          <option value="">Please select</option>
          {departurePorts.map(port => (
            <option value={port.name} key={port.name}>{port.name}</option>
          ))}
        </Select>
      </FormGroupItem>
      <FormGroupItem>
        <Label
          dictionary={dictionaryItem('SearchFormCrossings', 'To')}
          htmlFor="to"
        />
        <Select
          id="to"
          disabled={disabled || (!from && !to)}
          name="to"
          onChange={onChange}
          value={to}
          {...testingAttr(`${props['data-testid']}__to`)}
        >
          <option value="">Please select</option>
          {arrivalPorts.map(port => (
            <option value={port.name} key={port.name}>{port.name}</option>
          ))}
        </Select>
      </FormGroupItem>
    </FormGroup>
  );
}

CrossingsJourney.propTypes = {
  arrivalPorts: PropTypes.arrayOf(PropTypes.shape({})),
  departurePorts: PropTypes.arrayOf(PropTypes.shape({})),
  'data-testid': PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CrossingsJourney.defaultProps = {
  arrivalPorts: [],
  departurePorts: [],
  'data-testid': '',
  from: '',
  to: '',
  disabled: false,
};

export default memo(CrossingsJourney);

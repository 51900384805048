import styled, { css } from 'styled-components';

import TextComponent from '../ui/Text';

export default styled.div`
  display: flex;

  ${({ onClick }) => !!onClick && css`
    &:hover {
      cursor: pointer;
    }
  `}
`;

export const Feature = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  padding-right: 0.75rem;

  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}
`;

export const Text = styled(TextComponent)`
  font-size: 0.875rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  line-height: 1.25em;

  ${({ size }) => css`
    font-size: size || 0.875rem;
  `}
`;

export const List = styled.ul`
  width: 100%;
  list-style-type: none;
  padding-left: 0;
  margin: 0;

  ${({ highlight, compact, theme }) => highlight && css`
    padding: ${compact ? 0 : '1rem 0'};
    background-color: ${compact ? 'transparent' : theme.COLOR_WHITE};
  `}
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0.25rem 1.25rem;
  padding-bottom: 0.5rem;

  ${({ onClick }) => onClick && css`
    &:hover {
      cursor: pointer;
    }
  `}
  ${({ spaced }) => spaced && css`
    margin-bottom: 1rem;
    padding-left: 0;
    padding-bottom: 0.25rem;
  `}
  ${({ compact }) => compact && css`
    margin-bottom: 0.5rem;
  `}
`;

import styled from 'styled-components';
import { Text, Title } from '../ui';
import Button from '../ui/Button/ButtonBrand.style';
import { above } from '../../styles/libs';

export default styled.div`
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ theme }) => above(theme.TABLET)`
    height: 100vh;
  `}
`;

export const AlternativeResultsHeading = styled.div`
  margin: 0.5rem;
  padding: 0.5rem;

  ${({ theme }) => above(theme.DESKTOP)`
    background-color: ${theme.COLOR_WHITE};
    margin: 0;
    padding: 1.25rem;
  `}
`;

export const AlternativeResultsTitle = styled(Title)`
  font-size: 1rem;
`;

export const SearchResultsHeaderSticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const Error = styled.div`
  padding: 1.25rem;
`;

export const Loader = styled.div`
  padding: 2rem;
`;

export const Results = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const ViewMore = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 20px; // specifically requested
  background-color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const ViewMoreButton = styled(Button)`
  margin: 0 auto;
`;

export const ViewMoreText = styled(Text)`
  padding-top: 20px; // specifically requested
  text-align: center;
`;

export const CampsiteResultsContainer = styled.div``;

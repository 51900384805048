import gql from 'graphql-tag';

export default gql`
  query AvailabilityAndUserConfig($dictionaryKeys: [String], $pathBuilder: any) {
    dictionary: dictionaryFind(keys: $dictionaryKeys) @rest(
      pathBuilder: $pathBuilder
      type: "DictionaryItem",
    ) {
      key
      value
    }
  }
`;

import styled, { css } from 'styled-components';

import ButtonComponent from '../ui/Button';
import TextComponent from '../ui/Text';
import TitleComponent from '../ui/Title';
import { Row as RowComponent, Col as ColComponent } from '../ui/Grid';

import { above, below } from '../../styles/libs';
import detectIE from '../../lib/detectIE';

export default styled.div`
  transition: all 150ms ease;
  ${({ sticky }) => sticky && css`position: sticky; `}
  z-index: 1;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  position: relative;
`;

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1.25rem;
  ${({ paddingBottom }) => paddingBottom && css`
    padding-bottom: ${paddingBottom};
  `};
  ${({ center }) => center && css`
    flex-direction: column;
    justify-content: center;
  `};
`;

export const Button = styled(ButtonComponent)`
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  color: ${({ theme }) => theme.COLOR_WHITE};
  min-height: 3.5rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  ${detectIE() && css`
    display: none;
  `};
`;

export const Transition = styled.div`
  transition: all 150ms ease;
`;

export const Row = styled(RowComponent)``;

export const Col = styled(ColComponent)`
  ${({ theme }) => below(theme.TABLET)`
    flex: 0 0 100%;
    margin-bottom: 0.75rem;
  `}
  ${({ theme }) => above(theme.TABLET)`
    flex: 1;
    max-width: 16rem;
  `}
`;

export const Summary = styled.div`
  flex: 0 0 100%;
  color: ${({ theme }) => theme.COLOR_WHITE};
  ${({ theme }) => above(theme.TABLET)`
    padding-left: 1.875rem;
    flex: 1;
  `}
`;

export const B = styled.b`
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.COLOR_WHITE};
  font-size: 0.875rem;
`;

export const Title = styled(TitleComponent)`
  ${({ theme }) => below(theme.TABLET)`
    margin-bottom: 0.75rem;
  `}
  color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const Text = styled(TextComponent)`
  display: inline-block;
  color: ${({ theme }) => theme.COLOR_WHITE};
  font-size: 0.875rem;

  ${({ theme }) => below(theme.TABLET)`
    &:first-child {
      margin-right: 1rem;
    }
  `}
  ${({ block }) => block && css`
    display: block;
  `}
`;

export const Actions = styled.div`
  flex: 0 0 100%;
  ${({ theme }) => above(theme.TABLET)`
    flex: 0 0 11rem;
  `}
`;

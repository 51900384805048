import styled from 'styled-components';
import StyledBadge from '../ui/Badge/Badge.style';

import detectIE from '../../lib/detectIE';
import { Title as TitleComponent } from '../ui';
import { MAP_FOOTER_SINGLE_ROW_BREAKPOINT } from '../Map/Map.style';
import { below } from '../../styles/libs';

const borderWidth = '0.16rem';

export const Wrapper = styled.section`
  position: absolute;
  display: flex;
  bottom: 1.5rem;
  left: 1rem;
  width: calc(100% - 1rem);
  min-width: 250px;
  min-height: 3rem;
  border: ${({ theme }) => `${borderWidth} solid ${theme.COLOR_BRAND_PRIMARY_DARK}`};
  background: ${({ theme }) => theme.COLOR_WHITE};
  border-radius: 5px;
  cursor: pointer;

  @media only screen and (min-width: ${({ theme }) => theme.DESKTOP + 1}px) {
    display: flex;
  }

  @media only screen and (min-width: 1200px) { // bespoke to release full width button
    width: auto;
  }

  @media only screen and (min-width: ${MAP_FOOTER_SINGLE_ROW_BREAKPOINT}) {
    right: 1rem;
    left: auto;
  }

  ${!!detectIE() && `
    right: 4rem;
    height: 2rem;
  `}
`;

export const Aside = styled.aside`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 1rem;
  border-right: ${({ theme }) => `${borderWidth} solid ${theme.COLOR_BRAND_PRIMARY_DARK}`};
  min-width: 95px;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    height: 0.75rem;
    width: 0.75rem;
    background: ${({ theme }) => theme.COLOR_WHITE};
    border-top: ${({ theme }) => `${borderWidth} solid ${theme.COLOR_BRAND_PRIMARY_DARK}`};
    border-right: ${({ theme }) => `${borderWidth} solid ${theme.COLOR_BRAND_PRIMARY_DARK}`};
    transform: translate(58%, -50%) rotate(45deg);
  }
`;

export const ComparisonBadges = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  /* TODO: find better solution */
  min-width: 140px;
`;

export const Row = styled.div`
  display: flex;
  margin-top: 0.25rem;

  &:first-child {
    margin-top: 0;
  }
`;

export const Label = styled(TitleComponent)`
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: normal;
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  flex: 1;

  ${({ theme }) => below(theme.DESKTOP)`
    font-size: 0.685rem;
  `}
`;

export const Badge = styled(StyledBadge)`
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  color: ${({ theme }) => theme.COLOR_WHITE};
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  min-width: 1.6rem;
  text-align: center;
`;

import styled, { css } from 'styled-components';

export const List = styled.ul`
  margin: 1rem 0;
  padding: 0 0 0 1rem;

  ${({ subList }) => subList && css`
    margin: 0.5rem 0;
    font-size: 0.9375rem;
  `}
`;

export const Item = styled.li`
  &:not(:last-child) {
    margin: 0 0 0.25rem;
  }

  ${({ title }) => title && css`
    position: relative;
    left: -1rem;
    font-weight: bold;
  `}
  ${({ unstyled }) => unstyled && css`list-style: none;`}
`;

import React from 'react';
import PropTypes from 'prop-types';
import { CHILD, INFANT } from '../../lib/partyMemberTypes';
import PartyDateOfBirth from './PartyDateOfBirth';
import IbePropTypes from '../../IbePropTypes';

function PartyDateOfBirths({
  arrivalDate,
  guests,
  onChange,
  disabled,
  dataError,
  isDobValid,
  isOverseas,
  lightTheme,
}) {
  let childCount = 0;
  let infantCount = 0;
  if (!isOverseas) return <></>;
  return guests.map((guest, index) => {
    if (guest.type === CHILD) {
      const label = `Child ${childCount + 1}`;
      childCount += 1;
      return (
        <PartyDateOfBirth
          arrivalDate={arrivalDate}
          isDobValid={isDobValid}
          dataError={dataError}
          guest={guest}
          index={index}
          onChange={onChange}
          key={label}
          label={label}
          disabled={disabled}
          lightTheme={lightTheme}
        />
      );
    }
    if (guest.type === INFANT) {
      const label = `Infant ${infantCount + 1}`;
      infantCount += 1;
      return (
        <PartyDateOfBirth
          arrivalDate={arrivalDate}
          isDobValid={isDobValid}
          dataError={dataError}
          guest={guest}
          index={index}
          onChange={onChange}
          key={label}
          label={label}
          disabled={disabled}
          lightTheme={lightTheme}
        />
      );
    }
    return null;
  });
}

PartyDateOfBirths.propTypes = {
  arrivalDate: PropTypes.string.isRequired,
  dataError: PropTypes.bool,
  disabled: PropTypes.bool,
  guests: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.guest)).isRequired,
  isDobValid: PropTypes.func.isRequired,
  isOverseas: PropTypes.bool.isRequired,
  lightTheme: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

PartyDateOfBirths.defaultProps = {
  dataError: false,
  disabled: false,
};

export default PartyDateOfBirths;

import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose, withApollo } from 'react-apollo';
import Router, { withRouter } from 'next/router';

import withStateMutation from '../../hocs/withStateMutation';
import UPDATE_QUOTE from '../Quote/graphql/updateQuote';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import GET_USER from '../../config/graphql/getUser';
import RESET_SESSION from '../Quote/graphql/resetSession';

import quoteMembershipTypes, { getENumWithValue } from '../../config/quoteMembershipTypes';
import { BASKET_STATES } from '../../config/products';
import { updateQuoteCache } from '../../resolvers/quote';

import { dictionaryItem } from '../../hocs/withDictionary';
import isLoggedIn from '../../lib/isLoggedIn';
import { getProductCodeByName } from '../../lib/campsiteTypes';
import IbePropTypes from '../../IbePropTypes';

import Title from '../ui/Title';
import LoadingSpinner from '../ui/Loading/LoadingSpinner';

import svgError from '../../static/images/icons/Error.svg';

import { Actions, Action, AlertIcon } from './PopUp.styles';
import MEMBERSHIP_STATUS from '../../config/membershipStatus';
import routes from '../../constants/routes';
import FetchPolicy from '../../constants/FetchPolicy';
import { navigateTo, POST_TYPES } from '../../lib/helpers/navigation';
import { appendQuery } from '../../lib/location';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  resetSession: PropTypes.func.isRequired,
  updateQuote: PropTypes.func.isRequired,
  config: PropTypes.shape({
    configuration: PropTypes.shape(IbePropTypes.configuration),
  }),
  quote: PropTypes.shape(IbePropTypes.quote),
  showProceed: PropTypes.bool,
  userStatus: PropTypes.shape({
    user: PropTypes.shape(IbePropTypes.user),
    refetch: PropTypes.func,
    loading: PropTypes.bool,
  }),
};

const defaultProps = {
  config: {
    configuration: {},
  },
  quote: {},
  showProceed: false,
  userStatus: {
    user: {},
    refetch: () => {},
  },
};

function MembershipExpiredPopUp(props) {
  const [renewing, setRenewing] = useState(false);

  const {
    config, quote, handleClose, resetSession,
    showProceed, updateQuote, userStatus,
  } = props;

  useEffect(() => {
    if (userStatus?.user?.membershipStatus === MEMBERSHIP_STATUS.ACTIVE) {
      handleClose();
      Router.push({
        pathname: routes.checkout,
      });
    }
  }, [userStatus]);

  const onRenewMembership = () => {
    setRenewing(true);
    const query = window.location.search;
    const returnUrl = `${window.location.href.split('?')[0]}?${query}`;

    const renewalLink = appendQuery(config?.configuration.membershipRenewalLink, {
      ReturnUrl: returnUrl,
      UseReturnURL: 'true',
    });

    navigateTo(renewalLink, {
      type: POST_TYPES.MEMBERSHIP_RENEWAL,
    });
  };

  const onEmptyBasket = async () => {
    await resetSession();
    handleClose();
  };

  const onContinueAsNonMember = async () => {
    if (!quote) {
      return;
    }
    const { outfit, product } = quote;
    const membershipType = getENumWithValue(quoteMembershipTypes.NotLoggedInOrNonMember);

    const {
      vehicleHeight,
      vehicleLength,
      vehicleMake,
      vehicleModel,
      vehicleReg,
      vehicleType,
    } = outfit ?? {};

    const productCode = getProductCodeByName(
      product,
      config?.configuration.products,
    );

    const payload = {
      errataAccepted: true,
      membershipType,
      outfit: vehicleLength ? {
        vehicleHeight,
        vehicleLength,
        vehicleMake,
        vehicleModel,
        vehicleReg,
        vehicleType,
      } : undefined,
      partyMembers: quote.partyMembers?.map(({ dateOfBirth, type, personId }) => ({
        dateOfBirth,
        type,
        personId,
      })) ?? [],
      productCode,
    };

    await updateQuote(payload);
    handleClose();
    Router.push({
      pathname: routes.checkout,
    });
  };

  const onRefreshMembershipStatus = () => {
    // TEMP for testing
    if (localStorage.bypass) {
      handleClose();
      Router.push({
        pathname: routes.checkout,
      });
      return;
    }
    userStatus.refetch();
  };
  const loading = userStatus?.loading;
  return (
    <Fragment>
      <AlertIcon icon={svgError} />
      <Title
        dictionary={dictionaryItem('MembershipExpiredPopUp')}
        align="center"
        tag={3}
        size={2}
        marginBottom
      />
      {loading && <LoadingSpinner />}
      <Actions>
        {(showProceed && quote?.basketState !== BASKET_STATES.OVERSEAS) ? <Action
          onClick={onContinueAsNonMember}
          dictionary={dictionaryItem('MembershipExpiredPopUp', 'Proceed')}
          disabled={loading}
        /> : <Action
          onClick={onEmptyBasket}
          dictionary={dictionaryItem('MembershipExpiredPopUp', 'EmptyBasket')}
          disabled={loading}
        />}
        {renewing ? <Action
          onClick={onRefreshMembershipStatus}
          dictionary={dictionaryItem('MembershipExpiredPopUp', 'Refresh')}
          disabled={loading}
        /> : <Action
          onClick={onRenewMembership}
          dictionary={dictionaryItem('MembershipExpiredPopUp', 'Renew')}
          disabled={loading}
        />}
      </Actions>
    </Fragment>
  );
}

MembershipExpiredPopUp.propTypes = propTypes;
MembershipExpiredPopUp.defaultProps = defaultProps;

export default compose(
  withApollo,
  withRouter,
  graphql(GET_CONFIGURATION, {
    name: 'config',
  }),
  graphql(GET_USER, {
    name: 'userStatus',
    options: {
      fetchPolicy: FetchPolicy.CACHE_ONLY,
    },
    skip: props => !isLoggedIn(),
  }),
  graphql(UPDATE_QUOTE, {
    props: ({ mutate }) => ({
      options: {
        notifyOnNetworkStatusChange: true,
      },
      updateQuote: input => mutate({
        update: updateQuoteCache,
        variables: { input },
        refetchQueries: ['Quote'],
      }),
    }),
  }),
  withStateMutation({ name: 'updateQuote' }),
  graphql(RESET_SESSION, {
    props: ({ mutate }) => ({
      resetSession: () => mutate({
        refetchQueries: ['Quote'],
      }),
    }),
  }),
)(MembershipExpiredPopUp);

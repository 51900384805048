import PropTypes from 'prop-types';
import { compose, sortBy, uniqBy } from 'lodash/fp';

export function findPossibleRoutes(routes, to, from) {
  const filteredRoutes = routes.filter(({ arrivalPortName, departurePortName }) => (
    to === arrivalPortName && from === departurePortName
  )) || [];

  return filteredRoutes;
}

// Parse a route into a port
function parseRoutes(routes, from) {
  const isArrival = !!from;
  const newRoutes = routes;

  if (!newRoutes) return [];

  const fromArray = newRoutes.map(route => ({
    id: route.id,
    name: isArrival ? route.arrivalPortName : route.departurePortName,
    routeCode: route.routeCode,
  }));

  return fromArray;
}

// Filter routes with departure port as selected from route
const filterArrivalPortRoutes = (routes, from) => routes
  .filter(({ departurePortName }) => departurePortName === from);

// Parse routes to ports, dedup and sort
const getPorts = compose(sortBy('name'), uniqBy('name'), parseRoutes);

function handleFerryPortChange(event, onChange, routes, from) {
  const { name, value } = event.target;

  let route = null;
  let suppliers = [];

  // when both outbound ports are selected, create route out of them
  if (name === 'to') {
    const possibleRoutes = findPossibleRoutes(routes, value, from);
    suppliers = possibleRoutes.map(({ supplierCode }) => supplierCode);

    // store allSuppliers handling this outbound route,
    // so then can be use to filter inbound routes in the future
    route = possibleRoutes[0] || null;
  }

  return onChange(route, value, name, suppliers);
}

function CrossingsJourneyContainer({
  children, onChange, from, routes,
}) {
  const arrivalPortRoutes = filterArrivalPortRoutes(routes, from);

  let arrivalPorts = [];
  const departurePorts = getPorts(routes);

  arrivalPorts = getPorts(arrivalPortRoutes, true);

  return (
    children({
      arrivalPorts,
      departurePorts,
      onChange: event => handleFerryPortChange(event, onChange, routes, from),
      from,
    })
  );
}

CrossingsJourneyContainer.propTypes = {
  from: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
};

CrossingsJourneyContainer.defaultProps = {
  from: '',
  routes: [],
};

export default CrossingsJourneyContainer;

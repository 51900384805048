import React, { memo } from 'react';
import PropTypes from 'prop-types';

import CampsiteFeatureIconStyled from './CampsiteFeatureIcon.style';
import dotSVG from '../../static/images/icons/Dot.svg';

function CampsiteFeatureIcon({
  hideNoIcon, name, iconUrl, id, small, ...attrs
}) {
  if (hideNoIcon && !iconUrl) {
    return null;
  }
  const icon = `<img src=${iconUrl} title=${name} alt=${name} />`;
  const isValidUrl = iconUrl?.startsWith('http');

  return (
    <CampsiteFeatureIconStyled
      icon={(iconUrl && isValidUrl) ? icon : dotSVG}
      title={name || id}
      small={small}
      hasIconUrl={isValidUrl}
      {...attrs}
    />
  );
}

CampsiteFeatureIcon.propTypes = {
  iconUrl: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  name: PropTypes.string,
  hideNoIcon: PropTypes.bool,
  small: PropTypes.bool,
};

CampsiteFeatureIcon.defaultProps = {
  iconUrl: '',
  name: '',
  id: '',
  hideNoIcon: false,
  small: false,
};

export default memo(CampsiteFeatureIcon);

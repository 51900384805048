import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sum } from 'lodash';

import { differenceInDays } from '../../lib/dates';
import { alwaysArray } from '../../lib';
import ItxProvider from '../../lib/helpers/Itx/ItxProvider';

import { dictionaryItem } from '../../hocs/withDictionary';

import AvailabilityDetailsFooter from './AvailabilityDetailsFooter';
import AvailabilityDetailsErrata from './AvailabilityDetailsErrata';
import AvailabilityDetailsSpecReq from './AvailabilityDetailsSpecReq';
import Supplements from '../Supplements';
import BookingSummary from '../BookingSummary/BookingSummary';

import Text from '../ui/Text';
import Title from '../ui/Title';
import { LoadingSpinner } from '../ui/Loading';
import SupplementsCampsiteContent from '../Supplements/SupplementsCampsiteContent';

import StyledAvailabilityDetails, { Section } from './AvailabilityDetails.style';
import MessageWarning from '../ui/Message/MessageWarning.style';
import IbePropTypes from '../../IbePropTypes';
import CancellationReason from '../Amend/Forms/CancellationReason';

class AvailabilityDetails extends Component {
  static propTypes = {
    advancedGuestMode: PropTypes.bool,
    basePrice: PropTypes.number,
    bookingEdited: PropTypes.bool,
    campsiteId: PropTypes.string.isRequired,
    campsiteAddress: PropTypes.string,
    campsiteName: PropTypes.string,
    canAddMoreCampingPitches: PropTypes.bool.isRequired,
    handleAddMoreCampingPitches: PropTypes.func.isRequired,
    componentId: PropTypes.string,
    data: PropTypes.shape({
      availabilityDetails: PropTypes.shape({
        supplements: PropTypes.arrayOf(PropTypes.shape({})),
        errata: PropTypes.arrayOf(PropTypes.string),
        extras: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }).isRequired,
    disabledForms: PropTypes.bool.isRequired,
    error: PropTypes.shape({}),
    pitchName: PropTypes.string,
    handleQuoteChange: PropTypes.func.isRequired,
    quoteCount: PropTypes.number.isRequired,
    onlyExtras: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    payload: PropTypes.shape().isRequired,
    maxOccupancy: PropTypes.number,
    memberPricesAreSelected: PropTypes.bool.isRequired,
    toggleBasket: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onAdditionalCampingPitch: PropTypes.func,
    handleCheckMembership: PropTypes.func.isRequired,
    quote: PropTypes.shape(IbePropTypes.quote),
    configuration: PropTypes.shape(IbePropTypes.configuration),
    payloads: PropTypes.shape(IbePropTypes.payloads),
    basePrices: PropTypes.shape({}),
    pitches: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.pitch)),
    product: PropTypes.shape(IbePropTypes.product),
    updatePayloads: PropTypes.func,
    showCancellation: PropTypes.bool,
    onProductTypeMismatch: PropTypes.func.isRequired,
    searchPitches: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.pitch)),
    bookedPitches: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.pitch)),
  };

  static defaultProps = {
    advancedGuestMode: false,
    basePrice: null,
    bookingEdited: false,
    campsiteAddress: '',
    campsiteName: '',
    componentId: undefined,
    error: undefined,
    maxOccupancy: null,
    quote: {
      partyMembers: [],
      crossingBookings: [],
      campsiteBookings: [],
      id: '',
      extras: [],
    },
    configuration: undefined,
    onAdditionalCampingPitch: undefined,
    payloads: null,
    basePrices: {},
    pitches: [],
    pitchName: '',
    product: {},
    updatePayloads: () => {},
    showCancellation: false,
    searchPitches: [],
    bookedPitches: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      payload: {
        ...props.payload,
        errataAccepted: !!props.payload.id && !props.bookingEdited,
        supplements: this.filterSupplements(),
      },
      isCancelReasonError: false,
    };
  }

  componentDidMount() {
    this.props.onSubmit(false);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (!prevProps.data.availabilityDetails && data.availabilityDetails) {
      this.updateSupplements();
    }
  }

  checkToAddRemoveNonMemberSupplement = (supplementsFromState) => {
    const supplements = [...supplementsFromState];
    const { data } = this.props;
    const hasData = data && data.availabilityDetails && data.availabilityDetails.supplements;

    if (hasData) {
      // auto inject nonMemberSupplement into state for enforced selection
      const nonMemberSupplement = data.availabilityDetails.supplements.find(s => s.name === 'Non Member Supplement');

      const selectedNonMemberSupplementIndex
        = supplements.findIndex(s => s.uId === nonMemberSupplement?.uId);

      if (this.props.memberPricesAreSelected) {
        if (selectedNonMemberSupplementIndex === -1 && nonMemberSupplement) {
          supplements.push(nonMemberSupplement);
        }
      } else if (selectedNonMemberSupplementIndex > -1 && nonMemberSupplement) {
        supplements.splice(selectedNonMemberSupplementIndex, 1);
      }
    }

    return supplements;
  }

  updateSupplements = () => {
    const { payload } = this.state;
    const supplements = this.filterSupplements();
    // If we have advanced camping guests, skip this logic
    // as it overwrites the advanced payload
    if (this.props.advancedGuestMode) return;
    this.setState({
      payload: {
        ...payload,
        supplements,
      },
    }, () => {
      this.props.updatePayloads(this.state.payload);
    });
  }

  filterSupplements = () => {
    const { data, payload } = this.props;

    let supplements = [];

    if (
      data && data.availabilityDetails && data.availabilityDetails.supplements
      && payload && payload.supplements
    ) {
      supplements = [
        ...data.availabilityDetails.supplements,
        ...(data.availabilityDetails?.extras ?? []),
      ]
        .filter((s) => {
          const matchingSupplement = payload.supplements
            .find(supplement => supplement.uId === s.uId);
          return !s.isMandatory && s.type !== 'OCCUPANCY' && !!matchingSupplement;
        });
    }

    return supplements;
  }

  handleChange = (event, value) => {
    const { errataMissedWarning } = this.state;
    const payload = { ...this.state.payload };
    const { name } = event.target;
    payload[name] = value;

    this.setState({
      payload,
      ...name === 'errataAccepted' && errataMissedWarning && { errataMissedWarning: !value },
    });
  };

  handleGuestsChange = (guests) => {
    const { payload } = this.state;
    payload.guests = guests;
    this.setState({ payload });
  };

  handleSpecReqChange = (event, specialRequests) => {
    // determine wether we are working with the payloads camping payload or the normal payload
    const lastCampingIndex = (this.props.payloads?.camping?.length ?? 0) - 1;
    const payload = {
      ...(this.props.payloads ?
        this.props.payloads.camping?.[lastCampingIndex] : this.props.payload),
    };
    const { id } = event.target;

    let specReq = [];
    if (payload.specReq) {
      specReq = [...payload.specReq];
    }

    const exists = specReq.find(req => req.name === id);
    if (!exists) {
      const { name } = specialRequests?.find(request => request.name === id) ?? {};
      specReq.push({ name });
    } else {
      specReq = specReq.filter(req => req.name !== exists.name);
    }

    payload.specReq = specReq;
    this.props.updatePayloads(payload);
  };

  handlePayloadChange = (payload) => {
    this.setState({ payload });
  };

  handleExtrasPrice = (price, name) => {
    const payload = {
      ...this.state.payload,
      additionalCost: {
        ...this.state.additionalCost,
        [name]: price,
      },
    };
    this.setState({
      payload,
    }, () => {
      this.props.handleQuoteChange({
        payload,
      });
    });
  };

  handleSupplementChange = (supplements) => {
    const payload = {
      ...this.state.payload,
      specReq: this.props.payload.specReq,
      supplements,
    };
    this.setState({
      payload,
    }, () => {
      this.props.updatePayloads(payload);
    });
  }

  getSpecialRequests = () => {
    if (!this.props.quote) return [];
    const partyMemberWithSpecReq = this.props.quote.partyMembers
      .find(member => !!member.specialRequests?.length);

    if (!partyMemberWithSpecReq) return [];
    return partyMemberWithSpecReq.specialRequests;
  }

  handleMissedErrata = () => {
    // scroll to Errata section
    const yOffset = -70;
    const element = this.errata;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });

    // Activate alert styling for Errata section
    this.setState({ errataMissedWarning: true });
  }

  handleSelectCancellationReason = (e) => {
    this.setState({
      payload: {
        ...this.state.payload,
        cancelReasonId: e.target.value,
      },
      isCancelReasonError: false,
    });
  }

  validatePayload = (cancellationReasonRequired) => () => {
    if (!cancellationReasonRequired) {
      return true;
    }

    const cancellationReasonId = this.state.payload?.cancelReasonId;

    if (!cancellationReasonId) {
      this.setState({
        isCancelReasonError: true,
      });
      const cancellationReasonSelect = document.querySelector('#cancellationReason');

      if (cancellationReasonSelect) {
        cancellationReasonSelect.focus();
      }
    }

    return !!cancellationReasonId;
  }

  render() {
    const {
      advancedGuestMode,
      basePrice,
      campsiteId,
      canAddMoreCampingPitches,
      data,
      disabledForms,
      error,
      handleAddMoreCampingPitches,
      loading,
      onSubmit,
      onlyExtras,
      quoteCount,
      quote,
      toggleBasket,
      handleCheckMembership,
      configuration,
      onAdditionalCampingPitch,
      payloads,
      basePrices,
      pitches,
      pitchName,
      product,
      showCancellation,
      onProductTypeMismatch,
    } = this.props;

    let {
      campsiteAddress,
      campsiteName,
    } = this.props;

    const selectedSite = this.props.quote?.campsiteBookings.find(
      (site) => site?.campsite?.id === this.props.campsiteId,
    );

    if (selectedSite) {
      campsiteAddress = selectedSite.campsite?.address;
      campsiteName = selectedSite.campsite?.name;
    }

    const payload = { ...this.state.payload };

    payload.supplements = this.checkToAddRemoveNonMemberSupplement(payload.supplements);

    // we use the props payload for specReq selection, because that is where the data
    // needs to be kept however we need non stale specReq to merge with internal state
    // for the bookingSummary and footer
    payload.specReq = [...this.props.payload.specReq];

    const extraPrice = sum(
      payload.supplements
        .filter(({ type }) => type !== 'OCCUPANCY')
        .map(({ quantity, totalPrice }) => totalPrice * quantity),
    );
    const guestsTotal = payload.guests?.reduce(
      (total, guest) => total + (guest.price || 0), 0,
    );
    const lastCampingIndex = (payloads?.camping?.length ?? 0) - 1;
    const advancedGuestPrice = sum(payloads?.camping?.map((campingPayload, i) => {
      const priceIndex = `${campingPayload.pitchTypeId}-${i + 1}`;
      return basePrices[priceIndex];
    }) ?? []);
    const primaryBasePrice = basePrices[payload?.pitchTypeId] || basePrice;
    const totalPrice = sum([primaryBasePrice, extraPrice, guestsTotal, advancedGuestPrice]);
    const { availabilityDetails } = data;
    const numnberOfNights = differenceInDays(payload.end, payload.start);

    const selectedPitch = pitches.find(
      (pitch) => pitch.id === payload?.pitchTypeId,
    );

    const displayPitchName = selectedPitch?.name ?? pitchName;

    const selectedPitchWithFees = this.props.searchPitches.find(
      (pitch) => pitch.code === selectedPitch?.id,
    );

    // When adding Tent Pitch this.props.searchPitches can = [], amendmentFee can be undefined;
    const { amendmentFee } = selectedPitchWithFees || {};

    const bookedPitch = this.props.bookedPitches.find((pitch) => pitch.code === selectedPitch?.id);
    const previousTotalPrice = bookedPitch?.totalPrice ?? 0;

    // @TODO JH I guess here we are assessing the start and end dates in the payload
    // however we may this or another stay in the basket that has enough nigths
    // we are currently only assessing this campsite stay.
    const showUpSell = ItxProvider.checkToShowPackageUpgradeDialog(
      quote,
      numnberOfNights,
    );

    const upSellSaving = ItxProvider.getItxSaving(quote);

    // we either take the specReq from camping if we are adding AdvancedGuests
    let specialRequests = this.props.payload.specReq;
    if (this.props.payloads?.camping?.length) {
      specialRequests = this.props.payloads?.camping?.[lastCampingIndex].specReq || [];
    }

    if (loading && !availabilityDetails) {
      return (
        <Section>
          <LoadingSpinner />
          <Text dictionary={dictionaryItem('AvailabilityDetails', 'Fetching')} align="center" />
        </Section>
      );
    }

    if (!availabilityDetails || error) {
      return (
        <Section>
          <MessageWarning>
            Error: Failed to load availability details.
          </MessageWarning>
        </Section>
      );
    }
    const supplementsToDisplay = alwaysArray(availabilityDetails.extras)
      .filter(supplement => !supplement.isMandatory && supplement.name !== 'Non Member Supplement');

    const productSpecificSpecialRequests = configuration?.specialRequests?.filter(
      r => r.productCode === product?.productCode,
    );
    const cancellationReasonsEnabled = product?.enableAmendmentAndCancellationReasons;
    const cancellationReasonRequired =
      showCancellation && cancellationReasonsEnabled && !!quote?.bookingReference;

    return (
      <StyledAvailabilityDetails>
        {supplementsToDisplay.length > 0 &&
          <Section>

            <Title dictionary={dictionaryItem('AvailabilityDetailsSupplements')} marginBottom size={3} tag={1} />

            <Supplements
              disabled={disabledForms}
              onChange={this.handleSupplementChange}
              fetchedSupplements={supplementsToDisplay}
              numOfNights={
                differenceInDays(
                  new Date(this.props.payload.end),
                  new Date(this.props.payload.start),
                )
              }
              renderContent={contentProps => (
                <SupplementsCampsiteContent
                  {...contentProps}
                />
              )}
              values={payload.supplements}
            />
          </Section>
        }
        <Section>
          <AvailabilityDetailsSpecReq
            disabled={disabledForms}
            handleChange={
              event => this.handleSpecReqChange(event, configuration?.specialRequests)
            }
            fetchedRequests={productSpecificSpecialRequests}
            specReq={specialRequests}
            selectedSpecReq={this.getSpecialRequests()}
          />
        </Section>
        {cancellationReasonRequired &&
          <Section>
            <CancellationReason
              bookingId={quote.bookingReference}
              options={configuration?.cancellationReasons}
              value={this.state.payload.cancelReasonId || ''}
              onChange={this.handleSelectCancellationReason}
              showTitle={false}
              error={this.state.isCancelReasonError}
            />
          </Section>
        }
        <Section>
          <BookingSummary
            bookingSummary={payload}
            selectedSpecReq={this.getSpecialRequests()}
            basePrice={primaryBasePrice}
            campsiteAddress={campsiteAddress}
            campsiteName={campsiteName}
            availabilityDetails={availabilityDetails}
            pitchName={displayPitchName || payload.pitchName}
            partyMemberTypes={configuration?.partyMemberTypes}
            towTypes={configuration?.towTypes}
            vehicleTypes={configuration?.vehicleTypes}
            arrivalTime={payload.arrivalTime}
            pitches={pitches}
            payloads={payloads}
          />
        </Section>
        <Section alternateBg highlightAlert={this.state.errataMissedWarning}>
          <div ref={(el) => { this.errata = el; }}>
            <AvailabilityDetailsErrata
              disabled={disabledForms}
              errata={alwaysArray(availabilityDetails.errata)}
              errataAccepted={this.state.payload.errataAccepted}
              handleChange={this.handleChange}
            />
          </div>
        </Section>
        <Section>
          <AvailabilityDetailsFooter
            advancedGuestMode={advancedGuestMode}
            errorCodeTypes={configuration?.errorCodeTypes}
            campingIsUpsellEnabled={configuration?.configurableValues?.campingUpsellIsEnabled}
            campsiteAddress={campsiteAddress}
            campsiteId={campsiteId}
            campsiteName={campsiteName}
            canAddMoreCampingPitches={canAddMoreCampingPitches}
            disabled={disabledForms || !this.state.payload?.errataAccepted}
            errataMissedWarning={this.state.errataMissedWarning}
            handleAddMoreCampingPitches={handleAddMoreCampingPitches}
            handleMissedErrata={this.handleMissedErrata}
            quoteCount={quoteCount}
            onlyExtras={onlyExtras}
            onCompleted={() => onSubmit(false)}
            onSubmit={() => onSubmit(true)}
            pitchName={displayPitchName || payload.pitchName}
            showUpSell={showUpSell}
            upSellSaving={upSellSaving}
            toggleBasket={toggleBasket}
            totalPrice={totalPrice}
            productCode={product?.productCode}
            handleCheckMembership={handleCheckMembership}
            onAdditionalCampingPitch={onAdditionalCampingPitch}
            quote={this.props.quote}
            payloads={payloads}
            validatePayload={this.validatePayload(cancellationReasonRequired)}
            onProductTypeMismatch={onProductTypeMismatch}
            amendmentFee={amendmentFee}
            previousTotalPrice={previousTotalPrice}
            {...payload}
          />
        </Section>
      </StyledAvailabilityDetails>
    );
  }
}

export default AvailabilityDetails;

import styled, { css } from 'styled-components';

import { above, below } from '../../../styles/libs';

import { Row as RowComponent, Col as ColComponent } from '../../ui/Grid';
import detectIE from '../../../lib/detectIE';

import { Section as SectionStyled } from '../Quote.style';
import TextStyled from '../../ui/Text/Text.style';
import ButtonBorderedStyled from '../../ui/Button/ButtonBordered.style';
import ButtonBrandStyled from '../../ui/Button/ButtonBrand.style';
import { cssButtonLarge } from '../../ui/Button/Button.style';

export default styled.div`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  display: flex;
  flex-direction: column;
  flex: 1;

  ${detectIE() && `
    display: block;
  `}
`;

export const Header = styled.header`
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
`;

export const Section = styled(SectionStyled)``;

export const SectionRow = styled(RowComponent)``;

export const SectionCol = styled(ColComponent)`
  flex: 0 0 100%;

  ${({ theme }) => above(theme.DESKTOP)`
    flex: 0 0 50%;
    ${detectIE() && css`flex: 1;`}

    padding-right: 3rem;
  `}
`;

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
  flex: 1;
  padding: 1.25rem;
`;

export const FooterRow = styled(RowComponent)`
  justify-content: space-between;
`;

export const FooterCol = styled(ColComponent)`
  flex: 0 0 100%;
  text-align: center;

  ${({ theme }) => above(theme.DESKTOP)`
    flex: auto;

    &:first-of-type {
      margin-right: auto;
    }
  `}
`;

export const FooterPrice = styled(TextStyled)`
  font-size: 2.25rem;
  text-align: right;

  ${({ theme }) => below(theme.DESKTOP)`
    text-align: center;
    padding: 1rem 0;
  `}
`;

export const ButtonAddToBasket = styled(ButtonBrandStyled)`
  width: 100%;
  background-color: ${props => (props.isStale ? ({ theme }) => theme.COLOR_GRAY : ({ theme }) => theme.COLOR_LAKE_GREEN_DARK)};
  ${({ disabled }) => disabled && css`pointer-events: none;`}

  &:hover {
    background-color: ${props => (props.isStale ? ({ theme }) => theme.COLOR_GRAY : ({ theme }) => theme.COLOR_LAKE_GREEN_DARK)};
  }

  ${({ theme }) => above(theme.DESKTOP)`
    ${cssButtonLarge}
  `}
`;

export const ButtonImportantInformation = styled(ButtonBorderedStyled)`
  width: 100%;

  ${({ theme }) => above(theme.DESKTOP)`
    ${cssButtonLarge}
  `}
`;

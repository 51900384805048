import React from 'react';
import PropTypes from 'prop-types';

import ButtonBrand from '../ui/Button/ButtonBrand.style';
import testingAttr from '../../lib/testingAttr';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleContinueClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

function QuoteCreate({ disabled, handleContinueClick, loading }) {
  return (
    <ButtonBrand
      block
      onClick={() => handleContinueClick(true)}
      size="xl"
      disabled={disabled || loading}
      {...testingAttr('quote-create-button')}
    >
      Continue
    </ButtonBrand>
  );
}

QuoteCreate.propTypes = propTypes;

export default QuoteCreate;

import PropTypes from 'prop-types';
import React from 'react';

import TabPanel from './ComparisonTabContent.style';

const propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  active: PropTypes.bool,
};

const defaultProps = {
  active: false,
};

function ComparisonTabContent(props) {
  const {
    index, active, children,
  } = props;

  return (
    <TabPanel
      active={active}
      id={`section${index}`}
      role="tabpanel"
      aria-hidden={active && 'true'}
    >
      {children}
    </TabPanel>
  );
}

ComparisonTabContent.propTypes = propTypes;
ComparisonTabContent.defaultProps = defaultProps;

export default ComparisonTabContent;

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { compose, graphql, withApollo } from 'react-apollo';
import { withRouter } from 'next/router';

import isMember from '../../lib/isMember';
import MembershipLightBox from '../MembershipLightBox/MembershipLightBox';
import { dataLayerManager } from '../../lib/dataLayer';
import { checkWebview } from '../../lib/helpers/mobileApp';

import GET_POPUP from '../PopUp/graphql/getPopUp';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';

import {
  BecomeAMemberCard,
  Details,
  CTA,
  Button,
} from './BecomeAMember.style';
import { Text, Title } from '../ui';

import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';

const propTypes = {
  advertRef: PropTypes.shape({}),
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  theme: PropTypes.shape({
    COLOR_WHITE: PropTypes.string,
  }),
  userStatus: PropTypes.shape({
    loading: PropTypes.bool,
    user: PropTypes.shape({
      membershipExpired: PropTypes.bool,
    }),
  }),
  popups: PropTypes.shape({
    becomeAMemberPopUp: PropTypes.bool,
  }),
  data: PropTypes.shape({
    configuration: PropTypes.shape(IbePropTypes.configuration),
    loading: PropTypes.bool,
  }),
  toggleBasket: PropTypes.func.isRequired,
  isOverseas: PropTypes.bool.isRequired,
  router: PropTypes.shape(IbePropTypes.router).isRequired,
};

const defaultProps = {
  advertRef: {},
  theme: {},
  userStatus: {
    loading: true,
    user: null,
  },
  popups: {},
  data: {},
};

function BecomeAMember({
  client, theme, data, popups, router, ...props
}) {
  const { configuration, loading } = data;
  if (loading || checkWebview(router.query)) return null;

  const isValidMember = props.userStatus ? isMember(props.userStatus.user) : false;

  if (isValidMember) return null;

  const getCardType = () => (props.userStatus.user && props.userStatus.user.membershipExpired ? 'Renew' : 'Membership');

  const typeOfCard = getCardType();

  const cta = useRef(getDictionaryItem(client, `BecomeAMemberCard__${typeOfCard}__Title`));

  const handleClick = () => {
    dataLayerManager.pushPromoClick(
      cta.current,
      true,
      `${getCardType}${cta.current}`,
      typeOfCard,
      'Search Tab Footer',
    );

    client.writeData({ data: { becomeAMemberPopUp: true } });
  };

  return (
    <BecomeAMemberCard
      backgroundImage={configuration?.membershipContent?.listingImageUrl}
      ref={props.advertRef}
    >
      <Details>
        <MembershipLightBox
          active={popups.becomeAMemberPopUp}
          isOverseas={props.isOverseas}
          toggleBasket={props.toggleBasket}
        />
        <Title
          color={theme.COLOR_WHITE}
          dictionary={dictionaryItem('BecomeAMemberCard', `${typeOfCard}`)}
          marginBottom
          size={2}
          tag={1}
        />
        <Text
          color={theme.COLOR_WHITE}
          dictionary={dictionaryItem('BecomeAMemberCard', `${typeOfCard}`)}
          size="1rem"
        />
      </Details>
      <CTA>
        <Button
          target="_blank"
          dictionary={dictionaryItem('BecomeAMemberCard', `${typeOfCard}`)}
          onClick={handleClick}
          size="small"
        />
      </CTA>
    </BecomeAMemberCard>
  );
}

BecomeAMember.propTypes = propTypes;
BecomeAMember.defaultProps = defaultProps;

export default compose(
  withTheme,
  withApollo,
  withRouter,
  graphql(GET_POPUP, {
    props: ({ data }) => ({ popups: data }),
  }),
  graphql(GET_CONFIGURATION),
)(BecomeAMember);

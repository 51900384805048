import alwaysArray from './alwaysArray';

export default (routes, allSuppliers, departureZone, arrivalZone) => {
  const newRoutes =
    routes
      .filter(route => (
        allSuppliers.some(supplier => supplier === route.supplierCode) &&
        // Make sure that user will depart from the zone that he/she arrived
        arrivalZone === route.departurePortZone &&
        // Make sure that user will arrive to the zone that he departed from.
        departureZone === route.arrivalPortZone
      ));

  return alwaysArray(newRoutes);
};

import gql from 'graphql-tag';

export default gql`
  mutation updateConfirmationModalState(
    $active: Boolean!
  ) {
    updateConfirmationModalState(
      active: $active,
    ) @client
  }
`;

import styled from 'styled-components';

import theme from '../../theme';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${theme.MAX_WIDTH};
  margin: 0 auto;
  padding: 1rem;
`;

export const Input = styled.input`
  border: 0;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
`;

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns';

import { formatToHyphenFormat, formatToISOWithoutTimezone } from '../../lib/format';
import { isDesktopOrSmaller, isTabletOrSmaller } from '../../lib/helpers/layout';
import testingAttr from '../../lib/testingAttr';

import { dictionaryItem } from '../../hocs/withDictionary';

import TimePicker from '../TimePicker';
import { Label } from '../ui/Form';

import {
  DateGroup, DateTimePickerField, Flex, TimeGroup, DateInput,
} from './DateTimePicker.style';

import svgCalendar from '../../static/images/icons/Calendar.svg';

import { DATE_FORMAT_INPUT } from '../../config/locale';

class DateTimePicker extends PureComponent {
  static propTypes = {
    'data-testid': PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    customStyled: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ])),
    handleDateInput: PropTypes.func.isRequired,
    handleKeyDownDate: PropTypes.func.isRequired,
    label: PropTypes.string,
    inputRef: PropTypes.shape({
      current: PropTypes.shape({}),
    }),
    valueDate: PropTypes.string,
    valueTime: PropTypes.string,
    minDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    onDateChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }

  static defaultProps = {
    'data-testid': '',
    disabled: false,
    name: '',
    customStyled: [],
    label: '',
    inputRef: {
      current: null,
    },
    valueDate: '',
    minDate: '',
    valueTime: '',
  }

  handleTimeChange = (event) => {
    // prevValue is currently selected date
    const prevValue =
      this.props.valueDate ?
        new Date(formatToHyphenFormat(this.props.valueDate)) :
        this.props.minDate;

    const { value } = event.target;

    const hours = value === '-1' ? '12:00' : value;

    // Set the new time to the prev value
    const newDate = formatToISOWithoutTimezone(prevValue, hours);

    return this.props.onDateChange(newDate);
  }

  formatDateInput = (valueDate) => {
    if (!valueDate) return '';

    // handle ISO format for crossings
    if (isValid(new Date(valueDate)) && valueDate.includes('T')) {
      return format(new Date(valueDate), 'DD/MM/YYYY');
    }

    return valueDate;
  }

  handleKeyDownDate = (event) => {
    if (event.keyCode === 8) {
      this.setState({
        [this.props.name]: '',
      });
    }

    this.props.handleKeyDownDate(event);
  }

  render() {
    const {
      customStyled,
      inputRef,
      label,
      onFocus,
      valueDate,
    } = this.props;

    const time = String(this.props.valueTime);

    return (
      <Fragment>
        <Flex>
          <DateGroup>
            <Label htmlFor={`${label}-date`}>{label}</Label>
          </DateGroup>
          <TimeGroup>
            <Label
              dictionary={dictionaryItem('DateTimePicker', 'TimeGroup')}
              htmlFor={`${label}-time`}
            />
          </TimeGroup>
        </Flex>
        <DateTimePickerField customStyled={customStyled}>
          <DateGroup>
            <DateInput
              autoComplete="nofill"
              block
              type="text"
              required
              customStyled={{ border: 0 }}
              disabled={this.props.disabled}
              icon={svgCalendar}
              id={`${label}-date`}
              name={this.props.name}
              onChange={this.props.handleDateInput}
              onKeyDown={this.handleKeyDownDate}
              onFocus={onFocus}
              placeholder={DATE_FORMAT_INPUT}
              readOnly={isTabletOrSmaller()}
              inputRef={inputRef}
              value={this.formatDateInput(valueDate)}
              {...testingAttr(`${this.props['data-testid']}__date`)}
            />
          </DateGroup>
          <TimeGroup>
            <TimePicker
              block
              disabled={this.props.disabled || !this.formatDateInput(valueDate)}
              name="time"
              id={`${label}-time`}
              onChange={this.handleTimeChange}
              readOnly={isDesktopOrSmaller()}
              value={time}
              {...testingAttr(`${this.props['data-testid']}__time`)}
            />
          </TimeGroup>
        </DateTimePickerField>
      </Fragment>
    );
  }
}

export default DateTimePicker;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Input, CheckIcon, CheckboxLabel } from './Checkbox.style';

import svgTick from '../../../../static/images/icons/Tick.svg';
import CHECKBOX_VARIANTS from './consts';

class Checkbox extends Component {
  static propTypes = {
    customStyled: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ])),
      PropTypes.string,
      PropTypes.shape({}),
    ]),
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    'data-testid': PropTypes.string,
    defaultChecked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    disabled: PropTypes.bool,
    errored: PropTypes.bool,
    variant: PropTypes.oneOf([...Object.values(CHECKBOX_VARIANTS), undefined]),
  }

  static defaultProps = {
    customStyled: '',
    checked: null,
    'data-testid': '',
    defaultChecked: null,
    onBlur() { },
    onChange() { },
    onFocus() { },
    disabled: false,
    errored: false,
    variant: undefined,
  }

  constructor(props) {
    super(props);

    this.isControlled = this.props.checked !== null;

    this.state = {
      checked: this.props.defaultChecked !== null ?
        this.props.defaultChecked :
        false,
    };
  }

  handleFocus = event => this.props.onFocus(event);

  handleBlur = event => this.props.onBlur(event);

  handleChange = (event) => {
    const { checked } = event.target;

    // If not controlled, use local state
    if (!this.isControlled) {
      this.setState({ checked });
    }

    if (this.props.onChange) {
      this.props.onChange(event, checked);
    }
  };

  render() {
    // If not controlled, use local state
    const isChecked = this.isControlled ? this.props.checked : this.state.checked;

    return (
      <>
        <Input
          checked={isChecked}
          id={this.props.name}
          name={this.props.name}
          disabled={this.props.disabled}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          data-testid={`${this.props['data-testid']}_input`}
        />

        <CheckboxLabel
          customStyled={this.props.customStyled}
          disabled={this.props.disabled}
          data-testid={`${this.props['data-testid']}_label`}
          htmlFor={this.props.name}
          errored={this.props.errored}
          variant={this.props.variant}
        >
          {isChecked && <CheckIcon icon={svgTick} aria-hidden="true" />}
        </CheckboxLabel>
      </>

    );
  }
}

export default Checkbox;

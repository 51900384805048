import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';

import theme from '../styles/config/theme';

export default importComponent => (
  class extends Component {
    state = {
      component: null,
    }

    componentDidMount() {
      importComponent()
        .then((res) => {
          this.setState({ component: res.default });
        });
    }

    render() {
      const AsyncComponent = this.state.component;
      return AsyncComponent ?
        (
          <ThemeProvider theme={theme}>
            <AsyncComponent {...this.props} />
          </ThemeProvider>
        ) : null;
    }
  }
);

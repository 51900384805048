import React, {
  Children, Component, cloneElement,
} from 'react';

import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { withApollo, compose, graphql } from 'react-apollo';

import Modal, { ModalClose, ModalContent } from '../../ui/Modal';
import LoginPopUp from '../../PopUp/LoginPopUp';
import BasketPopUp from '../../PopUp/BasketPopUp';
import UpSellPopUp from '../../PopUp/UpSellPopUp';
import RemovePackagePopUp from '../../PopUp/RemovePackagePopUp';
import RemoveVouchersPopUp from '../../PopUp/RemoveVouchersPopUp';
import MembershipExpiredPopUp from '../../PopUp/MembershipExpiredPopUp';

import { ComparisonModal } from '../../Comparison';

import GET_POPUP from '../../PopUp/graphql/getPopUp';

import GET_CONFIGURATION from '../../../config/graphql/getConfiguration';
import GET_QUOTE from '../../Quote/graphql/getQuote';
import { USER_DECLINED_UP_SELL_IN_SESSION } from '../../../lib/constants';
import { BASKET_STATES } from '../../../config/products';
import IbePropTypes from '../../../IbePropTypes';
import DepositComponentSummaryPopUp from '../../PopUp/DepositComponentSummaryPopUp';
import SiteNightVoucherModal from '../../SiteNightVoucher/SiteNightVoucherModal';
import EXTRA_TYPES from '../../../config/extraTypes';

class SearchLayout extends Component {
  static propTypes = {
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    config: PropTypes.shape({
      configuration: PropTypes.shape(IbePropTypes.configuration),
    }),
    router: PropTypes.shape(IbePropTypes.router),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    popups: PropTypes.shape(IbePropTypes.popups),
    toggleBasket: PropTypes.func.isRequired,
    theme: PropTypes.shape({}),
    quote: PropTypes.shape(IbePropTypes.quote),
  }

  static defaultProps = {
    config: {
      configuration: {},
    },
    router: {
      query: {
        campaignCode: null,
      },
    },
    popups: {
      siteNightVoucherPopUp: { open: false, minimum: undefined },
      bookingOverlapDialog: false,
      voucherDialog: false,
      loginPopUp: {
        open: false,
        title: '',
        prompt: '',
        showMembership: false,
      },
      basketPopUp: {
        open: false,
        name: '',
      },
      upSellPopUp: {
        open: false,
        saving: 0,
      },
      removePackagePopUp: {
        open: false,
        componentId: '',
      },
      removeVouchersPopUp: {
        open: false,
        componentId: '',
      },
      membershipExpiredPopUp: {
        open: false,
      },
      noticePopUp: {
        open: false,
        type: '',
      },
    },
    theme: {},
    quote: {},
  }

  searchListing = React.createRef();

  reviewWrapper = React.createRef();

  handleBasketDialogClose = client => client.writeData({ data: { basketPopUp: { __typename: 'BasketPopUp', open: false, name: '' } } });

  handleBasketDialogViewBasket = client => {
    this.handleBasketDialogClose(client);
    this.props.toggleBasket(true);
  }

  handleDepositComponentSummaryDialogClose = client => client.writeData({
    data: {
      depositComponentSummaryPopUp: {
        __typename: 'DepositComponentSummaryPopUp',
        open: false,
        components: [],
      },
    },
  })

  handleRemovePackageDialogClose = client => client.writeData({
    data: {
      removePackagePopUp: {
        __typename: 'RemovePackagePopUp',
        open: false,
        componentId: '',
      },
    },
  })

  handleRemoveVouchersDialogClose = client => client.writeData({
    data: {
      removeVouchersPopUp: {
        __typename: 'RemoveVouchersPopUp',
        open: false,
        componentId: '',
      },
    },
  })

  handleUpSellDialogClose = client => client.writeData({
    data: {
      upSellPopUp: {
        __typename: 'UpSellPopUp',
        open: false,
        saving: 0,
      },
    },
  })

  handleMembershipExpiredClose = (client) => {
    client.writeData({
      data: {
        membershipExpiredPopUp: { __typename: 'MembershipExpiredPopUp', open: false },
      },
    });
  }

  handleDeclineUpSellDialog = (client) => {
    window.sessionStorage.setItem(USER_DECLINED_UP_SELL_IN_SESSION, true);
    this.handleUpSellDialogClose(client);
  }

  handleAcceptUpSellDialog = (client) => {
    this.handleUpSellDialogClose(client);
  }

  handleBasketDialogContinue = (client) => {
    this.handleBasketDialogClose(client);
    this.props.toggleBasket(false);
  }

  handleLoginPopupClose = () => {
    const { client } = this.props;
    client.writeData({
      data: {
        loginPopUp: {
          __typename: 'LoginPopUp',
          open: false,
          title: '',
          prompt: '',
          redirectTo: '',
          showMembership: false,
        },
      },
    });
  }

  render() {
    const { children, client } = this.props;
    return (
      <>
        <Modal
          active={this.props.popups.depositComponentSummaryPopUp?.open}
          overlay
        >
          <ModalContent size="small">
            <ModalClose onClick={() => this.handleDepositComponentSummaryDialogClose(client)} />
            <DepositComponentSummaryPopUp
              components={this.props.popups.depositComponentSummaryPopUp?.components}
            />
          </ModalContent>
        </Modal>

        <Modal
          active={this.props.popups.loginPopUp?.open}
          overlay
        >
          <ModalContent size="small">
            <ModalClose onClick={this.handleLoginPopupClose} />
            <LoginPopUp
              title={this.props.popups.loginPopUp?.title}
              prompt={this.props.popups.loginPopUp?.prompt}
              redirectTo={this.props.popups.loginPopUp?.redirectTo}
              showMembership={this.props.popups.loginPopUp?.showMembership}
              quote={this.props.quote}
              closePopup={this.handleLoginPopupClose}
            />
          </ModalContent>
        </Modal>

        <Modal
          active={this.props.popups.basketPopUp?.open}
          overlay
        >
          <ModalContent size="small" light={!!this.props.router.query.ukItinerary}>
            <ModalClose onClick={() => this.handleBasketDialogClose(client)} />
            <BasketPopUp
              client={client}
              itemName={this.props.popups.basketPopUp?.name}
              onClose={() => this.handleBasketDialogContinue(client)}
              onViewBasketClick={() => this.handleBasketDialogViewBasket(client)}
              isOverseas={this.props.quote?.basketState === BASKET_STATES.OVERSEAS}
              hasOSNV={!!this.props.quote?.extras?.find(
                (extra) => extra.type === EXTRA_TYPES.SITE_NIGHT_VOUCHER,
              )}
              light={!!this.props.router.query.ukItinerary}
            />
          </ModalContent>
        </Modal>

        <Modal
          active={this.props.popups.upSellPopUp?.open}
          overlay
        >
          <ModalContent size="small">
            <ModalClose onClick={() => this.handleUpSellDialogClose(client)} />
            <UpSellPopUp
              saving={this.props.popups.upSellPopUp?.saving}
              onDeclineUpSellClick={() => this.handleDeclineUpSellDialog(client)}
              onApplyUpSellClick={() => this.handleAcceptUpSellDialog(client)}
            />
          </ModalContent>
        </Modal>

        <Modal
          active={this.props.popups.removePackagePopUp?.open}
          overlay
        >
          <ModalContent size="small">
            <ModalClose onClick={() => this.handleRemovePackageDialogClose(client)} />
            <RemovePackagePopUp
              componentId={this.props.popups.removePackagePopUp?.componentId}
              handleClose={() => this.handleRemovePackageDialogClose(client)}
            />
          </ModalContent>
        </Modal>

        <Modal
          active={this.props.popups.removeVouchersPopUp?.open}
          overlay
        >
          <ModalContent size="small">
            <ModalClose onClick={() => this.handleRemoveVouchersDialogClose(client)} />
            <RemoveVouchersPopUp
              componentId={this.props.popups.removeVouchersPopUp?.componentId}
              handleClose={() => this.handleRemoveVouchersDialogClose(client)}
            />
          </ModalContent>
        </Modal>

        <Modal
          active={this.props.popups.membershipExpiredPopUp?.open}
          overlay
        >
          <ModalContent size="small">
            <ModalClose onClick={() => this.handleMembershipExpiredClose(client)} />
            <MembershipExpiredPopUp
              showProceed={this.props.popups.membershipExpiredPopUp?.showProceed}
              handleClose={() => this.handleMembershipExpiredClose(client)}
              quote={this.props.quote}
            />
          </ModalContent>
        </Modal>

        <ComparisonModal />

        {this.props.router.query.bookingId &&
        <SiteNightVoucherModal
          active={this.props.popups.siteNightVoucherPopUp?.open}
          minimum={this.props.popups.siteNightVoucherPopUp?.minimum}
          toggleBasket={this.props.toggleBasket}
          quote={this.props.quote}
        />}

        {/* Render search form and results */}
        {Children.map(children, child => (
          cloneElement(child, {})
        ))}
      </>
    );
  }
}

export default compose(
  withApollo,
  withTheme,
  withRouter,
  graphql(GET_QUOTE, {
    props: ({ data }) => ({
      quote: data?.quote,
    }),
    skip: ({ router }) => router.query.bookingWidget === 'true',
  }),
  graphql(GET_CONFIGURATION, {
    name: 'config',
  }),
  graphql(GET_POPUP, {
    name: 'popups',
  }),
)(SearchLayout);

import styled, { css } from 'styled-components';

import Icon from '../ui/Icon';

export default styled(Icon)`
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  font-size: 1.75rem;
  margin-right: ${({ marginRight }) => marginRight || '0.5rem'};

  ${({ small, theme }) => small && css`
    color: ${theme.COLOR_TEXT_BODY};
    font-size: 1.25rem;
  `}
  ${({ hasIconUrl }) => hasIconUrl && css`
    // converted #000000 to #237274 using https://codepen.io/sosuke/pen/Pjoqqp
    // filter: invert(44%) sepia(11%) saturate(2425%) hue-rotate(133deg) brightness(82%) contrast(89%);
  `}
`;

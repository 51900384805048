import styled from 'styled-components';

import { ButtonBrand, ButtonIcon } from '../../ui/Button';

export const SearchButton = styled(ButtonBrand)`
  background-color: ${({ theme }) => theme.COLOR_AMBER_RED};
  border-color: ${({ theme }) => theme.COLOR_WHITE};

  &:hover {
    background-color: ${({ theme }) => theme.COLOR_AMBER_RED_DARK};
  }
`;

export const SearchIcon = styled(ButtonIcon)`

`;

import React from 'react';
import useComponentError from '../lib/hooks/component-error';

function withComponentError(WrappedComponent, componentName) {
  return function ComponentError(props) {
    const componentError = useComponentError(componentName);
    return <WrappedComponent {...componentError} {...props} />;
  };
}

export default withComponentError;

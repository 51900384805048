export const searchByType = {
  LOCATION: 'location',
  CAMPSITE: 'campsite',
};

export const MY_LOCATION_SEARCH_KEY = 'my-location'; // used for Session Storage and URL query params.

export const defaultSearchByTypeUk = searchByType.CAMPSITE;
export const defaultSearchByTypeOverseas = searchByType.LOCATION;

export const GOOGLE_FEATURE_TYPES = {
  COUNTRY: 'COUNTRY',
  ADMINISTRATIVE_AREA_LEVEL_1: 'ADMINISTRATIVE_AREA_LEVEL_1',
  ADMINISTRATIVE_AREA_LEVEL_2: 'ADMINISTRATIVE_AREA_LEVEL_2',
  LOCALITY: 'LOCALITY',
};

// These are the feature types we will show borders for
export const APPLICABLE_FEATURE_TYPES = [
  GOOGLE_FEATURE_TYPES.COUNTRY,
  GOOGLE_FEATURE_TYPES.ADMINISTRATIVE_AREA_LEVEL_1,
  GOOGLE_FEATURE_TYPES.ADMINISTRATIVE_AREA_LEVEL_2,
];

export const LOCATION_ICON_PATH = 'M0.5 13.1194C0.5 6.12438 6.23074 0.5 13.3 0.5C20.3693 0.5 26.1 6.12438 26.1 13.1194C26.1 14.4138 25.9881 15.4524 25.6763 16.5058C25.3633 17.5629 24.8417 18.6594 23.9938 20.0604C22.8519 21.9473 21.1514 24.3354 18.6377 27.8657C17.3914 29.6159 15.9451 31.647 14.2679 34.0369C14.055 34.3338 13.6917 34.5 13.3 34.5C12.9086 34.5 12.5455 34.334 12.3325 34.0375C10.5255 31.4465 8.98664 29.278 7.67706 27.4325C5.31915 24.1097 3.70446 21.8343 2.60576 20.026C1.7576 18.6301 1.23623 17.5421 0.923473 16.4923C0.611857 15.4464 0.5 14.4138 0.5 13.1194ZM6.19586 13.1194C6.19586 17.0198 9.37619 20.1791 13.3 20.1791C17.2238 20.1791 20.4041 17.0198 20.4041 13.1194C20.4041 9.21899 17.2238 6.05969 13.3 6.05969C9.37619 6.05969 6.19586 9.21899 6.19586 13.1194Z';

import styled, { css } from 'styled-components';

import BasketToggle from '../Basket/BasketToggle';

import StyledButton from '../ui/Button/Button';

import { above } from '../../styles/libs';
import detectIE from '../../lib/detectIE';

export const BasketToggleMobile = styled(BasketToggle)`
  ${({ theme }) => above(theme.TABLET)`display: none;`}
`;

export default styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  top: ${({ scrollPosition }) => (scrollPosition ? `${scrollPosition}px` : null)};
  ${({ scroll, theme }) => scroll && above(theme.DESKTOP)`
    position: relative;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  `}
`;

export const Header = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  z-index: 1;
`;

const button = css`
  width: 100%;
  border-radius: 0;
  padding: 0 0.5rem;
  height: 52px;
  color: ${({ theme }) => theme.COLOR_TEXT_BODY};
  ${({ theme }) => above(theme.TABLET)`
    padding: 0 1.25rem;
  `}
`;

export const Back = styled(StyledButton)`
  ${button}
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTER};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  }
`;

export const Close = styled(StyledButton)`
  ${button}
  background-color: ${({ theme }) => theme.COLOR_WHITE};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTER};
  }
`;

export const NavigationRow = styled.div`
  display: flex;
  width: 100%;
`;

export const Overflow = styled.div`
  ${({ scroll, theme }) => {
    if (!scroll) return '';
    return above(theme.DESKTOP)`
      flex: 1;
      position: sticky;
      top: 0;
      overflow-x: hidden;
      overflow-y: auto;
    `;
  }}
  ${!!detectIE() && `
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
  `}
  ${({ theme }) => above(theme.TABLET)`
    width: 100%;
  `}
`;

export const Body = styled.section`
  padding: 1.25rem;
  ${({ theme }) => above(theme.TABLET)`
    padding: 2rem;
  `}
  ${!!detectIE() && `
    padding-bottom: 4rem;
  `}
`;

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { propType } from 'graphql-anywhere';

import checkLoggedIn from '../../lib/isLoggedIn';
import testingAttr from '../../lib/testingAttr';
import { isNoneNullOrUndefined } from '../../lib/helpers/outfits';
import { roundToNearest } from '../../lib/format';

import { dictionaryItem } from '../../hocs/withDictionary';

import { Label, Select } from '../ui/Form';

import GET_USER_OUTFITS from './graphql/getUserOutfits';
import { TOW_TYPES, VEHICLE_TYPES } from '../../config/outfits';

const defaultOutfit = {
  id: null,
  externalId: null,
  towHeight: '',
  towLength: '',
  towMake: null,
  towModel: null,
  towType: '',
  vehicleHeight: '',
  vehicleLength: '',
  vehicleMake: null,
  vehicleModel: null,
  vehicleReg: null,
  vehicleType: '',
};

class OutfitSelect extends PureComponent {
  static fragments = {
    outfitTypes: gql`
      fragment KeyValue on OutfitTypes {
        key
        value
      }
    `,
  };

  static propTypes = {
    customStyled: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      ),
      PropTypes.string,
      PropTypes.shape({}),
    ]),
    hasHeight: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    towTypes: PropTypes.arrayOf(propType(OutfitSelect.fragments.outfitTypes)),
    vehicleTypes: PropTypes.arrayOf(
      propType(OutfitSelect.fragments.outfitTypes),
    ),
  };

  static defaultProps = {
    customStyled: '',
    towTypes: [],
    vehicleTypes: [],
  };

  /**
   * Return the user outfit name
   * ex. Car & Caravan: vehicleMake: Citroën Berlingo
   */
  getName = ({
    towType = 0,
    vehicleMake,
    vehicleModel,
    vehicleType,
    towMake,
    towModel,
  }) => {
    let make;
    let model;

    if (vehicleType !== VEHICLE_TYPES.NONE) {
      make = vehicleMake;
      model = vehicleModel;
    } else if (towType !== TOW_TYPES.NONE) {
      make = towMake;
      model = towModel;
    }

    return ` ${make} ${model}`;
  };

  /**
   * Find and return the entire user outfit to the outfit form
   */
  handleChange = (userOutfits, event) => {
    const { onChange } = this.props;
    const { value } = event.target;

    const ROUND_TO_NEAREST = 10;

    const outfit =
      userOutfits.find(({ externalId }) => externalId === value) || {};

    const {
      towHeight,
      towLength,
      vehicleHeight,
      vehicleLength,
    } = outfit;

    // We round to the nearest 10 to avoid any 3 D.P. errors in the outfit form
    // Any outfits saved with a length/height of e.g. 2415 are rounded to e.g. 2420
    const roundedOutfit = {
      ...outfit,
      towHeight: roundToNearest(towHeight, ROUND_TO_NEAREST),
      towLength: roundToNearest(towLength, ROUND_TO_NEAREST),
      vehicleHeight: roundToNearest(vehicleHeight, ROUND_TO_NEAREST),
      vehicleLength: roundToNearest(vehicleLength, ROUND_TO_NEAREST),
    };

    onChange({ ...defaultOutfit, ...roundedOutfit }, event);
  };

  validateVehicle = (outfit) => {
    if (isNoneNullOrUndefined(outfit.vehicleType)) return false;

    if (this.props.hasHeight) {
      return outfit.vehicleLength && outfit.vehicleHeight;
    }

    return !!outfit.vehicleLength;
  };

  validateTow = (outfit) => {
    // if towType does not exists just return true
    if (!outfit.towType) return false;

    // if its ferry check if height exist
    if (this.props.hasHeight) {
      return outfit.towLength && outfit.towHeight;
    }
    // if its campsite check only length
    return !!outfit.towLength;
  };

  validateOutfits = (outfits) => {
    const newOutfits = outfits.filter(
      outfit => !(outfit.vehicleType !== VEHICLE_TYPES.NONE && outfit.towType !== TOW_TYPES.NONE),
    );

    return newOutfits.filter(
      outfit => this.validateVehicle(outfit) || this.validateTow(outfit),
    );
  };

  render() {
    const { customStyled, ...selectProps } = this.props;

    const isLoggedIn = checkLoggedIn();

    if (!isLoggedIn) return null;
    return (
      <Query
        fetchPolicy="network-only"
        query={GET_USER_OUTFITS}
        skip={!this.props.vehicleTypes.length && !this.props.towTypes.length}
      >
        {({ data, loading }) => {
          let outfitsToDisplay = [];

          if (loading) {
            return null;
          }
          if (!loading && data && data.userOutfits) {
            if (data.userOutfits.outfits === null) {
              return null;
            }
            outfitsToDisplay = this.validateOutfits(data.userOutfits.outfits);
          }

          return (
            <Fragment>
              <Label
                htmlFor="vehicle-select"
                dictionary={dictionaryItem('OutfitSelect', 'ExistingVehicle')}
              />
              <Select
                block
                customStyled={customStyled}
                id="vehicle-select"
                name="externalId"
                {...selectProps}
                onChange={event => this.handleChange(data.userOutfits.outfits, event)}
                {...testingAttr('outfit-form__prepopulated-outfit')}
              >
                <option value="">
                  Add or pick existing vehicle for this booking
                </option>

                {outfitsToDisplay.map(option => (
                  <option key={option.externalId} value={option.externalId}>
                    {this.getName(option)}
                  </option>
                ))}
              </Select>
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

export default OutfitSelect;

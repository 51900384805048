import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import UPDATE_CROSSING_COST from '../graphql/updateCrossingCost.gql';

class QuoteCrossingUpdateCost extends PureComponent {
  static propTypes = {
    children: PropTypes.func,
    error: PropTypes.shape({}),
    loading: PropTypes.bool,
    onCompleted: PropTypes.func,
    onError: PropTypes.func,
    onSubmit: PropTypes.func,
    onSuccess: PropTypes.func,
    payload: PropTypes.shape({}),
  }

  static defaultProps = {
    children: null,
    error: null,
    loading: false,
    onCompleted() { },
    onError() { },
    onSubmit() { },
    onSuccess() { },
    payload: {},
  }

  state = {
    error: this.props.error,
  }

  handleSubmit = (event, updateCrossingCost) => {
    event.stopPropagation();

    const {
      onCompleted, onError, onSubmit, onSuccess, payload,
    } = this.props;

    onSubmit();
    this.setState({ error: null });

    updateCrossingCost({ variables: { input: payload } }).then(({ data: { results } }) => {
      this.setState({ loading: false });
      onSuccess(results);
    }).catch((error) => {
      console.error(error);
      this.setState({ error, loading: false });
      onError(error);
    }).finally(onCompleted);
  };

  render() {
    const loading = this.props.loading || this.state.loading;
    const error = this.props.error || this.state.error;
    const { children } = this.props;

    return (
      <Mutation
        mutation={UPDATE_CROSSING_COST}
        fetchPolicy="no-cache"
      >
        {updateCrossingCost => children && children({
          error,
          disabled: loading,
          loading,
          onClick: event => this.handleSubmit(event, updateCrossingCost),
        })}
      </Mutation>
    );
  }
}

export default QuoteCrossingUpdateCost;

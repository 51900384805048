import React from 'react';
import PropTypes from 'prop-types';

import SupplementsCrossingContentStyled, {
  Header, Name, Select,
} from './SupplementsCrossingContent.style';

function SupplementsCrossingContent(props) {
  return (
    <SupplementsCrossingContentStyled>
      <Header>
        <Name>{props.name}</Name>
        {!!props.maxQuantity &&
          <Select
            disabled={props.disabled || !props.checked}
            onChange={props.onQuantity}
            size="xs"
            type="number"
            value={props.quantity}
          >
            {[...Array(props.maxQuantity).keys()].map(n => n + 1).map(key => (
              <option key={key}>{key}</option>
            ))}
          </Select>
        }
      </Header>
    </SupplementsCrossingContentStyled>
  );
}

SupplementsCrossingContent.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  maxQuantity: PropTypes.number,
  name: PropTypes.string.isRequired,
  onQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.number,
};

SupplementsCrossingContent.defaultProps = {
  checked: false,
  disabled: false,
  maxQuantity: 0,
  quantity: null,
};

export default SupplementsCrossingContent;

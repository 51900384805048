import React, { Component, createRef } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import {
  addDays, startOfDay, isAfter, isValid, format,
} from 'date-fns';

import moment from 'moment';

import testingAttr from '../../../lib/testingAttr';

import { dateInputValidation as dateInputValidationRegex, numbersAndBackSlash as numbersAndBackSlashRegex } from '../../../lib/regexes';
import { formatDatepickerDate, formatToHyphenFormat, formatToISOWithoutTimezone } from '../../../lib/format';
import pickStateOrPayloadDate from '../../../lib/pickStateOrPayloadDate';

import { Datepicker } from '../../ui/Datepicker';
import DateTimePicker from '../../DateTimePicker';

import { dictionaryItem } from '../../../hocs/withDictionary';

import {
  FormGroup, FormGroupItem, cssInput,
} from '../SearchForm.style';

import { TIME_FORMAT_MILITARY } from '../../../config/locale';

// Returns formatted UTC time
function formatTimeValue(dateTime) {
  if (!dateTime) return '';

  const formattedTime = format(dateTime, TIME_FORMAT_MILITARY);

  return formattedTime;
}

class CrossingsDatePickerGroup extends Component {
  static propTypes = {
    availabilityMaxDate: PropTypes.string.isRequired,
    dateErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleDateClear: PropTypes.func.isRequired,
    handleKeyDownDate: PropTypes.func.isRequired,
    inboundDateTime: PropTypes.string,
    onDateChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    oneWay: PropTypes.bool.isRequired,
    outboundDateTime: PropTypes.string,
  };

  static defaultProps = {
    inboundDateTime: '',
    outboundDateTime: '',
  };

  state = {
    endDate: '',
    startDate: '',
  }

  inputRef = createRef();

  validateDateInputs = (event) => {
    const { name, value } = event.target;

    // persist the previous errors.
    const errorArr = this.props.dateErrors.filter(error => error.type !== name);
    // go to adding errors if Regex fails and if its full date.
    // (no point for validation while user is typing)

    if (!dateInputValidationRegex.test(value) && value.length === 10) {
      // add error while date is not valid
      const newDate = formatToHyphenFormat(value);

      if (name === 'startDate' && !isValid(new Date(newDate))) errorArr.push({ type: 'startDate', message: dictionaryItem('CrossingsDatePickerGroup', 'Start', 'Date', 'Error') });
      if (name === 'endDate' && !isValid(new Date(newDate))) errorArr.push({ type: 'endDate', message: dictionaryItem('CrossingsDatePickerGroup', 'End', 'Date', 'Error') });
    }

    this.props.onError(errorArr);
    // handle populating
    this.handleDateInput(event);

    // update payload
    if (name === 'startDate' && (dateInputValidationRegex.test(value) || isValid(new Date(value)))) {
      const correctValue = formatDatepickerDate(value);
      this.props.onDateChange(`${correctValue}T12:00:00`, 'outboundItinerary');
    } if (name === 'endDate' && (dateInputValidationRegex.test(value) || isValid(new Date(value)))) {
      const correctValue = formatDatepickerDate(value);
      this.props.onDateChange(`${correctValue}T12:00:00`, 'inboundItinerary');
    }
  }

  handleDateInput = (event) => {
    const { name, value } = event.target;

    // IE, to prevent inputing anything but number and slashes
    if (numbersAndBackSlashRegex.test(value)) {
      this.setState({
        [name]: value,
      });
    }
  }

  pickStateOrPayloadDate = (stateDate, itineraryDate) => {
    if (stateDate) return stateDate;
    if (itineraryDate) return format(itineraryDate, 'DD/MM/YYYY');
    return '';
  }

  handleDateChange = async ([startDate, endDate]) => {
    // When you click cell, wipe the typed in value
    if (startDate && !endDate) {
      this.setState({ startDate: '' });
    } else if (startDate && endDate) {
      this.setState({ endDate: '' });
    }
    // Fallback time to 0, in case user will input wrong date for example 12/90/2019
    // then new Date === Invalid Date, and you cannot do Invalid Date.getHours
    const newStartDate = startDate ?
      formatToISOWithoutTimezone(startDate, formatTimeValue(this.props.outboundDateTime) || '12:00') :
      '';

    const newEndDate = endDate ?
      formatToISOWithoutTimezone(endDate, formatTimeValue(this.props.inboundDateTime) || '12:00') :
      '';

    await this.handleOutboundChange(newStartDate);
    await this.props.onDateChange(newEndDate, 'inboundItinerary');
  }

  handleDateClear = async (type) => {
    if (type === 'inboundItinerary') {
      await this.setState({ endDate: '' });
    } else if (type === 'outboundItinerary') {
      await this.setState({ startDate: '' });
    }

    return this.props.handleDateClear(type);
  }

  handleOutboundChange = (crossingDateTime) => {
    if (isAfter(new Date(crossingDateTime), new Date(this.props.inboundDateTime))) {
      this.handleDateClear('inboundItinerary');
    }

    this.props.onDateChange(crossingDateTime, 'outboundItinerary');
  }

  render() {
    const { oneWay } = this.props;

    const defaultMinDate = startOfDay(addDays(new Date(), 1));

    const startDate = pickStateOrPayloadDate(this.state.startDate, this.props.outboundDateTime);
    let datepickerStartDate = '';

    if (isValid(new Date(formatToHyphenFormat(startDate)))) {
      datepickerStartDate = format(new Date(formatToHyphenFormat(startDate)), 'YYYY-MM-DD');
    }

    const endDate = pickStateOrPayloadDate(this.state.endDate, this.props.inboundDateTime);
    const datepickerEndDate = isValid(new Date(formatToHyphenFormat(endDate))) ? formatToHyphenFormat(endDate) : '';

    const datepickerOptions = {
      anchor: this.inputRef,
      closingIgnoreList: ['pickerNameToPreventAutoComplete', 'startDate', 'endDate'],
      endDate: datepickerEndDate,
      onChange: this.handleDateChange,
      handleClear: this.props.handleDateClear,
      options: {
        dateRange: !oneWay,
        minDate: defaultMinDate,
        maxDate: new Date(this.props.availabilityMaxDate),
        months: oneWay ? 1 : 2,
        name: 'campsiteSearch_dateRange',
      },
      startDate: datepickerStartDate,
    };

    return (
      <Datepicker {...datepickerOptions}>
        {({ handleFocus }) => (
          <>
            <FormGroup>
              <FormGroupItem responsive>
                <DateTimePicker
                  handleDateClear={() => this.handleDateClear('outboundItinerary')}
                  customStyled={cssInput}
                  onFocus={handleFocus}
                  label="Departing"
                  minDate={defaultMinDate}
                  name="startDate"
                  inputRef={this.inputRef}
                  handleDateInput={this.validateDateInputs}
                  handleKeyDownDate={this.props.handleKeyDownDate}
                  onDateChange={date => this.handleOutboundChange(date, 'outboundItinerary')}
                  onTimeChange={(...args) => this.props.onDateChange(...args, 'outboundItinerary')}
                  valueDate={startDate}
                  // TODO: use date-fns for formatting display.
                  // currently it's adding 1 hour to time on DST changeover days
                  valueTime={moment(this.props.outboundDateTime).format('HH:mm')}
                  {...testingAttr('crossings-dates__outboundItinerary')}
                />
              </FormGroupItem>

              {!oneWay &&
                <FormGroupItem responsive>
                  <DateTimePicker
                    handleDateClear={() => this.handleDateClear('inboundItinerary')}
                    customStyled={cssInput}
                    onFocus={handleFocus}
                    label="Returning"
                    minDate={defaultMinDate}
                    name="endDate"
                    handleDateInput={this.validateDateInputs}
                    handleKeyDownDate={this.props.handleKeyDownDate}
                    onDateChange={date => this.props.onDateChange(date, 'inboundItinerary')}
                    onTimeChange={(...args) => this.props.onDateChange(...args, 'inboundItinerary')}
                    valueDate={endDate}
                    valueTime={moment(this.props.inboundDateTime).format('HH:mm')}
                    {...testingAttr('crossings-dates__inboundItinerary')}
                  />
                </FormGroupItem>
              }
            </FormGroup>
          </>
        )}
      </Datepicker>
    );
  }
}

export default withTheme(CrossingsDatePickerGroup);

import styled, { css } from 'styled-components';

import Icon from '../ui/Icon';

export default styled.div`
  ${({ marginBottom }) => marginBottom && css`margin-bottom: 1.5rem;`}

  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
  border: 2px solid ${({ selected, theme }) => (selected ? theme.COLOR_BRAND_PRIMARY : 'transparent')};
  border-radius: 5px;
  margin-top: 1rem;
  width: 100%;

  ${({ isClickable }) => isClickable && css`
    transition: all 250ms ease;
    outline: 0;
    cursor: pointer;

    ${({ disabled }) => disabled && css`
      background-color: ${({ theme }) => theme.COLOR_WHITE};
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
    `}

    &:hover:not(:disabled) {
      border-color: ${({ selected, theme }) => (selected ? theme.COLOR_BRAND_PRIMARY : theme.COLOR_GRAY_LIGHT)};
    }

    &:first-child {
      margin-top: 0;
    }
  `}
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  min-height: 4.5rem;
  padding: 0.75rem 1.5rem;
`;

export const Content = styled.div`
  flex: 1;
  min-width: 0;
`;

export const Tick = styled(Icon)`
  color: ${({ selected, theme }) => (selected ? theme.COLOR_BRAND_PRIMARY : theme.COLOR_GRAY_LIGHT)};
  font-size: 1.25rem;
  margin-right: 1.5rem;

  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}
`;

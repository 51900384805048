import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { Container, Content } from './SearchResultsUkItinerary.style';
import { ButtonClose } from '../ui/Button';
import Text from '../ui/Text';
import IbePropTypes from '../../IbePropTypes';

const SearchResultsUkItinerary = ({ theme, activeUkItinerary, handleClearUkItinerary }) => {
  const { name, itineraryLength, itineraryLengthTitle } = activeUkItinerary ?? {};
  return (
    <Container>
      <Content>
        <Text weight="bold" size="1.2rem">{name}</Text>
        <Text>{itineraryLengthTitle}: {itineraryLength} nights</Text>
      </Content>
      <ButtonClose
        onClick={handleClearUkItinerary}
        backgroundColor={theme.COLOR_WHITE}
        crossColor={theme.COLOR_TEXT_BODY}
      />
    </Container>
  );
};

SearchResultsUkItinerary.propTypes = {
  theme: PropTypes.shape({
    COLOR_WHITE: PropTypes.string,
    COLOR_TEXT_BODY: PropTypes.string,
  }).isRequired,
  activeUkItinerary: PropTypes.shape(IbePropTypes.ukItinerary).isRequired,
  handleClearUkItinerary: PropTypes.func.isRequired,
};

export default withTheme(SearchResultsUkItinerary);

import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import testingAttr from '../../lib/testingAttr';
import { Select } from '../ui/Form';

/**
 *
 * @param {number} steps The amount of intervals in an hour (eg. 4 = every 15 minutes)
 */
function timesProvider(steps = 1) {
  const date = new Date();
  const hoursInDay = 24;
  const amount = 60 / steps;
  const length = hoursInDay * steps;

  // Set to start of the day
  date.setHours(0, 0, 0, 0);

  const array = [...Array(length)].map(() => {
    const value = format(date, 'HH:mm');
    date.setUTCMinutes(date.getUTCMinutes() + amount);
    return value;
  });

  return array;
}

function TimePicker({ placeholder, ...props }) {
  const times = timesProvider();

  return (
    <Select
      customStyled={{ border: 0 }}
      {...props}
      {...testingAttr(`${props['data-testid']}`)}
    >
      <option value="-1">{placeholder}</option>
      {times.map(time => (
        <option key={time} value={time}>{time}</option>
      ))}
    </Select>
  );
}

TimePicker.propTypes = {
  'data-testid': PropTypes.string,
  placeholder: PropTypes.string,
};

TimePicker.defaultProps = {
  'data-testid': '',
  placeholder: 'Time',
};

export default TimePicker;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, withApollo } from 'react-apollo';
import { v4 as uuid } from 'uuid';

import getAvailabilityOptions from '../../lib/availability';
import { dictionaryItem } from '../../hocs/withDictionary';
import GET_AVAILABILITY from './graphql/getAvailability.gql';
import IbePropTypes from '../../IbePropTypes';
import FetchPolicy from '../../constants/FetchPolicy';
import getFetchPolicyWithExpiry from '../../lib/getFetchPolicyWithExpiry';
import { addTiming, types } from '../../lib/timings';

const defaultData = { availability: { pitchTypes: [] } };
class AvailabilitySearchQuery extends Component {
  static propTypes = {
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    children: PropTypes.func.isRequired,
    variables: PropTypes.shape({
      calendar: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
      }),
      payload: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
      }),
      partyMembers: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.partyMember)),
      siteCode: PropTypes.string,
      productCode: PropTypes.number,
      placeType: PropTypes.number,
      pitchId: PropTypes.string,
    }).isRequired,
    onCompleted: PropTypes.func.isRequired,
  }

  fetchStack = [];

  state = {
    availabilitySearch: {
      error: null,
      loading: false,
      data: defaultData,
    },
    availabilityTotal: {
      error: null,
      loading: false,
      data: defaultData,
    },
    inputDateError: null,
  }

  handleError = type => (error) => {
    this.setState({
      [type]: {
        error,
        data: defaultData,
      },
    });
  }

  fetchAvailabilitySearch = async () => {
    const {
      client,
      variables: {
        calendar: {
          start,
        },
        partyMembers,
        siteCode,
        productCode,
        placeType,
      },
    } = this.props;

    const availabilityOptionsVariables = {
      variables: {
        costPerNight: true,
        partyMembers,
        productCode,
        siteCode,
        start,
        placeType,
      },
    };

    const { variables } = getAvailabilityOptions(availabilityOptionsVariables);

    // the async query below can return response out of order of calling
    // we use a uuid to ensure only the last called utilized.
    const fetchRequestUuid = uuid();
    this.fetchStack.push(fetchRequestUuid);
    const cacheKey = `GET_AVAILABILITY${JSON.stringify(availabilityOptionsVariables.variables)}`;

    this.setState({
      availabilitySearchLoading: true,
    });

    try {
      // We use a small amount of cache here to make the UX of
      // clicking back and forth in the calender a little better
      const response = await client.query({
        query: GET_AVAILABILITY,
        fetchPolicy: getFetchPolicyWithExpiry(cacheKey, {
          defaultPolicy: FetchPolicy.CACHE_FIRST,
          expiry: 1000 * 60 * 3, // 3 minutes
          expiryPolicy: FetchPolicy.NETWORK_ONLY,
        }),
        notifyOnNetworkStatusChange: true,
        variables,
      });

      if (fetchRequestUuid === this.fetchStack[this.fetchStack.length - 1]) {
        this.setState({
          availabilitySearch: {
            error: response.error,
            loading: response.loading,
            networkStatus: response.networkStatus,
            data: response.data || defaultData,
            calendarFirstCellDate: variables.start,
          },
        }, () => {
          // TEMP Performance Hooks, TODO can be safely removed at any time
          addTiming(types.AVAILABILITY, 'Load Complete', true);
        });
      }
    } catch (e) {
      this.handleError('availabilitySearch')(e);
    } finally {
      this.setState({
        availabilitySearchLoading: false,
      });
    }
  };

  fetchAvailabilityTotal = async () => {
    const {
      client,
      variables: {
        partyMembers,
        payload: {
          end,
          start,
        },
        siteCode,
        productCode,
        placeType,
        pitchId,
      },
    } = this.props;

    // TEMP Performance Hooks, TODO can be safely removed at any time
    addTiming(types.CALENDAR, 'Render Complete', true);

    if (!start && !end) {
      const inputDateError = new Error();
      inputDateError.message = dictionaryItem('AvailabilityCalendar', 'Date', 'Missing', 'Error');
      this.setState({ inputDateError });
      return;
    }

    if (start === end || !end) {
      const inputDateError = new Error();
      inputDateError.message = dictionaryItem('AvailabilityCalendar', 'DepartureDate', 'Missing', 'Error');
      this.setState({ inputDateError });
      return;
    }

    this.setState({
      availabilityTotal: {
        ...this.state.availabilityTotal,
        loading: true,
      },
    });

    this.setState({ inputDateError: null });

    const { variables } = getAvailabilityOptions({
      variables: {
        costPerNight: false,
        partyMembers,
        productCode,
        siteCode,
        start,
        end,
        placeType,
        pitchId,
      },
    });

    try {
      const response = await client.query({
        query: GET_AVAILABILITY,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables,
      });

      const { data: { availability: { offers } } } = response;
      await this.props.onCompleted({ offers });
      this.setState({
        availabilityTotal: {
          ...response,
          data: response.data || defaultData,
        },
      });
    } catch (e) {
      this.handleError('availabilityTotal')(e);
    }
  };

  render() {
    const { children } = this.props;
    const {
      availabilitySearch,
      availabilitySearchLoading,
      availabilityTotal,
      inputDateError,
    } = this.state;

    // If there's no error and data then assume it's loading
    const loading = availabilitySearch.loading
      || (!availabilitySearch.error && !availabilitySearch.data);

    return (
      <>
        {children({
          inputDateError,
          availabilitySearch,
          availabilitySearchLoading,
          availabilityTotal,
          loading,
          fetchAvailabilitySearch: this.fetchAvailabilitySearch,
          fetchAvailabilityTotal: this.fetchAvailabilityTotal,
        })}
      </>
    );
  }
}

export default compose(
  withApollo,
  (WrappedComponent) => (props) => (
    // bypass withApollo and use custom ref, this is used to allow parent
    // QuoteCampsite to call fetchAvailabilityTotal externally.
    // eslint-disable-next-line react/prop-types
    <WrappedComponent {...props} ref={props.availabilitySearchQueryRef} />
  ),
)(AvailabilitySearchQuery);

import styled from 'styled-components';

import Icon from '../ui/Icon';
import Text from '../ui/Text';

import { above } from '../../styles/libs';
import { divide, multiply } from '../../styles/libs/calculate';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  min-height: 1.5rem;
  padding: 0.25rem 0;

  ${({ theme }) => above(theme.TABLET)`
    min-height: 2.5rem;
    padding: 0.5rem 0;
  `}
`;

export const NoteIcon = styled(Icon)`
  width: ${({ size }) => multiply(divide(size || '0.75rem', 3), 4)};
  height: ${({ size }) => multiply(divide(size || '0.75rem', 3), 4)};
  margin-right: 0.25rem;
  margin-top: -0.125em;
`;

export const NoteText = styled(Text)`
  color: ${({ color, theme }) => color || theme.COLOR_TEXT_BODY};
  display: block;
  font-size: ${({ size }) => size || '0.875rem'};
  line-height: 1.25;
`;

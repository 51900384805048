import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withProps } from 'recompose';
import { sortBy } from 'lodash/fp';

import { dictionaryItem } from '../../hocs/withDictionary';

import EmptyState from '../EmptyState';
import Checkbox from '../ui/Form/Checkbox';

import SupplementsStyled, {
  Row,
  Supplement,
  Col,
} from './Supplements.style';

// Composable function used for merging selected supplement values with fetched supplements
const mergeArraysBy = (key, values) => supplements => supplements
  .map(item => Object.assign(item, values.find(value => (value[key] === item[key]))));

class Supplements extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    fetchedSupplements: PropTypes.arrayOf(PropTypes.shape({})),
    hideEmpty: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    renderContent: PropTypes.func,
    values: PropTypes.objectOf(PropTypes.shape()),
    numOfNights: PropTypes.number.isRequired,
  };

  static defaultProps = {
    disabled: false,
    fetchedSupplements: [],
    hideEmpty: false,
    name: '',
    onChange() { },
    renderContent() { },
    values: [],
  }

  handleToggle = (event, { quantity, ...supplement }) => {
    const { onChange, values } = this.props;
    const { name } = event.target;

    if (values.has(name)) {
      values.delete(name);
    } else {
      values.set(name, { ...supplement, quantity: quantity || 1 });
    }

    onChange([...values.values()]);
  }

  handleQuantity = (event, uId) => {
    const key = uId;
    const { values } = this.props;
    const { value } = event.target;
    const supplement = values.get(key);

    if (!supplement) return;

    values.set(key, { ...supplement, quantity: value });

    this.props.onChange([...values.values()]);
  }

  render() {
    const {
      disabled,
      hideEmpty,
      fetchedSupplements,
      renderContent,
      values,
    } = this.props;

    // Merge supplements with selected and sort by name
    const formatSupplements = compose(
      sortBy('name'),
      mergeArraysBy('uId', [...values.values()]),
    );

    const supplements = formatSupplements([...fetchedSupplements]);
    if (!hideEmpty && !fetchedSupplements.length) {
      return <EmptyState dictionary={dictionaryItem('EmptyState')} />;
    }

    return (
      <SupplementsStyled>
        {supplements.map((supplement) => {
          const {
            id, uId, quantity, name, totalPrice,
          } = supplement;

          const inputName = `${this.props.name}${uId}`;
          const checked = values.has(inputName);

          return (
            <Supplement key={uId}>
              <Row>
                <Col flex="0 0 auto">
                  <Checkbox
                    checked={checked}
                    disabled={disabled}
                    name={inputName}
                    onChange={event => this.handleToggle(event, {
                      id, quantity, uId, name, totalPrice,
                    })}
                  />
                </Col>
                <Col>
                  {renderContent({
                    ...supplement,
                    // cannot divide by 0, so if falsy divide by 1!
                    numOfNights: this.props.numOfNights || 1,
                    checked,
                    disabled,
                    onQuantity: event => this.handleQuantity(event, inputName),
                  })}
                </Col>
              </Row>
            </Supplement>
          );
        })}
      </SupplementsStyled>
    );
  }
}

const enhancedComponent = withProps(({ values = [], name = '' }) => ({
  // Assign values to a map
  values: new Map(values.map(value => ([`${name}${value.uId}`, { ...value }]))),
}));

export default enhancedComponent(Supplements);

import React from 'react';
import PropTypes from 'prop-types';

import StyledFooter, { Actions } from './Footer.style';
import { ActionClear, ActionSubmit, MobileSubmit } from './Button.style';

const propTypes = {
  handleClear: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool.isRequired,
  'data-testid': PropTypes.string,
};

const defaultProps = {
  'data-testid': null,
  handleClear: null,
};

function Footer(props) {
  return (
    <StyledFooter>
      {!props.fullscreen &&
        <Actions>
          {
          props.handleClear && (
          <ActionClear
            type="button"
            onClick={props.handleClear}
            data-testid={
              props['data-testid'] ? `${props['data-testid']}__clear` : null
            }
          >
            Clear
          </ActionClear>)
          }
          <ActionSubmit
            type="button"
            onClick={props.handleSubmit}
            data-testid={
              props['data-testid'] ? `${props['data-testid']}__apply` : null
            }
          >
            Apply
          </ActionSubmit>
        </Actions>
      }

      {props.fullscreen &&
        <MobileSubmit
          type="button"
          onClick={props.handleSubmit}
          data-testid={
            props['data-testid'] ? `${props['data-testid']}__apply--full-screen` : null
          }
        >
          Apply
        </MobileSubmit>
      }
    </StyledFooter>
  );
}

Footer.defaultProps = defaultProps;
Footer.propTypes = propTypes;

export default Footer;

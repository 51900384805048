import styled from 'styled-components';

import { Row as RowComponent, Col as ColComponent } from '../ui/Grid';

export default styled.div``;

export const Supplement = styled.div`
  & + & {
    margin-top: 1rem;
  }
`;

export const Row = styled(RowComponent)``;

export const Col = styled(ColComponent)``;

import React from 'react';
import PropTypes from 'prop-types';

import Popper from '../ui/Popper';

function MapPopper(props) {
  if (props.isMobile && props.refMap?.current) {
    return (
      <div data-is-mobile-popper-wrapper>
        <Popper
          portal={props.refMap.current}
          open={props.isMobileMapOpen}
        >
          {({ ref }) => props.isMobileMapOpen && props.children({ ref })}
        </Popper>
      </div>
    );
  }

  return props.children();
}

MapPopper.propTypes = {
  isMobileMapOpen: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  refMap: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
};

MapPopper.defaultProps = {
  isMobile: PropTypes.bool,
  refMap: null,
};

export default MapPopper;

import { toUTC } from '../../lib/dates';

export const checkIfEventIsClosedOnDate = (event, date) => {
  const dateTime = toUTC(date).getTime();
  if (event?.openDates?.length) {
    return !event.openDates.some((dataRange) => (
      dateTime >= toUTC(dataRange.fromDate).getTime()
        && dateTime <= toUTC(dataRange.toDate).getTime()
    ));
  }
  return false;
};

export const checkIfEventEndsOnDate = (event, date) => {
  const dateTime = date instanceof Date
    ? date.getTime() : date.toDate().getTime();

  if (event?.openDates?.length) {
    return event.openDates.some((dataRange) => (
      dateTime === new Date(dataRange.toDate).getTime()
    ));
  }

  return false;
};

export const checkIfEventIsClosedOnWithinDateRange = (event, start, end) => {
  if (start && end && event) {
    const startTime = start instanceof Date
      ? start.getTime() : new Date(start).getTime();

    const endTime = start instanceof Date
      ? end.getTime() : new Date(end).getTime();

    if (event?.openDates?.length) {
      return !event.openDates.some((dataRange) => (
        startTime >= new Date(dataRange.fromDate).getTime()
          && endTime <= new Date(dataRange.toDate).getTime()
      ));
    }
  }

  return false;
};

export const getIsOverseas = (router) => (
  Number(router.query?.isOverseas === 'true')
);

export default null;

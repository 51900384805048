import React, { createRef, Component } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { withRouter } from 'next/router';
import { compose, withApollo, graphql } from 'react-apollo';
import { filter } from 'graphql-anywhere';
import memoize from 'memoize-one';

import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';
import testingAttr from '../../lib/testingAttr';
import { LENGTH_STEP, HEIGHT_STEP } from '../../lib/constants';

import { ButtonInfo } from '../ui/Button';
import { Label, Input } from '../ui/Form';
import { TitleAndIconWrapper } from '../Availability/Availability.style';
import OutfitLayout from './OutfitLayout';
import OutfitDetailLayout from './OutfitDetailLayout';
import OutfitLength from './OutfitLength';
import OutfitSelect from './OutfitSelect';
import OutfitType from './OutfitType';

import ErrorTypeMessage from '../ErrorTypeMessage';

import isLoggedIn from '../../lib/isLoggedIn';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import PLACE_EVENT_TYPES from '../../config/eventTypes';
import { isTabletOrSmaller } from '../../lib/helpers/layout';

import svgInfo from '../../static/images/icons/Info.svg';
import IbePropTypes from '../../IbePropTypes';
import LoadingSpinner from '../ui/Loading/LoadingSpinner';
import { VEHICLE_TYPES, TOURING_REQUIRED_TOW, TOW_TYPES } from '../../config/outfits';
import filterOutfitOptions, {
  validateSelectedType, isNoneNullOrUndefined, shouldValidateOutfitLength,
} from '../../lib/helpers/outfits';
import PRODUCT_TYPES from '../../config/products';
import {
  foundTowHeight, foundTowLength, foundVehicleHeight,
  foundVehicleLength, getLowestMaxLengthOrHeightValue,
} from './outfitHelpers';
import { formatMMtoM, formatMToMM } from '../../lib/format';
import { formatMMtoMOutfitDimensions } from '../../lib/availabilityOutfitHelpers';

const propTypes = {
  amend: PropTypes.bool,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  customStyled: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ])),
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  data: PropTypes.shape({
    configuration: PropTypes.shape(IbePropTypes.configuration),
    loading: PropTypes.bool,
    error: PropTypes.shape({}),
  }),
  disabled: PropTypes.bool.isRequired,
  eventType: PropTypes.number,
  isOverseas: PropTypes.bool,
  hasHeight: PropTypes.bool,
  maxOutfitLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
  outfit: PropTypes.shape({
    towHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    towLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    towType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    vehicleReg: PropTypes.string,
    vehicleHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    vehicleLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    vehicleType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  outfitErrors: PropTypes.arrayOf(PropTypes.shape({})),
  disableVehicleHeight: PropTypes.bool,
  disableTowHeight: PropTypes.bool,
  maxFerryHeight: PropTypes.number,
  dataError: PropTypes.shape({}),
  router: PropTypes.shape(IbePropTypes.router).isRequired,
  theme: PropTypes.shape({
    COLOR_WHITE: PropTypes.string,
  }),
  use: PropTypes.string.isRequired,
};

const defaultProps = {
  amend: false,
  customStyled: '',
  data: {
    loading: false,
    error: null,
    configuration: null,
  },
  eventType: PLACE_EVENT_TYPES.TOURING.id,
  isOverseas: false,
  hasHeight: false,
  maxOutfitLength: null,
  maxFerryHeight: null,
  onBlur() { },
  outfit: null,
  disableVehicleHeight: false,
  disableTowHeight: false,
  outfitErrors: [],
  dataError: null,
  onError: () => null,
  theme: {},
};

const defaultOutfit = {
  vehicleHeight: null,
  vehicleLength: null,
  vehicleMake: null,
  vehicleModel: null,
  vehicleReg: null,
  vehicleType: '',
};

const defaultTow = {
  towHeight: null,
  towLength: null,
  towMake: null,
  towModel: null,
  towType: 0,
};

class OutfitForm extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  state = {
    error: false,
    popOverVehicle: '',
    popOverTow: '',
    vehicleLengthInputValueInM: '',
    vehicleHeightInputValueInM: '',
    towLengthInputValueInM: '',
    towHeightInputValueInM: '',
  }

  popOverVehicleRef = createRef();

  popOverVehicleIcon = createRef();

  popOverVehicleLengthRef = createRef();

  popOverVehicleHeightRef = createRef();

  popOverTowRef = createRef();

  popOverTowIcon = createRef();

  popOverTowLengthRef = createRef();

  popOverTowHeightRef = createRef();

  // Main input validation checks - note handleChange has immediate validation and
  // availability.js in validation checks required fields
  validateInput = memoize(
    (
      maxLength = null,
      towLength = null,
      vehicleLength = null,
      towHeight = null,
      vehicleHeight = null,
      vehicleType = null,
      towType = null,
      defaultMaxVehicleOverallLength = null,
      client,
      maxFerryHeight,
    ) => {
      if (![
        maxLength, towLength, vehicleLength, towHeight,
        vehicleHeight, vehicleType, towType, defaultMaxVehicleOverallLength,
      ].some(val => val !== null)) return false;

      const errorArr = [];

      // Numeric and max 2 decimal check - shouldn't happen as handleChange
      // stops incorrect values, but there as fallback
      const valuesToCheck = {
        towL: towLength,
        vehL: vehicleLength,
        maxL: maxLength,
        towH: towHeight,
        vehH: vehicleHeight,
      };
      const fieldsToValidate = {
        towL: 'towLength',
        vehL: 'vehicleLength',
        maxL: 'maxOutfitLength',
        towH: 'towHeight',
        vehH: 'vehicleHeight',
      };

      Object.entries(valuesToCheck).forEach(([key, value]) => {
        const valueInM = formatMMtoM(value);
        if (valueInM && this.validateNumericOnlyTwoDecimalsMax(valueInM)) {
          const type = fieldsToValidate[key];
          errorArr.push({
            id: uuid(),
            type,
            message: getDictionaryItem(client, 'OutfitForm__NumericOnly__Error__Text'),
          });
        }
      });

      if (this.props.hasHeight) {
        const heightErrors = this.validateHeight(
          towHeight, vehicleHeight, vehicleType, towType, maxFerryHeight,
        );
        heightErrors.forEach(({
          heightErrorFieldType, isHeightError, messageKey,
        }) => {
          if (isHeightError) {
            const message = getDictionaryItem(client, messageKey);
            errorArr.push({
              id: uuid(),
              type: heightErrorFieldType,
              message,
            });
          }
        });
      }

      const lengthErrors = this.validateLength(
        maxLength, towLength, vehicleLength, vehicleType,
        towType, defaultMaxVehicleOverallLength,
      );
      lengthErrors.forEach(({
        lengthErrorFieldType,
        isLengthError,
        messageKey,
      }) => {
        if (isLengthError) {
          const message = getDictionaryItem(client, messageKey);
          errorArr.push({
            id: uuid(),
            type: lengthErrorFieldType,
            message,
          });
        }
      });
      return this.props.onError(errorArr);
    },
  );

  componentDidMount() {
    const { outfit } = this.props;
    if (!outfit) return;
    this.setState({
      vehicleLengthInputValueInM: formatMMtoM(outfit.vehicleLength) || '',
      vehicleHeightInputValueInM: formatMMtoM(outfit.vehicleHeight) || '',
      towLengthInputValueInM: formatMMtoM(outfit.towLength) || '',
      towHeightInputValueInM: formatMMtoM(outfit.towHeight) || '',
    });
  }

  componentDidUpdate(prevProps) {
    const {
      data, outfit, isOverseas, eventType, use,
    } = this.props;
    if (!outfit || !data.configuration) return;

    this.validateInput(
      this.props.maxOutfitLength,
      this.props.outfit.towLength,
      this.props.outfit.vehicleLength,
      this.props.outfit.towHeight,
      this.props.outfit.vehicleHeight,
      this.props.outfit.vehicleType,
      this.props.outfit.towType,
      data.configuration.defaultMaxVehicleOverallLength,
      this.props.client,
      this.props.maxFerryHeight,
    );

    if (prevProps.eventType && (prevProps.eventType !== this.props.eventType)) {
      const { vehicleTypes = [] } = data.configuration;
      const productCode =
        isOverseas ? PRODUCT_TYPES.CAMC_MEMBER_PRODUCT : PRODUCT_TYPES.CAMC_UK_PRODUCT;
      const filteredVehicleTypes =
        filterOutfitOptions(vehicleTypes, productCode, eventType, use);
      const selectedVehicleType = filteredVehicleTypes.find((vehicleType) => (
        vehicleType.key === outfit?.vehicleType
      ));
      const defaultVehicleType = filteredVehicleTypes[0];

      this.handleChange({
        target: {
          name: 'vehicleType',
          value: selectedVehicleType?.key ?? defaultVehicleType.key,
        },
      });
    }
  }

  validateHeight = (
    userInputtedTowHeight,
    userInputtedVehicleHeight,
    vehicleType,
    towType,
    maxFerryHeight,
  ) => {
    const { towTypes, vehicleTypes } = this.props.data.configuration;
    const heightErrors = [];

    const foundVehicleType = foundVehicleHeight(vehicleType, vehicleTypes);
    const minVehicleHeightAllowed = foundVehicleType?.minHeight;
    // max default ferry height?
    const maxVehicleHeightAllowed = foundVehicleType?.maxHeight;
    const vehicleIsTooLow = userInputtedVehicleHeight
      ? userInputtedVehicleHeight < minVehicleHeightAllowed
      : false;
    const vehicleIsTooHigh = userInputtedVehicleHeight
      ? userInputtedVehicleHeight > maxVehicleHeightAllowed
      : false;

    const foundTowType = foundTowHeight(towType, towTypes);
    const minTowHeightAllowed = foundTowType?.minHeight;
    const maxTowHeightAllowed = getLowestMaxLengthOrHeightValue(
      foundTowType?.maxHeight, maxFerryHeight,
    );
    const towIsTooLow = userInputtedTowHeight
      ? userInputtedTowHeight < minTowHeightAllowed
      : false;
    const towIsTooHigh = userInputtedTowHeight
      ? userInputtedTowHeight > maxTowHeightAllowed
      : false;

    if (vehicleIsTooLow || vehicleIsTooHigh) {
      const vehicleReplacements = {
        VehicleOrTowType: foundVehicleType?.value,
        MinHeight: formatMMtoMOutfitDimensions(minVehicleHeightAllowed, true),
        MaxHeight: formatMMtoMOutfitDimensions(maxVehicleHeightAllowed, true),
      };
      const messageKey = vehicleIsTooHigh
        ? 'OutfitForm__Height__TooHigh__Error__Text'
        : 'OutfitForm__Height__TooLow__Error__Text';

      heightErrors.push({
        id: uuid(),
        heightErrorFieldType: 'vehicleHeight',
        isHeightError: true,
        replacements: vehicleReplacements,
        messageKey,
      });
    }
    if (towIsTooLow || towIsTooHigh) {
      const towReplacements = {
        VehicleOrTowType: foundTowType?.value,
        MinHeight: formatMMtoMOutfitDimensions(minTowHeightAllowed, true),
        MaxHeight: formatMMtoMOutfitDimensions(maxTowHeightAllowed, true),
      };
      const messageKey = towIsTooHigh
        ? 'OutfitForm__Height__TooHigh__Error__Text'
        : 'OutfitForm__Height__TooLow__Error__Text';

      heightErrors.push({
        id: uuid(),
        heightErrorFieldType: 'towHeight',
        isHeightError: true,
        replacements: towReplacements,
        messageKey,
      });
    }
    return heightErrors;
  };

  validateLength = (
    maxOutfitLengthFromPitch, userInputtedTowLength, userInputtedVehicleLength,
    vehicleType, towType, defaultMaxVehicleOverallLength,
  ) => {
    if (Number.isNaN(maxOutfitLengthFromPitch)) return false;
    const { towTypes, vehicleTypes } = this.props.data.configuration;
    const lengthErrors = [];

    // Determine if the current context involves a ferry crossing.
    // `maxFerryHeight` is used as a proxy flag to indicate ferry situations.
    const isFerry = this.props.maxFerryHeight;

    const {
      doesVehicleNeedToFitOnPitch, foundVehicleType,
    } = foundVehicleLength(vehicleType, vehicleTypes);
    // We should ignore the max length of a PITCH only
    // if it does NOT need to fit on a pitch (!doesVehicleNeedToFitOnPitch)
    // or if it is going on a ferry (isFerry)
    const ignoreMaxVehicleLengthFromPitch = !doesVehicleNeedToFitOnPitch || isFerry;

    const minVehicleLengthAllowed = foundVehicleType?.minLength;
    const maxVehicleLengthAllowed = getLowestMaxLengthOrHeightValue(
      foundVehicleType?.maxLength,
      maxOutfitLengthFromPitch,
      ignoreMaxVehicleLengthFromPitch,
      defaultMaxVehicleOverallLength,
    );

    // null indicates we bypass validation
    const effectiveVehicleLength = userInputtedVehicleLength || null;

    const vehicleIsTooShort = effectiveVehicleLength
      ? effectiveVehicleLength < minVehicleLengthAllowed
      : false;
    const vehicleIsTooLong = effectiveVehicleLength
      ? effectiveVehicleLength > maxVehicleLengthAllowed
      : false;

    if (vehicleIsTooShort || vehicleIsTooLong) {
      const vehicleReplacements = {
        VehicleOrTowType: foundVehicleType?.value,
        MinLength: formatMMtoMOutfitDimensions(minVehicleLengthAllowed, true),
        MaxLength: formatMMtoMOutfitDimensions(maxVehicleLengthAllowed, true),
      };
      const messageKey = vehicleIsTooLong
        ? 'OutfitForm__Length__TooLong__Error__Text'
        : 'OutfitForm__Length__TooShort__Error__Text';

      lengthErrors.push({
        id: uuid(),
        lengthErrorFieldType: 'vehicleLength',
        isLengthError: true,
        replacements: vehicleReplacements,
        messageKey,
      });
    }

    const { doesTowNeedToFitOnPitch, foundTowType } = foundTowLength(towType, towTypes);

    // We should ignore the max length of a PITCH only
    // if it does NOT need to fit on a pitch (!doesTowNeedToFitOnPitch)
    // or if it is going on a ferry (isFerry)
    const ignoreMaxTowLengthFromPitch = !doesTowNeedToFitOnPitch || isFerry;

    // null indicates we bypass validation.
    // We do not handle validation on required inputs here.
    // See validation/availability.js
    const effectiveTowLength = userInputtedTowLength || null;

    const minTowLengthAllowed = foundTowType?.minLength;
    const maxTowLengthAllowed = getLowestMaxLengthOrHeightValue(
      foundTowType?.maxLength, maxOutfitLengthFromPitch, ignoreMaxTowLengthFromPitch,
    );

    const towIsTooShort = effectiveTowLength
      ? effectiveTowLength < minTowLengthAllowed
      : false;

    const towIsTooLong = effectiveTowLength
      ? effectiveTowLength > maxTowLengthAllowed
      : false;

    if (towIsTooShort || towIsTooLong) {
      const towReplacements = {
        VehicleOrTowType: foundTowType?.value,
        MinLength: formatMMtoMOutfitDimensions(minTowLengthAllowed, true),
        MaxLength: formatMMtoMOutfitDimensions(maxTowLengthAllowed, true),
      };
      const messageKey = towIsTooLong
        ? 'OutfitForm__Length__TooLong__Error__Text'
        : 'OutfitForm__Length__TooShort__Error__Text';

      lengthErrors.push({
        id: uuid(),
        lengthErrorFieldType: 'towLength',
        isLengthError: true,
        replacements: towReplacements,
        messageKey,
      });
    }
    return lengthErrors;
  }

  // @Params: value: number
  // @Returns: boolean
  validateNumericOnlyTwoDecimalsMax = (value) => {
    if (!value) return undefined;
    const numericOnlyMaxTwoDecimalsRegex = /^\d+(\.\d{1,2})?$/;
    if (!value.toString().match(numericOnlyMaxTwoDecimalsRegex)) {
      return true;
    } return false;
  }

  handleChange = ({ target: { name, value } }) => {
    const {
      eventType = PLACE_EVENT_TYPES.TOURING.id,
      isOverseas,
      data: { configuration },
      use,
    } = this.props;
    let outfit = { ...this.props.outfit };

    // Validation here is to provide instant UX feedback. It is however overridden by validateInput.
    const errorList = [];
    let valueAmount = value;

    // Fail-safe validation for numeric/decimals (mostly handled in outfitLength)
    // then convert to mm for payload.
    if (
      name === 'vehicleLength'
      || name === 'towLength'
      || name === 'vehicleHeight'
      || name === 'towHeight'
    ) {
      const isNumericAndMaxTwoDecimalsOnlyError = this.validateNumericOnlyTwoDecimalsMax(
        valueAmount,
      );
      if (isNumericAndMaxTwoDecimalsOnlyError) {
        errorList.push({
          id: uuid(),
          type: 'NumericOnly',
          message: getDictionaryItem(
            this.props.client, 'OutfitForm__NumericOnly__Error__Text',
          ),
        });
      }
      const valueInMm = formatMToMM(valueAmount) || null;
      valueAmount = valueInMm;
    }

    if (errorList.length > 0) {
      this.props.onError(errorList);
    }

    // 2. Reset local state for input value on outfit type change to ensure
    // less chance of mistake on incorrect length, however see more notes below relating to
    // CAMC23UAT-226 we want or need to reset vehicle dimensions when form hasBookings
    // in edit mode. This can lead to an un-submittable form.

    if (name === 'vehicleType' && !this.props.disabled) {
      this.setState({
        vehicleLengthInputValueInM: '',
        vehicleHeightInputValueInM: '',
      });
    }
    if (name === 'towType') {
      this.setState({
        towLengthInputValueInM: '',
        towHeightInputValueInM: '',
      });
    }

    // 3. Build form payload
    const vehicleTypeValue = Number(name === 'vehicleType' ? valueAmount : outfit.vehicleType);
    const vanOrCarRooftent =
      vehicleTypeValue === VEHICLE_TYPES.CAR_ROOF_TENT ||
      vehicleTypeValue === VEHICLE_TYPES.VAN_ROOF_TENT ||
      vehicleTypeValue === VEHICLE_TYPES.ROOF_TENT;
    const noCar = vehicleTypeValue === VEHICLE_TYPES.NO_CAR;
    const defaultTowType = vanOrCarRooftent ? TOW_TYPES.NONE : TOW_TYPES.TENT;
    const isCamping = eventType === PLACE_EVENT_TYPES.CAMPING.id;
    const lengthRequired = !isCamping || isOverseas || vanOrCarRooftent;

    const { towTypes = [], vehicleTypes = [] } = configuration;
    const productCode =
      isOverseas ? PRODUCT_TYPES.CAMC_MEMBER_PRODUCT : PRODUCT_TYPES.CAMC_UK_PRODUCT;
    const filteredTowTypes =
      filterOutfitOptions(towTypes, productCode, eventType, use, vehicleTypeValue, vehicleTypes);
    const showRegistrationField = !!vehicleTypeValue &&
      vehicleTypeValue !== VEHICLE_TYPES.NO_CAR &&
      isCamping;
    const registrationRequired = (this.props.amend && vehicleTypeValue !== VEHICLE_TYPES.NO_CAR) ||
      (isCamping && vehicleTypeValue !== VEHICLE_TYPES.NO_CAR);
    outfit.registrationRequired = registrationRequired;

    const towRequired = (isOverseas && vehicleTypeValue === VEHICLE_TYPES.CAR) ||
      (eventType === PLACE_EVENT_TYPES.TOURING.id &&
        TOURING_REQUIRED_TOW.includes(vehicleTypeValue));
    const towType = filteredTowTypes.find(t => t.key === Number(outfit.towType)) ?
      outfit.towType : filteredTowTypes[0]?.key;

    if (name === 'vehicleType') {
      let vehicleLength = null;
      let vehicleReg = showRegistrationField && outfit.vehicleReg ? outfit.vehicleReg : null;

      // CAMC23UAT-226
      // an edge case here to persist vehicleLength and vehicleReg when changing event type when
      // disabled due to hasBooking when editing. This is because the resetting of any value can
      // lead to the form being un-submittable due to the fact the fields can't be edited.
      // Roof tent currently is the only vehicle type that can exist across event types.
      if (this.props.disabled && vanOrCarRooftent) {
        vehicleLength = outfit.vehicleLength;
        vehicleReg = outfit.vehicleReg;
      }

      outfit = {
        ...outfit,
        ...defaultOutfit,
        ...(outfit.externalId && outfit.towType === TOW_TYPES.NONE) && { externalId: '' },
        [name]: valueAmount,
        towRequired,
        lengthRequired,
        towType: isCamping ? defaultTowType : towType,
        vehicleHeight: null,
        vehicleLength,
        vehicleMake: noCar ? null : outfit.vehicleMake,
        vehicleModel: noCar ? null : outfit.vehicleModel,
        vehicleReg,
        towLength: isCamping ? null : outfit.towLength,
      };
    } else if (name === 'towType') {
      outfit = {
        ...outfit,
        defaultTow,
        ...(outfit.externalId && outfit.vehicleType === VEHICLE_TYPES.NONE) && { externalId: '' },
        [name]: valueAmount,
        towLength: null,
        towHeight: null,
        towMake: null,
        towModel: null,
      };
    } else {
      outfit[name] = valueAmount;
    }

    this.props.onChange(outfit, name);
  }

  handlePredefinedType = (outfit, event, vehicleTypes, towTypes) => {
    const { name } = event.target;

    const validOutfit = validateSelectedType(outfit, vehicleTypes, towTypes);

    if (name.includes('Type')) {
      this.props.onError(this.props.outfitErrors.filter(oE => oE.type !== 'vehicleType'));
    }

    this.setState({
      vehicleLengthInputValueInM: formatMMtoM(validOutfit.vehicleLength) || '',
      vehicleHeightInputValueInM: formatMMtoM(validOutfit.vehicleHeight) || '',
      towLengthInputValueInM: formatMMtoM(validOutfit.towLength) || '',
      towHeightInputValueInM: formatMMtoM(validOutfit.towHeight) || '',
    });

    this.props.onChange(validOutfit, name);
  }

  handleError = (error) => {
    this.setState({ error });
    this.props.onError(error);
  }

  togglePopOver = (helpBubble, text) => {
    this.setState({
      [helpBubble]: text,
    });
  }

  setPopUpText = (helpBubble, text) => {
    this.togglePopOver(helpBubble, text);
  }

  handlePopUpText = (key, name) => {
    this.setPopUpText(key, dictionaryItem('OutfitForm', this.props.use, 'PopOver', name));
  }

  render() {
    const {
      amend,
      customStyled,
      disabled,
      data: { error, loading, configuration },
      onBlur,
      hasHeight,
      outfit,
      disableTowHeight,
      disableVehicleHeight,
      theme,
      client,
      eventType = PLACE_EVENT_TYPES.TOURING.id,
      isOverseas,
      use,
    } = this.props;
    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorTypeMessage error={error} />;

    const form = outfit || {};
    const { towTypes = [], vehicleTypes = [] } = configuration;
    const productCode =
      isOverseas ? PRODUCT_TYPES.CAMC_MEMBER_PRODUCT : PRODUCT_TYPES.CAMC_UK_PRODUCT;
    const filteredVehicleTypes =
      filterOutfitOptions(vehicleTypes, productCode, eventType, use);
    const filteredTowTypes =
      filterOutfitOptions(towTypes, productCode, eventType, use, form.vehicleType, vehicleTypes);

    const isUserLoggedIn = isLoggedIn();

    const isCamping = eventType === PLACE_EVENT_TYPES.CAMPING.id;
    const vehicleLabelKey = isCamping
      ? 'OutfitForm__VehicleType__UK__Text'
      : 'OutfitForm__VehicleType__Overseas__Text';
    const noCar = form.vehicleType === VEHICLE_TYPES.NO_CAR;

    const showRegistrationField = (!isNoneNullOrUndefined(form.vehicleType)) &&
      form.vehicleType !== VEHICLE_TYPES.NO_CAR &&
      isCamping;

    const showLengthField =
      form.vehicleType === VEHICLE_TYPES.CAR_ROOF_TENT ||
      form.vehicleType === VEHICLE_TYPES.VAN_ROOF_TENT ||
      form.vehicleType === VEHICLE_TYPES.ROOF_TENT ||
      shouldValidateOutfitLength(
        form.vehicleType,
        true,
        isOverseas,
        configuration,
        eventType,
      );
    return (
      <>
        {isUserLoggedIn && (isOverseas || !isCamping) &&
          <OutfitSelect
            customStyled={customStyled}
            disabled={disabled}
            onChange={(selectedOutfit, event) => this.handlePredefinedType(
              selectedOutfit, event, filteredVehicleTypes, filteredTowTypes,
            )}
            towTypes={filter(OutfitSelect.fragments.outfitTypes, towTypes)}
            vehicleTypes={filter(OutfitSelect.fragments.outfitTypes, vehicleTypes)}
            value={form.externalId}
            hasHeight={hasHeight}
            name="vehicleType"
          />
        }

        <OutfitLayout
          amend={amend}
          outfitType={
            <OutfitType
              customStyled={customStyled}
              disabled={disabled}
              name="vehicleType"
              types={filteredVehicleTypes}
              label={getDictionaryItem(client, vehicleLabelKey)}
              onBlur={onBlur}
              onChange={this.handleChange}
              value={form.vehicleType}
              dataError={this.props.outfitErrors.some(err => err.type === 'outfit')}
              showPlaceholder
              responsiveHeight={showRegistrationField && showLengthField}
              {...testingAttr('vehicle-type')}
            />
          }
          onTogglePopOver={() => setTimeout(() => this.togglePopOver('popOverVehicle', ''), 0)}
          popOverText={this.state.popOverVehicle}
          renderOutfitLength={() => (
            <>
              <TitleAndIconWrapper>
                <Label responsiveHeight={showRegistrationField && showLengthField}>
                  {getDictionaryItem(client, 'OutfitForm__CarLength__Text')}
                </Label>
                {isTabletOrSmaller() &&
                  <ButtonInfo
                    ariaLabel="Information"
                    color={theme.COLOR_WHITE}
                    icon={svgInfo}
                    marginLeft="0.5rem"
                    onClick={() => this.handlePopUpText('popOverVehicle', 'VehicleLength')}
                    noPadding
                    type="button"
                  />
                }
              </TitleAndIconWrapper>
              <OutfitLength
                customStyled={customStyled}
                disabled={disabled}
                error={this.state.error}
                name="vehicleLength"
                onBlur={onBlur}
                onChange={this.handleChange}
                onInputChange={(value) => this.setState({ vehicleLengthInputValueInM: value })}
                value={this.state.vehicleLengthInputValueInM}
                onError={this.validateInput}
                onFocus={() => this.handlePopUpText('popOverVehicle', 'VehicleLength')}
                step={LENGTH_STEP}
                dataError={this.props.outfitErrors.some(
                  err => err.type === 'vehicleLength'
                    || err.type === 'length',
                )}
                popOverRef={this.popOverVehicleLengthRef}
                {...testingAttr('vehicle-length')}
              />
            </>
          )}
          renderOutfitHeight={() => (
            <>
              <TitleAndIconWrapper>
                <Label>{getDictionaryItem(client, 'OutfitForm__CarHeight__Text')}</Label>
                {isTabletOrSmaller() &&
                  <ButtonInfo
                    ariaLabel="Information"
                    color={theme.COLOR_WHITE}
                    icon={svgInfo}
                    marginLeft="0.5rem"
                    onClick={() => this.handlePopUpText('popOverVehicle', 'VehicleHeight')}
                    noPadding
                    type="button"
                  />
                }
              </TitleAndIconWrapper>
              <OutfitLength
                customStyled={customStyled}
                disabled={disableVehicleHeight}
                label="Height"
                name="vehicleHeight"
                onBlur={onBlur}
                onChange={this.handleChange}
                onInputChange={(value) => this.setState({ vehicleHeightInputValueInM: value })}
                value={this.state.vehicleHeightInputValueInM}
                onError={this.validateInput}
                onFocus={() => this.handlePopUpText('popOverVehicle', 'VehicleHeight')}
                step={HEIGHT_STEP}
                placeholder="Height (m)"
                {...testingAttr('vehicle-height')}
              />
            </>
          )}
          renderOutfitRegistration={() => (
            <>
              <TitleAndIconWrapper>
                <Label responsiveHeight={showRegistrationField && showLengthField}>
                  {getDictionaryItem(client, 'OutfitForm__Registration__Text')}
                </Label>
              </TitleAndIconWrapper>
              <Input
                block
                placeholder={getDictionaryItem(client, 'OutfitForm__Registration__Placeholder__Text')}
                disabled={disabled}
                label={getDictionaryItem(client, 'OutfitForm__Registration__Text')}
                name="vehicleReg"
                onBlur={onBlur}
                onChange={this.handleChange}
                value={!form.vehicleReg ? '' : form.vehicleReg}
                error={this.props.outfitErrors.some(err => err.type === 'registration')}
                {...testingAttr('vehicleReg')}
              />
            </>
          )}
          showRegistrationField={showRegistrationField}
          showHeightField={hasHeight}
          showLengthFields={
            showLengthField &&
            (!isNoneNullOrUndefined(form.vehicleType)) && !isCamping}
          showLengthField={showLengthField}
        />

        {!isCamping && <OutfitLayout
          heightRef={this.popOverTowHeightRef}
          rowRef={this.popOverTowRef}
          iconRef={this.popOverTowIcon}
          onTogglePopOver={() => this.togglePopOver('popOverTow', '')}
          popOverText={this.state.popOverTow}
          outfitType={
            <OutfitType
              customStyled={customStyled}
              disabled={disabled}
              name="towType"
              types={filteredTowTypes}
              label="Towing"
              onBlur={onBlur}
              onChange={this.handleChange}
              onError={this.handleError}
              value={form.towType}
              dataError={!!this.props.dataError}
              showPlaceholder
              {...testingAttr('tow-type')}
            />
          }
          renderOutfitLength={() => (
            <>
              <TitleAndIconWrapper>
                <Label responsiveHeight={showRegistrationField && showLengthField}>
                  {getDictionaryItem(client, 'OutfitForm__CarLength__Text')}
                </Label>
                {isTabletOrSmaller() &&
                  <ButtonInfo
                    ariaLabel="Information"
                    color={theme.COLOR_WHITE}
                    icon={svgInfo}
                    marginLeft="0.5rem"
                    onClick={() => this.handlePopUpText('popOverTow', 'TowLength')}
                    noPadding
                    type="button"
                  />
                }
              </TitleAndIconWrapper>
              <OutfitLength
                customStyled={customStyled}
                disabled={disabled}
                error={this.state.error}
                label="Length"
                name="towLength"
                onBlur={onBlur}
                onChange={this.handleChange}
                onInputChange={(value) => this.setState({ towLengthInputValueInM: value })}
                value={this.state.towLengthInputValueInM}
                onError={this.validateInput}
                onFocus={() => this.handlePopUpText('popOverTow', 'TowLength')}
                dataError={this.props.outfitErrors.some(
                  err => err.type === 'towLength' || err.type === 'length',
                )}
                popOverRef={this.popOverTowLengthRef}
                {...testingAttr('tow-length')}
              />
            </>
          )}
          renderOutfitHeight={() => (
            <>
              <TitleAndIconWrapper>
                <Label>{getDictionaryItem(client, 'OutfitForm__CarHeight__Text')}</Label>
                {isTabletOrSmaller() &&
                  <ButtonInfo
                    ariaLabel="Information"
                    color={theme.COLOR_WHITE}
                    icon={svgInfo}
                    marginLeft="0.5rem"
                    onClick={() => this.handlePopUpText('popOverTow', 'TowHeight')}
                    noPadding
                    type="button"
                  />
                }
              </TitleAndIconWrapper>
              <OutfitLength
                customStyled={customStyled}
                disabled={disableTowHeight}
                label="Height"
                name="towHeight"
                onBlur={onBlur}
                onError={this.handleError}
                onChange={this.handleChange}
                onInputChange={(value) => this.setState({ towHeightInputValueInM: value })}
                value={this.state.towHeightInputValueInM}
                onFocus={() => this.handlePopUpText('popOverTow', 'TowHeight')}
                placeholder="Height (m)"
                {...testingAttr('tow-height')}
              />
            </>
          )}
          showHeightField={hasHeight}
          showLengthFields={!!form.towType}
        />}

        {amend &&
          <OutfitDetailLayout
            renderVehicleMake={noCar && !showLengthField ? null : () => (
              <>
                <TitleAndIconWrapper>
                  <Label>{getDictionaryItem(client, 'OutfitForm__Make__Text')}</Label>
                </TitleAndIconWrapper>
                <Input
                  block
                  disabled={disabled}
                  label={getDictionaryItem(client, 'OutfitForm__Make__Text')}
                  name="vehicleMake"
                  onBlur={onBlur}
                  onChange={this.handleChange}
                  value={form.vehicleMake}
                  {...testingAttr('vehicleMake')}
                />
              </>
            )}
            renderVehicleModel={noCar && !showLengthField ? null : () => (
              <>
                <TitleAndIconWrapper>
                  <Label>{getDictionaryItem(client, 'OutfitForm__Model__Text')}</Label>
                </TitleAndIconWrapper>
                <Input
                  block
                  disabled={disabled}
                  label={getDictionaryItem(client, 'OutfitForm__Model__Text')}
                  name="vehicleModel"
                  onBlur={onBlur}
                  onChange={this.handleChange}
                  value={form.vehicleModel}
                  {...testingAttr('vehicleModel')}
                />
              </>
            )}
            renderVehicleRegistration={() => (
              !isCamping &&
              <>
                <TitleAndIconWrapper>
                  <Label>{getDictionaryItem(client, 'OutfitForm__Registration__Text')}</Label>
                </TitleAndIconWrapper>
                <Input
                  block
                  placeholder={getDictionaryItem(client, 'OutfitForm__Registration__Placeholder__Text')}
                  disabled={disabled}
                  label={getDictionaryItem(client, 'OutfitForm__Registration__Text')}
                  name="vehicleReg"
                  onBlur={onBlur}
                  onChange={this.handleChange}
                  value={!form.vehicleReg ? '' : form.vehicleReg}
                  error={this.props.outfitErrors.some(err => err.type === 'registration')}
                  {...testingAttr('vehicleReg')}
                />
              </>
            )}
          />
        }
      </>
    );
  }
}

export default compose(
  withRouter,
  withTheme,
  withApollo,
  graphql(GET_CONFIGURATION),
)(OutfitForm);

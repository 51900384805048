import moment from 'moment';
import { stringify } from 'query-string';
import { partyConfig } from '../config/personType';

import dp from '../components/ui/Datepicker/datepicker';
import { formatToHyphenFormat } from './format';
import { sortObjectsByKey } from './sortArrayOfObjects';
import AVAILABILITY_TYPES from '../config/availabilityTypes';
import { differenceInDays } from './dates';

const mapPartyTypes = {
  0: 'adult',
  1: 'child',
  2: 'infant',
  3: 'pet',
};

const partyTypeMap = new Map();
partyConfig.forEach(party => partyTypeMap.set(party.type, party));

export function stringifyParty(party, partyOptions) {
  // eg. '&childAges=6&infantAges=2'
  // if minimum age was not provided, send default minimum age.
  // eg. for child it will be 3 for infant it will be 2.
  const agesString = party.filter(({ age, type }) => (type !== 0 && typeof age !== 'undefined'))
    .reduce((acc, cur) => `${acc}&${mapPartyTypes[cur.type]}Ages=${cur.age || partyTypeMap.get(cur.type).fromAge}`, '');

  // Map party type lengths to query string parameters
  const typeLengths = {
    numAdults: party.filter(({ type }) => type === 0).length,
    numChildren: party.filter(({ type }) => type === 1).length,
    numInfants: party.filter(({ type }) => type === 2).length,
    numPets: party.filter(({ type }) => type === 3).length,
  };

  // eg. '&numAdults=2&numChildren=1'
  const typesString = Object.keys(typeLengths)
    .filter(type => typeLengths[type])
    .reduce((acc, cur) => `${acc}&${cur}=${typeLengths[cur]}`, '');

  return `${typesString}${agesString}`;
}

export function getPitchHasAvailabilityForDates(
  start,
  end,
  calendarStart,
  availabilityArray,
) {
  if (availabilityArray && availabilityArray.length && start && end) {
    const startIndex = differenceInDays(start, calendarStart);
    const endIndex = differenceInDays(end, calendarStart);

    // we check all night for availability except the last, as this is the day you leave on.
    for (let i = startIndex; i < endIndex + 1; i += 1) {
      if (!availabilityArray[i]) {
        return false;
      }
    }

    return true;
  }

  return false;
}

export function getCalendarStartAndEndForStartDate(date) {
  // Use either the selected date or today
  const startDate = date || null;
  const momentStartDate = startDate ? moment(formatToHyphenFormat(startDate)) : moment();

  /**
   * We need to send the entire calendar month - including the remainder days from the
   * adjacent months - to return all the prices for the availability calendar
   */
  const calendarStart = moment(momentStartDate).startOf('month');
  const calendar = dp().getCalendar(calendarStart);

  // Get the start and end dates from the calendar
  const start = calendar.cells[0].date.format('YYYY-MM-DD');
  const end = calendar.cells.pop().date.format('YYYY-MM-DD');

  return { start, end };
}

/**
 * @summary Parse AvailabilitySearch options for gql query
 * @param {Object} options
 * @param {Object} options.variables
 * @param {...Object} options
 * @return {Object} The options
 */
export default function Availability({ variables = {}, ...options } = {}) {
  /**
   * Build the path for link-rest
   * @param {Object} variables
   * @param {Object} variables.partyMembers
   * @param {Object} variables.partyOptions
   * @param {...Object} query
   * @return {string} The rest API route
   */
  function pathBuilder({ args: { partyMembers, partyOptions, ...query } }) {
    const partyString = stringifyParty(partyMembers, partyOptions);
    const queryString = stringify(query);

    const searchType = query.pitchTypeId ? 'Details' : 'Search';

    return `Reservations/Availability${searchType}?${queryString}${partyString}`;
  }

  let { start = null, end = null } = variables;

  // If costPerNight is true then it's a calendar AvailabilitySearch request
  if (variables.costPerNight) {
    const calendarStartEndDates = getCalendarStartAndEndForStartDate(start);

    start = calendarStartEndDates.start;
    end = calendarStartEndDates.end;
  }

  return {
    variables: {
      pathBuilder,
      ...variables,
      start,
      end,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    ...options,
  };
}

export function parseVariables(variables = {}, path = '') {
  function pathBuilder({ args: { partyMembers, partyOptions, ...query } }) {
    const partyString = stringifyParty(partyMembers, partyOptions);
    const queryString = stringify(query);
    return `${path}?${queryString}${partyString}`;
  }

  const { start = null, end = null } = variables;

  return {
    pathBuilder,
    ...variables,
    start,
    end,
  };
}

export const PITCH_SORT_TYPES = {
  PRICE_ASC: 'PRICE_ASC', // default if no prioity is set
  PRICE_DSC: 'PRICE_DSC',
  PRICE_DEFAULT: 'PRICE_DEFAULT',
};

export const PITCH_SORT_LABELS = {
  [PITCH_SORT_TYPES.PRICE_ASC]: 'AvailabilityPitches__SortPriceAscending__Option',
  [PITCH_SORT_TYPES.PRICE_DSC]: 'AvailabilityPitches__SortPriceDescending__Option',
  [PITCH_SORT_TYPES.PRICE_DEFAULT]: 'AvailabilityPitches__SortPriceDefault__Option',
};

function haveSortPriority(pitches) {
  return !!pitches.find(pitch => pitch.prioritySortOrder > 0);
}

export function getDefaultSort(pitches = []) {
  return haveSortPriority(pitches) ? PITCH_SORT_TYPES.PRICE_DEFAULT : PITCH_SORT_TYPES.PRICE_ASC;
}

export function sortPitches(pitches = [], key = 'minPricePerNight', sortType) {
  const activeSortType = sortType ?? getDefaultSort(pitches);
  const pitchesHaveSortPriority = haveSortPriority(pitches);
  if (activeSortType === PITCH_SORT_TYPES.PRICE_DEFAULT && pitchesHaveSortPriority) {
    return sortObjectsByKey(pitches, 'prioritySortOrder');
  }
  const sortedPitches =
    sortObjectsByKey(pitches, key, sortType === PITCH_SORT_TYPES.PRICE_DSC);
  return sortedPitches;
}

export function getTotalCost(availabilityTotal, pitchId, isMembershipPrice) {
  if (!availabilityTotal.data || !pitchId) return null;

  const pitchAvailabilityTotal = availabilityTotal.data.availability.pitchTypes.find(
    ({ code }) => code === pitchId,
  );

  const availabilityKey =
    isMembershipPrice ? AVAILABILITY_TYPES.MEMBER : AVAILABILITY_TYPES.NON_MEMBER;
  const totalCost = pitchAvailabilityTotal?.[availabilityKey]?.[0] ?? 0;

  return totalCost;
}

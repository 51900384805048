import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import { Label, Select } from '../ui/Form';

const propTypes = {
  customStyled: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ])),
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  label: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  dataError: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showPlaceholder: PropTypes.bool,
  responsiveHeight: PropTypes.bool,
};

const defaultProps = {
  customStyled: '',
  types: [],
  onBlur() {},
  onFocus() {},
  value: '',
  showPlaceholder: false,
  responsiveHeight: false,
};

const idIfy = string => string.replace(/\s/g, '-').toLowerCase();

function OutfitType({
  customStyled, label, types, onChange, onFocus, responsiveHeight,
  value, dataError, showPlaceholder, ...selectProps
}) {
  return (
    <Fragment>
      <Label
        htmlFor={idIfy(label)}
        responsiveHeight={responsiveHeight}
      >
        {label}
      </Label>
      <Select
        {...selectProps}
        block
        customStyled={customStyled}
        error={dataError}
        id={idIfy(label)}
        onChange={onChange}
        onFocus={onFocus}
        type="number"
        value={value}
      >
        {showPlaceholder &&
          <option value="" disabled={value !== ''}>Please select...</option>}
        {types.map(type => (
          <option key={type.key} value={type.key}>{type.value}</option>
        ))}
      </Select>
    </Fragment>
  );
}

OutfitType.propTypes = propTypes;
OutfitType.defaultProps = defaultProps;

export default memo(OutfitType);

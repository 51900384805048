import styled from 'styled-components';

import withDictionary from '../../hocs/withDictionary';

export default styled.div`
  background-color: #f0f0ee;
`;

export const ErrataFooter = styled.footer`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
`;

export const ErrataLabel = withDictionary(styled.label`
  font-size: 0.875rem;
  overflow: hidden;
  padding-left: 1rem;
`, {
  name: 'Label',
});

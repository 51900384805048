import styled, { css } from 'styled-components';
import { above } from '../../styles/libs';
import StyledText from '../ui/Text';
import { Button } from '../ui';
import zIndex from '../../config/zIndex';

export const Wrapper = styled.div`
  position: absolute;
  max-width: 26.125rem;
  margin: 1rem;
  padding: 2rem;
  ${({ theme, mainBubble }) => `background-color: ${mainBubble ? theme.COLOR_GRAY_LIGHTER : theme.COLOR_WHITE}`};
  opacity: 0.95;
  border-radius: 5px;
  z-index: ${zIndex.POPOVER};
  display: ${({ open }) => (open ? 'block' : 'none')};
  align-items: center;

  ${({
    displayArrow, theme, arrowTop, mainBubble,
  }) => displayArrow && (
    `&::after {
    content: "";
    top: ${arrowTop || '0.875rem'};
    transform: translateY(-50%);
    left: -10px;
    position: absolute;
    border-style: solid;
    border-width: 0.875rem 1rem 0.875rem 0rem;
    border-color: transparent ${mainBubble ? theme.COLOR_GRAY_LIGHTER : theme.COLOR_WHITE} transparent transparent};
    z-index: ${zIndex.POPOVER};
  }`)}

  ${({ mainBubble }) => mainBubble && css`
    max-width: 520px;
    width: 100%;
    left: auto;
    top: 0px;

    ${({ theme }) => above(theme.DESKTOP)`
      left: 600px;
      top: 0px;
    `}
  `}
`;

export const MainTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MainText = styled(StyledText)`
  margin-left: 0.5rem;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
`;

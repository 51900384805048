import styled, { css } from 'styled-components';
import { above } from '../../../styles/libs';
import zIndex from '../../../config/zIndex';

export default styled.div`
  display: inline-block;
  position: relative;
`;

const borderWidth = '0.375rem';

const translate = (placement) => {
  if (['left', 'right'].includes(placement)) return `0, -${borderWidth}, 0`;
  return `-${borderWidth}, 0, 0`;
};
const xy = placement => (['bottom', 'top'].includes(placement) ? 'left' : 'top');

export const TooltipPositioner = styled.div`
  z-index: ${zIndex.POPOVER};
`;

export const TooltipWrapper = styled.div`
  ${({ placement }) => css`${placement}: -${borderWidth};`}
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  color: ${({ theme }) => theme.COLOR_WHITE};
  border-radius: 3px;
  font-size: 0.75rem;
  max-width: 9rem;
  ${({ theme }) => above(theme.TABLET)`
    max-width: 18rem;
  `}
  padding: 0.5rem 0.75rem;
  position: relative;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;


  &::before {
    content: '';
    position: absolute;
    ${({ placement }) => css`
      ${placement}: 100%;
      ${xy(placement)}: 50%;
      transform: translate3d(${translate(placement)});
    `}
    border: solid transparent;
    border-color: rgba(0, 0, 0, 0);

    /* Arrow border placement */
    ${({ placement, theme }) => css`border-${placement}-color: ${theme.COLOR_BRAND_PRIMARY_DARK};`}
    height: 0;
    width: 0;
    pointer-events: none;
    border-width: ${borderWidth};
  }
`;

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Actions } from './SearchSummary.style';

function SearchSummary({
  defaultMinimize,
  ...props
}) {
  return (
    <>
      {props.renderSummary}
      {props.renderActions &&
        <Actions>
          {props.renderActions}
        </Actions>
      }
    </>
  );
}

SearchSummary.propTypes = {
  defaultMinimize: PropTypes.bool,
  renderActions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  renderSummary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

SearchSummary.defaultProps = {
  defaultMinimize: false,
};

export default memo(SearchSummary);

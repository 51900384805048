import styled, { css } from 'styled-components';

import { Section as StyledSection } from '../Quote/Quote.style';

export default styled.div``;

export const Section = styled(StyledSection)`
  ${({ alternateBg }) => alternateBg && css`
    background-color: #f0f0ee;
  `}
  ${({ highlightAlert }) => highlightAlert && css`
    border: 1px solid ${({ theme }) => theme.COLOR_DANGER};
    border-radius: 1px;
  `}
`;

import styled from 'styled-components';
import { above } from '../../../../../styles/libs';

const headerHeight = 5.5; // height of header + 1rem;

export default styled.div`
  position: relative;
  padding: 0 0.5rem;
  ${({ theme }) => above(theme.TABLET)`
    :last-child::after {
      content: "";
      position: absolute;
      height: calc(100% - ${headerHeight}rem);
      width: 1px;
      left: 0;
      top: ${headerHeight}rem;
      background: ${theme.COLOR_GRAY_LIGHT};
    }
  `}
  ${({ fullscreen }) => (fullscreen ? `
    margin: 0 auto;
  ` : null)}
`;

export const Button = styled.button`
  display: block;
`;

export const Header = styled.div`
  padding: 2rem 0 1rem;
  text-align: center;
  font-weight: 600;
`;

export const Table = styled.table`
  width: 100%;
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  line-height: 0;
`;

export const LoadingPrices = styled.div`
  position: absolute;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.COLOR_TEXT_MUTED};
`;

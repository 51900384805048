import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { withRouter } from 'next/router';
import { compose } from 'react-apollo';

import IbePropTypes from '../../IbePropTypes';
import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';

import Title from '../ui/Title';
import Text from '../ui/Text';

import svgCheck from '../../static/images/icons/Check.svg';

import {
  Actions,
  Action,
  ActionBrand,
  AlertIcon,
} from './PopUp.styles';
import testingAttr from '../../lib/testingAttr';

const propTypes = {
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  itemName: PropTypes.string,
  onClose: PropTypes.func,
  onViewBasketClick: PropTypes.func,
  hasOSNV: PropTypes.bool,
  isOverseas: PropTypes.bool,
  light: PropTypes.bool,
  router: PropTypes.shape(IbePropTypes.router).isRequired,
  theme: PropTypes.shape({
    COLOR_LAKE_GREEN_ORIGINAL: PropTypes.string,
    COLOR_TEXT_BODY: PropTypes.string,
  }),
};

const defaultProps = {
  itemName: '',
  onClose() {},
  onViewBasketClick() {},
  hasOSNV: false,
  isOverseas: false,
  light: false,
  theme: {},
};

function BasketPopUp(props) {
  const {
    isOverseas, client, light, theme, router, hasOSNV,
  } = props;
  const siteNameKey = '{{CampsiteName}}';
  let titleText = getDictionaryItem(client, light ? 'BasketPopUp__UkItinerary__Title' : 'BasketPopUp__Title');
  let decriptionText = getDictionaryItem(client, light ? 'BasketPopUp__UkItinerary__Text' : 'BasketPopUp__Text');

  if (light) {
    decriptionText = decriptionText.replace(siteNameKey, props.itemName);
  } else {
    titleText = titleText.replace(siteNameKey, props.itemName);
  }

  const handleAddOSNV = () => {
    client.writeData({
      data: {
        siteNightVoucherPopUp: { __typename: 'SiteNightVoucherPopUp', open: true, minimum: 0 },
        basketPopUp: { __typename: 'BasketPopUp', open: false, name: '' },
      },
    });
  };

  const secondaryActionText = light ? 'BackToItinerary' : `AddAnother${isOverseas ? '' : 'UK'}`;
  const renderPrimaryButton = () => {
    if (router.query?.bookingId) {
      if (isOverseas && !hasOSNV) {
        return (
          <Action
            onClick={handleAddOSNV}
            dictionary={
              dictionaryItem('BasketPopUp', 'AddOSNV')
            }
          />
        );
      }
      return null;
    }
    return (
      light ? (
        <ActionBrand
          onClick={props.onClose}
          dictionary={
            dictionaryItem('BasketPopUp', secondaryActionText)
          }
          {...testingAttr('basket-popup-add-another-button')}
          light={light}
        />
      ) : (
        <Action
          onClick={props.onClose}
          dictionary={
            dictionaryItem('BasketPopUp', `AddAnother${isOverseas ? '' : 'UK'}`)
          }
          {...testingAttr('basket-popup-add-another-button')}
          light={light}
        />
      )
    );
  };

  useEffect(() => {
    const mainElement = document.querySelector('main[data-main]');
    const mainElementBounds = mainElement.getBoundingClientRect();
    if (mainElement && mainElementBounds.top < 0) {
      mainElement.scrollIntoView(true);
    }
  }, []);

  return (
    <Fragment>
      <AlertIcon
        icon={svgCheck}
        color={light ? theme.COLOR_LAKE_GREEN_ORIGINAL : theme.COLOR_TEXT_BODY}
      />
      <Title
        align="center"
        tag={3}
        size={2}
        marginBottom
      >
        {titleText}
      </Title>
      <Text align="center" size="1rem">{decriptionText}</Text>
      <Actions light={light}>
        {renderPrimaryButton()}
        <Action
          onClick={props.onViewBasketClick}
          dictionary={dictionaryItem('BasketPopUp', 'ViewBasket')}
          {...testingAttr('basket-popup-view-basket-button')}
          light={light}
        />
      </Actions>
    </Fragment>
  );
}

BasketPopUp.propTypes = propTypes;
BasketPopUp.defaultProps = defaultProps;

export default compose(withTheme, withRouter)(BasketPopUp);

import styled, { css } from 'styled-components';

import StyledButtonBordered from '../../../Button/ButtonBordered.style';
import StyledButtonBrand from '../../../Button/ButtonBrand.style';

const FooterButton = css`
  max-width: 15rem;
  min-height: 0;
  padding: 0 1rem;
`;

export const ActionClear = styled(StyledButtonBordered)`
  ${FooterButton}
`;

export const ActionSubmit = styled(StyledButtonBrand)`
  ${FooterButton}
`;

export const MobileSubmit = styled(StyledButtonBrand)`
  margin: 0 0.5rem 1rem;
  padding: 1rem;
  width: calc(100% - 1rem);
`;

import styled, { css } from 'styled-components';

import { above } from '../../styles/libs';
import detectIE from '../../lib/detectIE';
import zIndex from '../../config/zIndex';

export default styled.div`
  background-color: ${({ theme, isBookingWidget }) => (isBookingWidget ? 'transparent' : theme.COLOR_GRAY_LIGHTEST)};
  width: 100%;
  height: 100%;
  padding-top: 0.25rem;
`;

export const Stick = styled.div`
  z-index: ${zIndex.TAB_CONTENT_STICKY_BECOME_A_MEMBER};
  ${({ sticky, theme }) => sticky && above(theme.TABLET)`
    @media screen and (min-height: 768px) {
      position: sticky;
      bottom: 0;
    }
  `}
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${!!detectIE() && `
    display: block;
  `}
`;

export const TabsNavigation = styled.div`
  flex-shrink: 0;
  display: flex;
  min-height: ${({ theme }) => theme.BUTTON_SIZE_MEDIUM};
  max-width: calc(600px - 48px);

  ${({ theme }) => above(theme.DESKTOP)`
    min-height: 3.75rem;
  `};
`;

export const TabsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-height: 0px;
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};

  ${detectIE() && css`
    height: ${({ contentHeight }) => `${contentHeight}px` || 'auto'}
  `};
`;

export const TabLink = styled.a`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-right: 0.125rem;
  background-color: ${({ active, theme }) => (active ? theme.COLOR_BRAND_PRIMARY : theme.COLOR_WHITE)};
  border: 3px solid ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  border-bottom: 0;
  color: ${({ active, theme }) => (active ? theme.COLOR_WHITE : theme.COLOR_BRAND_PRIMARY_DARK)};
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  text-align: center;

  ${!!detectIE() && `
    padding: 0.6rem 0;
  `}

  &:hover {
    background-color: ${({ active, theme }) => (active ? theme.COLOR_BRAND_PRIMARY : theme.COLOR_GRAY_LIGHTEST)};
  }

  ${({ active, theme }) => above(theme.DESKTOP)`
    font-size: 1rem;
    margin-top: ${active ? '0.625rem' : '1rem'};
    margin-right: 0.25rem;
  `};

  &:last-of-type {
    margin-right: 0;
  }
`;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';

import { dictionaryItem } from '../../../hocs/withDictionary';

import QuoteCrossingLayoutJourneys from './QuoteCrossingLayoutJourneys';
import RoomSelect, { RoomSelectFerryContent } from '../../RoomSelect';

import Text from '../../ui/Text';
import Title from '../../ui/Title';

import {
  InboundAboveMaxQuantity,
  InboundBelowMinQuantity,
  OutboundAboveMaxQuantity,
  OutboundBelowMinQuantity,
} from './QuoteCrossingAccommodation.errors';

import IbePropTypes from '../../../IbePropTypes';

const errorMap = {
  inbound: {
    AboveMaxQuantity: InboundAboveMaxQuantity,
    BelowMinQuantity: InboundBelowMinQuantity,
  },
  outbound: {
    AboveMaxQuantity: OutboundAboveMaxQuantity,
    BelowMinQuantity: OutboundBelowMinQuantity,
  },
};

function findAccommodation(result, payload) {
  return payload.map(
    ({ quantity, uId }) => ({
      ...result.find(accom => accom.uId === uId),
      quantity,
    }),
  );
}

function getAccommodationById(data = [], accommId) {
  const accommodation = data.find(({ uId }) => uId === accommId) || {};
  return accommodation;
}

function sortAccommodation(accommodation) {
  if (!accommodation) return [];

  return accommodation
    .sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    })
    .sort((a, b) => a.maxOccupancy - b.maxOccupancy)
    .sort((a, b) => b.quantity - a.quantity);
}

export const handleValidationAccommodation = (
  accommodation,
  partyMembers,
  isMandatory,
  errorKey,
) => {
  const errors = [];
  const partyAmount = partyMembers.length;

  const numberOfRooms = accommodation
    .map(({ quantity }) => quantity)
    .reduce((acc, cur) => acc + cur, 0);

  const allocatedSpace = accommodation
    .map(({ quantity, maxOccupancy }) => (quantity * maxOccupancy))
    .reduce((acc, cur) => acc + cur, 0);

  // If party amount is more than the allocated room space
  // Mandatory only
  if (isMandatory && partyAmount > allocatedSpace) {
    errors.push(errorMap[errorKey].BelowMinQuantity);
  }

  // If party members is less than the number of rooms
  if (partyAmount < numberOfRooms) {
    errors.push(errorMap[errorKey].AboveMaxQuantity);
  }

  return errors;
};

export const handleValidate = (
  [outboundOptions, inboundOptions],
  { partyMembers, inboundItinerary, outboundItinerary },
) => {
  const isOutboundMandatory = outboundItinerary.isAccomMandatory;
  const outboundAccommodation = findAccommodation(
    outboundOptions,
    outboundItinerary.accommodation,
  );
  const outboundErrors = handleValidationAccommodation(outboundAccommodation, partyMembers, isOutboundMandatory, 'outbound');

  let inboundErrors = [];
  if (inboundItinerary.accommodation) {
    let inboundAccommodation = null;
    inboundAccommodation = findAccommodation(
      inboundOptions,
      inboundItinerary.accommodation,
    );
    if (inboundAccommodation) {
      const isInboundMandatory
        = inboundItinerary.isAccomMandatory ? inboundItinerary.isAccomMandatory : false;
      inboundErrors = handleValidationAccommodation(inboundAccommodation, partyMembers, isInboundMandatory, 'inbound');
    }
  }

  const errors = [...outboundErrors, ...inboundErrors];

  if (errors.length) return errors;

  return null;
};

function QuoteCrossingAccommodation({
  accommPriceError,
  availableInboundAccommodation,
  availableOutboundAccommodation,
  defaultMaxCrossingAccomQuantity,
  disabled,
  errors,
  isStale,
  onCompleted,
  onError,
  onSubmit,
  onInboundAccommodationChange,
  onOutboundAccommodationChange,
  onSuccess,
  payload,
  showInbound,
  isInboundRequired,
  isOutboundRequired,
}) {
  const handleChange = itineraryKey => (accommodation) => {
    // Merge accommodation result into payload
    const newPayload = {
      ...payload,
      [itineraryKey]: {
        ...payload[itineraryKey],
        accommodation,
      },
    };

    const accommodationErrors = handleValidate(
      [availableOutboundAccommodation, availableInboundAccommodation],
      newPayload,
    );

    onError(accommodationErrors);

    return accommodation;
  };

  const handleInboundChange = compose(onInboundAccommodationChange, handleChange('inboundItinerary'));
  const handleOutboundChange = compose(onOutboundAccommodationChange, handleChange('outboundItinerary'));

  const dictionaryPrefix = 'CrossingAccommodation';

  const { inboundItinerary, outboundItinerary } = payload;
  const inboundItineraryAccommodation =
    inboundItinerary.accommodation ? inboundItinerary.accommodation : null;
  const outboundItineraryAccommodation = outboundItinerary.accommodation;

  const inboundAccommodation = sortAccommodation(availableInboundAccommodation);
  const outboundAccommodation = sortAccommodation(availableOutboundAccommodation);

  const renderContentOutbound = (contentProps) => {
    const selectedAccommodation =
      getAccommodationById(outboundItineraryAccommodation, contentProps.uId);

    return (
      <RoomSelectFerryContent
        selectedAccommodation={selectedAccommodation}
        leg="outbound"
        {...contentProps}
      />
    );
  };

  const renderContentInbound = (contentProps) => {
    const selectedAccommodation =
      getAccommodationById(inboundItineraryAccommodation, contentProps.uId);

    return (
      <RoomSelectFerryContent
        selectedAccommodation={selectedAccommodation}
        leg="inbound"
        {...contentProps}
      />
    );
  };

  return (
    <QuoteCrossingLayoutJourneys
      accommPriceError={accommPriceError}
      dictionaryPrefix={dictionaryPrefix}
      errors={errors}
      noInbound={!availableInboundAccommodation.length}
      noOutbound={!availableOutboundAccommodation.length}
      renderInboundText={() => (
        <Text size="0.875rem" dictionary={dictionaryItem(dictionaryPrefix, 'Inbound', 'Mandatory', isInboundRequired ? 'True' : 'False')} marginBottom />
      )}
      renderOutboundText={() => (
        <Text size="0.875rem" dictionary={dictionaryItem(dictionaryPrefix, 'Outbound', 'Mandatory', isOutboundRequired ? 'True' : 'False')} marginBottom />
      )}
      renderColLeft={() => (
        <RoomSelect
          disabled={disabled}
          hideEmpty
          maxQuantity={defaultMaxCrossingAccomQuantity}
          multi
          onChange={handleOutboundChange}
          onError={onError}
          onSuccess={onSuccess}
          renderContent={renderContentOutbound}
          renderLabel={() => (
            <Title dictionary={dictionaryItem('CrossingAccommodation', 'Outbound', 'Upgrade')} marginTop size={3} tag={2} />
          )}
          renderPlaceholder={() => (
            <Text size="0.875rem" weight="bold" dictionary={dictionaryItem(dictionaryPrefix, 'Outbound', 'Placeholder')} />
          )}
          isRequired={isOutboundRequired}
          rooms={outboundAccommodation}
          selectedRooms={outboundItineraryAccommodation}
          totalMaxOccupancy={payload.partyMembers.length}
        />
      )}
      renderColRight={() => showInbound && (
        <RoomSelect
          disabled={disabled}
          hideEmpty
          maxQuantity={defaultMaxCrossingAccomQuantity}
          multi
          onChange={handleInboundChange}
          onError={onError}
          onSuccess={onSuccess}
          renderContent={renderContentInbound}
          renderLabel={() => (
            <Title dictionary={dictionaryItem('CrossingAccommodation', 'Inbound', 'Upgrade')} marginTop size={3} tag={2} />
          )}
          renderPlaceholder={() => (
            <Text size="0.875rem" weight="bold" dictionary={dictionaryItem(dictionaryPrefix, 'Inbound', 'Placeholder')} />
          )}
          isRequired={isInboundRequired}
          rooms={inboundAccommodation}
          selectedRooms={inboundItineraryAccommodation}
          totalMaxOccupancy={payload.partyMembers.length}
        />
      )}
    />
  );
}

QuoteCrossingAccommodation.propTypes = {
  accommPriceError: PropTypes.arrayOf(PropTypes.shape({})),
  availableInboundAccommodation: PropTypes.arrayOf(PropTypes.shape({})),
  availableOutboundAccommodation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultMaxCrossingAccomQuantity: PropTypes.number,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.shape({})),
  isInboundRequired: PropTypes.bool.isRequired,
  isOutboundRequired: PropTypes.bool.isRequired,
  isStale: PropTypes.bool.isRequired,
  onInboundAccommodationChange: PropTypes.func.isRequired,
  onOutboundAccommodationChange: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
    outboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
    partyMembers: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.partyMember)),
  }).isRequired,
  showInbound: PropTypes.bool.isRequired,
};

QuoteCrossingAccommodation.defaultProps = {
  accommPriceError: [],
  availableInboundAccommodation: [],
  errors: [],
  defaultMaxCrossingAccomQuantity: null,
  disabled: false,
};

export default memo(QuoteCrossingAccommodation);

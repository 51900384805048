import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'next/router';
import { ApolloConsumer } from 'react-apollo';

import { uniq } from 'lodash';
import updateRouterQuery from '../../lib/updateRouterQuery';

import ButtonBrand from '../ui/Button/ButtonBrand.style';
import Text from '../ui/Text';

import { dictionaryItem } from '../../hocs/withDictionary';

import StyledAvailabilityError from './AvailabilityExpandSearch.style';
import ErrorTypeMessage from '../ErrorTypeMessage/ErrorTypeMessage';
import { getNearestCampsitesOptions, GET_NEAREST_BOUNDARIES } from '../../lib/nearestCampsites';
import { searchByType } from '../../constants/search';

class AvailabilityExpandSearch extends Component {
  static propTypes = {
    router: PropTypes.shape({
      query: PropTypes.shape({
        zoomLevel: PropTypes.string,
      }).isRequired,
    }).isRequired,
    query: PropTypes.shape({}).isRequired,
    lat: PropTypes.number.isRequired,
    lon: PropTypes.number.isRequired,
  }

  state = {
    loading: false,
    error: null,
  }

  handleAlternativeSearch = async (client) => {
    // Reset error and loading state
    this.setState({ error: null, loading: true });

    // Get query options
    const { variables, ...options } = getNearestCampsitesOptions({
      ...this.props.query,
      lat: this.props.lat,
      lon: this.props.lon,
    });

    // Fetch boundaries for nearest campsite
    const { data, ...response } = await client.query({
      query: GET_NEAREST_BOUNDARIES,
      variables,
      ...options,
      fetchPolicy: 'network-only',
    });

    const { loading } = response;
    let { error } = response;

    // Check response has returned data
    if (!data.nearestBoundaries) {
      error = new Error('There was an error fetching nearby campsites.');
    }

    // Check lat/lng values are all unique
    const values = Object.values(data.nearestBoundaries);
    if (uniq(values).length !== values.length) {
      error = new Error('There was an error fetching nearby campsites.');
    }

    // Handle client and remote errors
    if (error) {
      this.setState({ error, loading });
      return;
    }

    // Update the query with the new boundaries
    updateRouterQuery('/sites', {
      ne_lat: data.nearestBoundaries.bottomRightLat,
      ne_lng: data.nearestBoundaries.topLeftLon,
      sw_lat: data.nearestBoundaries.topLeftLat,
      sw_lng: data.nearestBoundaries.bottomRightLon,
      activePin: null,
      campsiteId: null,
      location: null,
      campsiteName: null,
      siteCode: null,
      searchBy: searchByType.LOCATION,
    });
  }

  render() {
    const { error, loading } = this.state;

    return (
      <StyledAvailabilityError>
        <Text dictionary={dictionaryItem('AvailabilityError', 'NoAvailability')} align="center" size="1rem" marginBottom />

        <ApolloConsumer>
          {client => (
            <ButtonBrand
              disabled={loading}
              block
              onClick={() => this.handleAlternativeSearch(client)}
              size="xl"
              dictionary={dictionaryItem('AvailabilityError')}
            />
          )}
        </ApolloConsumer>

        {error &&
          <ErrorTypeMessage error={error} marginTop>
            {error.message}
          </ErrorTypeMessage>
        }
      </StyledAvailabilityError>
    );
  }
}

export default withRouter(AvailabilityExpandSearch);

import styled from 'styled-components';

export default styled.div`
  display: block;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

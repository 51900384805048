import styled from 'styled-components';

import TextStyled from '../ui/Text/Text.style';
import { Col as ColComponent, Row as RowComponent } from '../ui/Grid';
import { Select as SelectComponent } from '../ui/Form';

import { above, below } from '../../styles/libs';

export default styled.div``;

export const Header = styled.header`
  display: flex;
`;

export const Row = styled(RowComponent)``;

export const Col = styled(ColComponent)``;

export const ColLarge = styled(ColComponent)`
  flex: 0 0 100%;

  ${({ theme }) => above(theme.DESKTOP)`
    flex: 1;
  `}
`;

export const Select = styled(SelectComponent)``;

export const Name = styled(TextStyled)`
  ${({ theme }) => below(theme.DESKTOP)`
    margin-bottom: 0.5rem;
  `}

  font-size: 0.875rem;
  padding-top: 0.0625em;
`;

export const Price = styled(TextStyled)`
  ${({ theme }) => above(theme.DESKTOP)`
    text-align: right;
  `}
`;

import styled, { css } from 'styled-components';
import { below } from '../../../styles/libs';

import IconComponent from '../../ui/Icon/Icon';

import StyledButtonBordered from '../../ui/Button/ButtonBordered.style';

export default styled(StyledButtonBordered)`
  border-color: ${({ theme }) => theme.COLOR_WHITE};
  background-color: transparent;
  color:  ${({ theme }) => theme.COLOR_WHITE};
  max-width: 9rem;

  &:not(:focus) {
    &:hover svg {
      color: ${({ theme }) => theme.COLOR_WHITE};
    }
  }

  &:not([disabled]) {
    &:focus svg {
      color: ${({ theme }) => theme.COLOR_WHITE};
    }
  }

  ${({ active }) => active && css`
    background-color: ${({ theme }) => theme.COLOR_WHITE};
    color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};

    &:not([disabled]):focus {
      background-color: ${({ theme }) => theme.COLOR_WHITE};
      color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};

      svg {
        color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
      }
    }
  `}

  ${({ theme }) => below(theme.TABLET)`
    &:not([disabled]) {
      &:hover,
      &:focus {
        background-color: ${theme.COLOR_WHITE};
        color: ${theme.COLOR_BRAND_PRIMARY_DARK};
      }
    }

    &:not(:focus) {
      &:hover svg,
      &:focus svg {
        color: ${theme.COLOR_BRAND_PRIMARY_DARK};
      }
    }
  `}
`;

export const Icon = styled(IconComponent)`
  color: ${({ theme, active }) => (active ?
    theme.COLOR_BRAND_PRIMARY_DARK : theme.COLOR_WHITE)};
  font-size: 1.5rem;
  margin-right: 0.5rem;

  ${({ largeIcon }) => largeIcon && css`
    font-size: 2.25rem;
    max-height: 1.5rem;
  `}
`;

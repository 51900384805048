import styled, { css } from 'styled-components';

import { above, below } from '../../styles/libs';
import { ButtonBordered } from '../ui/Button';

export default styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const SmallLine = styled.span`
  content: "";
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  width: 100%;
  height: 0.25rem;
`;

export const ListingWrapper = styled.div`
  ${({ theme }) => below(theme.TABLET)`
    padding-bottom: 4rem;
  `}
`;

export const StyledListingMap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const ListingFooter = styled.div`
  width: 100%;
  margin-top: -2.95rem;
  z-index: 1;
  bottom: 0;
  pointer-events: none;

  ${({ isMap }) => css`
    position: ${isMap ? 'absolute' : 'sticky'};
  `}

  ${({ theme }) => above(theme.TABLET)`
    display: none;
  `}
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75));
    width: 100%;
    height: 150%;
  }
`;

export const ToggleMap = styled(ButtonBordered)`
  position: relative;
  ${({ mapOpen }) => !mapOpen && css`
    padding: 0.5rem;
    font-size: 0.85rem;

    &:not([disabled]) {
      &:hover,
      &:focus {
        background-color: ${({ theme }) => theme.COLOR_WHITE};
        color: ${({ theme }) => theme.COLOR_CYAN_DARK};
      }
    }
  `}
  pointer-events: auto;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border-color: ${({ theme }) => theme.COLOR_CYAN_DARK};
  color: ${({ theme }) => theme.COLOR_CYAN_DARK};
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: color 0.25s, background 0.25s, border 0.25s;

  &:focus {
    box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
`;

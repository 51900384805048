import React from 'react';
import PropTypes from 'prop-types';

import Text from './EmptyState.style';

function EmptyState({ text, ...props }) {
  return (
    <Text {...props}>
      {text}
    </Text>
  );
}

EmptyState.propTypes = {
  text: PropTypes.string,
};

EmptyState.defaultProps = {
  text: '',
};

export default EmptyState;

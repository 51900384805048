import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';
import { withRouter } from 'next/router';
import { uniqBy, cloneDeep } from 'lodash';
import { startOfDay, addDays, format } from 'date-fns';

import buildPath from '../../lib/helpers/restLink';
import updateRouterQuery from '../../lib/updateRouterQuery';
import { defaults as defaultCampsite } from '../../resolvers/campsite';
import { stringifyPartyMembers } from '../../lib/helpers/party';
import {
  validateOutfitCampsite, validateParty, validateArrivalTime,
} from '../../lib/validation/availability';
import { getTotalCost, sortPitches } from '../../lib/availability';
import { getBookableDaysFromToday } from '../../lib/campsiteTypes';
import isLoggedIn, { isLoggedInMember } from '../../lib/isLoggedIn';

import Text from '../ui/Text';
import { MessageWarning } from '../ui/Message';
import LoadingSpinner from '../ui/Loading/LoadingSpinner';

import { QuoteCreate } from '../Quote';
import { partyConfig } from '../../config/personType';
import { ids, keys } from '../../config/campsiteTypes';
import { dictionaryItem } from '../../hocs/withDictionary';
import GET_PITCHES from './graphql/getPitches.gql';
import GET_USER from '../../config/graphql/getUser';
import GET_AVAILABILITY_AND_USER_CONFIG from './graphql/getAvailabilityAndUserConfig';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import PLACE_EVENT_TYPES,
{ SINGLE_EVENT_TYPE } from '../../config/eventTypes';
import { isMembershipInBasket } from '../../config/quoteMembershipTypes';
import IbePropTypes from '../../IbePropTypes';
import { RENEWAL_STATUSES } from '../../config/membershipStatus';

import {
  AvailabilityDuration,
  AvailabilityExpandSearch,
  AvailabilityParty,
  AvailabilityPitches,
} from '.';

import AvailabilityGuests from './AvailabilityGuests';
import AvailabilityArrivalTime from './AvailabilityArrivalTime';
import AvailabilityMemberNonMember from './AvailabilityMemberNonMember';
import AvailabilityOutfitDetails from './AvailabilityOutfitDetails';
import AvailabilityAddGuests from './AvailabilityAddGuests';
import ErrorTypeMessage from '../ErrorTypeMessage';
import EventsButtonsWrapper from '../SearchForm/Campsites/EventsButtonsWrapper';

import StyledAvailability, {
  Body,
  CheckboxWrapper,
  CheckboxLabel,
  CheckboxText,
} from './Availability.style';
import { DATE_FORMAT_DEFAULT } from '../../config/locale';
import FetchPolicy from '../../constants/FetchPolicy';
import { getErrorElement } from '../../lib/helpers/availability';
import { getIsOverseas } from './helpers';
import Title from '../ui/Title';
import Checkbox from '../ui/Form/Checkbox';
import AvailabilityDurationInfo from './AvailabilityDurationInfo';

class Availability extends Component {
  static propTypes = {
    availabilitySearch: PropTypes.shape({
      calendarFirstCellDate: PropTypes.string,
      data: PropTypes.shape({
        availability: PropTypes.shape({
          pitchTypes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
      error: PropTypes.shape(IbePropTypes.error),
      loading: PropTypes.bool,
      networkStatus: PropTypes.number,
    }),
    availabilitySearchLoading: PropTypes.bool,
    availabilityTotal: PropTypes.shape({
      error: PropTypes.shape(IbePropTypes.apolloError),
      loading: PropTypes.bool,
      data: PropTypes.shape({
        availability: PropTypes.shape({
          pitchTypes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    }),
    closedDateError: PropTypes.shape({
      message: PropTypes.string,
    }),
    disabledForms: PropTypes.bool.isRequired,
    getPitches: PropTypes.shape({
      campsite: PropTypes.shape(IbePropTypes.campsite),
      error: PropTypes.shape(IbePropTypes.apolloError),
      loading: PropTypes.bool,
    }),
    data: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.shape(IbePropTypes.apolloError),
      dictionary: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    events: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.event)),
    configuration: PropTypes.shape(IbePropTypes.configuration),
    campsiteId: PropTypes.string,
    continued: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    fetchAvailabilitySearch: PropTypes.func.isRequired,
    fetchAvailabilityTotal: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    hasBookings: PropTypes.bool,
    inputDateError: PropTypes.shape({
      message: PropTypes.string,
    }),
    loading: PropTypes.bool,
    loadingAvailability: PropTypes.bool,
    prices: PropTypes.shape({
      count: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.shape({
        error: PropTypes.shape(IbePropTypes.apolloError),
        loading: PropTypes.bool,
      })),
    }),
    onContinueClick: PropTypes.func.isRequired,
    onQueryChange: PropTypes.func.isRequired,
    onSelectEvent: PropTypes.func.isRequired,
    payload: PropTypes.shape(IbePropTypes.quotePayload),
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    updatePayload: PropTypes.func.isRequired,
    query: PropTypes.shape(IbePropTypes.query),
    type: PropTypes.number.isRequired,
    selectedEvent: PropTypes.shape(IbePropTypes.event),
    handleMemberNonMemberChange: PropTypes.func.isRequired,
    memberPricesAreSelected: PropTypes.bool.isRequired,
    handleUpdateQuote: PropTypes.func.isRequired,
    product: PropTypes.shape(IbePropTypes.product),
    quote: PropTypes.shape(IbePropTypes.quote),
    user: PropTypes.shape(IbePropTypes.user),
    client: PropTypes.shape(IbePropTypes.client),

  };

  static defaultProps = {
    availabilitySearch: {
      data: {
        availability: {
          pitchTypes: [],
        },
      },
    },
    availabilitySearchLoading: false,
    availabilityTotal: {
      data: {
        availability: {
          pitchTypes: [],
        },
      },
    },
    closedDateError: undefined,
    getPitches: {
      error: false,
      campsite: defaultCampsite.campsite,
    },
    inputDateError: null,
    data: {},
    events: [],
    campsiteId: null,
    continued: false,
    error: false,
    hasBookings: false,
    loading: false,
    loadingAvailability: false,
    prices: {
      count: 0,
      data: [],
    },
    query: {},
    product: {},
    configuration: {},
    payload: {},
    selectedEvent: null,
    quote: {},
    user: null,
    client: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      guestsErrata: false,
      fieldFocus: '',
      outfitFieldsTouched: {
        vehicleLength: !!props.payload.outfit.vehicleLength,
        vehicleType: !!props.payload.outfit.vehicleType,
        towLength: !!props.payload.outfit.towLength,
        towType: !!props.payload.outfit.towType,
      },
      outfitLengthErrors: [],
      outfitErrors: [],
      arrivalTimeErrors: [],
      partyErrors: [],
      submitted: false,
      sortType: undefined,
    };
  }

  componentDidMount() {
    // As availability is such a heavy request we need to fetch it manually
    this.props.fetchAvailabilitySearch();
    this.props.fetchAvailabilityTotal();
  }

  handleSortTypeChange = (sortType) => {
    this.setState({ sortType });
  }

  maxCampsitePartyLength = (pitches) => {
    if (!pitches.length) return 0;

    const maxPeopleInCampsite = pitches
      .map(pitch => pitch.maxOccupancy)
      .reduce((acc, cur) => Math.max(acc, cur));

    return maxPeopleInCampsite;
  };

  handleResetDates = async () => {
    const {
      fetchAvailabilityTotal,
      handleChange,
      onQueryChange,
      payload,
      query,
      updatePayload,
    } = this.props;
    const copiedQuery = { ...query };

    const mutatedPayload = cloneDeep(payload);

    mutatedPayload.start = undefined;
    mutatedPayload.end = undefined;

    await updatePayload(mutatedPayload);

    await handleChange({ continued: false });

    await onQueryChange({
      ...copiedQuery,
      start: mutatedPayload.start,
      end: mutatedPayload.end,
    });

    await updateRouterQuery(
      '/sites',
      {
        start: mutatedPayload.start,
        end: mutatedPayload.end,
      },
      'replace',
    );

    await handleChange(mutatedPayload);

    await fetchAvailabilityTotal(mutatedPayload);
  }

  handleCalendarMonthChange = (response) => {
    this.props.handleChange(response, this.props.fetchAvailabilitySearch);
  };

  handleDurationChange = async (duration) => {
    const {
      fetchAvailabilityTotal,
      handleChange,
      onQueryChange,
      payload,
      query,
      updatePayload,
    } = this.props;
    const copiedQuery = { ...query };
    const [start, end] = duration;

    payload.start = start;
    payload.end = end || start;

    await updatePayload(payload);

    await handleChange({ continued: false });

    await onQueryChange({
      ...copiedQuery,
      start,
      end,
    });

    await updateRouterQuery(
      '/sites',
      {
        start: payload.start,
        end: payload.end,
      },
      'replace',
    );

    await handleChange(payload);

    await fetchAvailabilityTotal(payload);
  };

  handlePartyChange = async (payload, skipValidation = false) => {
    await this.props.updatePayload(payload);
    const isOverseas = getIsOverseas(this.props.router);
    const partyErrors = skipValidation ? [] : validateParty(payload.partyMembers, true, isOverseas);
    await this.setState({
      partyErrors,
    });

    this.props.fetchAvailabilitySearch();
    this.props.fetchAvailabilityTotal();
  };

  // Merge user outfit with quote payload outfit
  handleUserOutfitChange = async (outfit) => {
    const { payload, updatePayload } = this.props;
    payload.outfit = { ...outfit };
    updatePayload(payload);

    await this.validate(payload);

    this.props.handleChange({ continued: false });
  };

  handleArrivalTimeChange = async (arrivalTime) => {
    const { payload, updatePayload } = this.props;
    payload.arrivalTime = arrivalTime;
    this.setState({
      arrivalTimeErrors: [],
    });
    updatePayload(payload);

    this.props.handleChange({ continued: false });
  };

  handleOutfitBlur = ({ target }) => {
    const { name } = target;
    const { outfitFieldsTouched } = this.state;

    this.setState({
      outfitFieldsTouched: {
        ...outfitFieldsTouched,
        [name]: true,
      },
    });
  };

  // Replace quote payload outfit prop with changed value
  handleOutfitDetailsChange = async (outfit) => {
    const { payload, updatePayload } = this.props;
    payload.outfit = outfit;

    await updatePayload(payload);

    await this.validate(payload);

    this.props.handleChange({ continued: false });
  };

  handlePitchChange = ({ id, maxOccupancy, name }) => {
    const payload = { ...this.props.payload };
    payload.pitchTypeId = id;
    this.props.handleChange({
      continued: false,
      payload,
      maxOccupancy,
      pitchName: name,
    });
  };

  handleOutfitLengthError = (errors) => {
    this.setState({
      outfitLengthErrors: [...errors],
    });
  };

  handleSubmit = async () => {
    this.setState({ submitted: true });
    await this.validate(this.props.payload, true);

    const element = getErrorElement();
    if (element) {
      element.scrollIntoView({ block: 'center' });
      return element.focus();
    }

    return this.props.onContinueClick(true);
  };

  // Get the most recently focused date input field and set it in state
  handleFieldFocus = (name) => {
    this.setState({ fieldFocus: name });
  }

  triggerGuestsErrata = (event) => {
    this.setState({
      guestsErrata: event.target.checked,
    });
  };

  validate = (payload, submit = false) => {
    const { configuration, router, client } = this.props;
    const isOverseas = getIsOverseas(router);
    const outfitErrors = validateOutfitCampsite(
      payload.outfit, isOverseas, configuration, router, client,
    );
    const partyErrors = validateParty(payload.partyMembers, true, isOverseas);
    const arrivalTimeErrors = submit ? validateArrivalTime(payload.arrivalTime) : [];

    this.setState({
      outfitErrors: [...outfitErrors, ...this.state.outfitLengthErrors],
      partyErrors,
      arrivalTimeErrors,
    });
  };

  render() {
    const {
      availabilitySearch,
      availabilitySearchLoading,
      availabilityTotal,
      campsiteId,
      continued,
      disabledForms,
      getPitches,
      data,
      data: { dictionary },
      error,
      hasBookings,
      configuration,
      loadingAvailability,
      loading,
      payload,
      handleMemberNonMemberChange,
      memberPricesAreSelected,
      product,
      router,
      quote,
    } = this.props;

    const {
      outfitErrors,
      outfitFieldsTouched,
      outfitLengthErrors,
      submitted,
      sortType,
    } = this.state;

    const uniqueOutfitErrors = uniqBy([
      ...outfitLengthErrors,
      ...outfitErrors,
    ], 'message');

    const filteredOutfitErrors = submitted
      ? uniqueOutfitErrors
      : uniqueOutfitErrors.filter(({ type }) => {
        if (type === 'outfit') {
          return outfitFieldsTouched.towType;
        }
        return outfitFieldsTouched[type];
      });

    // If any of the queries are still loading
    if (
      loadingAvailability ||
      (!availabilitySearch.networkStatus && !availabilitySearch.error) ||
      availabilitySearch.networkStatus < 7 ||
      getPitches.loading ||
      data.loading ||
      loading ||
      !configuration
    ) {
      return <LoadingSpinner marginTop />;
    }

    if (getPitches.error || !getPitches.campsite) {
      return <ErrorTypeMessage error={getPitches.error} />;
    }

    const { availability } = availabilitySearch.data;

    const pitches = getPitches.campsite.pitches.map(({ __typename, ...pitch }) => {
      const pitchAvailabilitySearch = availability.pitchTypes.find(
        ({ code }) => code === pitch.id,
      );
      const totalCost =
        getTotalCost(availabilityTotal, payload.pitchTypeId, memberPricesAreSelected);

      const availabilityTotalPitch = availabilityTotal.data.availability.pitchTypes.find(
        pitchType => pitchType.code === pitch.id,
      );

      return {
        ...pitch,
        ...pitchAvailabilitySearch,
        calendarFirstCellDate: availabilitySearch.calendarFirstCellDate,
        selected: pitch.id === payload.pitchTypeId,
        restrictions: availabilityTotalPitch
          ? availabilityTotalPitch.restrictions
          : null,
        totalCost,
      };
    }).filter((pitchItem) => {
      const eventType = Number(router.query?.eventType) ||
        PLACE_EVENT_TYPES.TOURING.id;
      const isOverseasCampsite = getPitches.campsite?.type === ids.OVERSEAS_SITE;
      const isOverseas = getIsOverseas(router);
      const onlySingleTypePitches = !getPitches.campsite.pitches.some(
        (pitch) => pitch.eventType !== SINGLE_EVENT_TYPE.id,
      );

      return pitchItem.eventType === eventType ||
        (isOverseas && pitchItem.eventType === SINGLE_EVENT_TYPE.id) ||
        (isOverseasCampsite && pitchItem.eventType === SINGLE_EVENT_TYPE.id) ||
        onlySingleTypePitches;
    });

    const sortedPitches =
      sortPitches(pitches, memberPricesAreSelected ? 'memberMinPricePerNight' : 'nonMemberMinPricePerNight', sortType);

    const selectedPitch =
      sortedPitches.find(({ id }) => id === payload.pitchTypeId) || {};

    const noAvailability = !selectedPitch.totalCost;

    const partyString = stringifyPartyMembers(
      payload.partyMembers,
      configuration?.partyMemberTypes,
    );

    // Disabled because its handled by BE
    let invalidDates = false;
    if (
      error &&
      error.networkError &&
      error.networkError.result &&
      error.networkError.result.errorCode === 3
    ) {
      invalidDates = true;
    }

    if (this.props.closedDateError) {
      invalidDates = true;
    }

    // Check outfit vehicle exists and has a length value
    const invalidOutfit =
      !payload.outfit.vehicleType ||
      payload.outfit.vehicleLength === null ||
      Number.isNaN(payload.outfit.vehicleLength);

    // TODO: Find a dynamic way to set the party max amount of type
    partyConfig[0].max = selectedPitch.maxOccupancy;
    partyConfig[1].max = selectedPitch.maxOccupancy - 1;
    partyConfig[2].max = selectedPitch.maxOccupancy - 1;

    const dictionaryMap = Object.assign(
      {},
      ...dictionary.map(obj => ({ [obj.key]: obj.value })),
    );
    const bookableDaysFromToday =
      getBookableDaysFromToday(
        getPitches.campsite.type,
        configuration.products,
        getIsOverseas(router),
      );

    /**
     * @TODO product code or name should be supplied with campsite data
     * at a later date, here we are manually mapping for now.
     */

    const userIsMember = isLoggedInMember(this.props.user) || isMembershipInBasket(quote);
    const isOverseasSite = getPitches.campsite.type === ids[keys.OVERSEAS_SITE];
    const isUkClubSite = getPitches.campsite.type === ids[keys.UK_CLUB_SITES];
    const { isMemberExclusive } = selectedPitch ?? {};

    const nonMemberBookableDate
      = product?.nonMemberBookableDate || configuration.availabilityMaxDate;
    const memberBookableDate = product?.memberBookableDate || configuration.availabilityMaxDate;
    const maxDate = memberBookableDate;
    const minDate = format(
      startOfDay(addDays(new Date(), bookableDaysFromToday)),
      DATE_FORMAT_DEFAULT,
    );
    const eventTypeIds = [...new Set(
      getPitches.campsite.pitches.map(item => item.eventType),
    )];
    const eventType = Number(router.query?.eventType ?? PLACE_EVENT_TYPES.TOURING.id);

    return (
      <StyledAvailability>
        {isUkClubSite &&
          <StyledAvailability branded>
            <EventsButtonsWrapper
              activeId={eventType}
              handleButtonClick={this.props.onSelectEvent}
              events={this.props.events}
              eventIds={eventTypeIds}
              quote={this.props.quote}
              center
            />
          </StyledAvailability>
        }

        <Body>
          <AvailabilityParty
            data={partyConfig}
            disabled={hasBookings || disabledForms}
            max={this.maxCampsitePartyLength(getPitches.campsite.pitches)}
            maxForCurrentPitch={selectedPitch.maxOccupancy}
            onChange={this.handlePartyChange}
            maxChildAge={selectedPitch.maxChildAge}
            minChildAge={selectedPitch.minChildAge}
            payload={payload}
            dataError={!!this.state.partyErrors?.length}
            productCode={product.productCode}
            isOverseas={isOverseasSite}
            useDob
          />

          {this.state.partyErrors.map(err => (
            <MessageWarning
              key={err.message}
              marginTop
              dictionary={err.message}
            />
          ))}
        </Body>

        <Body>
          <AvailabilityDuration
            availability={availability}
            availabilitySearchLoading={availabilitySearchLoading}
            disabled={disabledForms}
            error={!selectedPitch}
            endDate={payload.end}
            fetchAvailability={this.handleCalendarMonthChange}
            loading={loadingAvailability}
            memberBookableDate={memberBookableDate}
            memberPricesAreSelected={memberPricesAreSelected}
            noAvailability={noAvailability}
            nonMemberBookableDate={nonMemberBookableDate}
            onGoToMemberPrices={handleMemberNonMemberChange}
            onCancelMemberPrices={this.handleResetDates}
            onRequestText={
              dictionaryMap.AvailabilityDuration__OnRequest__Button
            }
            userIsMember={userIsMember}
            handleFieldFocus={this.handleFieldFocus}
            onChange={this.handleDurationChange}
            options={{
              dateRange: true,
              handleFieldFocus: this.handleFieldFocus,
              maxDate,
              minDate,
              months: 1,
              name: 'campsiteAvailability_dateRange',
              showRemainder: false,
              static: true,
            }}
            fieldFocus={this.state.fieldFocus}
            selectedEvent={this.props.selectedEvent}
            selectedPitch={selectedPitch}
            startDate={payload.start}
            dataError={!!this.props.inputDateError || !!this.props.error}
            pitches={sortedPitches}
            handlePitchChange={this.handlePitchChange}
          />

          {!!this.props.inputDateError && (
            <MessageWarning
              key={this.props.inputDateError.message}
              marginTop
              marginBottom
              dictionary={this.props.inputDateError.message}
            />
          )}

          {!!this.props.closedDateError && (
            <MessageWarning
              key={this.props.closedDateError.message}
              marginTop
              marginBottom
              dictionary={this.props.closedDateError.message}
            />
          )}

          {selectedPitch.code && (
            <AvailabilityDurationInfo
              selectedPitch={selectedPitch}
              partyString={partyString}
              memberPricesAreSelected={memberPricesAreSelected}
              startDate={payload.start}
              endDate={payload.end}
            />
          )}
        </Body>

        <Body>
          {(!userIsMember && !(isOverseasSite || isMemberExclusive)) &&
          !RENEWAL_STATUSES.includes(this.props.user?.membershipStatus) &&
            <AvailabilityMemberNonMember
              memberPrice={selectedPitch.memberMinPricePerNight}
              nonMemberPrice={selectedPitch.nonMemberMinPricePerNight}
              memberPricesAreSelected={memberPricesAreSelected}
              handleMemberNonMemberChange={handleMemberNonMemberChange}
            />
          }
          <AvailabilityPitches
            campsiteId={campsiteId}
            disabled={disabledForms}
            end={this.props.router?.query?.end}
            handlePitchChange={this.handlePitchChange}
            loading={loadingAvailability}
            memberPricesAreSelected={memberPricesAreSelected}
            pitches={sortedPitches}
            pitchTypesLink={configuration.pitchTypesLink}
            pitchTypeId={payload.pitchTypeId}
            showPrice={!!payload.start && !!payload.end}
            start={this.props.router?.query?.start}
            sortType={sortType}
            handleSortTypeChange={this.handleSortTypeChange}
          />
        </Body>

        <Body>
          <AvailabilityOutfitDetails
            disabled={hasBookings || disabledForms}
            maxOutfitLength={
              selectedPitch.maxOutfitLength ||
              configuration.defaultMaxVehicleOverallLength
            }
            error={invalidOutfit}
            onError={this.handleOutfitLengthError}
            onUserOutfitChange={this.handleUserOutfitChange}
            onOutfitBlur={this.handleOutfitBlur}
            onOutfitChange={this.handleOutfitDetailsChange}
            outfit={payload.outfit}
            outfitErrors={filteredOutfitErrors}
            eventType={eventType}
            isOverseas={isOverseasSite}
          />

          {filteredOutfitErrors.map(err => (
            <MessageWarning
              key={err.message}
              marginTop
            >
              {err.message}
            </MessageWarning>
          ))}
        </Body>

        {(selectedPitch.fromArrivalTime > -1 && isUkClubSite) ? (
          <Body>
            <AvailabilityArrivalTime
              start={selectedPitch.fromArrivalTime}
              end={selectedPitch.toArrivalTime}
              onChange={this.handleArrivalTimeChange}
              arrivalTimeErrors={this.state.arrivalTimeErrors}
              value={payload.arrivalTime}
            />
            {this.state.arrivalTimeErrors.map(err => (
              <MessageWarning
                key={err.message}
                marginTop
                dictionary={err.message}
              />
            ))}
          </Body>
        ) : null}

        {!isOverseasSite ?
          <>
            <Body>
              <AvailabilityAddGuests />
            </Body>
            <Body withBackground>
              <AvailabilityGuests
                payload={payload}
                configuration={configuration}
                onChange={this.props.updatePayload}
                handleUpdateQuote={this.props.handleUpdateQuote}
                validatePayload={this.validate}
                getErrorElement={getErrorElement}
                maxLength={Math.max(selectedPitch?.maxOccupancy - payload.partyMembers?.length, 0)}
                maxChildAge={selectedPitch.maxChildAge}
                minChildAge={selectedPitch.minChildAge}
                isOverseas={isOverseasSite}
              />
            </Body>
          </> :
          <Body>
            <Title
              marginBottom
              tag={1}
              size={4}
              dictionary={dictionaryItem('AvailabilityGuests')}
            />

            <CheckboxWrapper>
              <Checkbox
                checked={this.state.guestsErrata}
                disabled={disabledForms}
                id="guests"
                onChange={this.triggerGuestsErrata}
                name="guests"
              />

              <CheckboxLabel htmlFor="guests">
                <CheckboxText
                  dictionary={dictionaryItem('AvailabilityGuests__Label')}
                />
              </CheckboxLabel>
            </CheckboxWrapper>

            {this.state.guestsErrata && (
              <Text
                marginTop
                dictionary={dictionaryItem('AvailabilityGuests__Errata')}
              />
            )}
          </Body>
        }

        {this.props.availabilityTotal.error && (
          <ErrorTypeMessage
            error={this.props.availabilityTotal.error}
            marginBottom
          />
        )}

        {!continued && !selectedPitch.restrictions && !invalidDates && (
          <Body>
            <QuoteCreate
              disabled={disabledForms}
              handleContinueClick={this.handleSubmit}
              loading={loading || availabilityTotal.loading}
              payload={payload}
            />

            {data.error && (
              <ErrorTypeMessage errorCode={data.error} marginBottom />
            )}

            {getPitches.error && (
              <ErrorTypeMessage errorCode={getPitches.error} marginBottom />
            )}
          </Body>
        )}

        {(!!selectedPitch.restrictions || invalidDates) && (
          <Body>
            <AvailabilityExpandSearch
              lat={this.props.getPitches.campsite.location.lat}
              lon={this.props.getPitches.campsite.location.lon}
              query={this.props.router.query}
            />
          </Body>
        )}
      </StyledAvailability>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  graphql(GET_CONFIGURATION, {
    props: ({ data }) => ({
      configuration: data.configuration,
      loading: data.loading,
    }),
  }),
  graphql(GET_USER, {
    options: {
      fetchPolicy: FetchPolicy.CACHE_ONLY,
    },
    props: ({ data }) => ({
      user: data.user,
    }),
    skip: props => !isLoggedIn(),
  }),
  graphql(GET_AVAILABILITY_AND_USER_CONFIG, {
    options: () => ({
      variables: {
        dictionaryKeys: ['AvailabilityDuration__OnRequest__Button'],
        pathBuilder: buildPath('dictionary/find'),
      },
    }),
  }),
  graphql(GET_PITCHES, {
    name: 'getPitches',
    options: ({ campsiteId }) => ({
      variables: {
        id: campsiteId,
        skip: !campsiteId,
      },
    }),
  }),
)(Availability);

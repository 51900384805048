import gql from 'graphql-tag';

export default gql`
  query UserOutfits {
    userOutfits @rest(
      type: "UserOutfits"
      path: "User/Outfits"
      method: "GET"
    ) {
      outfits {
        externalId
        towHeight
        towLength
        towMake
        towModel
        towType
        vehicleHeight
        vehicleLength
        vehicleMake
        vehicleModel
        vehicleReg
        vehicleType
      }
    }
  }
`;

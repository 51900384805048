import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { returnSupplierLogo } from '../../../lib/helpers/crossings';

import CrossingCost from './CrossingCost';
import TripDescription from './TripDescription';

import Row from '../../ui/Grid/Row';
import {
  ResultWrapper, CompanyImg, Col, PriceCol, ImageCol, ImageWrapper,
} from './SearchResultCrossings.style';
import { dataLayerManager } from '../../../lib/dataLayer';
import IbePropTypes from '../../../IbePropTypes';

class SearchResultCrossings extends PureComponent {
  static propTypes = {
    details: PropTypes.shape({
      id: PropTypes.string,
      outboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
      inboundItinerary: PropTypes.shape(IbePropTypes.itinerary),
      charges: PropTypes.shape({
        totalAmount: PropTypes.number,
      }),
      fareType: PropTypes.number,
      supplierCode: PropTypes.string,
    }),
    hasCheaperItxOption: PropTypes.bool,
    index: PropTypes.number,
    isFeatured: PropTypes.bool,
    isITXApplicable: PropTypes.bool,
    ports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    showBackButton: PropTypes.bool,
    onResultClick: PropTypes.func,
    query: PropTypes.shape({
      outfit: PropTypes.shape({}),
    }),
    quote: PropTypes.shape({}),
    suppliers: PropTypes.arrayOf(PropTypes.shape({})),
  }

  static defaultProps = {
    details: null,
    hasCheaperItxOption: false,
    index: null,
    isFeatured: false,
    isITXApplicable: false,
    onResultClick() { },
    query: {},
    quote: {},
    showBackButton: false,
    suppliers: [],
  }

  handleResultClick = () => {
    const outfit = {
      ...this.props.query.outfit,
    };

    const details = this.props.showBackButton ? null : {
      ...this.props.details,
      outfit,
    };
    if (details) {
      dataLayerManager.pushProductClick(
        dataLayerManager.category.CROSSINGS, details, this.props.index,
      );
    }

    this.props.onResultClick(details);
  }

  render() {
    const {
      details: {
        charges: { totalAmount },
        fareType,
        id,
        inboundItinerary,
        outboundItinerary,
        supplierCode,
      },
      hasCheaperItxOption,
      isFeatured,
      isITXApplicable,
      ports,
      quote,
      showBackButton,
    } = this.props;

    const isReturn = !!inboundItinerary;

    const url = returnSupplierLogo(this.props.suppliers, supplierCode);

    return (
      <ResultWrapper isFeatured={isFeatured}>
        <Row size="small">

          <ImageCol>
            <ImageWrapper>
              <CompanyImg
                src={url}
              />
            </ImageWrapper>
          </ImageCol>

          <Col isReturn={isReturn}>
            <TripDescription
              time={outboundItinerary.timeTable}
              transportName={outboundItinerary.transportName}
              departurePort={outboundItinerary.departurePort}
              arrivalPort={outboundItinerary.arrivalPort}
              ports={ports}
              text="Outbound"
            />
          </Col>

          {isReturn &&
            <Col isReturn>
              <TripDescription
                return
                time={inboundItinerary.timeTable}
                transportName={inboundItinerary.transportName}
                departurePort={inboundItinerary.departurePort}
                arrivalPort={inboundItinerary.arrivalPort}
                ports={ports}
                text="Return"
              />
            </Col>
          }

          <PriceCol isITXApplicable={isITXApplicable}>
            <CrossingCost
              id={id}
              isFeatured={isFeatured}
              isReturn={isReturn}
              totalAmount={totalAmount}
              onClick={this.handleResultClick}
              inboundItinerary={inboundItinerary}
              outboundItinerary={outboundItinerary}
              showBackButton={showBackButton}
              hasCheaperItxOption={hasCheaperItxOption}
              isITXApplicable={isITXApplicable}
              isITXCrossing={fareType === 1}
              ports={ports}
              quote={quote}
              url={url}
              index={this.props.index}
            />
          </PriceCol>

        </Row>
      </ResultWrapper>
    );
  }
}

export default SearchResultCrossings;

import PropTypes from 'prop-types';
import React from 'react';

import { TabWrapper, TabLink, Badge } from './ComparisonTabLink.style';

const propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

const defaultProps = {
  active: false,
};

function ComparisonTabLink(props) {
  const {
    index, active, label, quantity, handleClick,
  } = props;

  return (
    <TabWrapper
      role="presentation"
      active={active}
    >
      <TabLink
        href={index}
        active={active}
        role="tab"
        ariaControls={`section${index}`}
        ariaSelected={active && 'true'}
        onClick={event => handleClick(event, index)}
      >
        {label} <Badge active={active}>{quantity}</Badge>
      </TabLink>
    </TabWrapper>
  );
}

ComparisonTabLink.propTypes = propTypes;
ComparisonTabLink.defaultProps = defaultProps;

export default ComparisonTabLink;

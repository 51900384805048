// Handle map control button responsiveness based on map width
const mapResponsiveControls = (containerWidth) => {
  const MAP_LARGE_PX = 1065;
  const MAP_MEDIUM_PX = 768;
  const MAP_SMALL_PX = 282;

  const mapComparisonControl = document.querySelector('#mapComparisonControl');
  const mapFilterControls = document.querySelector('#mapFilterControls');
  if (!mapComparisonControl) {
    return;
  }

  if (containerWidth >= MAP_LARGE_PX) {
    if (mapFilterControls) mapFilterControls.style.bottom = '1.5rem';
    mapComparisonControl.style.left = 'auto';
    mapComparisonControl.style.right = '1rem';
  } else {
    if (mapFilterControls) mapFilterControls.style.bottom = '5.5rem';
    mapComparisonControl.style.left = '1rem';
    mapComparisonControl.style.right = 'unset';
  }

  if (containerWidth <= MAP_MEDIUM_PX) {
    if (mapFilterControls) mapFilterControls.style.width = '100%';
  } else if (mapFilterControls) mapFilterControls.style.width = 'auto';

  if (containerWidth <= MAP_SMALL_PX) {
    mapComparisonControl.style.minWidth = 'unset';
    mapComparisonControl.style.marginLeft = 'unset';
    mapComparisonControl.style.marginBottom = 'unset';
    if (mapComparisonControl.firstChild) {
      mapComparisonControl.firstChild.style.minWidth = 'unset';
      mapComparisonControl.firstChild.style.padding = '0.5rem 0.3rem';
    }
    if (mapComparisonControl.lastChild) {
      mapComparisonControl.lastChild.style.minWidth = 'unset';
      mapComparisonControl.lastChild.style.padding = '0.5rem 1rem';
      mapComparisonControl.lastChild.style.padding = '0.5rem 1rem';
      mapComparisonControl.lastChild.firstChild.style.flexDirection = 'column';
    }
  } else {
    mapComparisonControl.style.display = 'flex';
    mapComparisonControl.style.minWidth = '250px';
    if (mapComparisonControl.firstChild) {
      mapComparisonControl.firstChild.style.minWidth = '95px';
      mapComparisonControl.firstChild.style.padding = '0.5rem 1rem';
    }
    if (mapComparisonControl.lastChild) {
      mapComparisonControl.lastChild.style.minWidth = '140px';
      mapComparisonControl.lastChild.style.padding = '0.5rem 1rem';
      mapComparisonControl.lastChild.firstChild.style.flexDirection = 'row';
    }
  }
};

export default mapResponsiveControls;

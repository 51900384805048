import styled from 'styled-components';

import { Text } from '../ui';
import { Select as SelectBase } from '../ui/Form';
import { above, between } from '../../styles/libs';

export const CostAndQuantity = styled.div`
  text-align: right;

  ${({ theme }) => between(theme.MOBILE_LARGE, theme.DESKTOP)`
    display: flex;
    align-items: center;
  `}

  ${({ theme }) => above(theme.DESKTOP_LARGE)`
    display: flex;
    align-items: center;
  `}
`;

export const Description = styled(Text)`
  font-size: 0.875rem;
  font-weight: 600;
`;

export const Details = styled.div`
  flex: 1;
  padding-right: 1rem;
`;

export const MaxOccupancy = styled(Text)`
  font-size: 0.875rem;
`;

export const Select = styled(SelectBase)``;

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

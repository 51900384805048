import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../../hocs/withDictionary';

import { Modal, Text, Title } from '../../ui';

import { ModalClose, ModalContent } from '../../ui/Modal/Modal.style';

function QuoteCrossingImportantRouteInfoModal({
  inboundErrata, onClose, open, outboundErrata,
}) {
  return (
    <Fragment>
      <Modal active={open} overlay>
        <ModalContent>
          <ModalClose onClick={onClose} />

          <Title
            dictionary={dictionaryItem('QuoteCrossing', 'ImportantRouteInfo')}
            marginBottom
            size={2}
            tag={0}
          />

          <Title
            dictionary={dictionaryItem('QuoteCrossing', 'ImportantRouteInfo', 'Outbound')}
            marginBottom
            marginTop
            size={3}
            tag={1}
          />

          {!!outboundErrata.length && outboundErrata.map(errata => (
            <Text key={errata} paragraph>{errata}</Text>
          ))}

          {!outboundErrata.length &&
            <Text dictionary={dictionaryItem('QuoteCrossing', 'ImportantRouteInfo', 'NoContent')} />
          }

          {inboundErrata &&
            <Fragment>
              <Title
                dictionary={dictionaryItem('QuoteCrossing', 'ImportantRouteInfo', 'Inbound')}
                marginBottom
                marginTop
                size={3}
                tag={1}
              />

              {!!inboundErrata.length && inboundErrata.map(errata => (
                <Text key={errata} paragraph>{errata}</Text>
              ))}

              {!inboundErrata.length &&
                <Text dictionary={dictionaryItem('QuoteCrossing', 'ImportantRouteInfo', 'NoContent')} />
              }
            </Fragment>
          }
        </ModalContent>
      </Modal>
    </Fragment>
  );
}

QuoteCrossingImportantRouteInfoModal.propTypes = {
  inboundErrata: PropTypes.arrayOf(PropTypes.string),
  outboundErrata: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

QuoteCrossingImportantRouteInfoModal.defaultProps = {
  inboundErrata: null,
};

export default memo(QuoteCrossingImportantRouteInfoModal);

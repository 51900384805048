import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { withRouter } from 'next/router';
import { withTheme } from 'styled-components';

import Icon from '../../ui/Icon';
import SearchTabs from '../../Search/SearchTabs';
import { DrawerContent, DrawerHandle } from '../../ui/Drawer';

import DrawerSearchStyled, {
  DrawerSearchInner,
  DrawerHandleBody,
  SearchFormWrapper,
  SearchHandle,
} from './DrawerSearch.style';

import svgArrowRgiht from '../../../static/images/icons/ArrowRight.svg';
import svgArrowLeft from '../../../static/images/icons/ArrowLeft.svg';
import PopOverWelcome from '../../PopOver/components/PopOverWelcome';
import MainHelpBubbleContent from '../../HelpBubble/MainHelpBubbleContent';
import COOKIES, { getCookie, setCookie } from '../../../lib/cookies';
import IbePropTypes from '../../../IbePropTypes';
import mapResponsiveControls from '../../Map/mapResponsiveControls';

const { HIDE_HELP_BUBBLE_COOKIE } = COOKIES;
class DrawerSearch extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.func,
    ]),
    expand: PropTypes.bool,
    hideHandle: PropTypes.bool,
    theme: PropTypes.shape({
      BASKET_TOGGLE_WIDTH: PropTypes.string,
      COLOR_BRAND_PRIMARY: PropTypes.string,
    }),
    toggleBasket: PropTypes.func.isRequired,
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    searchTabsClick: PropTypes.func.isRequired,
    stickyAdvert: PropTypes.bool.isRequired,
    onProductTypeMismatch: PropTypes.func,
    show: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    children: null,
    expand: false,
    hideHandle: false,
    theme: {},
    onProductTypeMismatch: null,
  }

  state = {
    open: true,
    showWelcomePopOver: !getCookie(HIDE_HELP_BUBBLE_COOKIE) &&
      !this.props.router.query.bookingWidget === 'true',
  }

  handleDrawerToggle = (open) => {
    let newOpen = open;
    if (typeof newOpen === 'undefined') newOpen = !this.state.open;
    this.setState({ open: newOpen });

    // wait for drawer to animate
    setTimeout(() => {
      const mapElement = document.querySelector('.Map');
      if (mapElement?.clientWidth) {
        mapResponsiveControls(mapElement.clientWidth);
      }
    }, 2000);
  }

  handlePopOverToggle = () => {
    this.setState({
      showWelcomePopOver: false,
    }, () => setCookie({ name: HIDE_HELP_BUBBLE_COOKIE }, true));
  };

  render() {
    const {
      children,
      expand,
      router: { query },
      stickyAdvert,
      theme,
      toggleBasket,
      hideHandle,
      show,
    } = this.props;

    const { showWelcomePopOver } = this.state;
    const isBookingWidget = query.bookingWidget === 'true';

    return (
      <DrawerSearchStyled
        direction="left"
        expand={expand}
        forwardRef={this.searchListing}
        inView={!query.siteCode}
        isBookingWidget={isBookingWidget}
        offset={theme.BASKET_TOGGLE_WIDTH}
        onRequestChange={this.handleDrawerToggle}
        open={this.state.open}
        position="relative"
        useMarginForAnimation
        zIndex={2}
        show={show}
      >
        <DrawerSearchInner>
          <PopOverWelcome
            onClose={this.handlePopOverToggle}
            open={showWelcomePopOver}
            placement="right-start"
            renderContent={() => <MainHelpBubbleContent />}
            size="large"
            variant="light"
          >
            {({ innerRef }) => (
              <SearchFormWrapper
                ref={innerRef}
                visible={typeof query.campsiteId === 'undefined' && typeof query.reviews === 'undefined'}
              >
                <DrawerContent
                  backgroundColor={isBookingWidget ? 'transparent' : theme.COLOR_BRAND_PRIMARY}
                >
                  <SearchTabs
                    stickyAdvert={stickyAdvert}
                    toggleBasket={toggleBasket}
                    searchTabsClick={this.props.searchTabsClick}
                    onProductTypeMismatch={this.props.onProductTypeMismatch}
                  >
                    {searchTabsProps => children({
                      formRef: searchTabsProps.formRef,
                    })}
                  </SearchTabs>
                </DrawerContent>

                {!hideHandle && !isBookingWidget &&
                  <DrawerHandle
                    handleDrawerToggle={() => this.handleDrawerToggle(!this.state.open)}
                    handleLabel="Open search"
                  >
                    <DrawerHandleBody>
                      <SearchHandle>
                        <Icon icon={this.state.open ? svgArrowLeft : svgArrowRgiht} />
                      </SearchHandle>
                    </DrawerHandleBody>
                  </DrawerHandle>
                }
              </SearchFormWrapper>
            )}
          </PopOverWelcome>
        </DrawerSearchInner>
      </DrawerSearchStyled>
    );
  }
}

export default compose(withRouter, withTheme)(DrawerSearch);

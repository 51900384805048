import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Picker } from '.';

import withDatepicker from '../hocs/withDatepicker';

import Wrapper from './Datepicker.style';

class Datepicker extends PureComponent {
  static propTypes = {
    anchor: PropTypes.shape({}),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.arrayOf(PropTypes.func),
      PropTypes.func,
      PropTypes.node,
    ]),
    className: PropTypes.string,
    closingIgnoreList: PropTypes.arrayOf(PropTypes.string),
    datepicker: PropTypes.shape().isRequired,
    endDate: PropTypes.string,
    fieldFocus: PropTypes.string,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func.isRequired,
    handleClear: PropTypes.func,
    handleClose: PropTypes.func.isRequired,
    handleDatepickerChange: PropTypes.func.isRequired,
    handleFocus: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func,
    handleOpen: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    fullscreen: PropTypes.bool,
    options: PropTypes.shape().isRequired,
    'data-testid': PropTypes.string,
    startDate: PropTypes.string,
    static: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    anchor: null,
    children: null,
    className: null,
    closingIgnoreList: [],
    endDate: '',
    fullscreen: false,
    handleInputChange() { },
    'data-testid': null,
    startDate: '',
    fieldFocus: '',
    handleBlur() {},
    handleClear: null,
  };

  render() {
    const { children, datepicker, handleOpen } = this.props;

    const pickerProps = {
      anchor: this.props.anchor,
      closingIgnoreList: this.props.closingIgnoreList,
      datepicker,
      endDate: this.props.endDate,
      fieldFocus: this.props.fieldFocus,
      fullscreen: this.props.fullscreen,
      handleBlur: this.props.handleBlur,
      handleClear: this.props.handleClear,
      handleClose: this.props.handleClose,
      handleChange: this.props.handleChange,
      handleFocus: this.props.handleFocus,
      handleDatepickerChange: this.props.handleDatepickerChange,
      handleSubmit: this.props.handleSubmit,
      open: this.props.datepicker.open,
      startDate: this.props.startDate,
      static: this.props.static,
    };

    return (
      <Wrapper>
        {children && children({
          ...pickerProps,
          handleOpen,
        })}
        {datepicker.open &&
          <Picker
            {...pickerProps}
            data-testid={this.props['data-testid']}
          />
        }
      </Wrapper>
    );
  }
}

export default withDatepicker(Datepicker);

import { Helper } from './Helper';
import { differenceInDays } from '../../dates';

export default class PayloadHelper extends Helper {
  constructor(
    payload,
  ) {
    super();
    let newPayload = { ...payload };
    if (!newPayload) newPayload = {};

    if (payload.campsiteBooking) {
      // eslint-disable-next-line
      const bookingDates =  payload.campsiteBooking.pitches[0].bookingDates;
      this.amountOfNights =
        this.getAmountOfNightsFromDates(bookingDates.toDate, bookingDates.fromDate);
    } else {
      this.amountOfNights = 0;
    }

    // eslint-disable-next-line
    this.amountOfSiteNightVouchers = this.getAmountOfSiteNightVouchers(payload.extras);

    this.hasInboundCrossing = this.hasAnyCrossingInbound(payload.inboundItinerary);
  }

  hasAnyCrossingInbound = (inboundItinerary) => {
    const isInbound = !!inboundItinerary && !!inboundItinerary.crossingRouteCode;

    return isInbound;
  }

  // gets 2 dates and returns number of nights between them.
  getAmountOfNightsFromDates = (toDate, fromDate) => {
    if (!toDate || !fromDate) return 0;

    const payloadNights =
      differenceInDays(
        new Date(toDate),
        new Date(fromDate),
      );

    return payloadNights;
  }
}

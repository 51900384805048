import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../ui/Form';
import testingAttr from '../../lib/testingAttr';
import { blockInvalidChar, formatToTwoDecimalPlaces } from '../../lib/numberField';

class OutfitLength extends PureComponent {
  static propTypes = {
    customStyled: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ])),
      PropTypes.string,
      PropTypes.shape({}),
    ]),
    'data-testid': PropTypes.string,
    dataError: PropTypes.bool,
    value: PropTypes.number,
    innerRef: PropTypes.shape({}),
    onBlur: PropTypes.func,
    onError: PropTypes.func,
    placeholder: PropTypes.string,
    step: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    onInputChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    'data-testid': '',
    dataError: false,
    error: false,
    customStyled: '',
    step: 50,
    innerRef: null,
    onBlur() { },
    onError() { },
    placeholder: 'Length (m)',
    value: '',
  };

  handleChange = async (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    // Instant UX feedback to stop more than 2 decimal places.
    if (
      fieldName === 'vehicleLength'
      || fieldName === 'towLength'
      || fieldName === 'vehicleHeight'
      || fieldName === 'towHeight'
    ) {
      const finalValue = formatToTwoDecimalPlaces(fieldValue);

      // Separate state of input value and the payload amount to
      // ensure formatted correctly e.g. 5.01 doesn't format to 5.
      await this.props.onInputChange(finalValue);

      const syntheticEvent = {
        target: {
          name: fieldName,
          value: finalValue,
        },
      };

      // Send value amount to onChange for payload processing
      // (e.g. formatting M to mm)
      await this.props.onChange(syntheticEvent);
    }
  }

  render() {
    const {
      customStyled,
      dataError,
      innerRef,
      placeholder,
      value,
      ...inputProps
    } = this.props;

    inputProps.error = this.props.error;
    inputProps.onChange = this.handleChange;

    return (
      <Input
        {...inputProps}
        block
        customStyled={customStyled}
        error={dataError}
        outerRef={innerRef && innerRef}
        type="number"
        placeholder={placeholder}
        value={value || ''}
        onKeyDown={blockInvalidChar}
        {...testingAttr(this.props['data-testid'])}
      />
    );
  }
}

export default OutfitLength;

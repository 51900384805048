import React, { memo } from 'react';

import { DAYS } from '../../constants';

import { DayOfTheWeek, PickerHeader, PickerHeaderBody } from './DaysOfTheWeek.style';

function DaysOfTheWeek() {
  return (
    <PickerHeader>
      <PickerHeaderBody>
        {DAYS.map(day => (
          <DayOfTheWeek key={day}>{day}</DayOfTheWeek>
        ))}
      </PickerHeaderBody>
    </PickerHeader>
  );
}

export default memo(DaysOfTheWeek);

import { fetchAllMatchingRoutes } from './helpers/crossings';
import sortArrayOfObjects from './sortArrayOfObjects';

export function cleanPartyMembers(partyMembers) {
  return partyMembers.map((partyMember) => {
    const {
      __typename, isNew, age, ...updatedPartyMember
    } = partyMember;
    return updatedPartyMember;
  });
}

export function cleanPayload({
  partyMembers, inboundItinerary, outboundItinerary, outfit,
}) {
  const newPayload = {
    partyMembers, inboundItinerary, outboundItinerary, outfit,
  };

  // if is a single journey remove, inboundItinerary.
  if (newPayload.inboundItinerary) {
    if (!newPayload.inboundItinerary.routeCode) {
      delete newPayload.inboundItinerary;
    }
  }
  return newPayload;
}

export function removeUniqueRoutes(removeFrom, compareAgainst) {
  const newArray =
    removeFrom
      .filter(item => compareAgainst.map(({ supplierCode }) => supplierCode)
        .includes(item.supplierCode));

  return newArray;
}

export function createRouteCode(
  allPossibleRoutesOutbound,
  allPossibleRoutesInbound,
  {
    allowAlternativeRoutes,
  },
) {
  const outboundRoutes = allPossibleRoutesInbound.length
    ? removeUniqueRoutes(allPossibleRoutesOutbound, allPossibleRoutesInbound)
    : allPossibleRoutesOutbound;

  const inboundRoutes = removeUniqueRoutes(allPossibleRoutesInbound, allPossibleRoutesOutbound);

  const outboundRoutesSorted = sortArrayOfObjects(outboundRoutes, 'supplierCode');
  const inboundRoutesSorted = sortArrayOfObjects(inboundRoutes, 'supplierCode');

  const routeCodeKey = allowAlternativeRoutes ? 'routeCodeWeb' : 'routeCode';

  const outboundRouteCodes = [...new Set(outboundRoutesSorted.map(route => route[routeCodeKey]))]
    .filter(value => !!value)
    .join(',');

  const inboundRouteCodes = [...new Set(inboundRoutesSorted.map(route => route[routeCodeKey]))]
    .filter(value => !!value)
    .join(',');

  return [outboundRouteCodes, inboundRouteCodes];
}

export function parseItinerary(itinerary) {
  return {
    crossingRouteCode: itinerary.crossingRouteCode,
    crossingDateTime: itinerary.crossingDateTime,
  };
}

// add all route codes to itinerary.
export const handleRouteCodeLogic = (routes, payload) => {
  const newPayload = { ...payload };
  const { allowAlternativeRoutes, sameReturnRoute } = payload;

  const allPossibleRoutesOutbound =
    fetchAllMatchingRoutes(payload.outboundItinerary, routes);
  const allPossibleRoutesInbound =
    fetchAllMatchingRoutes(payload.inboundItinerary, routes);

  const [
    outboundCrossingRouteCode,
    inboundCrossingRouteCode,
  ] = createRouteCode(
    allPossibleRoutesOutbound,
    allPossibleRoutesInbound,
    { allowAlternativeRoutes },
    sameReturnRoute,
  );

  newPayload.outboundItinerary.crossingRouteCode = outboundCrossingRouteCode;

  if (inboundCrossingRouteCode) {
    newPayload.inboundItinerary.crossingRouteCode = inboundCrossingRouteCode;
  }

  return payload;
};

export function parseCrossingsPayload(payload) {
  const newPayload = cleanPayload({ ...payload });

  newPayload.partyMembers = cleanPartyMembers(newPayload.partyMembers);

  if (newPayload.inboundItinerary) {
    newPayload.inboundItinerary =
      parseItinerary(payload.inboundItinerary);
  }

  newPayload.outboundItinerary =
    parseItinerary(payload.outboundItinerary);

  return newPayload;
}

import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import Text from '../../ui/Text';
import theme from '../../../styles/config/theme';
import { formatPortNameByCode } from '../../../lib/helpers/crossings';

import Row from '../../ui/Grid/Row';

import TripDescriptionStyled, {
  Arrow,
  ArrowContainer,
  CenteredText,
  Col,
  MiddleCol,
  ShipText,
  TextWrapper,
} from './TripDescription.style';

function truncate(value, limit) {
  if (value.includes(' ')) return false;
  return value.length > limit;
}

function TripDescription({
  arrivalPort,
  departurePort,
  ports,
  text,
  time,
  transportName,
}) {
  const portNameStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const departurePortName = formatPortNameByCode(ports, departurePort);
  const arrivalPortName = formatPortNameByCode(ports, arrivalPort);
  const charLimit = 10;

  return (
    <TripDescriptionStyled>
      <Row flex={1} size="xs">
        <Col>
          <TextWrapper>
            <Text
              size="0.875rem"
              weight="bold"
              align="center"
              style={truncate(departurePortName, charLimit) ? portNameStyle : null}
              title={departurePortName}
            >
              {departurePortName}
            </Text>
            <Text size="0.875rem" align="center">
              {format(time.fromDate, 'DD MMM YY')}
            </Text>
            <Text size="0.875rem" weight="bold" align="center">
              {format(time.fromDate, 'HH:mm')}
            </Text>
          </TextWrapper>
        </Col>

        <MiddleCol>
          <CenteredText size="0.875rem" color={theme.COLOR_LAKE_GREEN_ORIGINAL}>
            {text}
          </CenteredText>

          <ArrowContainer>
            <Arrow>&nbsp;</Arrow>
          </ArrowContainer>
          {transportName &&
            <ShipText title={transportName}>
              {transportName}
            </ShipText>
          }
        </MiddleCol>

        <Col>
          <TextWrapper>
            <Text
              size="0.875rem"
              weight="bold"
              align="center"
              style={truncate(arrivalPortName, charLimit) ? portNameStyle : null}
              title={arrivalPortName}
            >
              {arrivalPortName}
            </Text>
            <Text size="0.875rem" align="center">
              {format(time.toDate, 'DD MMM YY')}
            </Text>
            <Text size="0.875rem" weight="bold" align="center">
              {format(time.toDate, 'HH:mm')}
            </Text>
          </TextWrapper>
        </Col>
      </Row>
    </TripDescriptionStyled>
  );
}

TripDescription.propTypes = {
  text: PropTypes.string.isRequired,
  departurePort: PropTypes.string.isRequired,
  transportName: PropTypes.string,
  arrivalPort: PropTypes.string.isRequired,
  ports: PropTypes.arrayOf(PropTypes.shape).isRequired,
  time: PropTypes.shape({
    toDate: PropTypes.string,
    fromDate: PropTypes.string,
  }).isRequired,
};

TripDescription.defaultProps = {
  transportName: '',
};

export default TripDescription;

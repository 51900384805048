import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import {
  ColLength, ColLengths, Row, ColType,
} from './OutfitLayout.style';

const propTypes = {
  renderVehicleMake: PropTypes.func,
  renderVehicleModel: PropTypes.func,
  renderVehicleRegistration: PropTypes.func,
};

const defaultProps = {
  renderVehicleMake: null,
  renderVehicleModel: null,
  renderVehicleRegistration: null,
};

function OutfitDetailLayout({
  renderVehicleMake,
  renderVehicleModel,
  renderVehicleRegistration,
}) {
  return (
    <>
      <Row>
        {!!renderVehicleMake &&
        <ColType>
          {renderVehicleMake()}
        </ColType>}
        {!!renderVehicleModel &&
        <ColLengths>
          <Row>
            <ColLength>
              {renderVehicleModel()}
            </ColLength>
          </Row>
        </ColLengths>}
      </Row>
      {!!renderVehicleRegistration &&
      <Row>
        <ColType>
          {renderVehicleRegistration()}
        </ColType>
        <ColLengths />
      </Row>}
    </>
  );
}

OutfitDetailLayout.propTypes = propTypes;
OutfitDetailLayout.defaultProps = defaultProps;

export default withTheme(OutfitDetailLayout);

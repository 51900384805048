import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { isValid, isBefore, isAfter } from 'date-fns';
import { v4 as uuid } from 'uuid';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import { compose, withApollo } from 'react-apollo';
import { useLazyQuery } from '@apollo/client';
import { add } from 'lodash';
import PropTypes from 'prop-types';

import FETCH_POLICY from '../../constants/FetchPolicy';
import GET_GUEST_PRICE from './graphql/getGuestPrice';

import Label from '../ui/Form/Label';
import Text from '../ui/Text';
import { Select } from '../ui/Form';
import { MessageWarning } from '../ui/Message';
import MessageWarningStyle from '../ui/Message/MessageWarning.style';
import { ButtonBrand, ButtonLink } from '../ui/Button';
import { LoadingSpinner } from '../ui/Loading';
import theme from '../../styles/config/theme';
import binSvg from '../../static/images/icons/bin.svg';
import svgCalendar from '../../static/images/icons/Calendar.svg';

import {
  Datepicker,
  DateInputWrapper,
} from '../SearchForm/Campsites/CampsitesDatePickerWrapper.style';
import { Body, Input, BrandedText } from './Guests.style';
import { FormGroup, FormGroupItem } from '../SearchForm/SearchForm.style';

import {
  DATE_FORMAT_INPUT,
  DATE_FORMAT_DROPDOWN,
  DATE_FORMAT_DEFAULT,
} from '../../config/locale';
import { dictionaryItem } from '../../hocs/withDictionary';
import PRODUCT_TYPES from '../../config/products';
import { ADULT, CHILD, INFANT } from '../../lib/partyMemberTypes';
import { formatToHyphenFormat } from '../../lib/format';
import formatPrice from '../../lib/formatPrice';
import {
  parseVariables,
  guestOptions,
  validateDateOfBirth,
} from './guestHelpers';
import IbePropTypes from '../../IbePropTypes';

function GuestForm({
  onAddGuest,
  onCancel,
  configuration,
  maxDate,
  minDate,
  guest,
  payload,
  client,
  minChildAge,
  maxChildAge,
  isOverseas,
}) {
  const dateOfBirth = guest.dateOfBirth && moment(guest.dateOfBirth, DATE_FORMAT_DEFAULT);
  const [day, setDay] = useState(dateOfBirth?.date());
  const [month, setMonth] = useState(dateOfBirth?.month());
  const [year, setYear] = useState(dateOfBirth?.year());
  const [validated, setValidated] = useState(false);
  const [guestPrice, setGuestPrice] = useState(null);
  const [editingGuest, setEditingGuest] = useState({
    ...guest,
    stayStart: guest?.stayStart || minDate,
    stayEnd: guest?.stayEnd || maxDate,
  });
  const [inputDate, setInputDate] = useState({
    stayStart: guest?.stayStart || moment(minDate, DATE_FORMAT_DEFAULT).format(DATE_FORMAT_INPUT),
    stayEnd: guest?.stayEnd || moment(maxDate, DATE_FORMAT_DEFAULT).format(DATE_FORMAT_INPUT),
  });
  const [dobError, setDobError] = useState(false);

  const isChildType = editingGuest.type === CHILD || editingGuest.type === INFANT;

  const [
    getGuestPrice,
    { loading, data },
  ] = useLazyQuery(GET_GUEST_PRICE, {
    client,
    fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
  });

  const partyOptions = guestOptions(configuration);
  const stayStartRef = useRef(null);
  const { products } = configuration;
  let pitchConfig = null;
  const product = products.find(
    productItem => productItem.productCode === PRODUCT_TYPES.CAMC_UK_PRODUCT,
  );
  const { partyConfig } = product ?? {};
  if (partyConfig) {
    const childToAge = maxChildAge ?? partyConfig.find(({ type }) => type === 1).toAge;
    const childFromAge = minChildAge ?? partyConfig.find(({ type }) => type === 1).fromAge;
    const adultFromAge = childToAge !== null ? add(childToAge, 1) : null;
    pitchConfig = {
      childToAge, childFromAge, adultFromAge, minChildAge,
    };
  }

  useEffect(() => {
    let stayStart = editingGuest?.stayStart;
    let stayEnd = editingGuest?.stayEnd;
    if (stayStart && isBefore(stayStart, minDate)) {
      stayStart = minDate;
    }
    if (stayEnd && isAfter(stayEnd, maxDate)) {
      stayEnd = maxDate;
    }
    setEditingGuest({
      ...editingGuest,
      stayStart: stayStart || minDate,
      stayEnd: stayEnd || maxDate,
    });
  }, [minDate, maxDate]);

  useEffect(() => {
    const { price } = data?.guestPrice ?? {};
    if (price !== guestPrice) {
      setGuestPrice(price);
    }
  }, [data]);

  useEffect(() => {
    setDobError(false);
    if (day && year && month !== undefined) {
      const dateInput = moment(
        `${day}-${Number(month) + 1}-${year}`,
        DATE_FORMAT_DROPDOWN,
        true,
      );
      const formattedDateOfBirth = dateInput.format(DATE_FORMAT_DEFAULT);
      if (!dateInput.isValid()) {
        setDobError(true);
      } else {
        const { guestType, isValidAge } = validateDateOfBirth(
          formattedDateOfBirth, editingGuest.type, partyOptions, pitchConfig,
        );
        setEditingGuest({
          ...editingGuest,
          type: isValidAge ? editingGuest.type : guestType,
          dateOfBirth: formattedDateOfBirth,
        });
      }
    }
  }, [day, month, year]);

  useEffect(() => {
    if (validated) {
      const tempId = editingGuest?.personId || editingGuest?.tempId;
      onAddGuest({
        stayStart: editingGuest.stayStart,
        stayEnd: editingGuest.stayEnd,
        type: editingGuest.type,
        dateOfBirth: editingGuest.dateOfBirth,
        tempId: !tempId ? uuid() : tempId,
        price: guestPrice,
      });
    }
  }, [validated]);

  useEffect(() => {
    const isAdult = editingGuest.type === ADULT;
    const notOverseasAdult = !isAdult && isOverseas;
    const hasRequiredFields =
      editingGuest.type !== undefined &&
      editingGuest.stayStart &&
      editingGuest.stayEnd;
    // Only check if DOB is present for overseas children and infants
    if (
      hasRequiredFields &&
      editingGuest.stayStart !== editingGuest.stayEnd &&
      (notOverseasAdult ? !!editingGuest.dateOfBirth : true)
    ) {
      getGuestPrice({
        variables: parseVariables({
          pitchCode: payload.pitchTypeId,
          productCode: PRODUCT_TYPES.CAMC_UK_PRODUCT,
          guestType: editingGuest.type,
          ...(editingGuest.dateOfBirth && {
            dateOfBirth: editingGuest.dateOfBirth,
          }),
          arrivalDate: editingGuest.stayStart,
          departureDate: editingGuest.stayEnd,
        }, 'Reservations/GuestMemberPrice'),
      });
    }
  }, [editingGuest]);

  const onSelectGuestType = (e) => {
    setEditingGuest({
      ...editingGuest,
      dateOfBirth: isChildType || !isOverseas ? editingGuest.dateOfBirth : undefined,
      type: Number(e.target.value),
    });
    setYear(undefined);
    setMonth(undefined);
    setDay(undefined);
  };

  const onSelectDates = (dates) => {
    const [stayStart, stayEnd] = dates ?? [];
    setEditingGuest({
      ...editingGuest,
      stayStart: stayStart || stayEnd,
      stayEnd: stayEnd || stayStart,
    });
    setInputDate({
      stayStart: stayStart || stayEnd,
      stayEnd: stayEnd || stayStart,
    });
  };

  const onInputDates = (e) => {
    const dateType = e.target.name;
    let newDate = formatToHyphenFormat(
      e.target.value,
    );
    if (e.target.value?.length === 10 && isValid(new Date(newDate))) {
      if (isBefore(newDate, minDate) && dateType === 'stayStart') {
        newDate = minDate;
      }
      if (isAfter(newDate, maxDate) && dateType === 'stayEnd') {
        newDate = maxDate;
      }
      setEditingGuest({
        ...editingGuest,
        [dateType]: newDate,
      });
      setInputDate({
        ...inputDate,
        [dateType]: moment(new Date(newDate)).format(
          DATE_FORMAT_INPUT,
        ),
      });
    } else {
      setInputDate({
        ...inputDate,
        [dateType]: e.target.value,
      });
    }
  };

  const handleAddGuest = () => setValidated(true);

  const formattedstayStart = formatToHyphenFormat(editingGuest?.stayStart);

  const formattedStayEnd = formatToHyphenFormat(editingGuest?.stayEnd);

  const getDateInputValue = (dateValue) => {
    if (!dateValue || dateValue.length !== 10) {
      return inputDate?.stayStart;
    }
    return moment(formatToHyphenFormat(dateValue)).format(
      DATE_FORMAT_INPUT,
    );
  };

  const departureDateMissing = editingGuest?.stayStart === editingGuest?.stayEnd;
  const now = new Date();
  const previousYear = now.getFullYear() - 1;

  return (
    <Body>
      <Datepicker
        anchor={stayStartRef}
        closingIgnoreList={['stayStart', 'stayEnd']}
        fieldFocus=""
        startDate={
          editingGuest?.stayStart && isValid(new Date(formattedstayStart))
            ? formattedstayStart
            : ''
        }
        endDate={
          editingGuest?.stayEnd && isValid(new Date(formattedStayEnd))
            ? formattedStayEnd
            : ''
        }
        handleChange={() => {}}
        handleClear={() => setEditingGuest({
          ...editingGuest,
          stayStart: '',
          stayEnd: '',
        })}
        options={{
          dateRange: true,
          maxDate,
          minDate,
          months: 1,
          name: 'guestForm_dateRange',
        }}
        onChange={onSelectDates}
      >
        {({ handleOpen }) => (
          <>
            <FormGroup marginLess responsive>
              <FormGroupItem>
                <Label
                  dictionary={dictionaryItem('GuestForm', 'Age')}
                />
                <Select
                  block
                  onChange={onSelectGuestType}
                  placeholder="Select"
                  disabled={false}
                  value={editingGuest.type}
                >
                  <>
                    {partyOptions
                      .map((partyOption) => (
                        <option key={partyOption.id} value={partyOption.type}>
                          {partyOption.label}
                        </option>
                      ))}
                  </>
                </Select>
              </FormGroupItem>
              <FormGroupItem>
                <Label
                  dictionary={dictionaryItem('GuestForm', 'Arrive')}
                />
                <DateInputWrapper>
                  <Input
                    ariaLabel="Arrive date"
                    block
                    type="text"
                    name="stayStart"
                    id="stayStart"
                    icon={svgCalendar}
                    inputRef={stayStartRef}
                    onChange={onInputDates}
                    onFocus={() => {}}
                    onKeyDown={() => {}}
                    onClick={handleOpen}
                    placeholder={DATE_FORMAT_INPUT}
                    required
                    value={getDateInputValue(inputDate?.stayStart)}
                  />
                </DateInputWrapper>
              </FormGroupItem>
              <FormGroupItem>
                <Label
                  dictionary={dictionaryItem('GuestForm', 'Depart')}
                />
                <DateInputWrapper>
                  <Input
                    ariaLabel="Depart date"
                    block
                    type="text"
                    name="stayEnd"
                    id="stayEnd"
                    icon={svgCalendar}
                    inputRef={stayStartRef}
                    onChange={onInputDates}
                    onFocus={() => {}}
                    onKeyDown={() => {}}
                    onClick={handleOpen}
                    placeholder={DATE_FORMAT_INPUT}
                    required
                    value={getDateInputValue(inputDate?.stayEnd)}
                  />
                </DateInputWrapper>
              </FormGroupItem>
            </FormGroup>
            {
              isOverseas && isChildType && (
              <FormGroup marginLess>
                <FormGroupItem>
                  <Label
                    dictionary={dictionaryItem('GuestForm', 'DateOfBirth')}
                  />
                  <>
                    <DayPicker
                      classes="customSelect"
                      defaultValue="Day"
                      year={year || previousYear}
                      month={month || 0}
                      required
                      value={day}
                      onChange={(newDay) => {
                        setDay(newDay);
                      }}
                    />
                    <MonthPicker
                      classes="customSelect"
                      defaultValue="Month"
                      short
                      year={year || previousYear}
                      required
                      value={month}
                      onChange={(newMonth) => setMonth(Number(newMonth))}
                    />
                    <YearPicker
                      classes="customSelect"
                      defaultValue="Year"
                      reverse
                      required
                      value={year}
                      onChange={(newYear) => {
                        setYear(newYear);
                      }}
                    />
                  </>
                  {dobError && <MessageWarning
                    marginTop
                    dictionary={dictionaryItem('GuestForm', 'DateOfBirth', 'Error')}
                  />}
                </FormGroupItem>
              </FormGroup>
              )
            }
          </>
        )}
      </Datepicker>
      <FormGroup marginLess>
        <FormGroupItem>
          <ButtonLink
            dictionary={dictionaryItem('Guests', 'Cancel')}
            icon={binSvg}
            color={theme.COLOR_BERRY_RED}
            direction="row-reverse"
            textDecoration="underline"
            textTransform="none"
            onClick={onCancel}
          />
        </FormGroupItem>
        <FormGroupItem>
          <Text dictionary={dictionaryItem('Guests', 'SingleCost')} />
          {loading ? <LoadingSpinner inline size="0.5rem" /> : (
            <>
              {guestPrice || guestPrice === 0 ?
                <BrandedText>{formatPrice(guestPrice)}</BrandedText> : '--'}
            </>
          )}
        </FormGroupItem>
        <FormGroupItem>
          <ButtonBrand
            onClick={handleAddGuest}
            disabled={guestPrice === null ||
              guestPrice === undefined ||
              editingGuest?.type === undefined ||
              (isOverseas && (!editingGuest?.dateOfBirth && editingGuest?.type === CHILD)) ||
              departureDateMissing}
            block
            dictionary={dictionaryItem('Guests', 'AddToStay')}
          />
        </FormGroupItem>
      </FormGroup>
      {departureDateMissing && <MessageWarningStyle
        marginTop
        dictionary={dictionaryItem('Guests', 'DepartureDate', 'Missing', 'Error')}
      />}
    </Body>
  );
}

GuestForm.propTypes = {
  configuration: PropTypes.shape(IbePropTypes.configuration).isRequired,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  onAddGuest: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  payload: PropTypes.shape(IbePropTypes.payload).isRequired,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  guest: PropTypes.shape(IbePropTypes.guest),
  minChildAge: PropTypes.number,
  maxChildAge: PropTypes.number,
  isOverseas: PropTypes.bool.isRequired,
};

GuestForm.defaultProps = {
  guest: {},
  maxChildAge: undefined,
  maxDate: undefined,
  minChildAge: undefined,
  minDate: undefined,
};

export default compose(
  withApollo,
)(GuestForm);

import styled, { css } from 'styled-components';

import Icon from '../ui/Icon';
import { Body as StyledBody } from '../SiteCard/SiteCard.style';
import StyledLabel from '../ui/Form/Label';

import Text from '../ui/Text';
import { above } from '../../styles/libs';

export default styled.div`
  display: block;
  ${({ branded, theme }) => branded && css`
    padding: 0.5rem 2rem;
    background-color: ${theme.COLOR_BRAND_PRIMARY};
  `}
`;

export const Body = styled(StyledBody)`
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  ${({ theme, withBackground }) => withBackground && css`
    background-color: ${theme.COLOR_GRAY_LIGHTER};
  `}
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const Label = styled(StyledLabel)`
  margin-left: 1rem;
`;

export const CheckboxLabel = styled.label``;

export const CheckboxText = styled(Text)`
  margin-left: 1rem;
`;

export const TitleAndIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AvailabilityDurationInfoList = styled.ul`
  margin: 1rem 0 0;
  padding-inline-start: 1.5rem;
`;

export const AvailabilityDurationInfoItem = styled.li``;

export const LowAvailabilityKey = styled(Text)``;

export const LowAvailabilityValue = styled(Text)`
  color: ${({ theme }) => theme.COLOR_CYAN_DARK};
  font-weight: bold;
`;

export const FullKey = styled(Text)``;

export const FullValue = styled(Text)`
  color: #333;
  font-weight: bold;
`;

export const ToggleButtonColumnContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const ToggleButton = styled.button`
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
  border: 2px solid ${({ selected, theme }) => (selected ? theme.COLOR_BRAND_PRIMARY : 'transparent')};
  border-radius: 5px;
  margin-top: 1rem;
  text-align: left;
  transition: all 250ms ease;
  width: 100%;
  outline: 0;
  cursor: pointer;

  &:disabled {
    background-color: ${({ theme }) => theme.COLOR_WHITE};
    cursor: default;
  }

  &:hover:not(:disabled) {
    border-color: ${({ selected, theme }) => (selected ? theme.COLOR_BRAND_PRIMARY : theme.COLOR_GRAY_LIGHT)};
  }
  /* stylelint-disable */
  ${ToggleButtonColumnContainer} > & + & {
    margin-left: 1rem;
  }
  /* stylelint-enable */
`;

export const ToggleBodyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5625rem 1.5rem;
  margin: 0 -0.75rem;
`;

export const ToggleContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
`;

export const ToggleContent = styled.div`
  flex: 0 0 100%;
  margin-bottom: 1rem;
  padding-left: 1.25rem;
  ${({ theme }) => above(theme.MOBILE_LARGE)`
    flex: 1;
    margin-bottom: 0;
  `}
  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}
`;

export const ToggleTitle = styled(Text)`
  display: flex;
  width: 100%;
  font-size: 0.9375rem;
  font-weight: 600;
  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}
`;

export const ToggleDescription = styled(Text)`
  font-size: 0.9375rem;
`;

export const ToggleSubTitle = styled(Text)`
  font-size: 0.8rem;
`;

export const Tick = styled(Icon)`
  color: ${({ selected, theme }) => (selected ? theme.COLOR_BRAND_PRIMARY : theme.COLOR_GRAY_LIGHT)};
  font-size: 1.25rem;
  margin: 0.75rem 0;
  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SummaryItem = styled(Text)`
  ${({ theme }) => above(theme.TABLET)`
    display: inline;

    & + & {
      margin-left: 1rem;
    }
  `}
`;

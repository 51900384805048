import styled, { css } from 'styled-components';

import { above, below, between } from '../../../../../styles/libs';
import Icon from '../../../Icon';

export const Cell = styled.td`
  user-select: none;
  font-size: 0.875rem;
  position: relative;
  width: 14.28571%;

  &::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 1px 6px;
  background-color: ${({ visible, theme }) => (visible ? 'transparent' : theme.COLOR_GRAY_LIGHTEST)};
  border: 1px solid transparent;
  color: inherit;

  &:disabled {
    cursor: default;
  }

  ${({ theme }) => below(theme.MOBILE_LARGE)`
    padding: 1px 5px;
  `}

  ${({ theme }) => below(theme.MOBILE_MEDIUM)`
    padding: 1px 3px;
  `}

  ${({
    availabilitySearchLoading,
    disabled,
    inRange,
    selected,
    theme,
    isSelectedEventClosed,
    isSelectedEventFull,
    hasPrice,
    isLastSelectedDay,
    isSearchFormPicker,
  }) => {
    if (availabilitySearchLoading) {
      return '';
    }

    if ((selected || inRange) && isSearchFormPicker) {
      return `
        background-color: ${theme.COLOR_CALENDAR_SELECTED};
        color: #fff;

        &:hover,
        &:focus {
          background-color: ${theme.COLOR_CALENDAR_SELECTED};
        }`;
    }

    if ((selected || inRange) && (isSelectedEventClosed || isSelectedEventFull)) {
      return `
        background-color: ${theme.COLOR_DANGER};
        color: #fff;

        &:hover {
          background-color: ${theme.COLOR_DANGER};
        }`;
    }

    if (disabled) {
      return `
        background-color: #fff;
        color: rgba(0, 0, 0, 0.25);
      `;
    }

    // Styling for last selected date which has a 50/50 background
    if (
      !isSearchFormPicker
      && isLastSelectedDay
      && !isSelectedEventClosed
      && !isSelectedEventFull
    ) {
      return `
        background: linear-gradient(to bottom, ${theme.COLOR_CALENDAR_SELECTED} 50%, ${theme.COLOR_CALENDAR_AVAILABLE} 50%);
        color: #fff;
        border-bottom: 1px solid #FFF;
        border-top: none;

        &:hover,
        &:focus {
          background: linear-gradient(to bottom, ${theme.COLOR_CALENDAR_SELECTED} 50%, ${theme.COLOR_CALENDAR_AVAILABLE} 50%);
        }`;
    }

    if (selected && !isSelectedEventFull) {
      return `
        background-color: ${theme.COLOR_CALENDAR_SELECTED};
        color: #fff;

        &:hover,
        &:focus {
          background-color: ${theme.COLOR_CALENDAR_SELECTED};
        }`;
    }

    if (!isSearchFormPicker && hasPrice) {
      return `
        background-color: ${theme.COLOR_CALENDAR_AVAILABLE};
        color: #000;

        &:hover,
        &:focus {
          background-color: ${theme.COLOR_CALENDAR_AVAILABLE};
        }`;
    }

    if (inRange) {
      return `
        background-color: ${theme.COLOR_BRAND_PRIMARY};
        color: #fff;

        &:hover {
          background-color: ${theme.COLOR_BRAND_PRIMARY_DARK};
        }`;
    }

    return `
      &:hover {
        background-color: #eee;
      }

      &:focus {
        background-color: #eee;
        outline: none;
      }
    `;
  }}
`;

export const BorderedButton = styled(Button)`
  background-color: ${({ visible, theme }) => (visible ? theme.COLOR_GRAY_LIGHTER : theme.COLOR_GRAY_LIGHT)};
  border: 1px solid rgba(255, 255, 255, 0.5);

  ${({
    availabilitySearchLoading,
    disabled,
    inRange,
    selected,
    theme,
    notAllowed,
    isSelectedEventClosed,
    isSelectedEventFull,
    hasPrice,
    isLastSelectedDay,
    isSearchFormPicker,
  }) => {
    if (availabilitySearchLoading) {
      return '';
    }

    if ((selected || inRange) && (isSelectedEventClosed || isSelectedEventFull)) {
      return `
        background-color: ${theme.COLOR_DANGER};
        color: #fff;

        &:hover {
          background-color: ${theme.COLOR_DANGER};
        }`;
    }

    if (disabled) {
      return `
        background-color: #fff;
        color: rgba(0, 0, 0, 0.25);
      `;
    }

    // Styling for last selected date which has a 50/50 background
    if (
      !isSearchFormPicker
      && isLastSelectedDay
      && !isSelectedEventClosed
      && !isSelectedEventFull
    ) {
      return `
        background: linear-gradient(to bottom, ${theme.COLOR_CALENDAR_SELECTED} 50%, ${theme.COLOR_CALENDAR_AVAILABLE} 50%);
        color: #fff;
        border-bottom: 1px solid #FFF;

        &:hover,
        &:focus {
          background: linear-gradient(to bottom, ${theme.COLOR_CALENDAR_SELECTED} 50%, ${theme.COLOR_CALENDAR_AVAILABLE} 50%);
        }`;
    }

    if (selected) {
      return `
        background-color: ${theme.COLOR_CALENDAR_SELECTED};
        color: #fff;

        &:hover,
        &:focus {
          background-color: ${theme.COLOR_CALENDAR_SELECTED};
        }`;
    }

    if (isSelectedEventFull) {
      return `
      background-color: ${theme.COLOR_CALENDAR_FULL_FILL};
      color: ${theme.COLOR_CALENDAR_FULL_TEXT};

      &:hover,
      &:focus {
        background-color: ${theme.COLOR_CALENDAR_FULL_FILL};
      }`;
    }

    if (inRange) {
      return `
        background-color: ${theme.COLOR_CALENDAR_SELECTED};
        color: #fff;

        &:hover {
          background-color: ${theme.COLOR_BRAND_PRIMARY_DARK};
        }`;
    }

    if (notAllowed) {
      return `
        background-color: ${theme.COLOR_GRANITE_GRAY};
        color: ${theme.COLOR_WHITE};

        &:hover,
        &:focus {
          background-color: ${theme.COLOR_GRANITE_GRAY};
        }`;
    }

    if (hasPrice) {
      return `
        background-color: ${theme.COLOR_CALENDAR_AVAILABLE};
        color: #000;

        &:hover,
        &:focus {
          background-color: ${theme.COLOR_CALENDAR_AVAILABLE};
        }`;
    }

    return '';
  }}
`;

export const ButtonBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

const mobileDay = `
  padding: 0px 3px 0 0;
  font-size: 0.6rem;
`;

const desktopDay = `
  padding: 3px 5px 0 0;
  font-size: 0.875rem;
`;

export const Day = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  ${({ withMonth }) => withMonth && css`
    top: 5px;
    width: 50%;
    align-items: flex-end;
    flex-direction: column-reverse;
  `}
  ${({ theme }) => below(theme.TABLET)`
    ${mobileDay}
  `}
  ${({ theme }) => between(theme.TABLET, theme.DESKTOP_LARGE)`
    ${mobileDay}
  `}
  ${({ theme }) => above(theme.DESKTOP_LARGE)`
    ${desktopDay}
  `}
`;

const mobileTextSmall = `
  font-size: 0.6rem;
`;

const mobileText = `
  font-size: 0.7rem;
`;

const desktopText = `
  font-size: 0.875rem;
`;

export const Text = styled.div`
  width: 100%;
  font-weight: 600;
  line-height: 2rem;

  ${({ contain }) => contain && `
    line-height: 1rem;
    padding-bottom: 0.25rem;
  `}
  ${({ theme }) => above(theme.TABLET)`
    font-size: 0.9375rem;
  `}
  ${({ theme, contain }) => below(theme.DESKTOP)`
    line-height: ${contain ? '1' : '1.75rem'};
  `}
  ${({ theme, contain }) => below(theme.MOBILE_LARGE)`
    ${contain ? mobileTextSmall : mobileText}
    line-height: ${contain ? '1' : '1.5rem'};
  `}
  ${({ theme }) => between(theme.MOBILE_LARGE, theme.TABLET)`
    ${desktopText}
  `}
  ${({ theme }) => between(theme.TABLET, theme.DESKTOP_LARGE)`
    ${mobileText}
  `}
  ${({ theme }) => above(theme.DESKTOP_LARGE)`
    ${desktopText}
  `}
  ${({ contain }) => contain && 'line-height: 1;'}
  ${({ theme }) => below(theme.MOBILE_MEDIUM)`
    ${mobileText}
  `}
`;

export const Month = styled.div`
  margin-left: 0.5rem;
  display: inline-block;
  line-height: 0.5;
  ${({ withMonth }) => withMonth && css`
    margin-left: 0;
    display: block;
  `}
`;

const mobileLowAvailability = `
  left: 2px;
  top: 2px;
  padding: 2px 2px 0px;
  line-height: 0.5rem;
  font-size: 0.5rem;
  border-radius: 1px;
`;

const desktopLowAvailability = `
  left: 5px;
  top: 5px;
  padding: 1px 3px;
  border-radius: 3px;
  font-size: 0.7rem;
  line-height: 0.875rem;
`;

export const LowAvailability = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.COLOR_LAKE_GREEN_ORIGINAL};
  ${({ highlight }) => highlight && css`
    background: ${({ theme }) => theme.COLOR_WHITE};
  `}
  ${({ theme }) => below(theme.TABLET)`
    ${mobileLowAvailability}
  `}
  ${({ theme }) => between(theme.MOBILE_LARGE, theme.TABLET)`
    ${desktopLowAvailability}
  `}
  ${({ theme }) => between(theme.TABLET, theme.DESKTOP_LARGE)`
    ${mobileLowAvailability}
  `}
  ${({ theme }) => above(theme.DESKTOP_LARGE)`
    ${desktopLowAvailability}
  `}
`;

const mobileClosed = `
  left: 1px;
  right: 1px;
  bottom: 5px;
  padding: 4px 4px 4px;
  line-height: 0.5rem;
  font-size: 0.55rem;
  border-radius: 1px;
`;

const desktopClosed = `
  left: 7px;
  right: 7px;
  bottom: 9px;
  padding: 1px 3px;
  border-radius: 3px;
  font-size: 0.7rem;
  line-height: 0.875rem;
`;

export const Closed = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.COLOR_WHITE};
  background: ${({ theme }) => theme.COLOR_CALENDAR_CLOSED_FILL};
  ${({ highlight }) => highlight && css`
    background: ${({ theme }) => theme.COLOR_WHITE};
    color: ${({ theme }) => theme.COLOR_LAKE_GREEN_ORIGINAL};
  `}
  ${({ highlight, isSelectedEventClosed }) => (highlight && isSelectedEventClosed) && css`
    background: ${({ theme }) => theme.COLOR_WHITE};
    color: ${({ theme }) => theme.COLOR_DANGER};
  `}
  ${({ theme }) => below(theme.TABLET)`
    ${mobileClosed}
  `}
  ${({ theme }) => between(theme.TABLET, theme.DESKTOP_LARGE)`
    ${mobileClosed}
  `}
  ${({ theme }) => above(theme.DESKTOP_LARGE)`
    ${desktopClosed}
  `}
`;

export const MemberOnlyIcon = styled(Icon)`
`;

export const CustomText = styled(Text)`
  padding-bottom: 0.25rem;
  line-height: 1.1;

  ${({ isSelectedEventContact, theme }) => isSelectedEventContact && below(theme.TABLET)`
    font-size: 0.7rem;
  `};

  ${({ isLastSelectedDay, isSelectedEventClosed, isSelectedEventFull }) => `
    color: ${(isLastSelectedDay && !isSelectedEventClosed && !isSelectedEventFull) ? '#333333' : 'inherit'};
  `}
`;
